import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import useLangSelector from '../../../Hooks/useLangSelector';
import ProductType from '../../../../types/ProductType';
import { ProductSlugMapSelector, ProductsSelector } from '../../../bucket/BucketSlice';
import { IndexedObjects, SlugToIdMapper } from '../../../../types/StoreTypes';

function getProduct(
  slug: string | null,
  productSlugMap: SlugToIdMapper | undefined,
  products: IndexedObjects<ProductType> | undefined,
): ProductType | null {
  if (productSlugMap && products && slug && productSlugMap[slug] && products[productSlugMap[slug]])
    return products[productSlugMap[slug]];
  return null;
}

export default function useProductBySlugProvider(
  slug: string | null,
  includeInSSR = true,
): [
  /// Categories
  ProductType | null,
  /// Request State
  DataRequestStateEnum,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();

  /// Redux Selectors ----------------------------------------------------------
  const productSlugMap = useLangSelector(useAppSelector(ProductSlugMapSelector));
  const products = useLangSelector(useAppSelector(ProductsSelector));

  /// Current hook state -------------------------------------------------------
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => null);
  const [reqState, , setRequestSign] = useDataProviderState('product-by-slug', slug);
  const [product, setProduct] = useState<ProductType | null>(() =>
    slug && productSlugMap && products ? getProduct(slug, productSlugMap, products) : null,
  );

  /// on slug change  ----------------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug) {
      setCurrentSlug(slug);
      let foundProduct: ProductType | null = null;
      if (slug) foundProduct = getProduct(slug, productSlugMap, products);
      setProduct(foundProduct);

      /// Order data if there is not matching category in the bucket  ------------
      if (slug) {
        setRequestSign(slug);
        if (foundProduct === null) orderData('product-by-slug', slug);
      }
    }
  }, [slug]);

  useEffect(() => {
    /// Make sure we are listening to effect hook
    if (product !== null) return;
    if (currentSlug) setProduct(getProduct(currentSlug, productSlugMap, products));
  }, [productSlugMap, products]);

  /// SSR Data  ----------------------------------------------------------------
  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('product-by-slug', slug);

  return [product, product ? DataRequestStateEnum.FULFILLED : reqState];
}
