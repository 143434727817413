import { useEffect, useState } from 'react';
import { PostType } from '../../../types/PostTypes';
import { DataRequestStateEnum } from '../DataProviderState';
import useDataProviderState from '../useDataProviderState';
import { useAppSelector } from '../../../redux/hooks';
import { CategorySlugMapSelector } from '../../bucket/BucketSlice';
import { PostsByCategorySelector } from '../../posts/PostsSlice';
import useDataProviderOrder from '../useDataProviderOrder';
import usePostsFiller from '../../Hooks/usePostsFiller';
import useDataProviderSSROrder from '../useDataProviderSSROrder';
import useLangSelector from '../../Hooks/useLangSelector';
import { IndexedObjects, PagedObjects, SlugToIdMapper } from '../../../types/StoreTypes';
function getCategoryIdBySlug(slug: string | null, categorySlugToId: SlugToIdMapper | undefined): number | null {
  if (slug && categorySlugToId) {
    if (categorySlugToId[slug]) return categorySlugToId[slug];
  }
  return null;
}

function getPostsByCategoryId(
  categoryId: number | null,
  pageNumber: number,
  postsByCategory: IndexedObjects<PagedObjects<number[]>> | undefined,
): number[] | null {
  if (categoryId && postsByCategory) {
    if (postsByCategory[categoryId] && postsByCategory[categoryId][pageNumber]) {
      return postsByCategory[categoryId][pageNumber];
    }
  }
  return null;
}

/**
 * if slug is set to null, requests will not be fired
 *
 * @param slug
 * @param pageNumber
 * @param includeInSSR
 */

export default function useFCPostsByCategorySlugProvider(
  slug: string | null,
  pageNumber: number,
  includeInSSR = true,
): [
  /// Posts by category
  PostType[],
  /// Posts Request status,
  DataRequestStateEnum,
] {
  const orderData = useDataProviderOrder();
  const postFiller = usePostsFiller();

  /// Selectors ----------------------------------------------------------------
  const categorySlugToId = useLangSelector(useAppSelector(CategorySlugMapSelector));
  const postsByCategory = useLangSelector(useAppSelector(PostsByCategorySelector));

  /// Request info  ------------------------------------------------------------
  const createRequestSign = (slug: string, pageNumber: number) => `${slug}|${pageNumber}`;
  const [reqState, , setReqSign] = useDataProviderState(
    'posts-by-category-slug',
    slug ? createRequestSign(slug, pageNumber) : null,
  );

  /// first run setup ----------------------------------------------------------
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => slug);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(() => pageNumber);
  const [currentCategoryId, setCurrentCategoryId] = useState<number | null>(() =>
    getCategoryIdBySlug(slug, categorySlugToId),
  );
  const [currentPosts, setCurrentPosts] = useState<PostType[] | null>(() => {
    const posts = getPostsByCategoryId(currentCategoryId, pageNumber, postsByCategory);
    if (posts) return postFiller(posts);
    return null;
  });

  /// useEffect on input params ------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug || pageNumber !== currentPageNumber) {
      setCurrentSlug(slug);
      setCurrentPageNumber(pageNumber);
      if (slug === null) {
        setCurrentCategoryId(null);
        setCurrentPosts(null);
        setReqSign(null);
        return;
      }

      const newCategoryId = getCategoryIdBySlug(slug, categorySlugToId);
      const newPosts = getPostsByCategoryId(newCategoryId, pageNumber, postsByCategory);

      if (newPosts) setCurrentPosts(postFiller(newPosts));
      else setCurrentPosts(null);
      setCurrentCategoryId(newCategoryId);
      setReqSign(createRequestSign(slug, pageNumber));
    }
  }, [slug, pageNumber]);

  /// useEffect on Selectors  --------------------------------------------------
  useEffect(() => {
    if (currentCategoryId === null) setCurrentCategoryId(getCategoryIdBySlug(currentSlug, categorySlugToId));
    if (currentPosts === null) {
      const newPosts = getPostsByCategoryId(currentCategoryId, pageNumber, postsByCategory);
      if (newPosts) setCurrentPosts(postFiller(newPosts));
    }
  }, [categorySlugToId, postsByCategory]);

  /// useEffect on reqState ----------------------------------------------------
  useEffect(() => {
    if (currentSlug && currentPosts === null && reqState === DataRequestStateEnum.NOT_FOUND) {
      orderData('posts-by-category-slug', createRequestSign(currentSlug, currentPageNumber));
    }
  }, [reqState]);

  /// SSR ----------------------------------------------------------------------
  ENV_SSR &&
    includeInSSR &&
    useDataProviderSSROrder('posts-by-category-slug', slug ? createRequestSign(slug, currentPageNumber) : null);

  /// Result  ------------------------------------------------------------------
  return [currentPosts || [], reqState];
}
