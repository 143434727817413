import { LanguageWrapper, LPagedObjects } from '../../types/StoreTypes';
import { AuthorType, PagingInfoType } from '../../types/PostTypes';

export interface AuthorsState {
  latestAuthors: LPagedObjects<number[]>;
  latestAuthorsPaging: LanguageWrapper<PagingInfoType>;
}

export const AuthorsQueries = {
  getAvailablePagesForLatest: (state: AuthorsState): number[] => Object.keys(state.latestAuthors).map(Number),
  getLatestAuthors: (state: AuthorsState): LPagedObjects<number[]> => state.latestAuthors,
  getLatestPagingInfo: (state: AuthorsState): LanguageWrapper<PagingInfoType> => state.latestAuthorsPaging,
};

export const AuthorsReducers = {
  setLatest: (state: AuthorsState, pageNumber: number, categories: AuthorType[], language: string): void => {
    state.latestAuthors[language] = state.latestAuthors[language] || {};
    state.latestAuthors[language][pageNumber] = categories.map((author: AuthorType) => author.id);
  },
  setLatestPagingInfo: (state: AuthorsState, pagingInfo: PagingInfoType, language: string): void => {
    state.latestAuthorsPaging[language] = state.latestAuthorsPaging[language] || {};
    state.latestAuthorsPaging[language] = pagingInfo;
  },
};
