import { useEffect, useState } from 'react';
import { CategoryType, PostType } from '../../types/PostTypes';
import { CategoryTypeEnum } from '../simple/CategoryBadge';

export type FilteredCategoryType = {
  type: CategoryType;
  CategoryTypeEnum: CategoryTypeEnum;
  PostListItemHighlightType: string;
};

export function useFilterCategory(
  post: PostType,
  slug: string,
  categoryTypeAssigned: CategoryTypeEnum,
): FilteredCategoryType | undefined {
  const [currentPost, setCurrentPost] = useState<PostType>(post);
  const [filteredCategory, setFilteredCategory] = useState<FilteredCategoryType | undefined>(undefined);

  useEffect(() => {
    setCurrentPost(post);
  }, [post]);

  useEffect(() => {
    const filteredCategories = currentPost.categories?.filter((category: CategoryType) => category.slug === slug);
    if (filteredCategories && filteredCategories.length > 0) {
      setFilteredCategory({
        type: filteredCategories[0],
        CategoryTypeEnum: categoryTypeAssigned,
        PostListItemHighlightType: `PostListItem--highlight-${slug}`,
      });
    }
  }, []);

  return filteredCategory;
}
