import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LMenuItemsByGroup, MenuQueries, MenuReducers, MenuState } from './MenuState';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';

export const SLICE_NAME = 'Menu';

const initialState: MenuState = {
  menuByGroup: {},
};

export const MenuSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMenuByGroup: (state: MenuState, { payload }: PayloadAction<LMenuItemsByGroup>) => {
      Object.keys(payload).forEach((lang: string) => {
        Object.keys(payload[lang]).forEach((group: string) => {
          MenuReducers.setMenuByGroup(state, group, payload[lang][group], lang);
        });
      });
    },
  },
});

export const getMenuState = (state: RootState): MenuState => state[SLICE_NAME];

export const MenuByGroupSelector = createSelector(getMenuState, MenuQueries.getMenuByGroup);

export const { setMenuByGroup } = MenuSlice.actions;

export default MenuSlice.reducer;
