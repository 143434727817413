import React from 'react';
import { PostType } from '../../../../types/PostTypes';
import HeroPost from './HeroPost';
import BigHeroPost from './BigHeroPost';

export type HeroPostsProps = {
  bigPost: PostType;
  smallPosts: PostType[];
  title: string;
};

export default function HeroPosts({ bigPost, smallPosts, title }: HeroPostsProps): JSX.Element {
  return (
    <div className={'HeroPosts Layout--TwoColumn-Grid'}>
      <BigHeroPost post={bigPost} className={'p-6 pb-11 py-md-11 px-md-6 py-lg-16 pr-lg-23 pl-xl-0'} />

      <div className={'HeroPosts--side-posts p-6 pb-11 py-md-11 px-md-6 py-lg-16 pl-lg-23 pr-xl-0'}>
        <h2>{title}</h2>
        {smallPosts.map((post: PostType) => (
          <HeroPost key={post.id} post={post} className={'mt-6 mt-md-11'} />
        ))}
      </div>
    </div>
  );
}
