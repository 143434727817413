import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeTags } from '../../../../core/bucket/BucketSlice';
import { setLatestTags, setLatestTagsPagingInfo } from '../../../../core/tags/TagsSlice';
import { LObjectsByPage } from '../../../../types/PayloadActionTypes';
import { TagType } from '../../../../types/PostTypes';
import TagApi from '../../tag/TagApi';
import { WpTagOrderEnum } from '../../tag/WpTagOptions';
import { WpOrderEnum } from '../../post/WpPostOptions';

export function ProvideTagsByPageNumber(pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'all-tags',
      sign: '' + pageNumber,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (pageNumber: number): Promise<void> => {
      const tagApi = new TagApi(ENV_API_URL);
      await tagApi
        .setPerPage(100)
        .setOrderBy(WpTagOrderEnum.count)
        .setOrder(WpOrderEnum.Desc)
        .setPageNumber(pageNumber)
        .setLanguage(lang)
        .fetch();

      if (tagApi.result && tagApi.result.length > 0) {
        dispatch(storeTags({ [lang]: tagApi.result }));
        dispatch(
          setLatestTags({
            pageNumber: pageNumber,
            objects: tagApi.result,
            language: lang,
          } as LObjectsByPage<TagType>),
        );
        if (tagApi.pagingInfo) dispatch(setLatestTagsPagingInfo({ [lang]: tagApi.pagingInfo }));
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(pageNumber);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
