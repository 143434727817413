import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import { CategoryType } from '../../../../types/PostTypes';
import { CategoriesSelector, CategorySlugMapSelector } from '../../../bucket/BucketSlice';
import { IndexedObjects, SlugToIdMapper } from '../../../../types/StoreTypes';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import useLangSelector from '../../../Hooks/useLangSelector';

/**
 * Recognize "full" category, and result with it ONLY if there is one
 *  Categories are "full" if there is .meta field
 *
 * @param slug
 * @param slugToCategoryId
 * @param categories
 */
function getFullCategory(
  slug: string,
  slugToCategoryId: SlugToIdMapper | undefined,
  categories: IndexedObjects<CategoryType> | undefined,
): CategoryType | null {
  if (
    slugToCategoryId &&
    categories &&
    slugToCategoryId[slug] &&
    categories[slugToCategoryId[slug]] &&
    categories[slugToCategoryId[slug]].meta
  )
    return categories[slugToCategoryId[slug]];
  return null;
}

export default function useCategoryBySlugProvider(
  slug: string | null,
  includeInSSR = true,
): [
  /// Category object
  CategoryType | null,
  /// Request state
  DataRequestStateEnum,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();

  /// Redux Selectors ----------------------------------------------------------
  const categories = useLangSelector(useAppSelector(CategoriesSelector));
  const slugToCategoryId = useLangSelector(useAppSelector(CategorySlugMapSelector));

  /// Current hook state -------------------------------------------------------
  const [category, setCategory] = useState<CategoryType | null>(() =>
    slug && slugToCategoryId && categories ? getFullCategory(slug, slugToCategoryId, categories) : null,
  );
  const [reqState, , setRequestSign] = useDataProviderState('category-slug', slug);
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => null);

  /// on slug change  ----------------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug) {
      setCurrentSlug(slug);
      let foundCategory: CategoryType | null = null;
      if (slug) foundCategory = getFullCategory(slug, slugToCategoryId, categories);
      setCategory(foundCategory);

      /// Order data if there is not matching category in the bucket  ------------
      if (slug) {
        setRequestSign(slug);
        if (foundCategory === null) orderData('category-slug', slug);
      }
    }
  }, [slug]);

  useEffect(() => {
    /// Make sure we are listening to effect hook
    if (category !== null) return;
    if (currentSlug) setCategory(getFullCategory(currentSlug, slugToCategoryId, categories));
  }, [slugToCategoryId, categories]);

  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('category-slug', '' + slug);

  /// Hook result --------------------------------------------------------------
  return [category, category ? DataRequestStateEnum.FULFILLED : reqState];
}
