import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeAuthors, storeCategories, storePosts, storeTags } from '../../../../core/bucket/BucketSlice';
import { setLatestPosts, setLatestPostsPagingInfo } from '../../../../core/posts/PostsSlice';
import { AuthorType, CategoryType, TagType } from '../../../../types/PostTypes';
import FcPostsApi from '../../post/FcPostsApi';
import { FcOrderEnum } from '../../post/FcWpPostOptions';
import collectObjects from './CollectObjects';

export function ProvideLatestPosts(pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'latest-posts',
      sign: '' + pageNumber,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (pageNumber: number): Promise<void> => {
      const postApi = new FcPostsApi(ENV_API_URL);
      await postApi.setPage(pageNumber).orderBy(FcOrderEnum.Desc).setLanguage(lang).setPerPage(9).fetch();
      const posts = postApi.result;
      if (posts) {
        dispatch(storePosts({ [lang]: posts }));
        dispatch(
          setLatestPosts({
            language: lang,
            forObject: 1,
            pageNumber: pageNumber,
            objects: posts,
          }),
        );

        dispatch(
          storeCategories({
            [lang]: collectObjects<CategoryType>(posts, 'categories'),
          }),
        );
        dispatch(storeTags({ [lang]: collectObjects<TagType>(posts, 'tags') }));
        dispatch(storeAuthors({ [lang]: collectObjects<AuthorType>(posts, 'authors') }));
        if (postApi.pagingInfo) dispatch(setLatestPostsPagingInfo({ [lang]: postApi.pagingInfo }));
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(pageNumber);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
