import React from 'react';
import SWLink from '../../fuctional/SWLink';
import IconLink, { IconBorderEnum, IconFillEnum } from '../../simple/IconLink';
import HamburgerIcon from '../../../assets/svg/hamburger.svg';
import CloseIcon from '../../../assets/svg/close.svg';
import SearchIcon from '../../../assets/svg/search.svg';
import { MenuItemType } from '../../../types/MenuTypes';
import { ButtonTypeEnum } from '../../simple/Button';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import LogoColor from '../../../assets/svg/logo_color_large.svg';
import { useCurrentLanguage } from '../../../core/i18/LanguageContextProvider';
import { generateHomepage } from '../../router/GenerateUrl';

export type HorizontalMenuProps = {
  menuItems: { name: string; url: string }[];
  hamburgerHandler: () => void;
  hamburgerShown: boolean;
  searchHandler: () => void;
  stickyNavigationShown: boolean;
  className?: string;
};

export default function HorizontalMenu({
  menuItems,
  hamburgerHandler,
  hamburgerShown,
  searchHandler,
  stickyNavigationShown,
  className,
}: HorizontalMenuProps): JSX.Element {
  const language = useCurrentLanguage();
  const homepage = className !== 'storybook' ? generateHomepage(language.code) : '';
  return (
    <div className={`HorizontalMenu Layout--ThreeColumnCentered-Flex ${className ? className : ''}`}>
      <div className={'Layout__left'}>
        {stickyNavigationShown && (
          <SWLink to={homepage}>
            <LogoColor width={95} />
          </SWLink>
        )}
      </div>
      <div className={'Layout__center'}>
        <SkeletonTheme inline={true}>
          <ul className={'HorizontalMenu--menu-items'}>
            {menuItems.length > 0
              ? menuItems.map((item: MenuItemType, index) => (
                  <li key={index} className={'HorizontalMenu--menu-items-item px-2'}>
                    <SWLink to={item.url} className={'HorizontalMenu--menu-items-item-link'}>
                      {item.name}
                    </SWLink>
                  </li>
                ))
              : new Array(5).fill(0).map((_, index) => (
                  <li key={`menuSkeleton${index}`} className={'HorizontalMenu--menu-items-item px-2'}>
                    <Skeleton width={90} className={'HorizontalMenu--menu-items-item-link'} />
                  </li>
                ))}
          </ul>
        </SkeletonTheme>
      </div>
      <div className={'Layout__right'}>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          border={IconBorderEnum.NONE}
          component={'button'}
          className={`${ButtonTypeEnum.GHOST} HorizontalMenu--search mr-1`}
          onClick={searchHandler}
          aria-label={'Open Search'}
        >
          <SearchIcon />
        </IconLink>
        <IconLink
          fill={hamburgerShown ? IconFillEnum.WHITE : IconFillEnum.SECONDARY}
          border={hamburgerShown ? undefined : IconBorderEnum.PRIMARY}
          component={'button'}
          className={hamburgerShown ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.GHOST}
          onClick={hamburgerHandler}
          aria-label={hamburgerShown ? 'Open hamburger menu' : 'Close hamburger menu'}
        >
          {hamburgerShown ? <CloseIcon /> : <HamburgerIcon />}
        </IconLink>
      </div>
    </div>
  );
}
