import React from 'react';
import { AvatarCircleBorderStyle } from '../simple/AvatarCircle';
import Skeleton from 'react-loading-skeleton';

export type AvatarCircleSkeletonProps = {
  width: number;
  height: number;
  borderStyle: AvatarCircleBorderStyle;
  className?: string;
};

export default function AvatarCircleSkeleton({
  width,
  height,
  borderStyle,
  className,
}: AvatarCircleSkeletonProps): JSX.Element {
  return (
    <span className={`AvatarCircle ${className ? className : ''}`}>
      <Skeleton className={`AvatarCircle--image ${borderStyle}`} width={width} height={height} circle={true} />
    </span>
  );
}
