import React, { useEffect } from 'react';
import ProductPageBanner from '../sections/product/ProductPageBanner';
import ProductBottomBuy from '../sections/product/ProductBottomBuy';
import { DataRequestStateEnum } from '../../core/dataProviders/DataProviderState';
import InnerHTML from 'dangerously-set-html-content';
import useProductBySlugProvider from '../../core/dataProviders/providers/products/useProductBySlugProvider';
import { useHistory, useParams } from 'react-router-dom';
import { IRouteParams } from '../router/NewRoutes';
import use404Page from '../../core/Hooks/use404Page';
import { generateProductUrl } from '../router/GenerateUrl';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../simple/Button';
import SWLink from '../fuctional/SWLink';
import Skeleton from 'react-loading-skeleton';
import ProductPageBannerSkeleton from '../skeletons/ProductPageBannerSkeleton';
import ProductBottomBuySkeleton from '../skeletons/ProductBottomBuySkeleton';
import ProductPageSkeleton from '../skeletons/ProductPageSkeleton';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import Helmet from 'react-helmet';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';
import Image from '../simple/Image';

export default function ProductPage({ language }: IRouteParams): JSX.Element {
  const t = useTranslateMessage();
  const params = useParams<{ slug: string }>();
  const history = useHistory();
  const [product, reqState] = useProductBySlugProvider(params.slug);
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(product, reqState) ? product : null);

  useEffect(() => {
    if (hasValidData(product, reqState)) {
      setMetaDataPost(product);
      trackPageViewGTMEvent('product-detail', ['product-detail'], ['theFix']);
    }
  }, [reqState, product]);
  use404Page(reqState, language);

  useEffect(() => {
    if (product) {
      history.replace(generateProductUrl(product, language));
    }
  }, [product, language]);
  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <div className={'ProductPage'}>
        {reqState === DataRequestStateEnum.FULFILLED && product !== null ? (
          <ProductPageBanner product={product} className={'py-8 py-md-18 mb-7 mb-md-13'} />
        ) : (
          <ProductPageBannerSkeleton className={'py-8 py-md-18 mb-7 mb-md-13'} />
        )}
        <div className={'Layout--container'}>
          <div className={'ProductPage--Excerpt mb-7 mb-md-18'}>
            <div className={'ProductPage--Excerpt-Image py-20 px-20 py-md-15 pl-md-15 pr-md-0 mb-7 mb-md-0'}>
              {reqState === DataRequestStateEnum.FULFILLED && product !== null ? (
                <Image itemWithImages={product} className={'m-2'} />
              ) : (
                <Skeleton width={250} height={400} />
              )}
            </div>
            <div className={'ProductPage--Excerpt-Content'}>
              <p className={'ProductPage--Excerpt-Caption pb-6'}>Resources</p>
              {reqState === DataRequestStateEnum.FULFILLED && product !== null ? (
                <div className={'ProductPage--Excerpt-Text pb-0 pb-md-8'}>
                  {ENV_SSR ? (
                    <div dangerouslySetInnerHTML={{ __html: product.excerpt }} />
                  ) : (
                    <InnerHTML html={product.excerpt} />
                  )}
                </div>
              ) : (
                <p className={'ProductPage--Excerpt-Text pb-0 pb-md-8'}>
                  <Skeleton width={'80%'} count={4} />
                </p>
              )}
            </div>
          </div>

          <div className={'ProductPage--ExploreMore p-7 mb-18'}>
            <p className={'pb-5 pb-md-0'}>{t('productpage.more-resources')}</p>
            <Button
              btnType={ButtonTypeEnum.PRIMARY}
              width={ButtonWidthEnum.WIDE}
              height={ButtonHeightEnum.DEF}
              component={SWLink}
              to={'/research'}
            >
              {t('productpage.explore')}
            </Button>
          </div>

          <div className={'ProductPage--Wrapper'}>
            <div>
              {reqState === DataRequestStateEnum.FULFILLED && product !== null ? (
                <div className={'ProductPage--Content'}>
                  {ENV_SSR ? (
                    <div dangerouslySetInnerHTML={{ __html: product.description }} />
                  ) : (
                    <InnerHTML html={product.description} />
                  )}
                </div>
              ) : (
                <ProductPageSkeleton />
              )}
            </div>
            <div>
              {/*<div className={'ProductPage--Recommended p-11'}>
              <img
                className={'ProductPage--Recommended-Image mb-5'}
                width={45}
                height={45}
                src={'https://secure.gravatar.com/avatar/239b88349419b9fcfb951883f3e7568d?s=96&d=identicon&r=g'}
                alt={'...'}
              />
              <h4 className={'ProductPage--Recommended-Text pb-7'}>
                Full return to offices. Also, as Publishers could actually save money on the hybrid workplace with
                renting less space.
              </h4>
              <p className={'ProductPage--Recommended-Caption'}>Ebook recommended</p>
              <p className={'ProductPage--Recommended-By'}>
                <span>Elon Musk</span> (CEO Space X)
              </p>
            </div>*/}
            </div>
          </div>
        </div>
        {reqState === DataRequestStateEnum.FULFILLED && product !== null ? (
          <ProductBottomBuy product={product} className={'py-12 mt-5 mt-md-0'} />
        ) : (
          <ProductBottomBuySkeleton className={'py-12 mt-5 mt-md-0'} />
        )}
      </div>
    </>
  );
}
