import { WpTerm } from '../types/fcWpRawTypes';
import mapFcWpTag from './mapFcWpTag';

import { PostImage, PostType } from '../../../types/PostTypes';
import { FcWpRawPost } from '../types/fcWpRawTypes';
import mapFcWpCategory from './mapFcWpCategory';
import mapFcWpAuthor from './mapFcWpAuthor';

const ESTIMATED_TIME_REGEX = /<span class="rt-time">.*?([&lt;]?.*?\d+)/;

/**
 * wp:term is array of objects
 * as we dont care about the groups, this reduce function will flatten groups to one array of objects
 */
export function reduceWpTermToFlatArray(acc: WpTerm[], item: WpTerm[]): WpTerm[] {
  return [...acc, ...item];
}

/**
 * each WpTermType has property "taxonomy" that defines WpTermType subobject
 */
export function filterByTaxonomy(taxonomy: string): (item: WpTerm) => boolean {
  return (item: WpTerm): boolean => item.taxonomy === taxonomy;
}

/// mapper helper functions -------------------------------------------------------------------------
//
const mapAuthors = (source: FcWpRawPost, target: PostType) => {
  if (!source._embedded['author']) return;
  if (Array.isArray(source._embedded.author)) target.authors = source._embedded.author.map(mapFcWpAuthor);
  else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    target.authors = Object.keys(source._embedded.author)
      .filter((id: string) => source._embedded.author[Number(id)].id)
      .map((id: string) => mapFcWpAuthor(source._embedded.author[Number(id)]));
  }
};

const mapImages = (source: FcWpRawPost, target: PostType) => {
  if (!source._embedded['wp:featuredmedia'] || !source._embedded['wp:featuredmedia'].media_details) return;
  if (!source._embedded['wp:featuredmedia'].media_details.sizes) return;
  const images = source._embedded['wp:featuredmedia'].media_details.sizes;

  const toPostImage = (obj: any): PostImage => ({
    alt: obj.alt || '',
    width: obj.width,
    height: obj.height,
    url: obj.source_url,
  });

  // eslint-disable-next-line prettier/prettier
  if (images.medium_large) target.imageMediumLarge = toPostImage(images.medium_large);
  if (images.medium) target.imageMedium = toPostImage(images.medium);
  if (images.large) target.imageLarge = toPostImage(images.large);
  if (images.full) target.imageFull = toPostImage(images.full);
};

function getEstimatedReadTime(readingTimeHtml: string, content: string): number {
  const match = ESTIMATED_TIME_REGEX.exec(readingTimeHtml);
  if (match) {
    return match[1].trim() === '&lt; 1' ? 0 : Number(match[1]);
  }
  return Math.floor(content.split(' ').length / 300);
}

/// main mapper   -----------------------------------------------------------------------------------

export default function mapWpPost(wpPostResponse: FcWpRawPost): PostType {
  const result: PostType = {
    id: wpPostResponse.id,
    slug: wpPostResponse.slug,
    title: wpPostResponse.title.rendered,
    link: wpPostResponse.link,
    date: wpPostResponse.date,
    tags: wpPostResponse.tags.map(mapFcWpTag),
    categories: wpPostResponse.categories.map(mapFcWpCategory),
    meta: wpPostResponse.yoast_head_json,
    authors: [],
    readTime: getEstimatedReadTime(wpPostResponse.reading_time, wpPostResponse.content.rendered),
    translations: [],
  };

  if (result.meta.canonical) {
    result.meta.canonical = result.meta.canonical.replace(/admin.thefix.media/g, 'thefix.media');
  }

  if (result.meta.og_url) {
    result.meta.og_url = result.meta.og_url.replace(/admin.thefix.media/g, 'thefix.media');
  }

  if (wpPostResponse.content) {
    result.content = wpPostResponse.content.rendered;
  }

  if (wpPostResponse.excerpt) {
    result.excerpt = wpPostResponse.excerpt.rendered;
  }

  if (wpPostResponse.languages) {
    Object.keys(wpPostResponse.languages).forEach((lang: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // const matched = /(\d{4}\/\d{2}\/\d{2}\/.*?\/)/.exec(wpPostResponse.languages[lang].lang_url);
      // if (matched) {
      // const prefix = lang !== NewRouteList.DefaultLanguage ? '' : '/' + lang;
      result.translations.push({
        language: lang,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        slug: wpPostResponse.languages[lang].lang_slug,
      });
      // }
    });
  }

  mapImages(wpPostResponse, result);
  mapAuthors(wpPostResponse, result);

  if (wpPostResponse.header) result.header = wpPostResponse.header;
  if (wpPostResponse.footer) result.footer = wpPostResponse.footer;

  return result;
}
