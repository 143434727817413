import hasValidData from '../../../utils/hasValidData';
import HeroPosts from '../../compound/post/cards/HeroPosts';
import HeroPostsSkeleton from '../../skeletons/HeroPostsSkeleton';
import React from 'react';
import useFCPostsByCategorySlugProvider from '../../../core/dataProviders/providers/useFCPostsByCategorySlugProvider';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export default function FeaturedHeaderSection({ className }: { className?: string }): JSX.Element {
  const t = useTranslateMessage();
  const [featuredPosts, featuredPostsReqState] = useFCPostsByCategorySlugProvider(
    t('slug.category.featured'),
    1,
    false,
  );
  return (
    <header className={`FeaturedHeaderSection Layout--container ${className ? className : ''}`}>
      {hasValidData(featuredPosts, featuredPostsReqState) ? (
        <HeroPosts bigPost={featuredPosts[0]} smallPosts={[featuredPosts[1], featuredPosts[2]]} title="Featured" />
      ) : (
        <HeroPostsSkeleton />
      )}
    </header>
  );
}
