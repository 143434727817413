import React from 'react';
import { AuthorType } from '../../../../types/PostTypes';
import PostDetailAuthorName from './PostDetailAuthorName';
import PostDetailAuthorSocials from './PostDetailAuthorSocials';
import useMediaQuery, { mediaQueries } from '../../../../core/Hooks/useMediaQuery';
import ReactHtmlParser from 'react-html-parser';

export type PostDetailAuthorProps = {
  author: AuthorType;
  className?: string;
};

export default function PostDetailAuthor({ author, className }: PostDetailAuthorProps): JSX.Element {
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);

  return (
    <div className={`PostDetailAuthor ${className ? className : ''}`}>
      <div className={'PostDetailAuthor--NameWrapper pb-4'}>
        <PostDetailAuthorName author={author} />
        {matchesMediumUp && <PostDetailAuthorSocials author={author} />}
      </div>
      <p className={'PostDetailAuthor--Description pb-4 pb-md-0'}>{ReactHtmlParser(author.description) || ''}</p>
      {!matchesMediumUp && <PostDetailAuthorSocials author={author} />}
    </div>
  );
}
