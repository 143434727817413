import React from 'react';
import BigHeroPostSkeleton from './BigHeroPostSkeleton';
import HeroPostSkeleton from './HeroPostSkeleton';

export default function HeroPostsSkeleton(): JSX.Element {
  return (
    <div className={'HeroPosts Layout--TwoColumn-Grid '}>
      <BigHeroPostSkeleton className={`p-6 pb-11 py-md-11 px-md-6 py-lg-16 pr-lg-23 pl-xl-0`} />

      <div className={'HeroPosts--side-posts p-6 pb-11 py-md-11 px-md-6 py-lg-16 pl-lg-23 pr-xl-0'}>
        <h2>Featured</h2>
        <HeroPostSkeleton className={'mt-6 mt-md-11'} />
        <HeroPostSkeleton className={'mt-6 mt-md-11'} />
      </div>
    </div>
  );
}
