import React from 'react';

export type SubscribePageHeadProps = {
  className?: string;
};

export default function SubscribePageHead({ className }: SubscribePageHeadProps): JSX.Element {
  return (
    <div className={`SubscribePageHead pt-8 pb-12 pt-md-28 pb-md-28 ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <p className={'SubscribePageHead--Caption pb-2'}>Subscribe us</p>
        <h1 className={'SubscribePageHead--Title pb-3'}>The Fix Newsletters</h1>
        <p className={'SubscribePageHead--Description'}>
          Everything you need to know about European media market every week in your inbox
        </p>
      </div>
    </div>
  );
}
