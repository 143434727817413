import React from 'react';
import SubscribePageHead from '../sections/subscription/SubscribePageHead';
import PartnersPromo from '../sections/subscription/PartnersPromo';
import ChooseNewsletterForm from '../compound/newsletter/ChooseNewsletterForm';

export default function SubscriptionPage(): JSX.Element {
  return (
    <>
      <SubscribePageHead />
      <PartnersPromo />

      <div className={'Layout--container my-5 my-md-12'}>
        <ChooseNewsletterForm />
      </div>
    </>
  );
}
