import useDataProviderOrder from '../../useDataProviderOrder';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import { PostType } from '../../../../types/PostTypes';
import usePostsFiller from '../../../Hooks/usePostsFiller';
import { useEffect, useState } from 'react';
import useDataProviderState from '../../useDataProviderState';
import { useAppSelector } from '../../../../redux/hooks';
import { DataRequestStateEnum } from '../../DataProviderState';
import { PostsBySearchTermSelector } from '../../../posts/PostsSlice';
import useLangSelector from '../../../Hooks/useLangSelector';
import { IndexedObjectsByString, PagedObjects } from '../../../../types/StoreTypes';

function getPostsBySearchTerm(
  searchTerm: string | null,
  pageNumber: number,
  postsBySearchTerm: IndexedObjectsByString<PagedObjects<number[]>> | undefined,
): number[] {
  if (searchTerm && postsBySearchTerm) {
    if (postsBySearchTerm[searchTerm] && postsBySearchTerm[searchTerm][pageNumber]) {
      return postsBySearchTerm[searchTerm][pageNumber];
    }
  }
  return [];
}

export default function useFCPostsBySearchTermProvider(
  searchTerm: string | null,
  pageNumber: number,
): [
  /// Posts by category
  PostType[],
  /// Posts Request status,
  DataRequestStateEnum,
] {
  const orderData = useDataProviderOrder();
  const postFiller = usePostsFiller();

  /// Selectors ----------------------------------------------------------------
  const postsBySearchTerm = useLangSelector(useAppSelector(PostsBySearchTermSelector));

  /// Request info  ------------------------------------------------------------
  const createRequestSign = (slug: string, pageNumber: number) => `${slug}|${pageNumber}`;
  const [reqState, , setReqSign] = useDataProviderState(
    'posts-by-search-term',
    createRequestSign(searchTerm || '', pageNumber),
  );

  /// first run setup ----------------------------------------------------------
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string | null>(() =>
    searchTerm !== null ? encodeURI(searchTerm) : null,
  );
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(() => pageNumber);
  const [currentPosts, setCurrentPosts] = useState<PostType[]>(() =>
    postFiller(getPostsBySearchTerm(searchTerm, pageNumber, postsBySearchTerm)),
  );

  /// useEffect on input params ------------------------------------------------
  useEffect(() => {
    if (searchTerm !== currentSearchTerm || pageNumber !== currentPageNumber) {
      setCurrentSearchTerm(searchTerm);
      setCurrentPageNumber(pageNumber);
      if (searchTerm === null) {
        setCurrentPosts([]);
        setReqSign(null);
        return;
      }

      const newPosts = getPostsBySearchTerm(searchTerm, pageNumber, postsBySearchTerm);

      setCurrentPosts(postFiller(newPosts));
      setReqSign(createRequestSign(searchTerm, pageNumber));
    }
  }, [searchTerm, pageNumber]);

  /// useEffect on Selectors  --------------------------------------------------
  useEffect(() => {
    if (currentSearchTerm && currentPosts.length === 0) {
      const newPosts = getPostsBySearchTerm(currentSearchTerm, pageNumber, postsBySearchTerm);
      setCurrentPosts(postFiller(newPosts));
    }
  }, [postsBySearchTerm]);

  /// useEffect on reqState ----------------------------------------------------
  useEffect(() => {
    if (currentSearchTerm && currentPosts.length === 0 && reqState === DataRequestStateEnum.NOT_FOUND) {
      orderData('posts-by-search-term', createRequestSign(currentSearchTerm, currentPageNumber));
    }
  }, [reqState]);

  /// SSR ----------------------------------------------------------------------
  if (ENV_SSR && searchTerm) useDataProviderSSROrder('posts-by-search-term', createRequestSign(searchTerm, pageNumber));

  return [currentPosts, reqState];
}
