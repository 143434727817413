import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IndexedObjects, PagedObjects, SlugToIdMapper } from '../../types/StoreTypes';
import { CategoryType } from '../../types/PostTypes';
import { RootState } from '../store';
import { createSelector } from 'reselect';
import { ObjectsByPage } from '../../types/PayloadActionTypes';

const NAME = 'categories';

export interface CategoriesState {
  categories: IndexedObjects<CategoryType>;
  slugToId: SlugToIdMapper;
  categoriesByPage: PagedObjects<number[]>;
}

const initialState: CategoriesState = {
  categories: {},
  slugToId: {},
  categoriesByPage: {},
};

export const categoriesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addCategory: (state: CategoriesState, { payload }: PayloadAction<CategoryType>) => {
      state.slugToId[payload.slug] = payload.id;
      state.categories[payload.id] = payload;
    },

    setByPage: (state: CategoriesState, { payload }: PayloadAction<ObjectsByPage<CategoryType>>) => {
      state.categoriesByPage[payload.pageNumber] = payload.objects.map((category: CategoryType) => category.id);
      payload.objects.forEach((category: CategoryType) => {
        state.slugToId[category.slug] = category.id;
        state.categories[category.id] = category;
      });
    },
  },
});

export const getCategoriesState = (state: RootState): CategoriesState => state[NAME];

export const categoriesSelector = createSelector(getCategoriesState, (state: CategoriesState) => state.categories);

export const slugToCategoryIdSelector = createSelector(getCategoriesState, (state: CategoriesState) => state.slugToId);

export interface CategoriesDispatch {
  addCategory: (category: CategoryType) => void;
  setByPage: (categoriesByPage: ObjectsByPage<CategoryType>) => void;
}

export const categoriesByPageSelector = createSelector(
  getCategoriesState,
  (state: CategoriesState) => state.categoriesByPage,
);

export const { addCategory, setByPage } = categoriesSlice.actions;

export default categoriesSlice.reducer;
