import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import ProvideMenuBySlugs from './actions/ProvideMenuBySlugs';

export default function useMenuBySlugDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('menu-by-slug', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) => {
      /// we can fetch all the slugs there are, at the same time
      if (pendingOrders[lang].length > 0) dispatch(ProvideMenuBySlugs(pendingOrders[lang], lang));
    });
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) => {
      /// we can fetch all the slugs there are, at the same time
      if (pendingOrders[lang].length > 0) dispatch(ProvideMenuBySlugs(pendingOrders[lang], lang));
    });
  }
}
