import { MenuItemType } from '../../types/MenuTypes';

export interface MenuItemsByGroup {
  [slug: string]: MenuItemType[];
}
export interface LMenuItemsByGroup {
  [lng: string]: {
    [slug: string]: MenuItemType[];
  };
}

export interface MenuState {
  menuByGroup: LMenuItemsByGroup;
}

export const MenuQueries = {
  getMenuByGroup: (state: MenuState): LMenuItemsByGroup => state.menuByGroup,
};

export const MenuReducers = {
  setMenuByGroup: (state: MenuState, group: string, items: MenuItemType[], language: string): void => {
    state.menuByGroup[language] = state.menuByGroup[language] || {};
    state.menuByGroup[language][group] = items;
  },
};
