import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import usePageBySlugProvider from '../../core/dataProviders/providers/pages/usePageBySlugProvider';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import Skeleton from 'react-loading-skeleton';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import InjectScripts from '../fuctional/InjectScripts';
import use404Page from '../../core/Hooks/use404Page';
import { IRouteParams } from '../router/NewRoutes';
import { generatePageUrl } from '../router/GenerateUrl';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function PagePage({ language }: IRouteParams): JSX.Element {
  const params = useParams<{ slug: string }>();
  const history = useHistory();
  const [page, reqState, setPageSlug] = usePageBySlugProvider(params.slug);
  const [metaData, setMetaData] = useParseWpMetaData(null);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const location = useLocation();

  use404Page(reqState, language);

  useEffect(() => {
    setPageSlug(params.slug);
  }, [params]);

  useEffect(() => {
    if (hasValidData(page, reqState)) {
      setMetaData(page);
      trackPageViewGTMEvent('page-detail', ['page-detail'], ['theFix']);
    }
  }, [reqState, page]);

  useEffect(() => {
    hasValidData(page, reqState) && setAnchor(document.getElementById(location.hash.substring(1)));
  }, [location, reqState, page]);

  useEffect(() => {
    if (anchor !== null) {
      const headerOffset = 70;
      const elementPosition = anchor.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [anchor]);

  useEffect(() => {
    if (page) {
      history.replace(generatePageUrl(page, language) + location.hash);
    }
  }, [page, language]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      {/* external scripts  -------------------------------------------------------------- */}
      {page && page.footer && page.header && <InjectScripts header={page.header} footer={page.footer} />}
      {/* -------------------------------------------------------------------------------- */}

      <div className={'PagePage'}>
        <LeftAlignedPageBanner
          title={true}
          requestState={reqState}
          titleValue={page?.title}
          description={false}
          topics={false}
        />
        <div className={'Layout--container'}>
          {hasValidData(page, reqState) ? (
            <div className={'PagePage--Content'} dangerouslySetInnerHTML={{ __html: page.content || '' }} />
          ) : (
            <div className={'PagePage--Content'}>
              <h1>
                <Skeleton />
              </h1>
              <p>
                <Skeleton count={3} />
              </p>
              <p>
                <Skeleton count={4} />
              </p>
              <p>
                <Skeleton count={3} />
              </p>
              <p>
                <Skeleton count={2} />
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
