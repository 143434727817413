import React from 'react';
import Time from '../../assets/svg/time.svg';
import { PostType } from '../../types/PostTypes';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';

export type EstimatedReadTimeProps = {
  post: PostType;
};

export default function EstimatedReadTime({ post }: EstimatedReadTimeProps): JSX.Element {
  const t = useTranslateMessage();
  const formattedTime =
    typeof t('fn.formatReadingTime') === 'function' ? t('fn.formatReadingTime')(post.readTime) : '3 minutes';

  return (
    <div className={'EstimatedReadTime'}>
      <Time />
      <span>{formattedTime}</span>
    </div>
  );
}
