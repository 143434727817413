import React from 'react';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import ProductType from '../../../types/ProductType';
import { useSetProductQuantityInCart } from '../../../core/Cart/LocalStorage/CartContextProvider';
import { useHistory } from 'react-router-dom';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import Image from '../../simple/Image';

export type ProductBottomBuyProps = {
  product: ProductType;
  className?: string;
};

export default function ProductBottomBuy({ className, product }: ProductBottomBuyProps): JSX.Element {
  const t = useTranslateMessage();
  const history = useHistory();
  const setQuantity = useSetProductQuantityInCart();
  const language = useCurrentLanguage();
  const handleBuy = () => {
    setQuantity(`${product.id}`, 1);
    language.code !== 'en' ? history.push(`/${language.code}/cart`) : history.push('/cart');
  };
  return (
    <div className={`ProductBottomBuy ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <div className={'ProductBottomBuy--Wrapper'}>
          <Image itemWithImages={product} className={'ProductBottomBuy--Image'} />
          <div className={'ProductBottomBuy--Headlines'}>
            <p className={'ProductBottomBuy--Caption pb-2'}>{t('productpage.buy-resource')}</p>
            <h2 className={'ProductBottomBuy--Title pb-4'}>{t('productpage.you-ready')}</h2>
            {product.price > 0 ? (
              <Button
                btnType={ButtonTypeEnum.PRIMARY}
                width={ButtonWidthEnum.WIDE}
                height={ButtonHeightEnum.DEF}
                onClick={handleBuy}
              >
                {t('productpage.get-it-now')} {product.price}€
              </Button>
            ) : (
              <Button
                btnType={ButtonTypeEnum.PRIMARY}
                width={ButtonWidthEnum.WIDE}
                height={ButtonHeightEnum.DEF}
                onClick={handleBuy}
              >
                {t('productpage.download-for-free')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
