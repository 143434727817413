import React, { useEffect, useState } from 'react';
import useAllCategories from '../../core/dataProviders/providers/categories/useAllCategoriesProvider';
import FeaturedHeaderSection from '../sections/homepage/FeaturedHeaderSection';
import usePageBySlugProvider from '../../core/dataProviders/providers/pages/usePageBySlugProvider';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import { IRouteParams } from '../router/NewRoutes';
import TopStoriesSection from '../sections/homepage/TopStoriesSection';
import LatestPostsSection from '../sections/homepage/LatestPostsSection';
import SubscribeToNewsletter from '../sections/homepage/SubscribeToNewsletter';
import MostPopularSection from '../sections/homepage/MostPopularSection';
//import LatestProductsSection from '../sections/homepage/LatestProductsSection';
import OpinionsSection from '../sections/homepage/OpinionsSection';
import TechnologySection from '../sections/homepage/TechnologySection';
import IndustrySection from '../sections/homepage/IndustrySection';
import AdvertisementBlock from '../fuctional/AdvertismentBlock';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function Homepage({ language, routeName }: IRouteParams): JSX.Element {
  const t = useTranslateMessage();
  useAllCategories(1, false);

  const [page, reqState] = usePageBySlugProvider(t('slug.page.home'));
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(page, reqState) ? page : null);
  const [currentScroll, setCurrentScroll] = useState<number>(0);
  useEffect(() => {
    if (hasValidData(page, reqState)) {
      setMetaDataPost(page);
      trackPageViewGTMEvent('homepage', ['homepage'], ['theFix']);
    }
  }, [reqState, page]);

  const handleScrollLocation = () => {
    setCurrentScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollLocation);
    return () => window.removeEventListener('scroll', handleScrollLocation);
  }, []);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <FeaturedHeaderSection className={'p-0 mb-7 mb-md-13'} />

      <TopStoriesSection className={'mb-2 mb-md-4'} currentScroll={currentScroll} />

      <LatestPostsSection currentScroll={currentScroll} />

      <SubscribeToNewsletter className={'mb-4 mb-md-9'} />

      <MostPopularSection className={'mb-9 mb-md-12'} currentScroll={currentScroll} />

      <div className={'Layout--container'}>
        <AdvertisementBlock id={'protag-in_feed_2'} />
      </div>

            {/*<LatestProductsSection className={'mb-9 mb-md-12'} title={true} />*/}

      <OpinionsSection
        IRouteParams={{ language, routeName }}
        className={'mb-9 mb-md-4'}
        currentScroll={currentScroll}
      />

      <TechnologySection
        IRouteParams={{ language, routeName }}
        className={'mb-9 mb-md-4'}
        currentScroll={currentScroll}
      />

      <IndustrySection
        IRouteParams={{ language, routeName }}
        className={'mb-9 mb-md-4'}
        currentScroll={currentScroll}
      />
    </>
  );
}
