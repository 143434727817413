import React, { useEffect, useRef } from 'react';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';

export type SlideOutWrapperProps = {
  mediaQuery: mediaQueries;
  windowWidth: number;
  toggleSlideOut: () => void;
  className?: string;
  children: React.ReactNode;
};

export default function SlideOutWrapper({
  mediaQuery,
  windowWidth,
  toggleSlideOut,
  className,
  children,
}: SlideOutWrapperProps): JSX.Element {
  const matchesUp = useMediaQuery(mediaQuery, true);
  const wrapper = useRef() as React.MutableRefObject<HTMLInputElement>;

  function onWindowClickEventListener(event: React.MouseEvent) {
    /// everything outside of this menu (wrapper) will trigger toggleShareMenuFn
    if (wrapper.current && !wrapper.current.contains(event.target as Node)) {
      toggleSlideOut();
    }
  }

  useEffect(() => {
    /// we will create an event handler for "window" clicks
    /// it will be used to close open menus
    window.addEventListener('click', onWindowClickEventListener);

    /// unregister event listener
    return () => {
      window.removeEventListener('click', onWindowClickEventListener);
    };
  }, [wrapper]);

  useEffect(() => {
    const wrapperHeight = wrapper.current.getBoundingClientRect().height;
    wrapper.current.style.bottom = matchesUp ? `-${wrapperHeight}px` : 'inherit';
  }, [windowWidth]);

  return (
    <div className={`SlideOut SlideOut--Style ${className ? className : ''}`} ref={wrapper}>
      {children}
    </div>
  );
}
