import React from 'react';
import { TagType } from '../../types/PostTypes';
import TagBadge, { TagTypeEnum } from '../simple/TagBadge';
import Skeleton from 'react-loading-skeleton';
import BadgeSkeleton from '../skeletons/BadgeSkeleton';
import { DataRequestStateEnum } from '../../core/dataProviders/DataProviderState';
import ReactHtmlParser from 'react-html-parser';

export type LeftAlignedPageBannerProps = {
  caption?: string;
  className?: string;
  requestState?: DataRequestStateEnum;
} & ({ title: true; titleValue: string | undefined } | { title: false; titleValue?: never }) &
  ({ description: true; descriptionValue: string | undefined } | { description: false; descriptionValue?: never }) &
  ({ topics: true; topicsArray: TagType[] | undefined } | { topics: false; topicsArray?: never });

export default function LeftAlignedPageBanner({
  caption,
  requestState = DataRequestStateEnum.FULFILLED,
  title,
  titleValue,
  description,
  descriptionValue,
  topics,
  topicsArray,
  className,
}: LeftAlignedPageBannerProps): JSX.Element {
  return (
    <div className={`LeftAlignedPageBanner ${className ? className : ''}`}>
      <div className={'Layout--container mb-6 mb-md-12 pt-8 pb-12 pt-md-24 pb-md-24'}>
        {caption && <p className={'LeftAlignedPageBanner--Caption pb-3'}>{caption}</p>}
        {title && (
          <>
            {requestState === DataRequestStateEnum.FULFILLED ? (
              <h1 className={'LeftAlignedPageBanner--Title pb-3'}>
                {titleValue !== undefined ? ReactHtmlParser(titleValue) : ''}
              </h1>
            ) : (
              <h1 className={'LeftAlignedPageBanner--Title pb-3'}>
                <Skeleton width={120} />
              </h1>
            )}
          </>
        )}
        {description && (
          <>
            {requestState === DataRequestStateEnum.FULFILLED ? (
              <p className={'LeftAlignedPageBanner--Description'}>
                {descriptionValue !== undefined ? ReactHtmlParser(descriptionValue) : ''}
              </p>
            ) : (
              <p className={'LeftAlignedPageBanner--Description'}>
                <Skeleton width={450} />
              </p>
            )}
          </>
        )}
        {topics && (
          <>
            {topicsArray?.length !== 0 ? (
              <div className={'pt-4 pt-md-8'}>
                {topicsArray?.length !== 0 &&
                  topicsArray?.map((tag: TagType, i: number) => (
                    // until the API is not fixed, `key` will be indexed by order
                    <TagBadge key={i} tag={tag} type={TagTypeEnum.LIGHT_LESS} className={'mb-2 mr-2'} />
                  ))}
              </div>
            ) : (
              <div className={'pt-4 pt-md-8'}>
                <BadgeSkeleton placeholders={10} className={'mr-2 mb-2'} inline={true} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
