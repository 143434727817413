import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function CapitalCtaSkeleton({
  className,
  children,
}: {
  className?: string;
  children?: JSX.Element;
}): JSX.Element {
  return (
    <span className={`CapitalCta ${className ? className : ''}`}>
      {children} <Skeleton width={90} style={children && { marginLeft: '5px' }} />
    </span>
  );
}
