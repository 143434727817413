import React from 'react';
import { AvatarCircleBorderStyle } from '../simple/AvatarCircle';
import AvatarCircleSkeleton from './AvatarCircleSkeleton';
import Skeleton from 'react-loading-skeleton';

export default function PostDetailAuthorNameSkeleton(): JSX.Element {
  return (
    <div className={`PostDetailAuthorName`}>
      <AvatarCircleSkeleton className={'mr-4'} width={62} height={62} borderStyle={AvatarCircleBorderStyle.THICK} />
      <div className={'PostDetailAuthorName--AuthorName'}>
        <span className={'PostDetailAuthorName--AuthorName-By'}>Article By</span>
        <h2 className={'PostDetailAuthorName--AuthorName-Author mb-2'}>
          <Skeleton width={90} />
        </h2>
      </div>
    </div>
  );
}
