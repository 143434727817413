import React from 'react';
import Skeleton from 'react-loading-skeleton';
import EstimatedReadTimeSkeleton from './EstimatedReadTimeSkeleton';
import BadgeSkeleton from './BadgeSkeleton';
import DateAndAuthorSkeleton from './DateAndAuthorSkeleton';

export default function PostPageBannerSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`PostPageBanner py-8 py-md-13 ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <div className={'PostPageBanner--ImgWrapper mb-12'}>
          <Skeleton className={'PostPageBanner--Img'} />
        </div>

        <div className={'PostPageBanner--wrapper px-0 px-md-11'}>
          <div className={'PostPageBanner--caption pb-6 pb-md-13'}>
            <BadgeSkeleton className={'mr-4 mb-4 mb-sm-0'} placeholders={1} inline={true} />
            <DateAndAuthorSkeleton />
          </div>

          <h1 className={'PostPageBanner--title pb-5 pt-5'}>
            <Skeleton count={2} width={'80%'} />
          </h1>

          <div className={'PostPageBanner--tags pt-5 pb-10'}>
            <BadgeSkeleton placeholders={5} className={'mr-2 mb-2'} inline={true} />
          </div>

          <div className={'PostPageBanner--action'}>
            <Skeleton height={0} />
            <EstimatedReadTimeSkeleton />
          </div>
        </div>
      </div>
    </div>
  );
}
