export interface ILanguage {
  name: string;
  code: string;
  flag: string;
}

export const languages = [
  {
    name: 'English',
    code: 'en',
    flag: 'GbFlag',
  } as ILanguage,
  {
    name: 'Deutsch',
    code: 'de',
    flag: 'DeFlag',
  } as ILanguage,
];

export const messages: { [lng: string]: { [key: string]: any } } = {
  en: {
    en: 'English',
    de: 'German',
    'header.subtitle': 'Cracking the media management puzzle through insights, solutions and data',
    'login.label': 'Login',
    'newsletter.label': 'Newsletter',
    'register.label': 'Become a member',
    'all-topics.label': 'All Topics',
    'search.hint': 'Search keywords',
    'search.label': 'Search',
    'cation.category': 'Category',
    'caption.author': 'Author',
    'caption.topic': 'Topic',
    'caption.search': 'Search',
    'author.by': 'By',
    'author.article-by': 'Article by',
    'title.latest-in': 'Latest in',
    'title.latest-by': 'Latest by',
    'title.search-results-for': 'Search results for',
    'title.latest-stories': 'Latest Stories',
    'title.latest-posts': 'Latest posts',
    'title.list-of-all-topics': 'List of all Topics',
    'title.topic': 'Topic',
    'title.to-fix': 'To Fix',
    'title.favourites': 'Favourites',
    'title.trending-now': 'Trending now',
    'title.most-popular': 'Most popular',
    'title.all-articles': 'All articles',
    'title.the-fix-research': 'The Fix Research',
    'title.latest-research': 'Latest research',
    'category.industry-news': 'Industry news',
    'category.business-models': 'Business models',
    'category.organization-building': 'Organization building',
    'category.technology': 'Technology',
    'category.listicle': 'Listicle',
    'cta.explore-more': 'Explore more',
    'cta.explore-all': 'Explore all',
    'cta.view-more': 'View more',
    'cta.view-all': 'View all',
    'cta.read-more': 'Read more',
    'cta.learn-more': 'Learn more',
    'cta.buy-now': 'Buy now',
    'cta.buy': 'Buy',
    'cta.download': 'Download',
    'cta.read-post-in-this-language': 'Read in English.',
    'socials.share-now': 'Share now',
    'socials.normal': 'Did you like what you just read?',
    'socials.highlight': 'Spread it to the world!',
    'newsletter.follow-us': 'Follow us',
    'newsletter.title-normal': 'Everything you need to know about European media market',
    'newsletter.title-highlight': 'every week in your inbox',
    'newsletter.your-email-address': 'Your email address',
    'newsletter.invalid-email-address': 'Invalid email address',
    'newsletter.subscribe': 'Subscribe',
    'newsletter.sending': 'Subscribing you...',
    'newsletter.error': 'Error occurred. You are either already subscribed or try again later',
    'newsletter.success': 'You have been successfully subscribed to The Fix newsletter',
    'cookies.bottom-line1': 'We are using cookies to give you the best experience on our website.',
    'cookies.bottom-line2': 'You can find out more about which cookies we are using or switch them off in',
    'cookies.bottom-line2-cta': 'settings',
    'cookies.bottom-accept': 'Accept',
    'cookies.settings-overview': 'Privacy Overview',
    'cookies.settings-overview-text':
      'This website uses cookies so that we can provide you with the best user experience possible. Cookie information is stored in your browser and performs functions such as recognising you when you return to our website and helping our team to understand which sections of the website you find most interesting and useful.',
    'cookies.settings-necessary': 'Strictly Necessary Cookies',
    'cookies.settings-necessary-text':
      'Strictly Necessary Cookies should be enabled at all times so that we can save your preferences for cookie settings.',
    'cookies.settings-enable-all': 'Enable All',
    'cookies.settings-save-settings': 'Save Settings',
    'footer.menu': 'Menu',
    'footer.subtitle': 'Cracking the media management puzzle through insights, solutions and data',
    'footer.description':
      'News media is going through a massive transformation. The Grand Restructuring of Advertising Revenue has left many publications vulnerable, and the effects can be felt around the world.',
    'post.post-available-in-this-language': 'This article is also available in English',
    'text.or': 'or',
    'text.price': 'Price',
    'text.free': 'Free',
    'cart.product': 'Product',
    'cart.price': 'Price',
    'cart.quantity': 'Quantity',
    'cart.subtotal': 'Subtotal',
    'cart.cart-total': 'Cart total',
    'cart.checkout': 'Checkout',
    'cart.no-items': 'There are no items in your cart',
    'checkout.billing-fail': 'There is an issue with your billing information',
    'checkout.submit-fail': 'We were unable to submit your data',
    'checkout.finish-fail': 'We were unable to finish your order',
    'checkout.billing-details': 'Billing details',
    'checkout.checkout': 'Checkout',
    'checkout.fname': 'First name',
    'checkout.lname': 'Last Name',
    'checkout.company': 'Company',
    'checkout.address': 'Street address',
    'checkout.city': 'Town / City',
    'checkout.country': 'Country',
    'checkout.postcode': 'Postcode',
    'checkout.email': 'Email address',
    'checkout.your-order': 'Your order',
    'checkout.finish-order': 'Finish order',
    'checkout.continue-to-pay': 'Continue to payment',
    'checkout.processing': 'We are processing your information',
    'payment.pay': 'Pay',
    'payment.failed': 'There was a problem with your order.',
    'payment.thank-you': 'Thank you for your purchase.',
    'payment.thank-you-message': 'The report is sent your email address',
    'productpage.get-report': 'Get the report',
    'productpage.email': 'Your email',
    'productpage.proceed': 'Proceed to Checkout and',
    'productpage.more-resources': 'Hey, there are more researches by The Fix.',
    'productpage.explore': 'Explore more resources',
    'productpage.author': 'Resource author',
    'productpage.buy-resource': 'Buy resource',
    'productpage.you-ready': 'Hey, are you ready to get the Report?',
    'productpage.get-it-now': 'Get it now for',
    'productpage.download-for-free': 'Download for free',
    'attention.text':
      'We’ve just launched a new website! It’s in the testing mode, so if you notice any bugs or mistakes, please shoot us a letter at ',

    /// Slugs ------------------------------------------------------------------
    'slug.menu.main-menu': 'main-menu',
    'slug.menu.sub-menu': 'sub-menu',
    'slug.menu.footer-menu': 'footer-menu',
    'slug.menu.top-menu': 'top-menu',

    'slug.page.home': 'homepage',
    'slug.page.research': 'research',
    'slug.page.latest': 'latest',
    'slug.page.all-topics': 'all-topics',

    'slug.category.featured': 'featured',
    'slug.category.top-stories': 'top-stories',
    'slug.category.opinion': 'opinion',
    'slug.category.tech': 'tech',
    'slug.category.listicle': 'listicle',
    'slug.category.industry-news': 'industry-news',
    'slug.category.partner': 'partner-post',

    'fn.formatReadingTime': (time: number) => {
      if (time === 1) return '1 Minute';
      else if (time === 0) return 'Less than a minute';
      return time + ' Minutes';
    },
  },
  de: {
    en: 'English',
    de: 'Deutsch',
    'header.subtitle': 'Das Medienmanagement-Puzzle durch Erkenntnisse, Lösungen und Daten knacken',
    'login.label': 'Anmeldung',
    'newsletter.label': 'Newsletter',
    'register.label': 'Mitglied werden',
    'all-topics.label': 'Alle Themen',
    'search.hint': 'Suche Schlüsselwörter',
    'search.label': 'Suche',
    'cation.category': 'Kategorie',
    'caption.author': 'Autor',
    'caption.topic': 'Thema',
    'caption.search': 'Suchen',
    'author.by': 'Von',
    'author.article-by': 'Artikel von',
    'title.latest-in': 'Neueste im',
    'title.latest-by': 'Neueste nach',
    'title.search-results-for': 'Suchergebnisse für',
    'title.latest-stories': 'Neueste Geschichten',
    'title.latest-posts': 'Neueste Beiträge',
    'title.list-of-all-topics': 'Liste aller Themen',
    'title.topic': 'Thema',
    'title.to-fix': 'Zu Fix',
    'title.favourites': 'Favoriten',
    'title.trending-now': 'Gerade angesagt',
    'title.most-popular': 'Am beliebtesten',
    'title.all-articles': 'Alle Artikel',
    'title.the-fix-research': 'The Fix Forschung',
    'title.latest-research': 'Neueste Forschung',
    'category.industry-news': 'Branchennachrichten',
    'category.business-models': 'Geschäftsmodelle',
    'category.organization-building': 'Organisationsaufbau',
    'category.technology': 'Technologie',
    'category.listicle': 'Liste',
    'cta.explore-more': 'Erkunde mehr',
    'cta.explore-all': 'Alles erkunden',
    'cta.view-more': 'Mehr sehen',
    'cta.view-all': 'Alle ansehen',
    'cta.read-more': 'Weiterlesen',
    'cta.learn-more': 'Mehr erfahren',
    'cta.buy-now': 'Jetzt kaufen',
    'cta.buy': 'Kaufen',
    'cta.download': 'Herunterladen',
    'cta.read-post-in-this-language': 'Auf Deutsch lesen.',
    'socials.share-now': 'Jetzt teilen',
    'socials.normal': 'Hat Ihnen gefallen, was Sie gerade gelesen haben?',
    'socials.highlight': 'Verbreite es in der Welt!',
    'newsletter.follow-us': 'Folgen Sie uns',
    'newsletter.title-normal': 'Alles, was Sie jede Woche über den europäischen Medienmarkt',
    'newsletter.title-highlight': 'wissen müssen in Ihrem Posteingang',
    'newsletter.your-email-address': 'Ihre E-Mail-Adresse',
    'newsletter.invalid-email-address': 'Ungültige E-Mail-Adresse',
    'newsletter.subscribe': 'Abonnieren',
    'newsletter.sending': 'Dich abonnieren...',
    'newsletter.error':
      'Ein Fehler ist aufgetreten. Sie sind entweder bereits abonniert oder versuchen es später erneut',
    'newsletter.success': 'Sie haben den The Fix-Newsletter erfolgreich abonniert',
    'cookies.bottom-line1': 'Wir verwenden Cookies, um Ihnen das beste Erlebnis auf unserer Website zu bieten.',
    'cookies.bottom-line2': 'Sie können mehr darüber erfahren, welche Cookies wir verwenden, oder sie in den',
    'cookies.bottom-line2-cta': 'Einstellungen deaktivieren',
    'cookies.bottom-accept': 'Akzeptieren',
    'cookies.settings-overview': 'Datenschutzübersicht',
    'cookies.settings-overview-text':
      'Diese Website verwendet Cookies, damit wir Ihnen die bestmögliche Benutzererfahrung bieten können. Cookie-Informationen werden in Ihrem Browser gespeichert und führen Funktionen aus, wie das Wiedererkennen von Ihnen, wenn Sie auf unsere Website zurückkehren, und helfen unserem Team zu verstehen, welche Abschnitte der Website für Sie am interessantesten und nützlichsten sind.',
    'cookies.settings-necessary': 'Unbedingt notwendige Cookies',
    'cookies.settings-necessary-text':
      'Unbedingt erforderliche Cookies sollten jederzeit aktiviert sein, damit wir Ihre Einstellungen für Cookie-Einstellungen speichern können.',
    'cookies.settings-enable-all': 'Alle aktivieren',
    'cookies.settings-save-settings': 'Einstellungen speichern',
    'footer.menu': 'Menü',
    'footer.subtitle': 'Das Medienmanagement-Puzzle durch Erkenntnisse, Lösungen und Daten knacken',
    'footer.description':
      'Die Nachrichtenmedien durchlaufen einen massiven Wandel. Die große Umstrukturierung der Werbeeinnahmen hat viele Publikationen anfällig gemacht, und die Auswirkungen sind auf der ganzen Welt zu spüren.',
    'post.post-available-in-this-language': 'Dieser Artikel ist auch in deutsch erhältlich',
    'text.or': 'oder',
    'text.price': 'Preis',
    'text.free': 'Kostenlos',
    'cart.product': 'Produkt',
    'cart.price': 'Preis',
    'cart.quantity': 'Menge',
    'cart.subtotal': 'Zwischensumme',
    'cart.cart-total': 'Warenkorb insgesamt',
    'cart.checkout': 'Zur Kasse',
    'cart.no-items': 'Es befinden sich keine Artikel in Ihrem Warenkorb',
    'checkout.billing-fail': 'Es gibt ein Problem mit Ihren Rechnungsinformationen',
    'checkout.submit-fail': 'Wir konnten Ihre Daten nicht übermitteln',
    'checkout.finish-fail': 'Wir konnten Ihre Bestellung nicht abschließen',
    'checkout.billing-details': 'Rechnungsdetails',
    'checkout.checkout': 'Zur Kasse',
    'checkout.fname': 'Vorname',
    'checkout.lname': 'Nachname',
    'checkout.company': 'Firma',
    'checkout.address': 'Adresse',
    'checkout.city': 'Stadt',
    'checkout.country': 'Land',
    'checkout.postcode': 'Postleitzahl',
    'checkout.email': 'E-Mail-Adresse',
    'checkout.your-order': 'Ihre Bestellung',
    'checkout.finish-order': 'Bestellung abschließen',
    'checkout.continue-to-pay': 'Weiter zur Zahlung',
    'checkout.processing': 'Wir verarbeiten Ihre Informationen',
    'payment.pay': 'Bezahlen',
    'payment.failed': 'Es gab ein Problem mit Ihrer Bestellung.',
    'payment.thank-you': 'Vielen Dank für Ihren Einkauf.',
    'payment.thank-you-message': 'Der Bericht wird an Ihre E-Mail-Adresse gesendet',
    'productpage.get-report': 'Bericht abrufen',
    'productpage.email': 'Ihre E-Mail',
    'productpage.proceed': 'Zur Kasse gehen und',
    'productpage.more-resources': 'Hey, es gibt weitere Untersuchungen von The Fix.',
    'productpage.explore': 'Weitere Ressourcen entdecken',
    'productpage.author': 'Autor der Ressource',
    'productpage.buy-resource': 'Ressource kaufen',
    'productpage.you-ready': 'Hey, bist du bereit für den Bericht?',
    'productpage.get-it-now': 'Jetzt kaufen für',
    'productpage.download-for-free': 'Kostenlos herunterladen',
    'attention.text':
      'Wir haben gerade eine neue Website gestartet! Es befindet sich im Testmodus. Wenn Sie also Bugs oder Fehler bemerken, schreiben Sie uns bitte einen Brief an ',

    /// Slugs ------------------------------------------------------------------
    'slug.menu.main-menu': 'main-menu-de',
    'slug.menu.sub-menu': 'sub-menu-de',
    'slug.menu.footer-menu': 'footer-menu-de',
    'slug.menu.top-menu': 'top-menu-de',

    'slug.page.home': 'startseite',
    'slug.page.latest': 'neueste',
    'slug.page.research': 'research',
    'slug.page.all-topics': 'alle-themen',

    'slug.category.featured': 'featured-de',
    'slug.category.top-stories': 'top-stories-de',
    'slug.category.opinion': 'opinion-de',
    'slug.category.tech': 'tech-de',
    'slug.category.listicle': 'listicle-de',
    'slug.category.industry-news': 'industry-news-de',
    'slug.category.partner': 'partner-post-de',

    'fn.formatReadingTime': (time: number) => {
      if (time === 1) return '1 Minute';
      else if (time === 0) return 'Weniger als einer Minute';
      return time + ' Minuten';
    },
  },
};
