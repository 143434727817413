import { useEffect, useState } from 'react';
import { TagType } from '../../../types/PostTypes';
import { useAppSelector } from '../../../redux/hooks';
import { CategorySlugMapSelector } from '../../bucket/BucketSlice';
import useLangSelector from '../../Hooks/useLangSelector';
import { IndexedObjects, SlugToIdMapper } from '../../../types/StoreTypes';
import useTagsFiller from '../../Hooks/useTagsFiller';
import { TopTagsForCategory } from '../../tags/TagsSlice';
function getCategoryIdBySlug(slug: string | null, categorySlugToId: SlugToIdMapper | undefined): number | null {
  if (slug && categorySlugToId) {
    if (categorySlugToId[slug]) return categorySlugToId[slug];
  }
  return null;
}

function getTopTagsForCategoryId(
  categoryId: number | null,
  topTagsForCategory: IndexedObjects<number[]> | undefined,
): number[] {
  if (categoryId && topTagsForCategory) {
    if (topTagsForCategory[categoryId]) {
      return topTagsForCategory[categoryId];
    }
  }
  return [];
}

export default function useLazyFCTopTagsForCategorySlugProvider(slug: string | null): TagType[] {
  const tagsFiller = useTagsFiller();

  /// Selectors ----------------------------------------------------------------
  const categorySlugToId = useLangSelector(useAppSelector(CategorySlugMapSelector));
  const topTagsForCategory = useLangSelector(useAppSelector(TopTagsForCategory));

  /// first run setup ----------------------------------------------------------
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => slug);
  const [currentCategoryId, setCurrentCategoryId] = useState<number | null>(() =>
    getCategoryIdBySlug(slug, categorySlugToId),
  );
  const [currentTags, setCurrentTags] = useState<TagType[]>(() => {
    const tags = getTopTagsForCategoryId(currentCategoryId, topTagsForCategory);
    return tagsFiller(tags);
  });

  /// useEffect on input params ------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug) {
      setCurrentSlug(slug);
      if (slug === null) {
        setCurrentCategoryId(null);
        setCurrentTags([]);
        return;
      }

      const newCategoryId = getCategoryIdBySlug(slug, categorySlugToId);
      const newTags = getTopTagsForCategoryId(newCategoryId, topTagsForCategory);

      if (newTags.length > 0) setCurrentTags(tagsFiller(newTags));
      else setCurrentTags([]);
      setCurrentCategoryId(newCategoryId);
    }
  }, [slug]);

  /// useEffect on Selectors  --------------------------------------------------
  useEffect(() => {
    if (currentCategoryId === null) setCurrentCategoryId(getCategoryIdBySlug(currentSlug, categorySlugToId));
    if (currentTags.length === 0) {
      const newTags = getTopTagsForCategoryId(currentCategoryId, topTagsForCategory);
      if (newTags.length > 0) setCurrentTags(tagsFiller(newTags));
    }
  }, [categorySlugToId, topTagsForCategory]);

  /// Result  ------------------------------------------------------------------
  return currentTags;
}
