import { WpRawAuthor, WpRawYoastGraph } from '../types/wpRawTypes';
import { AuthorType } from '../../../types/PostTypes';

export default function mapWpAuthor(author: WpRawAuthor): AuthorType {
  const result = {
    id: author.id,
    slug: author.slug,
    name: author.name,
    meta: author.yoast_head_json,
    description: author.description,
    socials: {},
  } as AuthorType;

  if (author.avatar_urls) {
    Object.keys(author.avatar_urls).forEach((size: string) => {
      if (!author.avatar_urls) return;
      if (size === '24') result.avatar_small = author.avatar_urls[size];
      if (size === '48') result.avatar_medium = author.avatar_urls[size];
      if (size === '96') result.avatar_big = author.avatar_urls[size];
    });
  }

  if (author['yoast_head_json'] && author.yoast_head_json['schema'] && author.yoast_head_json.schema['@graph']) {
    author.yoast_head_json.schema['@graph']
      .filter((graph: WpRawYoastGraph) => graph['@type'] === 'Person')
      .forEach((graph: WpRawYoastGraph) => {
        if (graph.sameAs) {
          graph.sameAs.forEach((url: string) => {
            if (url.indexOf('facebook') > -1) result.socials['facebook'] = url;
            else if (url.indexOf('instagram') > -1) result.socials['instagram'] = url;
            else if (url.indexOf('linkedin') > -1) result.socials['linkedin'] = url;
            else if (url.indexOf('twitter') > -1) result.socials['twitter'] = url;
            else result.socials['wwww'] = url;
          });
        }
      });
  }

  return result;
}
