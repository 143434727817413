import React from 'react';
import { PostType } from '../../../../types/PostTypes';
import { PostDateAndAuthor } from '../PostDateAndAuthor';
import EstimatedReadTime from '../../../simple/EstimatedReadTime';
import { CategoryBadge, CategoryTypeEnum } from '../../../simple/CategoryBadge';
import SWLink from '../../../fuctional/SWLink';
import { postUrl } from '../../../router/routeList';
import { useFilterCategory } from '../../../hooks/useFilterCategory';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';
import Image from '../../../simple/Image';

export interface PostListItemProps {
  post: PostType;
  className?: string;
}

export default function PostListItem({ post, className }: PostListItemProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = postUrl(post, language.code);
  const t = useTranslateMessage();

  const partnerCategory = useFilterCategory(post, t('slug.category.partner'), CategoryTypeEnum.SPECIAL_ONE);
  const opinionCategory = useFilterCategory(post, t('slug.category.opinion'), CategoryTypeEnum.TERTIARY);
  const filteredCategory = partnerCategory || opinionCategory;

  return (
    <div
      className={`PostListItem ${className ? className : ''}  ${
        filteredCategory ? `PostListItem--highlight ${filteredCategory.PostListItemHighlightType}` : ''
      }`}
    >
      <figure className={'PostListItem--figure Layout--TwoColumn-Grid'}>
        <SWLink to={url} title={post.title} className={'PostListItem--figure-ImgWrapper'}>
          <Image itemWithImages={post} className={'PostListItem--figure-Img'} />
        </SWLink>
        <figcaption className={`PostListItem--figcaption ${filteredCategory ? 'p-8' : ''} pl-lg-8`}>
          <div className={'PostListItem--content'}>
            {filteredCategory && (
              <CategoryBadge
                type={filteredCategory?.CategoryTypeEnum}
                category={filteredCategory.type}
                className={'mb-0 mb-md-4'}
              />
            )}
            <PostDateAndAuthor post={post} showAuthorAvatar={false} className={'pt-6 pt-lg-0'} />
            <h3 className={'my-4 PostListItem--figcaption-Title'}>
              <SWLink to={url} title={post.title} className={'PostListItem--figcaption-TitleLink'}>
                {post.title}
              </SWLink>
            </h3>
          </div>
          <div className={'PostListItem--action pb-4'}>
            <span>&nbsp;</span>
            <EstimatedReadTime post={post} />
          </div>
        </figcaption>
      </figure>
    </div>
  );
}
