import { WpTagOptions, WpTagOrderEnum } from './WpTagOptions';
import { PagingInfoType, TagType } from '../../../types/PostTypes';
import axios from 'axios';
import mapWpTag from '../mappers/mapWpTag';
import mapWpPagingInfo from '../mappers/mapWpPagingInfo';
import { WpOrderEnum } from '../post/WpPostOptions';

const API_TAGS = '/wp-json/wp/v2/tags';

export default class TagApi {
  private options: WpTagOptions;
  public result?: TagType[];
  public pagingInfo?: PagingInfoType;

  constructor(private readonly wpHost: string) {
    this.options = {
      tagId: [],
      slug: [],
    };
  }

  setLanguage(language: string): TagApi {
    this.options.language = language;
    return this;
  }

  setPageNumber(pageNumber: number): TagApi {
    this.options.page = pageNumber;
    return this;
  }

  setPerPage(perPage: number): TagApi {
    this.options.perPage = perPage;
    return this;
  }

  setSlug(slug: string): TagApi {
    this.options.slug.push(slug);
    return this;
  }

  setTagId(tagId: number): TagApi {
    this.options.tagId.push(tagId);
    return this;
  }

  setMinimalResult(): TagApi {
    this.options._fields = 'id,name,slug,link,yoast_head';
    return this;
  }

  setOrderBy(orderBy: WpTagOrderEnum): TagApi {
    this.options.orderby = orderBy;
    return this;
  }

  setOrder(order: WpOrderEnum): TagApi {
    this.options.order = order;
    return this;
  }

  filterBySearchTerm(searchTerm: string): TagApi {
    this.options.search = searchTerm;
    return this;
  }

  generateUrl(): string {
    let result: string[] = [];
    if (this.options.tagId.length > 0) {
      result = [...result, ...this.options.tagId.map((id: number) => 'includes[]=' + id)];
    }
    if (this.options.slug.length > 0) {
      result = [...result, ...this.options.slug.map((slug: string) => 'slug[]=' + slug)];
    }
    if (this.options.perPage) result.push('per_page=' + this.options.perPage);
    if (this.options.page) result.push('page=' + this.options.page);
    // if (this.options.language) result.push('lng=' + this.options.language);
    if (this.options._fields) result.push('_fields=' + this.options._fields);
    if (this.options.order) result.push('order=' + this.options.order);
    if (this.options.orderby) result.push('orderby=' + this.options.orderby);

    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';

    return this.wpHost + lang + API_TAGS + '?' + result.join('&');
  }

  generateSearchUrl(): string {
    let result: string[] = [];
    if (this.options.search) {
      result = [...result, 'search=' + this.options.search];
    }
    if (this.options.page) {
      result = [...result, 'page=' + this.options.page];
    }
    if (this.options.perPage) {
      result = [...result, 'per_page=' + this.options.perPage];
    }

    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';
    return this.wpHost + lang + API_TAGS + '?' + result.join('&');
  }

  async fetch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-tag');
    const response = await axios.get(this.generateUrl());
    if (response.status === 200) {
      this.pagingInfo = mapWpPagingInfo(response.headers);
      this.result = response.data.map(mapWpTag);
      return;
    }
    throw new Error('request failed');
  }

  async fetchSearch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-tag-search');
    const response = await axios.get(this.generateSearchUrl());
    if (response.status === 200) {
      this.result = response.data.map(mapWpTag);
      this.pagingInfo = mapWpPagingInfo(response.headers);
      return;
    }
    throw new Error('request failed');
  }
}
