import React from 'react';
import { PostType } from '../../../../types/PostTypes';
import DividerWithArrow from '../../../../assets/svg/divider_with_arrow.svg';
import ReactHtmlParser from 'react-html-parser';

export type PostContentExcerptProps = {
  post: PostType;
  className?: string;
};

export default function PostContentExcerpt({ post, className }: PostContentExcerptProps): JSX.Element {
  return (
    <div className={`PostContentExcerpt ${className ? className : ''}`}>
      <div className={'Divider Divider--secondary Divider--thick Divider--short mb-8'} />
      {post.excerpt && <h3 className={'PostContentExcerpt-Text mb-7'}>{ReactHtmlParser(post.excerpt)}</h3>}
      <div className={'PostContentExcerpt--divider'}>
        <DividerWithArrow />
      </div>
    </div>
  );
}
