import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routeList, { IMultiLanguageRouteSpec, RoutePathInLanguage } from './NewRouteList';
import AppContextProvider from '../../core/app/AppContextProvider';
import PageContainer from '../pages/PageContainer';
import ErrorPage from '../pages/404';
import Homepage from '../pages/Homepage';

export interface IRouteParams {
  language: string;
  routeName: string;
  routeMeta?: unknown;
}

export interface IRoutePath {
  routeName: string;
  path: string;
  exact: boolean;
  language: string;
}

function PageWrapper(
  Component: React.ComponentType<IRouteParams>,
  lang: string,
  routeName: string,
  routeMeta?: unknown,
): () => JSX.Element {
  // eslint-disable-next-line react/display-name
  return (): JSX.Element => (
    <AppContextProvider>
      <PageContainer>
        <Component language={lang} routeName={routeName} routeMeta={routeMeta} />
      </PageContainer>
    </AppContextProvider>
  );
}

export default function NewRoutes(): JSX.Element {
  return (
    <Switch>
      {
        /// Homepage for each language -----------------------------------------
        routeList.Languages.map((lang: string) => (
          <Route key={'language_homepage_' + lang} path={routeList.HomepageURLS[lang]} exact={true}>
            {PageWrapper(Homepage, lang, 'HOMEPAGE', {})}
          </Route>
        ))
      }
      {
        /// URLS by languages---------------------------------------------------
        routeList.Paths.map((routeObj: IMultiLanguageRouteSpec) =>
          routeList.Languages
            /// default language is set separately
            .filter((lang: string) => lang !== routeList.DefaultLanguage)
            .map((lang: string) => (
              <Route
                key={routeObj.routeName + '_' + lang}
                path={RoutePathInLanguage(lang, routeObj, routeList)}
                exact={routeObj.exact}
              >
                {PageWrapper(routeObj.Component, lang, routeObj.routeName, routeObj.routeMeta)}
              </Route>
            )),
        )
      }
      {
        /// URLS for default language ------------------------------------------
        routeList.Paths.map((routeObj: IMultiLanguageRouteSpec) => (
          <Route
            key={routeObj.routeName + '_' + routeList.DefaultLanguage}
            path={routeObj.path[routeList.DefaultLanguage]}
            exact={routeObj.exact}
          >
            {PageWrapper(routeObj.Component, routeList.DefaultLanguage, routeObj.routeName, routeObj.routeMeta)}
          </Route>
        ))
      }
      <Redirect from="*" to="/404" />
    </Switch>
  );
}
