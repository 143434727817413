import React from 'react';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import MailchimpForm from './MailchimpForm';

export default function MailchimpFormWrapper(): JSX.Element {
  return (
    <div className={'MailchimpForm'}>
      <MailchimpSubscribe
        url={'https://media.us4.list-manage.com/subscribe/post?u=a834ef9bf1c8802c5648e6345&id=9ed5704e6d'}
        render={({ subscribe, status, message }) => (
          <MailchimpForm
            status={status}
            message={message}
            onValidated={(formData: EmailFormFields) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
}
