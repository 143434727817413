import React from 'react';
import SWLink from '../../fuctional/SWLink';
import FooterNavigation from '../../compound/footer/FooterNavigation';
import FooterSocialNav from '../../compound/footer/FooterSocialNav';
import LogoWhite from '../../../assets/svg/logo_white.svg';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import { IconFillEnum } from '../../simple/IconLink';
import { MenuItemType } from '../../../types/MenuTypes';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import MailchimpFormWrapper from '../../compound/newsletter/MailchimpFormWrapper';
import { generateHomepage } from '../../router/GenerateUrl';

export default function Footer({ menuItems }: { menuItems: MenuItemType[] }): JSX.Element {
  const t = useTranslateMessage();
  const language = useCurrentLanguage();
  const homepage = menuItems.length > 0 && menuItems[0].name !== 'storybook' ? generateHomepage(language.code) : '';
  const matches = useMediaQuery(mediaQueries.md, true);
  return (
    <footer className={'Footer py-8 py-lg-19'}>
      <div className={'Layout--container Layout--TwoColumn-Grid'}>
        <div className={'Footer-top--left'}>
          <div className={'Footer--newsletter'}>
            <h3 className={'SubscribeToNewsletter--caption mb-5'}>{t('newsletter.follow-us')}</h3>
            <p className={'SubscribeToNewsletter--hook mb-4'}>
              {t('newsletter.title-normal')} <span>{t('newsletter.title-highlight')}</span>
            </p>
            <MailchimpFormWrapper />
          </div>
          <FooterNavigation menuItems={menuItems} />
        </div>
        <div className={'Footer-top--right pl-0 pl-lg-31'}>
          <SWLink to={homepage} className={'Footer--logo pb-9'} title={'TheFix Homepage'}>
            <LogoWhite />
          </SWLink>
          <p className={'pb-5 Footer--subtitle'}>{t('footer.subtitle')}</p>
          <p className={'pb-2 Footer--description'}>{t('footer.description')}</p>
          <SWLink className={'Footer--link pb-9'} to={'/about'} title={'About TheFix'}>
            {t('cta.read-more')}
          </SWLink>
          <FooterSocialNav fill={IconFillEnum.WHITE} />
        </div>
      </div>
      <div className={'Divider Divider--dashed Divider--light Layout--container my-8 my-md-11'} />
      <div className={'Layout--container Layout--TwoColumn-Grid'}>
        <div className={'Footer--copyright pb-8 pb-md-0'}>
          <p>
            &copy; Copyright by The Fix. 2022 All right reserved <span>&#9679;</span>
          </p>
          <nav className={'Footer--Horizontal-Nav'}>
            <SWLink to={'#'} title={'Terms of service'} className={'display-none'}>
              Terms of service
            </SWLink>
            <SWLink to={'#'} title={'Privacy Policy'} className={'display-none'}>
              Privacy Policy
            </SWLink>
          </nav>
        </div>
        <div className={'pl-0 pl-lg-31'}>
          <div className={'Footer--copyright'}>
            <p>Website by</p>
            {!matches && (
              <SWLink to={'/fatchilli-for-the-fix'} className={'ml-1'} title="FatChilli for Publishers">
                🌶️ FatChilli − one-stop shop for Publishers
              </SWLink>
            )}
            <nav className={'Footer--Horizontal-Nav'}>
              {matches && (
                <SWLink to={'/fatchilli-for-the-fix'} title="FatChilli for Publishers">
                  🌶️ FatChilli − one-stop shop for Publishers
                </SWLink>
              )}
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
