import React, { useEffect, useRef, useState } from 'react';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import { IRouteParams } from '../../router/NewRoutes';
import hasValidData from '../../../utils/hasValidData';
import SWLink from '../../fuctional/SWLink';
import { generateCategoryUrl } from '../../router/GenerateUrl';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../cards/HorizontalCardPosts';
import HorizontalCardPostsSkeleton from '../../skeletons/HorizontalCardPostsSkeleton';
import useFCPostsByCategorySlugProvider from '../../../core/dataProviders/providers/useFCPostsByCategorySlugProvider';
import useCategoryBySlugProvider from '../../../core/dataProviders/providers/categories/useCategoryBySlugProvider';

export type TechnologySectionProps = {
  IRouteParams: IRouteParams;
  className?: string;
  currentScroll: number;
};

export default function TechnologySection({
  IRouteParams,
  className,
  currentScroll,
}: TechnologySectionProps): JSX.Element {
  const t = useTranslateMessage();
  const [slug, setSlug] = useState<string | null>(null);
  const [slugSet, setSlugSet] = useState<boolean>(false);
  const [isInView, setIsInView] = useState<boolean>(false);
  const sectionWrapper = useRef<HTMLDivElement>(null);
  const [techPosts, techPostsReqState] = useFCPostsByCategorySlugProvider(slug, 1, false);
  const [techCategory, techCategoryReqState] = useCategoryBySlugProvider(slug, false);

  useEffect(() => {
    if (
      !slugSet &&
      sectionWrapper.current &&
      sectionWrapper.current.getBoundingClientRect().bottom >= 0 &&
      sectionWrapper.current.getBoundingClientRect().top <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      setIsInView(true);
      setSlugSet(true);
    }
  }, [currentScroll]);

  useEffect(() => {
    isInView && setSlug(t('slug.category.listicle'));
  }, [isInView]);

  return (
    <div className={`TechnologySection Layout--container ${className ? className : ''}`} ref={sectionWrapper}>
      {hasValidData(techCategory, techCategoryReqState) && hasValidData(techPosts, techPostsReqState) ? (
        <>
          <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
            <h2>{techCategory.name}</h2>
            <div className={'SectionHeader--right'}>
              <SWLink to={generateCategoryUrl(techCategory, IRouteParams.language)}>{t('cta.view-all')}</SWLink>
            </div>
          </SectionHeader>
          <HorizontalCardPosts posts={techPosts.slice(0, 3)} />
        </>
      ) : (
        <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={3} title={true} />
      )}
    </div>
  );
}
