import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { ProvideLatestPosts } from './actions/ProvideLatestPosts';

export default function useLatestPostsDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('latest-posts', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) => dispatch(ProvideLatestPosts(Number(pageNumber), lang))),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) => dispatch(ProvideLatestPosts(Number(pageNumber), lang))),
    );
  }
}
