import React, { useEffect, useRef, useState } from 'react';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import PopularPostsFilter from '../../compound/post/PopularPostsFilter';
import hasValidData from '../../../utils/hasValidData';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../cards/HorizontalCardPosts';
import HorizontalCardPostsSkeleton from '../../skeletons/HorizontalCardPostsSkeleton';
import PostListItem from '../../compound/post/lists/PostListItem';
import PostListItemSkeleton from '../../skeletons/PostListItemSkeleton';
import VerticalListPosts from '../lists/VerticalListPosts';
import VerticalListPostsSkeleton from '../../skeletons/VerticalListPostsSkeleton';
import usePopularPostsByCategorySlugProvider from '../../../core/dataProviders/providers/usePopularPostsByCategorySlugProvider';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import usePostBySlugProvider from '../../../core/dataProviders/providers/usePostBySlugProvider';

export type MostPopularSectionProps = { className?: string; currentScroll: number };

export default function MostPopularSection({ className, currentScroll }: MostPopularSectionProps): JSX.Element {
  const t = useTranslateMessage();
  const language = useCurrentLanguage();
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);

  const [slug, setSlug] = useState<string | null>(null);
  const [slugSet, setSlugSet] = useState<boolean>(false);
  const [isInView, setIsInView] = useState<boolean>(false);
  const sectionWrapper = useRef<HTMLDivElement>(null);
  /*const [popularPosts, popularPostsReqState] = usePopularPostsByCategorySlugProvider(slug, 1, false);*/

  useEffect(() => {
    if (
      !slugSet &&
      sectionWrapper.current &&
      sectionWrapper.current.getBoundingClientRect().bottom >= 0 &&
      sectionWrapper.current.getBoundingClientRect().top <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      setIsInView(true);
      setSlugSet(true);
    }
  }, [currentScroll]);

  useEffect(() => {
    /*isInView && setSlug('latest');*/
    if (language.code === 'en') {
      isInView &&
        setSlug1(
          'if-you-properly-welcome-new-users-and-subscribers-theyll-stay-longer-heres-how-to-design-your-onboarding-experience',
        );
      isInView && setSlug2('the-kyiv-independent-its-all-about-freedom');
      isInView && setSlug3('focus-on-value-instead-of-reach-ideas-for-metrics-to-use-in-your-newsroom');
      isInView && setSlug4('social-advanced-search-is-something-that-you-can-do-on-twitter-too');
      isInView &&
        setSlug5(
          'wikipedia-is-a-coveted-target-of-information-warfare-what-risks-does-it-face-interview-with-researcher-carl-miller',
        );
      isInView && setSlug6('advanced-search-how-to-dig-using-search-engines-part-i');
      isInView &&
        setSlug7(
          'the-senders-name-of-your-newsletter-can-make-a-huge-difference-in-the-open-rate-also-should-you-use-emojis-in-email-subjects',
        );
    }
    if (language.code === 'de') {
      setSlug1(
        'if-you-properly-welcome-new-users-and-subscribers-theyll-stay-longer-heres-how-to-design-your-onboarding-experience',
      );
      isInView && setSlug2('the-kyiv-independent-its-all-about-freedom');
      isInView && setSlug3('focus-on-value-instead-of-reach-ideas-for-metrics-to-use-in-your-newsroom');
      isInView && setSlug4('social-advanced-search-is-something-that-you-can-do-on-twitter-too');
      isInView &&
        setSlug5(
          'wikipedia-is-a-coveted-target-of-information-warfare-what-risks-does-it-face-interview-with-researcher-carl-miller',
        );
      isInView && setSlug6('advanced-search-how-to-dig-using-search-engines-part-i');
      isInView &&
        setSlug7(
          'the-senders-name-of-your-newsletter-can-make-a-huge-difference-in-the-open-rate-also-should-you-use-emojis-in-email-subjects',
        );
    }
  }, [isInView]);

  const [slug1, setSlug1] = useState<string | null>(null);
  const [slug2, setSlug2] = useState<string | null>(null);
  const [slug3, setSlug3] = useState<string | null>(null);
  const [slug4, setSlug4] = useState<string | null>(null);
  const [slug5, setSlug5] = useState<string | null>(null);
  const [slug6, setSlug6] = useState<string | null>(null);
  const [slug7, setSlug7] = useState<string | null>(null);

  const [post1, reqState1] = usePostBySlugProvider(slug1, false);
  const [post2, reqState2] = usePostBySlugProvider(slug2, false);
  const [post3, reqState3] = usePostBySlugProvider(slug3, false);
  const [post4, reqState4] = usePostBySlugProvider(slug4, false);
  const [post5, reqState5] = usePostBySlugProvider(slug5, false);
  const [post6, reqState6] = usePostBySlugProvider(slug6, false);
  const [post7, reqState7] = usePostBySlugProvider(slug7, false);

  return (
    <div className={`MostPopularSection Layout--container ${className ? className : ''}`} ref={sectionWrapper}>
      <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
        <h2>{t('title.most-popular')}</h2>
      </SectionHeader>

      {/*{matchesMediumUp && <PopularPostsFilter slug={slug} setSlug={setSlug} className={'pb-12'} />}*/}

      {/*{hasValidData(popularPosts, popularPostsReqState) ? (
        <HorizontalCardPosts posts={popularPosts.slice(1, 4)} type={HorizontalCardPostsType.POSTS} />
      ) : (
        <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={3} title={false} />
      )}*/}

      {reqState2 === DataRequestStateEnum.FULFILLED &&
      post2 !== null &&
      reqState3 === DataRequestStateEnum.FULFILLED &&
      post3 !== null &&
      reqState4 === DataRequestStateEnum.FULFILLED &&
      post4 !== null ? (
        <HorizontalCardPosts posts={[post2, post3, post4]} />
      ) : (
        <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={3} title={false} />
      )}

      <div className={'Layout--TwoToOneColumns-Grid mt-0 mt-md-5'}>
        {/*{hasValidData(popularPosts, popularPostsReqState) ? (
          <PostListItem post={popularPosts[0]} className={'mb-7 mb-md-0'} />
        ) : (
          <PostListItemSkeleton />
        )}

        {hasValidData(popularPosts, popularPostsReqState) ? (
          <VerticalListPosts posts={popularPosts.slice(4, 7)} />
        ) : (
          <VerticalListPostsSkeleton placeholders={3} title={false} />
        )}*/}

        {reqState1 === DataRequestStateEnum.FULFILLED && post1 !== null ? (
          <PostListItem post={post1} className={'mb-7 mb-md-0'} />
        ) : (
          <PostListItemSkeleton />
        )}

        {reqState5 === DataRequestStateEnum.FULFILLED &&
        post5 !== null &&
        reqState6 === DataRequestStateEnum.FULFILLED &&
        post6 !== null &&
        reqState7 === DataRequestStateEnum.FULFILLED &&
        post7 !== null ? (
          <VerticalListPosts posts={[post5, post6, post7]} />
        ) : (
          <VerticalListPostsSkeleton placeholders={3} title={false} />
        )}
      </div>
    </div>
  );
}
