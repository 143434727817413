import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import ProvidePostsBySearchTerm from './actions/ProvidePostsBySearchTerm';

export default function usePostsBySearchTermDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('posts-by-search-term', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang]
        .map((sign: string) => sign.split('|'))
        .map((signParts: string[]) => ({
          searchTerm: signParts[0],
          pageNumber: Number(signParts[1]),
        }))
        .forEach(({ searchTerm, pageNumber }) =>
          dispatch(ProvidePostsBySearchTerm(searchTerm, Number(pageNumber), lang)),
        ),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang]
        .map((sign: string) => sign.split('|'))
        .map((signParts: string[]) => ({
          searchTerm: signParts[0],
          pageNumber: Number(signParts[1]),
        }))
        .forEach(({ searchTerm, pageNumber }) =>
          dispatch(ProvidePostsBySearchTerm(searchTerm, Number(pageNumber), lang)),
        ),
    );
  }
}
