import { useEffect, useState } from 'react';
import { PostType } from '../../../types/PostTypes';
import { DataRequestStateEnum } from '../DataProviderState';
import useDataProviderState from '../useDataProviderState';
import { useAppSelector } from '../../../redux/hooks';
import { AuthorSlugMapSelector } from '../../bucket/BucketSlice';
import { PostsByAuthorSelector } from '../../posts/PostsSlice';
import useDataProviderOrder from '../useDataProviderOrder';
import usePostsFiller from '../../Hooks/usePostsFiller';
import useDataProviderMarkAsFulfilled from '../useDataProviderMarkAsFulfilled';
import useDataProviderSSROrder from '../useDataProviderSSROrder';
import useLangSelector from '../../Hooks/useLangSelector';

export default function useFCPostsByAuthorSlugProvider(
  slug: string,
  pageNumber: number,
  includeInSSR = true,
): [
  /// Posts by author
  PostType[],
  /// Posts Request status,
  DataRequestStateEnum,
  /// setAuthorSlug callback
  (slug: string) => void,
  /// set PageNumber
  (pageNumber: number) => void,
] {
  const createRequestSign = (slug: string, pageNumber: number) => `${slug}|${pageNumber}`;

  const [reqState, setReqSlug, setReqSign] = useDataProviderState(
    'posts-by-author-slug',
    createRequestSign(slug, pageNumber),
  );
  const authorSlugToId = useLangSelector(useAppSelector(AuthorSlugMapSelector));
  const postsByAuthor = useLangSelector(useAppSelector(PostsByAuthorSelector));
  const [currentAuthorId, setCurrentAuthorId] = useState<number | null>(null);
  const [currentSlug, setCurrentSlug] = useState<string>(slug);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(pageNumber);
  const [posts, setPosts] = useState<PostType[]>([]);
  const orderData = useDataProviderOrder();
  const fulfillDataRequest = useDataProviderMarkAsFulfilled();
  const postFiller = usePostsFiller();

  const [authorIdMapHasPassed, setAuthorIdMapHasPassed] = useState<boolean>(false);

  if (ENV_SSR && includeInSSR)
    useDataProviderSSROrder('posts-by-author-slug', createRequestSign(currentSlug, currentPageNumber));

  useEffect(() => {
    if (currentAuthorId !== null) return;
    if (authorSlugToId && authorSlugToId[currentSlug]) {
      setCurrentAuthorId(authorSlugToId[currentSlug]);
    }
    setAuthorIdMapHasPassed(true);
  }, [authorSlugToId, currentSlug]);

  useEffect(() => {
    if (!authorIdMapHasPassed) return;

    if (
      !postsByAuthor ||
      currentAuthorId === null ||
      !postsByAuthor.hasOwnProperty(currentAuthorId) ||
      !postsByAuthor[currentAuthorId].hasOwnProperty(currentPageNumber)
    ) {
      setPosts([]);
      if (reqState !== DataRequestStateEnum.RUNNING) {
        orderData('posts-by-author-slug', createRequestSign(currentSlug, currentPageNumber));
      }
    } else {
      setPosts(postFiller(postsByAuthor[currentAuthorId][currentPageNumber]));
      fulfillDataRequest('posts-by-author-slug', createRequestSign(currentSlug, currentPageNumber));
    }
  }, [currentAuthorId, currentPageNumber, postsByAuthor, authorIdMapHasPassed]);

  const setPageNumberCallback = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    setReqSign(createRequestSign(currentSlug, pageNumber));
  };

  const setSlugCallback = (slug: string) => {
    if (slug === currentSlug) return;
    setCurrentSlug(slug);
    setCurrentAuthorId(null);
    setAuthorIdMapHasPassed(false);
    setReqSlug(createRequestSign(slug, currentPageNumber));
  };

  return [posts, reqState, setSlugCallback, setPageNumberCallback];
}
