import React, { FormEventHandler } from 'react';

export enum InputTypeEnum {
  EMAIL = 'email',
  TEXT = 'text',
  NUMBER = 'number',
}

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string;
  type: InputTypeEnum;
  onChange: FormEventHandler<HTMLInputElement>;
  id?: string;
  name?: string;
  placeholder?: string;
  isRequired?: boolean;
  className?: string;
}

export default function Input({
  value,
  type = InputTypeEnum.TEXT,
  onChange,
  id,
  name,
  placeholder,
  isRequired,
  className,
  ...rest
}: InputProps): JSX.Element {
  return (
    <input
      className={`Input--${type} ${className ? className : ''}`}
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      id={id}
      name={name}
      required={isRequired}
      {...rest}
    />
  );
}
