import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { ProvideCategoriesByPageNumber } from './actions/ProvideCategoriesByPageNumber';

export default function useAllCategoriesDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('all-categories', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) =>
        dispatch(ProvideCategoriesByPageNumber(Number(pageNumber), lang)),
      ),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) =>
        dispatch(ProvideCategoriesByPageNumber(Number(pageNumber), lang)),
      ),
    );
  }
}
