import React, { useState } from 'react';
import LogoColor from '../../../assets/svg/logo_color_large.svg';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export type CookiesSettingsProps = {
  setCookieSettingsShown: (boolean: boolean) => void;
  cookiesHandler: () => void;
};

export default function CookiesSettings({ setCookieSettingsShown, cookiesHandler }: CookiesSettingsProps): JSX.Element {
  const t = useTranslateMessage();
  const [activeTab, setActiveTab] = useState<string>('overview');

  const handleTabSwitch = (event: React.ReactNode) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event && setActiveTab(event.target.getAttribute('data-attribute'));
  };

  return (
    <>
      <div className={'CookiesSettings'}>
        <div className={'CookiesSettings--wrapper'}>
          <div className={'CookiesSettings--left p-8'}>
            <div className={'CookiesSettings--logo mb-5 pb-5'}>
              <LogoColor />
            </div>
            <Button
              btnType={activeTab === 'overview' ? ButtonTypeEnum.PRIMARY_LESS : ButtonTypeEnum.DARK_LESS}
              width={ButtonWidthEnum.FULLWIDTH}
              height={ButtonHeightEnum.DEF}
              onClick={handleTabSwitch}
              data-attribute={'overview'}
              className={'mb-3'}
            >
              {t('cookies.settings-overview')}
            </Button>
            <Button
              btnType={activeTab === 'necessary' ? ButtonTypeEnum.PRIMARY_LESS : ButtonTypeEnum.DARK_LESS}
              width={ButtonWidthEnum.FULLWIDTH}
              height={ButtonHeightEnum.DEF}
              onClick={handleTabSwitch}
              data-attribute={'necessary'}
              className={'mb-3'}
            >
              {t('cookies.settings-necessary')}
            </Button>
          </div>
          <div className={'CookiesSettings--right p-8'}>
            {activeTab === 'overview' && (
              <div>
                <h3 className={'CookiesSettings--right-title pb-5'}>{t('cookies.settings-overview')}</h3>
                <p className={'CookiesSettings--right-text'}>{t('cookies.settings-overview-text')}</p>
              </div>
            )}
            {activeTab === 'necessary' && (
              <div>
                <h3 className={'CookiesSettings--right-title pb-5'}>{t('cookies.settings-necessary')}</h3>
                <p className={'CookiesSettings--right-text'}>{t('cookies.settings-necessary-text')}</p>
                <label className="CookiesSettings--switch mt-5">
                  <input type="checkbox" />
                  <span className="CookiesSettings--slider" />
                </label>
              </div>
            )}
            <div className={'CookiesSettings--right-buttons pt-5'}>
              <Button btnType={ButtonTypeEnum.SECONDARY} width={ButtonWidthEnum.DEF} height={ButtonHeightEnum.DEF}>
                {t('cookies.settings-enable-all')}
              </Button>
              <Button
                btnType={ButtonTypeEnum.SECONDARY}
                width={ButtonWidthEnum.DEF}
                height={ButtonHeightEnum.DEF}
                onClick={() => {
                  setCookieSettingsShown(false);
                  cookiesHandler();
                }}
              >
                {t('cookies.settings-save-settings')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={'Backdrop Backdrop-secondary'} />
    </>
  );
}
