import React from 'react';
import Skeleton from 'react-loading-skeleton';
import CapitalCtaSkeleton from './CapitalCtaSkeleton';
import Cart from '../../assets/svg/cart.svg';

export default function CardProductSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`CardProduct ${className ? className : ''}`}>
      <figure className={'CardProduct--figure'}>
        <span className={'CardProduct--figure-ImgWrapper mb-5'}>
          <Skeleton className={'CardProduct--figure-Img'} />
        </span>
        <figcaption>
          <h3 className={'CardProduct--figcaption-Title'}>
            <Skeleton count={3} className={'CardProduct--figcaption-TitleLink'} />
          </h3>
        </figcaption>
        <div className={'Divider Divider--dark Divider--dashed mb-5'} />
      </figure>
      <div className={'CardProduct--action pb-4'}>
        <CapitalCtaSkeleton>
          <Cart />
        </CapitalCtaSkeleton>
        <Skeleton width={90} className={'CardProduct--action-price mr-3'} />
      </div>
    </div>
  );
}
