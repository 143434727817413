import { useAppSelector } from '../../redux/hooks';
import { ProductsSelector } from '../bucket/BucketSlice';
import ProductType from '../../types/ProductType';
import useLangSelector from './useLangSelector';

export default function useProductsFiller(): (ids: number[]) => ProductType[] {
  const products = useLangSelector(useAppSelector(ProductsSelector));

  return (ids: number[]): ProductType[] => {
    return products
      ? ids
          /// use only available products
          .filter((id: number): boolean => products.hasOwnProperty(id))
          /// map id to productId
          .map((id: number): ProductType => products[id])
      : [];
  };
}
