import React from 'react';
import { TagType } from '../../types/PostTypes';
import SWLink from '../fuctional/SWLink';
import { generateTagUrl } from '../router/GenerateUrl';
import ReactHtmlParser from 'react-html-parser';
import { useCurrentLanguage } from '../../core/i18/LanguageContextProvider';

export enum TagTypeEnum {
  LIGHT_LESS = 'Button--light_less',
  DARK_LESS = 'Button--dark_less',
  PRIMARY_LESS = 'Button--primary_less',
}

export interface TagBadgeProps {
  type: TagTypeEnum;
  className?: string;
  onClick?: () => void;
  tag: TagType;
}

export default function TagBadge({ type, className, tag, onClick }: TagBadgeProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = generateTagUrl(tag, language.code);

  return (
    <SWLink
      to={url}
      className={`TagBadge ${type} ${className ? className : ''}`}
      onClick={onClick ? onClick : undefined}
    >
      {ReactHtmlParser(tag.name)}
    </SWLink>
  );
}
