import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFCPostsBySearchTermProvider from '../../core/dataProviders/providers/posts/useFCPostsBySearchTermProvider';
import useSearchTerm from '../../core/Hooks/useSearchTerm';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import ReactPaginate from 'react-paginate';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import SectionHeader, { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import { ButtonTypeEnum } from '../simple/Button';
import HorizontalCardPostsSkeleton from '../skeletons/HorizontalCardPostsSkeleton';
import hasValidData from '../../utils/hasValidData';
import { usePostsBySearchTermPagingInfo } from '../../core/dataProviders/providers/posts/postsPagingInfoHooks';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import { DataRequestStateEnum } from '../../core/dataProviders/DataProviderState';
import { Helmet } from 'react-helmet';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function SearchPage(): JSX.Element {
  const t = useTranslateMessage();
  const params = useParams<{ searchTerm: string; pageNumber: string }>();
  const searchPaging = usePostsBySearchTermPagingInfo(params.searchTerm || '');
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const searchTerm = useSearchTerm();

  const [posts, reqState] = useFCPostsBySearchTermProvider(
    params.searchTerm,
    params.pageNumber ? Number(params.pageNumber) : 1,
  );

  const handlePageChange = (event: { selected: number }) => {
    searchTerm(params.searchTerm, event.selected + 1);
    setCurrentPageNumber(event.selected);
  };

  useEffect(() => {
    if (hasValidData(posts, reqState)) {
      window.scrollTo({
        behavior: 'smooth',
        top: 200,
      });
      trackPageViewGTMEvent('search-list', ['search-list'], ['theFix']);
    }
  }, [currentPageNumber, reqState]);

  return (
    <>
      <Helmet>
        <title>Search results for {params.searchTerm} - TheFix Media</title>
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href={`https://www.thefix.media/search/${params.searchTerm}`} />
      </Helmet>

      <LeftAlignedPageBanner
        caption={t('caption.search')}
        title={true}
        titleValue={`${t('title.search-results-for')} ${params.searchTerm}`}
        description={false}
        topics={false}
        className={'mb-12'}
      />

      <div className={'Layout--container'}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>
            {t('title.search-results-for')} {params.searchTerm}
          </h2>
        </SectionHeader>
        {reqState === DataRequestStateEnum.EMPTY_RESPONSE && (
          <p className={'SearchPage--error p-5 mb-15'}>
            No result for term <span>“{params.searchTerm}”</span> found.
          </p>
        )}
        {reqState === DataRequestStateEnum.FAILED && (
          <p className={'SearchPage--error p-5 mb-15'}>Search failed, try again later</p>
        )}

        {hasValidData(posts, reqState) && <HorizontalCardPosts posts={posts} />}
        {!hasValidData(posts, reqState) &&
          reqState !== DataRequestStateEnum.EMPTY_RESPONSE &&
          reqState !== DataRequestStateEnum.FAILED && (
            <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={9} title={false} />
          )}
      </div>

      {searchPaging.pages > 1 && (
        <div className={'Layout--container pb-15'}>
          <ReactPaginate
            className={'ReactPaginate--container'}
            pageClassName={`Button ${ButtonTypeEnum.DARK_LESS} ReactPaginate--container-item`}
            activeClassName={`${ButtonTypeEnum.PRIMARY_LESS} ReactPaginate--container-item`}
            previousClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--previous`}
            nextClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--next`}
            breakLabel=""
            nextLabel={''}
            onPageChange={handlePageChange}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            pageCount={searchPaging.pages}
            forcePage={currentPageNumber}
            previousLabel={''}
          />
        </div>
      )}
    </>
  );
}
