import { useState, useEffect } from 'react';

export enum mediaQueries {
  xs = '(min-width: 0px)',
  sm = '(min-width: 576px)',
  md = '(min-width: 768px)',
  lg = '(min-width: 992px)',
  xl = '(min-width: 1200px)',
  xxl = '(min-width: 1400px)',
}

const useMediaQuery = (query: mediaQueries | string, defaultReturn: boolean): boolean => {
  if (typeof window === 'undefined') {
    return defaultReturn;
  }
  const [matches, setMatches] = useState(false);
  const media = window.matchMedia(query);
  if (media.matches !== matches) {
    setMatches(media.matches);
  }

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
