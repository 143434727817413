import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';
import { DataProviderQueries, DataProviderReducers, DataProviderState } from './DataProviderState';

export const SLICE_NAME = 'DataProvider';

const initialState: DataProviderState = {
  requests: {},
  ssr: {},
};

export interface DataProviderRequestSign {
  language: string;
  type: string;
  sign: string;
}

export const DataProviderSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addRequest: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign>) =>
      DataProviderReducers.add(state, payload.type, payload.sign, payload.language),

    runRequest: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign>) =>
      DataProviderReducers.start(state, payload.type, payload.sign, payload.language),

    fulfilledRequest: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign>) =>
      DataProviderReducers.fulfilled(state, payload.type, payload.sign, payload.language),

    failedRequest: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign>) =>
      DataProviderReducers.failed(state, payload.type, payload.sign, payload.language),

    emptyResponseRequest: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign>) =>
      DataProviderReducers.emptyResponse(state, payload.type, payload.sign, payload.language),

    addSsrRequest: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign>) =>
      DataProviderReducers.addSsr(state, payload.type, payload.sign, payload.language),

    flushSsrRequests: (state: DataProviderState) => DataProviderReducers.flushSsr(state),

    /// --------------------------------------------------------- [ Bulk requests

    runRequestGroup: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign[]>) =>
      payload.forEach((req: DataProviderRequestSign) =>
        DataProviderReducers.start(state, req.type, req.sign, req.language),
      ),

    fulfilledRequestGroup: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign[]>) =>
      payload.forEach((req: DataProviderRequestSign) =>
        DataProviderReducers.fulfilled(state, req.type, req.sign, req.language),
      ),

    failedRequestGroup: (state: DataProviderState, { payload }: PayloadAction<DataProviderRequestSign[]>) =>
      payload.forEach((req: DataProviderRequestSign) =>
        DataProviderReducers.failed(state, req.type, req.sign, req.language),
      ),
  },
});

export const {
  addRequest,
  runRequest,
  fulfilledRequest,
  failedRequest,
  emptyResponseRequest,
  runRequestGroup,
  fulfilledRequestGroup,
  failedRequestGroup,
  addSsrRequest,
  flushSsrRequests,
} = DataProviderSlice.actions;

export const getDataProviderState = (state: RootState): DataProviderState => state[SLICE_NAME];

export const DataProviderSelector = createSelector(getDataProviderState, DataProviderQueries.getRequests);

export default DataProviderSlice.reducer;
