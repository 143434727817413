import React from 'react';
import SWLink from '../../fuctional/SWLink';
import { MenuItemType } from '../../../types/MenuTypes';
import Skeleton from 'react-loading-skeleton';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export default function FooterNavigation({ menuItems }: { menuItems: MenuItemType[] }): JSX.Element {
  const t = useTranslateMessage();

  return (
    <nav className={'FooterNavigation pb-5'}>
      <h4 className={'FooterNavigation-Title'}>{t('footer.menu')}</h4>
      <ul className={'FooterNavigation-List'}>
        {menuItems.length !== 0
          ? menuItems.map((item: MenuItemType, index: number) => (
              <li key={index} className={'FooterNavigation-List-Item mb-3 mb-md-4'}>
                <SWLink to={item.url} className={'FooterNavigation-List-Item-Link'}>
                  {item.name}
                </SWLink>
              </li>
            ))
          : new Array(8).fill(0).map((_, index) => (
              <li key={`footerMenuSkeleton${index}`} className={'FooterNavigation-List-Item mb-3 mb-md-4'}>
                <Skeleton width={90} className={'FooterNavigation-List-Item-Link'} />
              </li>
            ))}
      </ul>
    </nav>
  );
}
