import React from 'react';
import { PostType } from '../../../../types/PostTypes';
import { PostDateAndAuthor } from '../PostDateAndAuthor';
import { postUrl } from '../../../router/routeList';
import SWLink from '../../../fuctional/SWLink';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';
import ReactHtmlParser from 'react-html-parser';
import Image from '../../../simple/Image';

export interface ContentPostProps {
  post: PostType;
  className?: string;
}

export default function ContentPost({ post, className }: ContentPostProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = postUrl(post, language.code);
  const t = useTranslateMessage();

  return (
    <div className={`ContentPost ${className ? className : ''}`}>
      <figure className={'ContentPost--figure'}>
        <SWLink to={url} title={post.title} className={'ContentPost--figure-ImgWrapper'}>
          <Image itemWithImages={post} className={'ContentPost--figure-Img'} />
        </SWLink>
        <figcaption>
          <PostDateAndAuthor post={post} showAuthorAvatar={false} />
          <h3 className={'ContentPost--title'}>{ReactHtmlParser(post.title)}</h3>
          <SWLink to={url} title={post.title} className={'ContentPost--cta'}>
            {t('cta.read-more')}
          </SWLink>
        </figcaption>
      </figure>
    </div>
  );
}
