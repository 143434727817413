import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { MenuItemType } from '../../types/MenuTypes';
import { RootState } from '../store';

const NAME = 'menu';

export interface MenuState {
  mainMenu: MenuItemType[];
}

const initialState: MenuState = {
  mainMenu: [],
};

export const menuSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setMainMenu: (state: MenuState, { payload }: PayloadAction<MenuItemType[]>) => {
      state.mainMenu = payload;
    },
  },
});


export const getMenuState = (state: RootState): MenuState => state[NAME];

export const mainMenuSelector = createSelector(getMenuState, (state: MenuState) => state.mainMenu);

export const { setMainMenu } = menuSlice.actions;

export interface MenuDispatch {
  setMainMenu: (menuItems: MenuItemType[]) => void;
}

export default menuSlice.reducer;
