import React from 'react';
import { PostType } from '../../types/PostTypes';
import ProductType from '../../types/ProductType';
import ImagePlaceholder from '../../assets/img/imagePlaceholder.png';

export interface ImageProps {
  itemWithImages: PostType | ProductType;

  className: string;
}

export default function Image({ itemWithImages, className }: ImageProps): JSX.Element {
  return (
    <>
      {itemWithImages.imageMedium && itemWithImages.imageMediumLarge && itemWithImages.imageLarge ? (
        <img
          width={itemWithImages.imageMedium.width}
          height={itemWithImages.imageMedium.height}
          src={itemWithImages.imageMedium.url}
          srcSet={`
            ${itemWithImages.imageMedium.url} ${itemWithImages.imageMedium.width}w, 
            ${itemWithImages.imageMediumLarge.url} ${itemWithImages.imageMediumLarge.width}w, 
            ${itemWithImages.imageLarge.url} ${itemWithImages.imageLarge.width}w
          `}
          alt={itemWithImages.imageMedium.alt}
          className={className}
        />
      ) : (
        <img width={400} height={250} src={ImagePlaceholder} alt={'image not found'} className={className} />
      )}
    </>
  );
}
