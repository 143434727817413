import React from 'react';
import { Link } from 'react-router-dom';
import { withSWUpdate } from '../../core/SWUpdateContextProvider';

/**
 * Wrapper for <Link component
 * if there is pending SW update, it will create new <a link and reload the page
 *
 * @param {*} props
 * @returns
 */
const SWLink = (props: any) => {
  if (props.newSWReady === true) {
    const newProps = { ...props };
    newProps.href = newProps.to;
    delete newProps.newSWReady;
    delete newProps.to;
    return <a {...newProps} data-attribute={'test'} />;
  } else {
    const newProps = { ...props };
    delete newProps.newSWReady;
    return <Link {...newProps} />;
  }
};

export default withSWUpdate(SWLink);
