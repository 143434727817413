import React from 'react';
import { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import SectionHeaderSkeleton from './SectionHeaderSkeleton';
import MiniImagePostSkeleton from './MiniImagePostSkeleton';

export type VerticalListPostsSkeletonProps = {
  title: boolean;
  placeholders: number;
  className?: string;
};

export default function VerticalListPostsSkeleton({
  title,
  placeholders,
  className,
}: VerticalListPostsSkeletonProps): JSX.Element {
  return (
    <div className={`VerticalListPosts ${className ? className : ''}`}>
      {title && <SectionHeaderSkeleton className={'mb-4 mb-md-6'} borderStyle={SectionHeaderBorderStyle.THICK} />}
      {new Array(placeholders).fill(0).map((_, index) => (
        <MiniImagePostSkeleton key={index} className={'py-4'} />
      ))}
    </div>
  );
}
