export enum WpOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export interface WpPostOptions {
  order: WpOrderEnum;
  categories: number[];
  tags: number[];
  posts: number[];
  embed: number;
  pageNumber?: number;
  perPage?: number;
}
