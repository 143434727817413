import React from 'react';
import { PostType } from '../../../types/PostTypes';
import PostListItem from '../../compound/post/lists/PostListItem';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';

export type VerticalListPostsProps = {
  title?: string;
  posts: PostType[];
  className?: string;
  children?: JSX.Element;
};

export default function VerticalCardPosts({ title, posts, className, children }: VerticalListPostsProps): JSX.Element {
  return (
    <div className={`VerticalCardPosts ${className ? className : ''}`}>
      {title && (
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{title}</h2>
        </SectionHeader>
      )}
      <div className={'Layout--OneColumnVertical-Grid'}>
        {posts.length > 0 &&
          posts.map((post: PostType) => <PostListItem key={post.id} post={post} className={'mb-7 mb-lg-9'} />)}
        {children}
      </div>
    </div>
  );
}
