import React from 'react';
import { PostType } from '../../../../types/PostTypes';
import EstimatedReadTime from '../../../simple/EstimatedReadTime';
import { PostDateAndAuthor } from '../PostDateAndAuthor';
import { postUrl } from '../../../router/routeList';
import SWLink from '../../../fuctional/SWLink';
import { useFilterCategory } from '../../../hooks/useFilterCategory';
import { CategoryBadge, CategoryTypeEnum } from '../../../simple/CategoryBadge';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';
import Image from '../../../simple/Image';

export interface HeroPostProps {
  post: PostType;
  className?: string;
}

export default function HeroPost({ post, className }: HeroPostProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = postUrl(post, language.code);
  const t = useTranslateMessage();

  const partnerCategory = useFilterCategory(post, t('slug.category.partner'), CategoryTypeEnum.SPECIAL_ONE);
  const opinionCategory = useFilterCategory(post, t('slug.category.opinion'), CategoryTypeEnum.TERTIARY);
  const filteredCategory = partnerCategory || opinionCategory;

  return (
    <div className={`HeroPost ${className ? className : ''}`}>
      <figure className={'HeroPost--figure mb-2 mb-md-6'}>
        {filteredCategory && (
          <CategoryBadge
            type={filteredCategory?.CategoryTypeEnum}
            category={filteredCategory.type}
            className={'mb-3'}
          />
        )}
        <SWLink to={url} title={post.title} className={'mb-5 mb-md-0'}>
          <Image itemWithImages={post} className={'HeroPost--figure-Img'} />
        </SWLink>
        <figcaption className={'HeroPost--figcaption ml-0 ml-md-8'}>
          <h3 className={'HeroPost--figcaption-Title mb-5'}>
            <SWLink to={url} title={post.title} className={'HeroPost--figcaption-TitleLink'}>
              {post.title}
            </SWLink>
          </h3>
          <PostDateAndAuthor post={post} showAuthorAvatar={false} />
        </figcaption>
      </figure>
      <div className={'HeroPosts--action'}>
        <span>&nbsp;</span>
        <EstimatedReadTime post={post} />
      </div>
    </div>
  );
}
