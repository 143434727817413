import React, { useEffect, useRef, useState } from 'react';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import getCookie from '../../../utils/getCookies';
import CookiesSettings from './CookiesSettings';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export default function CookiesPopup(): JSX.Element {
  const t = useTranslateMessage();
  const [cookieState, setCookieState] = useState<boolean>(true);
  const [cookieSettingsShown, setCookieSettingsShown] = useState<boolean>(false);
  const cookiesPopup = useRef<HTMLDivElement>(null);
  const cookiesHandler = () => {
    document.cookie = 'acceptedCookies=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    setCookieState(true);
  };

  useEffect(() => {
    setCookieState(getCookie('acceptedCookies') === 'true');
  }, []);

  const showCookiesSettings = () => {
    setCookieSettingsShown(true);
  };

  return (
    <>
      {!cookieState && (
        <div className={'CookiesPopup'} ref={cookiesPopup}>
          <div className={'Layout--container'}>
            <div className={'CookiesPopup--wrapper p-4 p-md-8'}>
              <div>
                <p>{t('cookies.bottom-line1')}</p>
                <p>
                  {t('cookies.bottom-line2')}{' '}
                  <span className={'CookiesPopup--settings'} onClick={showCookiesSettings}>
                    {t('cookies.bottom-line2-cta')}
                  </span>
                  .
                </p>
              </div>
              <div>
                <Button
                  btnType={ButtonTypeEnum.SECONDARY}
                  height={ButtonHeightEnum.DEF}
                  width={ButtonWidthEnum.DEF}
                  onClick={cookiesHandler}
                >
                  {t('cookies.bottom-accept')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {cookieSettingsShown && (
        <CookiesSettings setCookieSettingsShown={setCookieSettingsShown} cookiesHandler={cookiesHandler} />
      )}
    </>
  );
}
