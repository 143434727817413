import React from 'react';
import { PostType } from '../../../types/PostTypes';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import MiniImagePost from '../../compound/post/lists/MiniImagePost';

export type VerticalListPostsProps = {
  title?: string;
  posts: PostType[];
  className?: string;
};

export default function VerticalListPosts({ title, posts, className }: VerticalListPostsProps): JSX.Element {
  return (
    <div className={`VerticalListPosts ${className ? className : ''}`}>
      {title && (
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{title}</h2>
        </SectionHeader>
      )}
      {posts.map((post: PostType) => (
        <MiniImagePost post={post} key={post.id} className={'py-4'} />
      ))}
    </div>
  );
}
