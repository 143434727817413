import { WpRawCategory } from '../types/wpRawTypes';
import { CategoryType } from '../../../types/PostTypes';

export default function mapWpCategory(category: WpRawCategory): CategoryType {
  const res = {
    id: category.id,
    slug: category.slug,
    name: category.name,
    link: category.link,
    meta: category.yoast_head_json,
  } as CategoryType;

  if (category.description) res.description = category.description;

  return res;
}
