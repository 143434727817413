import { useAppDispatch } from '../../redux/hooks';
import { addSsrRequest } from './DataProviderSlice';
import { useParams } from 'react-router-dom';

export default function useDataProviderSSROrder(type: string, sign: string | null): void {
  const params = useParams<{ lng?: string }>();
  const dispatch = useAppDispatch();

  if (ENV_SSR) {
    if (sign === null) return;
    dispatch(
      addSsrRequest({
        type: type,
        sign: sign,
        language: params.lng ? params.lng : 'en',
      }),
    );
  }
}
