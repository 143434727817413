import { FormEvent } from 'react';

export default function useShareOnTwitterOnClick(
  domain: string,
  url: string,
  afterClick?: () => void,
): [string, (e: FormEvent<HTMLAnchorElement>) => void] {
  const shareUrl = `https://www.twitter.com/share?url=${domain}${url}?ref=pop`;
  const onClickFunction = (e: FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(
      shareUrl,
      'Twitter',
      `width=480, height=480, top=${window.innerHeight / 2 - 240}, left=${window.innerWidth / 2 - 240}`,
    );
    afterClick && afterClick();
  };
  return [shareUrl, onClickFunction];
}
