import React, { useEffect, useRef, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { PostType } from '../../../../types/PostTypes';
import ContentSubscribeForm from '../../newsletter/ContentSubscribeForm';
import { Portal } from 'react-portal';
import usePostBySlugProvider from '../../../../core/dataProviders/providers/usePostBySlugProvider';
import hasValidData from '../../../../utils/hasValidData';
import ContentPost from './ContentPost';
import { renderToStaticMarkup } from 'react-dom/server';
import AdvertisementBlock from '../../../fuctional/AdvertismentBlock';

export type PostContentProps = {
  post: PostType;
  className?: string;
};

export default function PostContent({ post, className }: PostContentProps): JSX.Element {
  const [contentPostIndex, setContentPostIndex] = useState<number>(0);
  const [contentPostSlug, setContentPostSlug] = useState<string | null>(null);
  const [contentPostsArray, setContentPostsArray] = useState<PostType[]>([]);
  const [postContent, setPostContent] = useState<string | undefined>(undefined);
  const [contentPost, contentPostReqState] = usePostBySlugProvider(contentPostSlug);
  const postContentDiv = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [contentPostElements] = useState(() =>
    typeof document === 'undefined'
      ? []
      : (document.getElementsByClassName('fc-content-post') as HTMLCollectionOf<HTMLElement>),
  );

  useEffect(() => {
    if (contentPostIndex < contentPostElements.length) {
      const data = contentPostElements[contentPostIndex].dataset;
      if (!data.url) {
        return;
      }
      const url = new URL(data.url);
      const postSlug = url.pathname.split('/')[url.pathname.split('/').length - 1];
      setContentPostSlug(postSlug);
    }
  }, [postContent, contentPostIndex]);

  useEffect(() => {
    if (hasValidData(contentPost, contentPostReqState)) {
      setContentPostsArray((prevState) => [...prevState, contentPost]);
      setContentPostIndex((prevState) => prevState + 1);
      setContentPostSlug(null);
    }
  }, [postContent, contentPost, contentPostReqState]);

  const setHiddenContentHeight = (value: number | string) => {
    const hiddenContent = document.getElementById('fc-poool-content');
    if (!hiddenContent) {
      return;
    }
    if (typeof value === 'string') {
      hiddenContent.style.height = value;
    }
    hiddenContent.style.height = `${value}px`;
  };

  useEffect(() => {
    if (!postContentDiv.current || !ENV_POOOL_PUBLIC_KEY) {
      return;
    }
    if (!document.getElementById('fc-poool-wall')) {
      addAdvertisement();
      return;
    }

    Access.init(ENV_POOOL_PUBLIC_KEY)
      .config({ cookies_enabled: true })
      .on('release', () => {
        addAdvertisement();
        setHiddenContentHeight('inherit');
      })
      .createPaywall({
        target: '#fc-poool-wall',
        content: '#fc-poool-content',
        mode: 'hide',
        percent: 100,
        pageType: 'premium',
      });
  }, [postContentDiv.current]);

  useEffect(() => {
    if (!post.content) {
      return;
    }
    /*setPostContent(
      `<p>content before wall is cool content before wall is cool content before wall is cool content before wall is cool content before wall is cool content before wall is cool content before wall is cool</p><div id='fc-poool-wall'></div><div id='fc-poool-content'>${post.content}</div>`,
    );*/
    if (!post.content.includes('fc-poool-wall')) {
      setPostContent(post.content);
      return;
    }
    const contentWithWall = post.content.replace(
      "<div id='fc-poool-wall'></div>",
      "<div id='fc-poool-wall'></div><div id='fc-poool-content'>",
    );
    setPostContent(`${contentWithWall}</div>`);
  }, [post.content]);

  const addAdvertisement = () => {
    const ad = renderToStaticMarkup(<AdvertisementBlock id={'protag-in_article_1'} />);
    const allParagraphs = postContentDiv.current.getElementsByTagName('p');
    const paragraphsCount = allParagraphs.length;
    // select paragraph after which the advertisement component will be added
    // after 3rd if possible, else the last available
    const targetParagraph = paragraphsCount >= 4 ? allParagraphs[3] : allParagraphs[paragraphsCount - 1];
    targetParagraph.insertAdjacentHTML('afterend', ad);
  };

  return (
    <>
      {!ENV_SSR && document.getElementById('subscribe-form') && (
        <Portal node={document.getElementById('subscribe-form')}>
          <ContentSubscribeForm className={'mb-10'} />
        </Portal>
      )}
      {!ENV_SSR &&
        contentPostIndex === contentPostElements.length &&
        contentPostsArray.length === contentPostElements.length &&
        contentPostsArray.map((post: PostType, i: number) => (
          <Portal node={contentPostElements[i]} key={i}>
            <ContentPost post={post} className={'p-5'} />
          </Portal>
        ))}

      {postContent && (
        <>
          {post.authors[0].name !== 'storybook' ? (
            <div className={`PostContent ${className ? className : ''}`} ref={postContentDiv}>
              {ENV_SSR ? <div dangerouslySetInnerHTML={{ __html: postContent }} /> : <InnerHTML html={postContent} />}
            </div>
          ) : (
            <div className={`PostContent ${className ? className : ''}`} ref={postContentDiv}>
              <InnerHTML html={postContent} />
            </div>
          )}
        </>
      )}
    </>
  );
}
