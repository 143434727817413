import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeAuthors, storeCategories, storePosts, storeTags } from '../../../../core/bucket/BucketSlice';
import { setPostsBySearchTerm, setPostsBySearchTermPagingInfo } from '../../../../core/posts/PostsSlice';
import FcPostsApi from '../../post/FcPostsApi';
import { AuthorType, CategoryType, TagType } from '../../../../types/PostTypes';
import collectObjects from './CollectObjects';

export default function ProvidePostsBySearchTerm(searchTerm: string, pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'posts-by-search-term',
      sign: `${searchTerm}|${pageNumber}`,
      language: lang,
    };

    try {
      dispatch(runRequest(requestSign));
      const postApi = new FcPostsApi(ENV_API_URL);
      await postApi.setPage(pageNumber).filterBySearchTerm(searchTerm).setLanguage(lang).setPerPage(9).fetchSearch();
      const posts = postApi.result || [];
      if (posts.length > 0) {
        /// Find category ID by categorySlug
        dispatch(storePosts({ [lang]: posts }));
        dispatch(
          setPostsBySearchTerm({
            language: lang,
            forObject: searchTerm,
            pageNumber: pageNumber,
            objects: posts,
          }),
        );
        dispatch(storeCategories({ [lang]: collectObjects<CategoryType>(posts, 'categories') }));
        dispatch(storeTags({ [lang]: collectObjects<TagType>(posts, 'tags') }));
        dispatch(storeAuthors({ [lang]: collectObjects<AuthorType>(posts, 'authors') }));
        if (postApi.pagingInfo)
          dispatch(setPostsBySearchTermPagingInfo({ [lang]: { [searchTerm]: postApi.pagingInfo } }));
        dispatch(fulfilledRequest(requestSign));
      } else {
        dispatch(emptyResponseRequest(requestSign));
      }
    } catch (ex) {
      dispatch(failedRequest(requestSign));
    }
  };
}
