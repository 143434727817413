import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import SectionHeader, { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import ReactPaginate from 'react-paginate';
import { ButtonTypeEnum } from '../simple/Button';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import HorizontalCardPostsSkeleton from '../skeletons/HorizontalCardPostsSkeleton';
import Skeleton from 'react-loading-skeleton';
import useAuthorBySlugProvider from '../../core/dataProviders/providers/authors/useAuthorBySlugProvider';
import useFCPostsByAuthorSlugProvider from '../../core/dataProviders/providers/useFCPostsByAuthorSlugProvider';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import Helmet from 'react-helmet';
import hasValidData from '../../utils/hasValidData';
import { usePostsByAuthorPagingInfo } from '../../core/dataProviders/providers/posts/postsPagingInfoHooks';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import use404Page from '../../core/Hooks/use404Page';
import { IRouteParams } from '../router/NewRoutes';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function AuthorPage({ language }: IRouteParams): JSX.Element {
  const params = useParams<{ slug: string }>();
  const t = useTranslateMessage();
  const authorPaging = usePostsByAuthorPagingInfo(params.slug);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [author, authorReqState] = useAuthorBySlugProvider(params.slug);
  const [authorPosts, authorPostsReqState, setAuthorPostsSlug, setAuthorPageNumber] = useFCPostsByAuthorSlugProvider(
    params.slug,
    1,
  );
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(author, authorReqState) ? author : null);

  use404Page(authorReqState, language);

  useEffect(() => {
    if (hasValidData(author, authorReqState)) {
      setMetaDataPost(author);
      trackPageViewGTMEvent('posts-by-author', ['posts-by-author'], ['theFix']);
    }
  }, [authorReqState, author]);

  useEffect(() => {
    setAuthorPageNumber(1);
    setAuthorPostsSlug(params.slug);
    setCurrentPageNumber(0);
  }, [params]);

  const handlePageChange = (event: { selected: number }) => {
    setAuthorPageNumber(event.selected + 1);
    setCurrentPageNumber(event.selected);
  };

  useEffect(() => {
    hasValidData(authorPosts, authorPostsReqState) &&
      window.scrollTo({
        behavior: 'smooth',
        top: 200,
      });
  }, [currentPageNumber, authorPostsReqState]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <LeftAlignedPageBanner
        caption={t('caption.author')}
        requestState={authorReqState}
        title={true}
        titleValue={author?.name}
        description={true}
        descriptionValue={author?.description}
        topics={false}
        className={'mb-12'}
      />

      <div className={'Layout--container'}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>Latest by {author ? author.name : <Skeleton width={200} />}</h2>
        </SectionHeader>

        {hasValidData(authorPosts, authorPostsReqState) ? (
          <HorizontalCardPosts posts={authorPosts} />
        ) : (
          <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={9} title={false} />
        )}
      </div>

      {authorPaging.pages > 1 && (
        <div className={'Layout--container pb-15'}>
          <ReactPaginate
            className={'ReactPaginate--container'}
            pageClassName={`Button ${ButtonTypeEnum.DARK_LESS} ReactPaginate--container-item`}
            activeClassName={`${ButtonTypeEnum.PRIMARY_LESS} ReactPaginate--container-item`}
            previousClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--previous`}
            nextClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--next`}
            breakClassName={`display-none`}
            breakLabel="..."
            nextLabel={''}
            onPageChange={handlePageChange}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            pageCount={authorPaging.pages}
            initialPage={0}
            previousLabel={''}
          />
        </div>
      )}
    </>
  );
}
