import React from 'react';

export enum AvatarCircleBorderStyle {
  THICK = 'AvatarCircle--bordered',
  NONE = '',
}

export type AvatarCircleProps = {
  imageSrc: string;
  alt?: string;
  link: string;
  width: number;
  height: number;
  borderStyle: AvatarCircleBorderStyle;
  className?: string;
};

export function AvatarCircle({
  imageSrc,
  alt,
  link,
  width,
  height,
  borderStyle,
  className,
}: AvatarCircleProps): JSX.Element {
  return (
    <a href={link} title={alt} className={`AvatarCircle ${className ? className : ''}`}>
      <img className={`AvatarCircle--image ${borderStyle}`} src={imageSrc} alt={alt} width={width} height={height} />
    </a>
  );
}
