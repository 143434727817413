import IRouteSpec from '../../core/app/IRouteSpec';
import { generatePath, matchPath } from 'react-router-dom';
import CategoryPage from '../pages/CategoryPage';
import PostPage from '../pages/PostPage';
import AuthorPage from '../pages/AuthorPage';
import TagPage from '../pages/TagPage';
import SearchPage from '../pages/SearchPage';
import { AuthorType, CategoryType, PostType, TagType } from '../../types/PostTypes';
import SubscriptionPage from '../pages/SubscriptionPage';
import ErrorPage from '../pages/404';
import ProductPage from '../pages/ProductPage';
import PagePage from '../pages/PagePage';
import LatestPage from '../pages/LatestPage';
import ProductsListPage from '../pages/ProductsListPage';
import AllTopicsPage from '../pages/AllTopicsPage';

export const PATH_DEFAULT_LANGUAGE = '/';
export const PATH_LANGUAGE = '/:lng(de)';
export const PATH_HOMEPAGE = '/';
export const PATH_SUBSCRIPTION = '/subscription';
export const PATH_ERROR = '/404';
export const PATH_ALLTOPICS = '/all-topics';
export const PATH_LATEST = '/latest';
export const PATH_PRODUCTS = '/research';
export const PATH_CATEGORY = '/category/:slug';
export const PATH_TAG = '/tag/:slug';
export const PATH_AUTHOR = '/author/:slug';
export const PATH_PRODUCT = '/product/:slug';
export const PATH_PAGE = '/:slug/';
export const PATH_POST = '/:year/:month/:day/:slug';
export const PATH_SEARCH = '/search/:searchTerm/:pageNumber?';

export const routeList = [
  {
    routeName: 'SUBSCRIPTION',
    path: PATH_SUBSCRIPTION,
    component: SubscriptionPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'ERROR',
    path: PATH_ERROR,
    component: ErrorPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'LATEST',
    path: PATH_LATEST,
    component: LatestPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'ALLTOPICS',
    path: PATH_ALLTOPICS,
    component: AllTopicsPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'PRODUCTS',
    path: PATH_PRODUCTS,
    component: ProductsListPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'CATEGORY',
    path: PATH_CATEGORY,
    component: CategoryPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'TAG',
    path: PATH_TAG,
    component: TagPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'AUTHOR',
    path: PATH_AUTHOR,
    component: AuthorPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'PRODUCT',
    path: PATH_PRODUCT,
    component: ProductPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'POST',
    path: PATH_POST,
    component: PostPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'SEARCH',
    path: PATH_SEARCH,
    component: SearchPage,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'PAGE',
    path: PATH_PAGE,
    component: PagePage,
    exact: true,
  } as IRouteSpec,
] as IRouteSpec[];

const HOMEPAGE_ROUTES = [
  {
    routeName: 'HOMEPAGE',
    path: PATH_DEFAULT_LANGUAGE,
    exact: true,
  } as IRouteSpec,
  {
    routeName: 'HOMEPAGE',
    path: PATH_LANGUAGE,
    exact: true,
  } as IRouteSpec,
];

/**
 * Helper function to identify route by URL
 * @param url
 */
export function matchUrl(url: string): IRouteSpec {
  let matched = routeList[0];

  /// paths are broken by language
  /// so to ease-up search
  /// we are preparing allRoutes array of all the URL variantions
  const allRoutes = [
    ...HOMEPAGE_ROUTES,
    ...routeList.map((route: IRouteSpec) => ({ ...route, path: PATH_LANGUAGE + route.path })),
    ...routeList,
  ];

  for (let i = 0; i < allRoutes.length; i++) {
    const matchedPath = matchPath(url, allRoutes[i].path);
    if (matchedPath === null || !matchedPath.isExact) continue;
    matched = {
      ...allRoutes[i],
      params: matchedPath.params,
    } as IRouteSpec;
    break;
  }
  return matched;
}

/**
 * TODO: make url generator...
 *
 * @param url
 */
export function switchURLLanguage(url: string): string {
  if (url.match(/^\/de/)) {
    return url.substr(3);
  }
  return '/de' + (url === '/' ? '' : url);
}

export function homepageUrl(language?: string): string {
  if (language && language !== 'en')
    return generatePath(PATH_LANGUAGE + PATH_HOMEPAGE, {
      lng: language,
    });

  return generatePath(PATH_HOMEPAGE);
}

export function categoryUrl(category: CategoryType, language?: string): string {
  if (language && language !== 'en')
    return generatePath(PATH_LANGUAGE + PATH_CATEGORY, {
      lng: language,
      slug: category.slug,
    });

  return generatePath(PATH_CATEGORY, {
    slug: category.slug,
  });
}

export function tagUrl(tag: TagType, language?: string): string {
  if (language && language !== 'en')
    return generatePath(PATH_LANGUAGE + PATH_TAG, {
      lng: language,
      slug: tag.slug,
    });

  return generatePath(PATH_TAG, {
    slug: tag.slug,
  });
}

export function authorUrl(author: AuthorType, language?: string): string {
  if (language && language !== 'en')
    return generatePath(PATH_LANGUAGE + PATH_AUTHOR, {
      lng: language,
      slug: author.slug,
    });

  return generatePath(PATH_AUTHOR, {
    slug: author.slug,
  });
}

export function postUrl(post: PostType, language?: string): string {
  const dateObject = new Date(post.date);
  if (language && language !== 'en')
    return generatePath(PATH_LANGUAGE + PATH_POST, {
      lng: language,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth() + 1,
      day: dateObject.getDate(),
      slug: post.slug,
    });

  return generatePath(PATH_POST, {
    year: dateObject.getFullYear(),
    month: dateObject.getMonth() + 1,
    day: dateObject.getDate(),
    slug: post.slug,
  });
}

export function searchUrl(searchTerm: string, pageNumber?: number, language?: string): string {
  if (language && language !== 'en')
    return generatePath(PATH_LANGUAGE + PATH_SEARCH, {
      lng: language,
      searchTerm: searchTerm,
      pageNumber: pageNumber,
    });

  return generatePath(PATH_SEARCH, {
    searchTerm: searchTerm,
    pageNumber: pageNumber,
  });
}
