import React from 'react';
import { AuthorType } from '../../types/PostTypes';
import SWLink from '../fuctional/SWLink';
import { useCurrentLanguage, useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import { generateAuthorUrl } from '../router/GenerateUrl';

export interface AuthorProps {
  author: AuthorType;
  separatorOverride?: string;
  className?: string;
}

export default function Author({ author, separatorOverride, className }: AuthorProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = author.name !== 'storybook' ? generateAuthorUrl(author, language.code) : '';
  const t = useTranslateMessage();

  return (
    <SWLink to={url} className={`Author ${className ? className : ''}`} title={author.name}>
      {separatorOverride ? separatorOverride : t('author.by')} <span>{author.name}</span>
    </SWLink>
  );
}
