export enum FcOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export interface FcPostOptions {
  order: FcOrderEnum;
  author?: number | string;
  category?: number | string;
  tag?: number | string;
  posts: (number | string)[];
  embed: number;
  pageNumber?: number;
  perPage?: number;
  slug?: string;
  search?: string;
  language?: string;
  range?: string;
}
