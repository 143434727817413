import React, { useEffect, useState } from 'react';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import SectionHeader, { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import ReactPaginate from 'react-paginate';
import { ButtonTypeEnum } from '../simple/Button';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import useLatestPostsProvider from '../../core/dataProviders/providers/useLatestPostsProvider';
import HorizontalCardPostsSkeleton from '../skeletons/HorizontalCardPostsSkeleton';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import usePageBySlugProvider from '../../core/dataProviders/providers/pages/usePageBySlugProvider';
import hasValidData from '../../utils/hasValidData';
import { useLatestPostsPagingInfo } from '../../core/dataProviders/providers/posts/postsPagingInfoHooks';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function LatestPage(): JSX.Element {
  const t = useTranslateMessage();
  const latestPaging = useLatestPostsPagingInfo();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [posts, reqState, setPageNumber] = useLatestPostsProvider(1);

  const [page, pageReqState] = usePageBySlugProvider(t('slug.page.latest'));
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(page, reqState) ? page : null);

  useEffect(() => {
    if (hasValidData(page, reqState)) {
      setMetaDataPost(page);
      trackPageViewGTMEvent('latest-posts', ['latest-posts'], ['theFix']);
    }
  }, [pageReqState, page]);

  const handlePageChange = (event: { selected: number }) => {
    setPageNumber(event.selected + 1);
    setCurrentPageNumber(event.selected);
  };

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }, [currentPageNumber, reqState]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <LeftAlignedPageBanner
        title={true}
        titleValue={t('title.latest-posts')}
        description={false}
        topics={false}
        className={'mb-12'}
      />

      <div className={'Layout--container'}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{t('title.latest-posts')}</h2>
        </SectionHeader>

        {hasValidData(posts, reqState) ? (
          <HorizontalCardPosts posts={posts} />
        ) : (
          <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={9} title={false} />
        )}
      </div>

      {latestPaging.pages > 1 && (
        <div className={'Layout--container pb-15'}>
          <ReactPaginate
            className={'ReactPaginate--container'}
            pageClassName={`Button ${ButtonTypeEnum.DARK_LESS} ReactPaginate--container-item`}
            activeClassName={`${ButtonTypeEnum.PRIMARY_LESS} ReactPaginate--container-item`}
            previousClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--previous`}
            nextClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--next`}
            breakLabel=""
            nextLabel={''}
            onPageChange={handlePageChange}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            pageCount={latestPaging.pages}
            forcePage={currentPageNumber}
            previousLabel={''}
          />
        </div>
      )}
    </>
  );
}
