import React from 'react';
import SWLink from '../../../fuctional/SWLink';
import CapitalCta, { CapitalCtaColorEnum } from '../../../simple/CapitalCta';
import Cart from '../../../../assets/svg/cart.svg';
import ProductType from '../../../../types/ProductType';
import { useSetProductQuantityInCart } from '../../../../core/Cart/LocalStorage/CartContextProvider';
import { useHistory } from 'react-router-dom';
import { generateProductUrl } from '../../../router/GenerateUrl';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';
import Image from '../../../simple/Image';

export type CardProductProps = {
  product: ProductType;
  className?: string;
};

export default function CardProduct({ product, className }: CardProductProps): JSX.Element {
  const t = useTranslateMessage();
  const history = useHistory();
  const setQuantity = useSetProductQuantityInCart();
  const language = useCurrentLanguage();
  const handleBuy = () => {
    setQuantity(`${product.id}`, 1);
    language.code !== 'en' ? history.push(`/${language.code}/cart`) : history.push('/cart');
  };
  const url = product.slug !== 'storybook' ? generateProductUrl(product, language.code) : '';
  return (
    <div className={`CardProduct ${className ? className : ''}`}>
      <figure className={'CardProduct--figure'}>
        <p className={'CategoryBadge Button--primary mb-3'}>Research</p>
        <SWLink to={url} title={product.name} className={'CardProduct--figure-ImgWrapper mb-5'}>
          <Image itemWithImages={product} className={'CardProduct--figure-Img'} />
        </SWLink>
        <figcaption>
          <h3 className={'CardProduct--figcaption-Title'}>
            <SWLink to={url} title={product.name} className={'CardProduct--figcaption-TitleLink'}>
              {product.name}
            </SWLink>
          </h3>
        </figcaption>
        <div className={'Divider Divider--dark Divider--dashed mb-5'} />
      </figure>
      <div className={'CardProduct--action pb-4'}>
        {product.price > 0 ? (
          <CapitalCta color={CapitalCtaColorEnum.PRIMARY} onClick={handleBuy}>
            <Cart /> {t('cta.buy')}
          </CapitalCta>
        ) : (
          <CapitalCta color={CapitalCtaColorEnum.PRIMARY} onClick={handleBuy}>
            {t('cta.download')}
          </CapitalCta>
        )}
        <p className={'CardProduct--action-price mr-3'}>
          {product.price > 0 ? `${t('text.price')}: ${product.price} €` : t('text.free')}
        </p>
      </div>
    </div>
  );
}
