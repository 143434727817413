import React from 'react';
import Author from './Author';
import { AuthorType } from '../../types/PostTypes';

export type DateAndAuthorProps = {
  date: string;
  author: AuthorType[];
  className?: string;
};

export default function DateAndAuthor({ date, author, className }: DateAndAuthorProps): JSX.Element {
  const dateObject = new Date(date);
  return (
    <div className={`DateAndAuthor ${className ? className : ''}`}>
      <span className={'DateAndAuthor-date'}>{`${dateObject.getDate()}.${
        dateObject.getMonth() + 1
      }.${dateObject.getFullYear()}`}</span>
      {author.map((author: AuthorType, index) => (
        <Author key={author.id} author={author} separatorOverride={index > 0 ? ', ' : undefined} />
      ))}
    </div>
  );
}
