import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { ProvidePageBySlug } from './actions/ProvidePageBySlug';

export default function useFcPageBySlugDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('page-by-slug', DataRequestStateEnum.PENDING);

  useEffect(() => {
    dispatchPendingOrders({pendingOrders: pendingOrders})
  }, [pendingOrders]);

  if (ENV_SSR) {
    dispatchPendingOrders({pendingOrders: pendingOrders})
  }

  function dispatchPendingOrders({pendingOrders}: { pendingOrders: any }) {
    Object.keys(pendingOrders).forEach((lang: string) =>
        pendingOrders[lang].forEach((slug: string) => dispatch(ProvidePageBySlug(slug, lang))),
    );
  }
}
