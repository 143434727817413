import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ProductBottomBuySkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`ProductBottomBuy ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <div className={'ProductBottomBuy--Wrapper'}>
          <div className={'ProductBottomBuy--Image'}>
            <Skeleton width={110} height={140} />
          </div>
          <div className={'ProductBottomBuy--Headlines'}>
            <p className={'ProductBottomBuy--Caption pb-2'}>Buy resource</p>
            <h2 className={'ProductBottomBuy--Title pb-4'}>Hey, are you ready to get the Report?</h2>
            <Skeleton width={250} height={40} />
          </div>
        </div>
      </div>
    </div>
  );
}
