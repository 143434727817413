import { useEffect, useState } from 'react';
import { MenuItemType } from '../../../types/MenuTypes';
import { DataRequestStateEnum } from '../DataProviderState';
import useDataProviderState from '../useDataProviderState';
import { useAppSelector } from '../../../redux/hooks';
import { MenuByGroupSelector } from '../../menu/MenuSlice';
import useDataProviderOrder from '../useDataProviderOrder';
import useDataProviderSSROrder from '../useDataProviderSSROrder';
import useLangSelector from '../../Hooks/useLangSelector';

export default function useMenuBySlugProvider(
  slug: string,
  includeInSSR = true,
): [
  /// MenuItems
  MenuItemType[],
  /// Req state
  DataRequestStateEnum,
] {
  const [reqState] = useDataProviderState('menu-by-slug', slug);
  const menuItemsByGroup = useLangSelector(useAppSelector(MenuByGroupSelector));
  const [menuItems, setMenuItems] = useState<MenuItemType[]>(() => {
    if (slug && menuItemsByGroup) return menuItemsByGroup[slug] || [];
    return [];
  });
  const orderData = useDataProviderOrder();
  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('menu-by-slug', slug);

  useEffect(() => {
    /// Already set...
    if (menuItems.length > 0) return;

    /// order data if there are NO data in the store, and request is NOT running
    if (menuItemsByGroup && menuItemsByGroup[slug]) {
      /// there are data in the store
      setMenuItems(menuItemsByGroup[slug]);
      // fulfillDataRequest('menu-by-slug', slug);
    } else {
      if (reqState !== DataRequestStateEnum.RUNNING && reqState !== DataRequestStateEnum.EMPTY_RESPONSE) {
        orderData('menu-by-slug', slug);
      }
    }
  }, [menuItemsByGroup, reqState]);

  if (ENV_SSR) return [menuItemsByGroup && menuItemsByGroup[slug] ? menuItemsByGroup[slug] : [], reqState];

  return [menuItems, reqState];
}
