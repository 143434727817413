import React from 'react';

export enum IconFillEnum {
  PRIMARY = 'IconLink--PrimaryFill',
  SECONDARY = 'IconLink--SecondaryFill',
  WHITE = 'IconLink--WhiteFill',
  BLACK = 'IconLink--BlackFill',
}

export enum IconStrokeEnum {
  PRIMARY = 'IconLink--PrimaryStroke',
  SECONDARY = 'IconLink--SecondaryStroke',
  WHITE = 'IconLink--WhiteStroke',
  BLACK = 'IconLink--BlackStroke',
}

export enum IconBorderEnum {
  PRIMARY = 'IconLink--PrimaryBorder',
  SECONDARY = 'IconLink--SecondaryBorder',
  WHITE = 'IconLink--WhiteBorder',
  BLACK = 'IconLink--BlackBorder',
  NONE = 'IconLink--BorderNone',
}

interface IconProps<C extends React.ElementType> {
  component?: C;
  children: React.ReactNode;
  fill?: IconFillEnum | undefined;
  stroke?: IconStrokeEnum | undefined;
  border?: IconBorderEnum | undefined;
  className?: string;
}

type IconPropsType<C extends React.ElementType> = IconProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof IconProps<C>>;

const IconLink = <C extends React.ElementType>(props: IconPropsType<C>): JSX.Element => {
  const { children, component, fill, stroke, border, className, ...rest } = props;
  const Component = component || 'a';
  return (
    <Component
      className={`IconLink 
      ${fill ? fill : ''}
      ${stroke ? stroke : ''} 
      ${border ? border : ''} 
      ${className ? className : ''}`}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default IconLink;
