import React from 'react';
import { AvatarCircleBorderStyle } from '../simple/AvatarCircle';
import AvatarCircleSkeleton from './AvatarCircleSkeleton';
import DateAndAuthorSkeleton from './DateAndAuthorSkeleton';

export default function PostDateAndAuthorSkeleton({
  showAuthorAvatar,
  className,
}: {
  showAuthorAvatar: boolean;
  className?: string;
}): JSX.Element {
  return (
    <div className={`PostDateAndAuthor ${className ? className : ''}`}>
      {showAuthorAvatar && <AvatarCircleSkeleton width={44} height={44} borderStyle={AvatarCircleBorderStyle.NONE} />}
      <DateAndAuthorSkeleton />
    </div>
  );
}
