import { WpOrderEnum, WpPostOptions } from './WpPostOptions';
import axios from 'axios';
import mapWpPost from '../mappers/mapWpPost';
import { PostType } from '../../../types/PostTypes';

const API_POSTS = '/wp-json/wp/v2/posts';

export default class PostsApi {
  private readonly wpHost: string;
  private options: WpPostOptions;

  constructor(wpHost: string) {
    this.wpHost = wpHost;
    this.options = {
      order: WpOrderEnum.Desc,
      tags: [],
      categories: [],
      posts: [],
      embed: 1,
    };
  }

  orderBy(order: WpOrderEnum): PostsApi {
    this.options.order = order;
    return this;
  }

  setPage(pageNumber: number): PostsApi {
    this.options.pageNumber = pageNumber;
    return this;
  }

  setPerPage(perPage: number): PostsApi {
    this.options.perPage = perPage;
    return this;
  }

  filterByCategory(categoryId: number): PostsApi {
    this.options.categories.push(categoryId);
    return this;
  }

  filterByTag(categoryId: number): PostsApi {
    this.options.tags.push(categoryId);
    return this;
  }

  filterByPost(postId: number): PostsApi {
    this.options.posts.push(postId);
    return this;
  }

  generateUrl(): string {
    let result: string[] = [];
    result.push('order=' + this.options.order);
    if (this.options.embed === 1) {
      result = [...result, '_embed=1'];
    }

    if (this.options.categories.length > 0) {
      result = [...result, ...this.options.categories.map(this.mapCreateKey('categories'))];
    }
    if (this.options.tags.length > 0) {
      result = [...result, ...this.options.tags.map(this.mapCreateKey('tags'))];
    }
    if (this.options.posts.length > 0) {
      result = [...result, ...this.options.posts.map(this.mapCreateKey('includes'))];
    }
    if (this.options.perPage) {
      result = [...result, 'per_page=' + this.options.perPage];
    }
    if (this.options.pageNumber) {
      result = [...result, 'page=' + this.options.pageNumber];
    }
    return this.wpHost + API_POSTS + '?' + result.join('&');
  }

  mapCreateKey(keyName: string): (id: number) => string {
    return (id: number): string => `${keyName}[]=${id}`;
  }

  async fetch(): Promise<PostType[]> {
    ENV_SSR && console.log('SSR-fetch-wp-posts');
    const response = await axios.get(this.generateUrl());
    if (response.status === 200) {
      return response.data.map(mapWpPost);
    }
    throw new Error('request failed');
  }
}
