import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import ProvidePostsByAuthorSlug from './actions/ProvidePostsByAuthorSlug';

export default function usePostsByAuthorSlugDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('posts-by-author-slug', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang]
        .map((sign: string) => sign.split('|'))
        .map((signParts: string[]) => ({
          slug: signParts[0],
          pageNumber: Number(signParts[1]),
        }))
        .forEach(({ slug, pageNumber }) => {
          dispatch(ProvidePostsByAuthorSlug(slug, pageNumber, lang));
        }),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang]
        .map((sign: string) => sign.split('|'))
        .map((signParts: string[]) => ({
          slug: signParts[0],
          pageNumber: Number(signParts[1]),
        }))
        .forEach(({ slug, pageNumber }) => {
          dispatch(ProvidePostsByAuthorSlug(slug, pageNumber, lang));
        }),
    );
  }
}
