import React from 'react';

export enum CheckboxBoxColorEnum {
  PRIMARY = 'Checkbox--Box-Primary',
  SECONDARY = 'Checkbox--Box-Secondary',
  LIGHT = 'Checkbox--Box-Light',
}

export enum CheckboxCheckColorEnum {
  PRIMARY = 'Checkbox--Check-Primary',
  SECONDARY = 'Checkbox--Check-Secondary',
  WHITE = 'Checkbox--Check-White',
}

export enum CheckboxTextColorEnum {
  PRIMARY = 'Checkbox--Text-Primary',
  SECONDARY = 'Checkbox--Text-Secondary',
  WHITE = 'Checkbox--Text-White',
}

export type CheckboxProps = {
  checked: boolean;
  boxColor: CheckboxBoxColorEnum;
  checkColor: CheckboxCheckColorEnum;
  textColor: CheckboxTextColorEnum;
  children?: React.ReactNode;
  onChange: (data: boolean) => void;
  id: string;
  name?: string;
  className?: string;
};

export default function Checkbox({
  children,
  boxColor,
  checkColor,
  textColor,
  onChange,
  id,
  name,
  className,
  checked,
}: CheckboxProps): JSX.Element {
  return (
    <>
      <input
        className={`Checkbox ${className ? className : ''}`}
        type={'checkbox'}
        onChange={(e) => onChange(e.currentTarget.checked)}
        checked={checked}
        id={id}
        name={name}
      />
      <label htmlFor={id} className={`Checkbox--label ${textColor} ${boxColor} ${checkColor}`}>
        {children}
        <svg fill="none" version="1.1" viewBox="0 0 9.707 7.0605" xmlns="http://www.w3.org/2000/svg">
          <path d="m9 0-5.6465 5.6465-2.6465-2.6465-0.70703 0.70703 3.3535 3.3535 6.3535-6.3535z" fill="#0F4168" />
        </svg>
      </label>
    </>
  );
}
