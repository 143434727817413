import React from 'react';
import { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import Skeleton from 'react-loading-skeleton';

export type SectionHeaderSkeletonProps = {
  borderStyle: SectionHeaderBorderStyle;
  className?: string;
};

export default function SectionHeaderSkeleton({ borderStyle, className }: SectionHeaderSkeletonProps): JSX.Element {
  return (
    <div className={`SectionHeader pt-4 ${borderStyle} ${className ? className : ''}`}>
      <h2 style={{ width: '25%' }}>
        <Skeleton />
      </h2>
    </div>
  );
}
