import React from 'react';
import SWLink from '../../fuctional/SWLink';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import LogoColor from '../../../assets/svg/logo_color_large.svg';
import UserIcon from '../../../assets/svg/user.svg';
import IconLink, { IconBorderEnum, IconFillEnum } from '../../simple/IconLink';
import HamburgerIcon from '../../../assets/svg/hamburger.svg';
import CloseIcon from '../../../assets/svg/close.svg';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import LanguageToggle from '../../compound/masthead/LanguageToggle';
import { generateHomepage } from '../../router/GenerateUrl';
import UkraineFlag from '../../../assets/img/ukraine-flag.png';

export type HeaderLineProps = {
  className?: string;
  hamburgerHandler: () => void;
  hamburgerShown: boolean;
};

export default function HeaderLine({ className, hamburgerHandler, hamburgerShown }: HeaderLineProps): JSX.Element {
  const t = useTranslateMessage();
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);
  const language = useCurrentLanguage();
  const homepage = className !== 'storybook' ? generateHomepage(language.code) : '';

  return (
    <div className={`HeaderLine ${className ? className : ''}`}>
      <div className={'Layout--ThreeColumnCentered-Flex'}>
        <div className={'Layout--left'}>
          {matchesMediumUp && (
            <SWLink to={'#'} className={'HeaderLine--UserLogin pr-4 display-none'}>
              <UserIcon className={'p-2'} />
              {t('login.label')}
            </SWLink>
          )}
          {/*<LanguageToggle />*/}
        </div>
        <div className={'Layout--center'}>
          <SWLink to={homepage} className={'HeaderLine--logo'} title={'TheFix Homepage'}>
            <LogoColor />
          </SWLink>
        </div>
        <div className={'Layout--right'}>
          {matchesMediumUp ? (
            <>
              <a
                href={'https://helpukraine.thefix.media/'}
                target={'_blank'}
                rel={'noreferrer'}
                className={'Help-Ukraine-link mr-6'}
              >
                <img src={UkraineFlag} alt={'Ukraine flag'} />
                &nbsp; Help Ukraine
              </a>
              <a
                className={'HeaderLine--PromoLink'}
                target={'_blank'}
                href={'https://www.thefix.foundation/'}
                rel="noreferrer"
              >
                The Fix Foundation
              </a>
              <Button
                btnType={ButtonTypeEnum.SECONDARY}
                height={ButtonHeightEnum.DEF}
                component={SWLink}
                to={'/subscription'}
                width={ButtonWidthEnum.DEF}
                className={'mr-4 display-none'}
              >
                {t('newsletter.label')}
              </Button>
              <Button
                btnType={ButtonTypeEnum.PRIMARY}
                height={ButtonHeightEnum.DEF}
                onClick={() => {
                  console.log('singup-click');
                }}
                width={ButtonWidthEnum.DEF}
                className={'display-none'}
              >
                {t('register.label')}
              </Button>
            </>
          ) : (
            <IconLink
              fill={hamburgerShown ? IconFillEnum.WHITE : IconFillEnum.SECONDARY}
              border={hamburgerShown ? undefined : IconBorderEnum.PRIMARY}
              component={'button'}
              className={hamburgerShown ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.GHOST}
              onClick={hamburgerHandler}
              aria-label={hamburgerShown ? 'Open hamburger menu' : 'Close hamburger menu'}
            >
              {hamburgerShown ? <CloseIcon /> : <HamburgerIcon />}
            </IconLink>
          )}
        </div>
      </div>
      {matchesMediumUp && <p className={'Headerline--hook pt-7'}>{t('header.subtitle')}</p>}
    </div>
  );
}
