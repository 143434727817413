import { IMultiLanguageRouteSpec, RoutePathInLanguage } from './NewRouteList';
import routeList from './NewRouteList';
import { matchPath } from 'react-router-dom';

/**
 * function goes through:
 *  Homepage,
 *  languages(except default),
 *  default language
 *
 * @param url
 * @returns [
 *  IMultiLanguageRouteSpec
 *  language
 *  ]
 */
export function matchUrl(url: string): [IMultiLanguageRouteSpec, string] {
  for (let i = 0; i < Object.keys(routeList.HomepagePath.path).length; i++) {
    const lang = Object.keys(routeList.HomepagePath.path)[i];
    const matched = matchPath(url, routeList.HomepagePath.path[lang]);
    if (matched === null || !matched.isExact) continue;
    return [routeList.HomepagePath, lang];
  }

  /// For all languages except default
  for (let i = 0; i < routeList.Paths.length; i++) {
    for (let l = 0; l < routeList.Languages.length; l++) {
      if (routeList.Languages[l] === routeList.DefaultLanguage) continue;
      const lang = routeList.Languages[l];
      const matched = matchPath(url, RoutePathInLanguage(lang, routeList.Paths[i], routeList));
      if (matched === null || !matched.isExact) continue;
      return [
        {
          ...routeList.Paths[i],
          params: matched.params,
        } as IMultiLanguageRouteSpec,
        lang,
      ];
    }
  }

  /// For default language
  for (let i = 0; i < routeList.Paths.length; i++) {
    const lang = routeList.DefaultLanguage;
    const matched = matchPath(url, RoutePathInLanguage(lang, routeList.Paths[i], routeList));
    if (matched === null || !matched.isExact) continue;
    return [
      {
        ...routeList.Paths[i],
        params: matched.params,
      } as IMultiLanguageRouteSpec,
      lang,
    ];
  }
  return [routeList.HomepagePath, routeList.DefaultLanguage];
}
