import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeFullPosts, storePostSlugMapping } from '../../../../core/bucket/BucketSlice';
import FcPostsApi from '../../post/FcPostsApi';

export function ProvidePostBySlug(slug: string, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'post-by-slug',
      sign: slug,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (slug: string): Promise<void> => {
      const postApi = new FcPostsApi(ENV_API_URL);
      const posts = await postApi.setSlug(slug).setLanguage(lang).fetchSingle();

      if (posts.length > 0) {
        const post = posts[0];
        dispatch(storeFullPosts({ [lang]: [post] }));
        if (post.slug !== slug) {
          dispatch(storePostSlugMapping({ [lang]: [{ ...post, slug: slug }] }));
        }

        // dispatch(storeCategories({ [lang]: post.categories }));
        // dispatch(storeTags({ [lang]: post.tags }));
        // dispatch(storeAuthors({ [lang]: post.authors }));
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(slug);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
