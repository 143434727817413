import React, { FormEvent, useEffect, useState } from 'react';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import TagList from '../compound/TagList';
import { TagTypeEnum } from '../simple/TagBadge';
import SectionHeader, { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import usePageBySlugProvider from '../../core/dataProviders/providers/pages/usePageBySlugProvider';
import hasValidData from '../../utils/hasValidData';
import useAllTagsProvider from '../../core/dataProviders/providers/tags/useAllTagsProvider';
import useAllTagsPagingInfo from '../../core/dataProviders/providers/tags/useAllTagsPagingInfo';
import { TagType } from '../../types/PostTypes';
import { DataRequestStateEnum } from '../../core/dataProviders/DataProviderState';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import Input, { InputTypeEnum } from '../simple/Input';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../simple/Button';
import useTagsBySearchTermProvider from '../../core/dataProviders/providers/tags/useTagsBySearchTermProvider';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function AllTopicsPage(): JSX.Element {
  const t = useTranslateMessage();
  const [page, reqState] = usePageBySlugProvider(t('slug.page.all-topics'));
  const allTopicsPaging = useAllTagsPagingInfo();
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [allTopics, setAllTopics] = useState<TagType[]>([]);
  const [allTopicsPageNumber, setAllTopicsPageNumber] = useState(1);
  const [allTopicsPage, allTopicsPageReqState] = useAllTagsProvider(allTopicsPageNumber);
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(page, reqState) ? page : null);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');
  const [inputSearchTerm, setInputSearchTerm] = useState<string | null>(null);
  const [searchedTopics, searchedTopicsReqState] = useTagsBySearchTermProvider(inputSearchTerm, 1);
  const searchSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInputSearchTerm(inputSearchValue);
  };

  const handleSearchValue = (event: React.FormEvent<HTMLInputElement>) => {
    setInputSearchValue(event.currentTarget.value);
  };

  useEffect(() => {
    if (hasValidData(allTopicsPage, allTopicsPageReqState)) {
      if (allTopicsPageNumber < allTopicsPaging.pages) {
        allTopicsPage[0] !== allTopics[0] &&
          setAllTopics((previousState) => {
            return previousState.concat(allTopicsPage);
          });
      }
    }
  }, [allTopicsPageReqState, allTopicsPage]);

  useEffect(() => {
    if (hasValidData(allTopicsPage, allTopicsPageReqState)) {
      if (allTopicsPageNumber < allTopicsPaging.pages) {
        if (scrolledToBottom) {
          setAllTopicsPageNumber((previousState) => {
            return previousState + 1;
          });
          setScrolledToBottom(false);
        }
      }
    }
  }, [scrolledToBottom]);

  const checkScrolledToBottom = () => {
    !inputSearchTerm &&
    window.scrollY + 500 > document.documentElement.getBoundingClientRect().height - window.innerHeight
      ? setScrolledToBottom(true)
      : setScrolledToBottom(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrolledToBottom);
    return () => window.removeEventListener('scroll', checkScrolledToBottom);
  });

  useEffect(() => {
    if (hasValidData(page, reqState)) {
      setMetaDataPost(page);
      trackPageViewGTMEvent('all-topics', ['all-topics'], ['theFix']);
    }
  }, [reqState, page]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <LeftAlignedPageBanner
        title={true}
        titleValue={t('title.list-of-all-topics')}
        description={false}
        topics={false}
        className={'mb-12'}
      />

      <div className={'Layout--container mb-12'}>
        <div className={'AllTopicsPage'}>
          <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
            <h2>
              {t('title.topic')} <span>{t('title.to-fix')}</span>
            </h2>
          </SectionHeader>

          <form className={'AllTopicsPage-searchForm mb-4'} onSubmit={searchSubmitHandler}>
            <Button btnType={ButtonTypeEnum.PRIMARY} width={ButtonWidthEnum.DEF} height={ButtonHeightEnum.DEF}>
              {t('search.label')}
            </Button>
            <Input
              id={'topics-search-input'}
              type={InputTypeEnum.TEXT}
              value={inputSearchValue}
              onChange={handleSearchValue}
              placeholder={t('search.hint')}
            />
          </form>

          {!inputSearchTerm && (
            <TagList tagListItems={allTopics} tagListReqState={allTopicsPageReqState} type={TagTypeEnum.DARK_LESS} />
          )}

          {inputSearchTerm && (
            <TagList
              tagListItems={searchedTopics}
              tagListReqState={searchedTopicsReqState}
              type={TagTypeEnum.DARK_LESS}
            />
          )}

          {searchedTopicsReqState === DataRequestStateEnum.EMPTY_RESPONSE && (
            <p className={'SearchPage--error p-5 mb-15'}>
              No result for term <span>“{inputSearchTerm}”</span> found.
            </p>
          )}
          {searchedTopicsReqState === DataRequestStateEnum.FAILED && (
            <p className={'SearchPage--error p-5 mb-15'}>Search failed, try again later</p>
          )}
        </div>
      </div>
    </>
  );
}
