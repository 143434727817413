import React, { useEffect, useState } from 'react';
import { PostType } from '../../../types/PostTypes';
import hasValidData from '../../../utils/hasValidData';
import PostDetailAuthorName from '../../compound/post/page/PostDetailAuthorName';
import PostDetailAuthorSocials from '../../compound/post/page/PostDetailAuthorSocials';
import PostDetailAuthorNameSkeleton from '../../skeletons/PostDetailAuthorNameSkeleton';
import PostDetailAuthorSocialsSkeleton from '../../skeletons/PostDetailAuthorSocialsSkeleton';
import PostContent from '../../compound/post/page/PostContent';
import PostContentSkeleton from '../../skeletons/PostContentSkeleton';
import CardPost from '../../compound/post/cards/CardPost';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import PostTranslationSwapper from '../../compound/post/page/PostTranslationSwapper';
import usePostBySlugProvider from '../../../core/dataProviders/providers/usePostBySlugProvider';
import ProductPromotional, { ProductPromotionalSize } from '../../compound/product/ProductPromotional';
import useProductBySlugProvider from '../../../core/dataProviders/providers/products/useProductBySlugProvider';
import useAllProductsProvider from '../../../core/dataProviders/providers/products/useAllProductsProvider';
import PostContentExcerpt from '../../compound/post/page/PostContentExcerpt';

export type PostPageContentWrapperProps = {
  post: PostType | null;
  reqState: DataRequestStateEnum;
  filteredCategoryPosts: PostType[];
  postCatPostsReqState: DataRequestStateEnum;
  className?: string;
};

export default function PostPageContentWrapper({
  post,
  reqState,
  filteredCategoryPosts,
  postCatPostsReqState,
  className,
}: PostPageContentWrapperProps): JSX.Element {
  const [allProducts, allProductReqState] = useAllProductsProvider(false);
  const [pinnedPostSlug, setPinnedPostSlug] = useState<string | null>(null);
  const [pinnedProductSlug, setPinnedProductSlug] = useState<string | null>(null);
  const [pinnedPost, pinnedPostReqState] = usePostBySlugProvider(pinnedPostSlug, false);
  const [pinnedProduct, pinnedProductReqState] = useProductBySlugProvider(pinnedProductSlug, false);
  const [pinnedPostPosition, setPinnedPostPosition] = useState(0);
  const [pinnedProductPosition, setPinnedProductPosition] = useState(0);
  const [isPinnedPost, setIsPinnedPost] = useState(false);
  const [isPinnedProduct, setIsPinnedProduct] = useState(false);

  useEffect(() => {
    if (hasValidData(post, reqState)) {
      getPinnedPostData();
      getPinnedProductData();
    }
  }, [reqState, post]);

  const getPinnedPostData = () => {
    const pinnedPost = document.getElementById('pinned-post');
    setIsPinnedPost(pinnedPost !== null);

    const middleColumn = document.getElementsByClassName('PostPage--MiddleColumn')[0];
    if (pinnedPost) {
      setPinnedPostPosition(pinnedPost.getBoundingClientRect().top - middleColumn.getBoundingClientRect().top);

      const pinnedPostUrl = pinnedPost.dataset.url;
      if (pinnedPostUrl) {
        const url = new URL(pinnedPostUrl);
        const postSlug = url.pathname.split('/')[url.pathname.split('/').length - 1];
        setPinnedPostSlug(postSlug);
      }
    }
  };

  const getPinnedProductData = () => {
    const pinnedProduct = document.getElementById('pinned-product');
    setIsPinnedProduct(pinnedProduct !== null);

    const middleColumn = document.getElementsByClassName('PostPage--MiddleColumn')[0];
    if (pinnedProduct) {
      setPinnedProductPosition(pinnedProduct.getBoundingClientRect().top - middleColumn.getBoundingClientRect().top);

      const pinnedProductUrl = pinnedProduct.dataset.url;
      if (pinnedProductUrl) {
        const url = new URL(pinnedProductUrl);
        const productSlug = url.pathname.split('/')[url.pathname.split('/').length - 1];
        setPinnedProductSlug(productSlug);
      }
    }
  };

  return (
    <div className={`PostPageContentWrapper Layout--container-wide ${className ? className : ''}`}>
      <div className={'Layout--ThreeColumnMiddleContent-Grid'}>
        <div className={'PostPage--LeftColumn mt-12'}>
          {hasValidData(post, reqState) ? (
            post.authors.map((author) => {
              return (
                <div key={`${author.name}-float`} className={'PostPage--AuthorAndFollow-Float pb-4 mb-16'}>
                  <PostDetailAuthorName author={author} />
                  <PostDetailAuthorSocials author={author} />
                </div>
              );
            })
          ) : (
            <div className={'PostPage--AuthorAndFollow-Float pb-4 mb-4'}>
              <PostDetailAuthorNameSkeleton />
              <PostDetailAuthorSocialsSkeleton />
            </div>
          )}
        </div>

        <div className={'PostPage--MiddleColumn'}>
          {hasValidData(post, reqState) ? (
            <>
              {/*<PostTranslationSwapper post={post} />*/}

              <PostContentExcerpt post={post} className={'mb-10'} />

              <PostContent post={post} />
            </>
          ) : (
            <PostContentSkeleton />
          )}
        </div>

        <div className={'PostPage--RightColumn'}>
          {isPinnedPost && hasValidData(pinnedPost, pinnedPostReqState) && (
            <CardPost post={pinnedPost} className={'PostPage--PinnedPost'} style={{ top: pinnedPostPosition }} />
          )}

          {isPinnedPost &&
            !hasValidData(pinnedPost, pinnedPostReqState) &&
            hasValidData(filteredCategoryPosts, postCatPostsReqState) && (
              <CardPost
                post={filteredCategoryPosts[0]}
                className={'PostPage--PinnedPost'}
                style={{ top: pinnedPostPosition }}
              />
            )}

          {isPinnedProduct && hasValidData(pinnedProduct, pinnedProductReqState) && (
            <ProductPromotional
              type={ProductPromotionalSize.SMALL}
              product={pinnedProduct}
              className={'PostPage--PinnedProduct mb-7 mt-20'}
              style={{ top: pinnedProductPosition }}
            />
          )}

          {isPinnedProduct &&
            !hasValidData(pinnedProduct, pinnedProductReqState) &&
            hasValidData(allProducts, allProductReqState) && (
              <ProductPromotional
                type={ProductPromotionalSize.SMALL}
                product={allProducts[0]}
                className={'PostPage--PinnedProduct mb-7 mt-20'}
                style={{ top: pinnedProductPosition }}
              />
            )}
        </div>
      </div>
    </div>
  );
}
