import React from 'react';

export type MiniPostWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

export default function MiniPostWrapper({ children, className }: MiniPostWrapperProps): JSX.Element {
  return <div className={`MiniPostWrapper ${className ? className : ''}`}>{children}</div>;
}
