import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import ProvidePostsByCategoryId from './actions/ProvidePostsByCategoryId';

export default function usePostsByCategoryIdDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('posts-by-category-id', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang]
        .map((sign: string) => sign.split('|'))
        .filter((sign: string[]) => sign.length === 2)
        .map((sign: string[]) => ({
          categoryId: Number(sign[0]),
          pageNumber: Number(sign[1]),
        }))
        .forEach(({ categoryId, pageNumber }) => dispatch(ProvidePostsByCategoryId(categoryId, pageNumber, lang))),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang]
        .map((sign: string) => sign.split('|'))
        .filter((sign: string[]) => sign.length === 2)
        .map((sign: string[]) => ({
          categoryId: Number(sign[0]),
          pageNumber: Number(sign[1]),
        }))
        .forEach(({ categoryId, pageNumber }) => dispatch(ProvidePostsByCategoryId(categoryId, pageNumber, lang))),
    );
  }
}
