import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeCategories, storeCategorySlugMapping } from '../../../../core/bucket/BucketSlice';
import CategoryApi from '../../category/CategoryApi';

export function ProvideCategoryBySlug(slug: string, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'category-slug',
      sign: slug,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (slug: string): Promise<void> => {
      const categoryApi = new CategoryApi(ENV_API_URL);
      await categoryApi.setSlug(slug).setLanguage(lang).fetch();

      if (categoryApi.result && categoryApi.result.length > 0) {
        dispatch(storeCategories({ [lang]: [categoryApi.result[0]] }));
        if (categoryApi.result[0].slug !== slug) {
          dispatch(storeCategorySlugMapping({ [lang]: [{ ...categoryApi.result[0], slug: slug }] }));
        }
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(slug);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
