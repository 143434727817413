import { useEffect, useState } from 'react';
import { TagType } from '../../../../types/PostTypes';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import useTagsFiller from '../../../Hooks/useTagsFiller';
import { LatestTagsSelector } from '../../../tags/TagsSlice';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import useLangSelector from '../../../Hooks/useLangSelector';

export default function useAllTagsProvider(
  pageNumber: number,
  includeInSSR = true,
): [
  /// Tags
  TagType[],
  /// Request State
  DataRequestStateEnum,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();
  const tagFiller = useTagsFiller();

  /// Redux Selectors ----------------------------------------------------------
  const latestTags = useLangSelector(useAppSelector(LatestTagsSelector));
  const tags = useLangSelector(useAppSelector(LatestTagsSelector));

  /// Current hook state -------------------------------------------------------
  const [currentPageNumber, setCurrentPageNumber] = useState<number | null>(() => null);
  const [currentTags, setCurrentTags] = useState<TagType[]>(() => {
    if (latestTags && latestTags[pageNumber] && tags) {
      return tagFiller(latestTags[pageNumber]);
    }
    return [];
  });
  const [reqState, , setDataProviderPageNumber] = useDataProviderState('all-tags', '' + pageNumber);

  /// on pageNumber change  ----------------------------------------------------
  useEffect(() => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPageNumber(pageNumber);

      if (latestTags && latestTags[pageNumber]) {
        setCurrentTags(tagFiller(latestTags[pageNumber]));
      } else {
        setCurrentTags([]);
        if (pageNumber) {
          setDataProviderPageNumber('' + pageNumber);
          orderData('all-tags', '' + pageNumber);
        }
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    /// Make sure we are listening to effect hook
    if (currentTags !== null && currentTags.length > 0) return;
    if (currentPageNumber && latestTags && latestTags[currentPageNumber])
      setCurrentTags(tagFiller(latestTags[currentPageNumber]));
  }, [tags]);

  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('all-tags', '' + pageNumber);

  return [currentTags, currentTags.length > 0 ? DataRequestStateEnum.FULFILLED : reqState];
}
