import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import WooProductApi from '../../product/WooProductApi';
import { storeProducts } from '../../../../core/bucket/BucketSlice';
import { setAllProducts } from '../../../../core/products/ProductSlice';

export function ProvideAllProducts(lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'all-products',
      sign: '1',
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (): Promise<void> => {
      const productApi = new WooProductApi(WOOCOMMERCE_PROXY);
      const products = await productApi.setLanguage(lang).fetchAllProducts();
      if (products) {
        dispatch(storeProducts({ [lang]: products }));
        dispatch(setAllProducts({ [lang]: products }));
        return;
      }
      throw new Error('404');
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi();
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
