import React from 'react';
import { PostType } from '../../../types/PostTypes';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import hasValidData from '../../../utils/hasValidData';
import PostDetailAuthor from '../../compound/post/page/PostDetailAuthor';
import PostDetailAuthorSkeleton from '../../skeletons/PostDetailAuthorSkeleton';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { AuthorType } from '../../../types/PostTypes';

export type PostPageAuthorsProps = {
  post: PostType | null;
  reqState: DataRequestStateEnum;
  className?: string;
};

export default function PostPageAuthors({ post, reqState, className }: PostPageAuthorsProps): JSX.Element {
  return (
    <div className={`PostPageAuthors Layout--container ${className ? className : ''}`}>
      <div className={'PostPage--ThinWidth-Centered'}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{hasValidData(post, reqState) ? (post.authors.length > 1 ? 'Authors' : 'Author') : 'Author'}</h2>
        </SectionHeader>

        {hasValidData(post, reqState) ? (
          post.authors.map((author: AuthorType) => {
            return <PostDetailAuthor key={`${author.name}-bottom`} author={author} className={'py-7 px-6 mb-7'} />;
          })
        ) : (
          <PostDetailAuthorSkeleton className={'py-7 px-6 mb-7'} />
        )}
      </div>
    </div>
  );
}
