import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ProductPageBannerSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`ProductPageBanner ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <div className={'ProductPageBanner--Wrapper Layout--TwoColumn-Grid'}>
          <div className={'ProductPageBanner--Headlines'}>
            <p className={'ProductPageBanner--Caption pb-6'}>Research</p>
            <h1 className={'ProductPageBanner--Title pb-6'}>
              <Skeleton width={'80%'} count={2} />
            </h1>
            <p className={'ProductPageBanner--Description pb-6'}>
              <Skeleton width={'70%'} count={4} />
            </p>
            <div className={'ProductPageBanner--Price py-6 mb-7'}>
              <div className={'ProductPageBanner--Price-Regular pr-0 pr-md-15'}>
                <p className={'ProductPageBanner--Price-Caption'}>Price:</p>
                <p className={'ProductPageBanner--Price-Value'}>
                  <Skeleton width={90} />
                </p>
              </div>
            </div>
            <Skeleton width={'40%'} height={40} />
          </div>
        </div>
      </div>
    </div>
  );
}
