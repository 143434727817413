import { useEffect, useState } from 'react';
import { DataRequests, DataRequestStateEnum } from './DataProviderState';
import { useAppSelector } from '../../redux/hooks';
import { DataProviderSelector } from './DataProviderSlice';

/**
 * filters data requests and returns requests mapped by language.code
 *
 * @param requests
 * @param type
 * @param state
 * @param memo
 */
function filterRequests(
  requests: DataRequests,
  type: string,
  state: DataRequestStateEnum,
  memo: { [lng: string]: string[] },
): { [lng: string]: string[] } {
  const newList: { [lng: string]: string[] } = {};
  Object.keys(requests).forEach((lang: string) => {
    if (!requests[lang][type]) return;
    const filteredByLanguage: string[] = Object.keys(requests[lang][type]).filter(
      (id: string) => requests[lang][type][id] === state && (!memo[lang] || memo[lang].indexOf(id) === -1),
    );
    if (filteredByLanguage.length > 0) newList[lang] = filteredByLanguage;
  });
  return newList;
}

export default function useDataProviderFilterByState(
  type: string,
  state: DataRequestStateEnum,
): { [lng: string]: string[] } {
  const requests = useAppSelector(DataProviderSelector);
  const [requestIds, setRequestIds] = useState<{ [lng: string]: string[] }>({});
  const [memo, setMemo] = useState<{ [lng: string]: string[] }>({});

  useEffect(() => {
    if (!requests) {
      setRequestIds({});
      return;
    }
    const newList = filterRequests(requests, type, state, memo);
    if (Object.keys(newList).length > 0) {
      setRequestIds(newList);
      setMemo(newList);
    }
  }, [requests]);

  if (ENV_SSR) {
    if (!requests || Object.keys(requests).length === 0) return {};
    return filterRequests(requests, type, state, {});
  }

  return requestIds;
}
