import React from 'react';
import useMediaQuery, { mediaQueries } from '../../core/Hooks/useMediaQuery';
import PostDetailAuthorNameSkeleton from './PostDetailAuthorNameSkeleton';
import PostDetailAuthorSocialsSkeleton from './PostDetailAuthorSocialsSkeleton';
import Skeleton from 'react-loading-skeleton';

export default function PostDetailAuthorSkeleton({ className }: { className?: string }): JSX.Element {
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);

  return (
    <div className={`PostDetailAuthor ${className ? className : ''}`}>
      <div className={'PostDetailAuthor--NameWrapper pb-4'}>
        <PostDetailAuthorNameSkeleton />
        {matchesMediumUp && <PostDetailAuthorSocialsSkeleton />}
      </div>
      <p className={'PostDetailAuthor--Description pb-4 pb-md-0'}>
        <Skeleton count={3} />
      </p>
      {!matchesMediumUp && <PostDetailAuthorSocialsSkeleton />}
    </div>
  );
}
