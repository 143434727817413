import { PostType } from '../../types/PostTypes';
import { IndexedObjects, PagedObjects } from '../../types/StoreTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ObjectsByPage, PrepareObjectForPageNumber } from '../../types/PayloadActionTypes';
import { RootState } from '../store';

export const NAME = 'posts';

export interface PostsState {
  byCategory: IndexedObjects<PagedObjects<number[]>>;
  byTag: IndexedObjects<PagedObjects<number[]>>;
  byAuthor: IndexedObjects<PagedObjects<number[]>>;
  latest: PagedObjects<number[]>;
  posts: IndexedObjects<PostType>;
  currentListing: PostType[];
}

const initialState: PostsState = {
  byCategory: {},
  byTag: {},
  byAuthor: {},
  posts: {},
  latest: {},
  currentListing: [],
};

function initializePagedObjects<T>(object: IndexedObjects<PagedObjects<T>>, key: number, page: number, value: T) {
  if (!object.hasOwnProperty(key)) object[key] = {};
  if (!object[key].hasOwnProperty(page)) object[key][page] = value;
}

type WithIdType = {
  id: number;
};

function getIds(objects: WithIdType[]): number[] {
  return objects.map((obj: WithIdType) => obj.id);
}

function objectsForPageNumber<T>(
  object: IndexedObjects<PagedObjects<T[]>>,
  forObject: number,
  pageNumber: number,
): T[] {
  if (!object.hasOwnProperty(forObject)) return [];
  if (!object[forObject].hasOwnProperty(pageNumber)) return [];
  return object[forObject][pageNumber];
}

export const postsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    storePost: (state: PostsState, { payload }: PayloadAction<PostType>) => {
      state.posts[payload.id] = payload;
    },

    setByCategory: (state: PostsState, { payload }: PayloadAction<ObjectsByPage<PostType>>) => {
      initializePagedObjects(state.byCategory, payload.forObject, payload.pageNumber, getIds(payload.objects));

      payload.objects.forEach((post: PostType) => {
        state.posts[post.id] = post;
      });
    },

    setByTag: (state: PostsState, { payload }: PayloadAction<ObjectsByPage<PostType>>) => {
      initializePagedObjects(state.byTag, payload.forObject, payload.pageNumber, getIds(payload.objects));

      payload.objects.forEach((post: PostType) => {
        state.posts[post.id] = post;
      });
    },

    setByAuthor: (state: PostsState, { payload }: PayloadAction<ObjectsByPage<PostType>>) => {
      initializePagedObjects(state.byTag, payload.forObject, payload.pageNumber, getIds(payload.objects));

      payload.objects.forEach((post: PostType) => {
        state.posts[post.id] = post;
      });
    },

    setLatest: (state: PostsState, { payload }: PayloadAction<ObjectsByPage<PostType>>) => {
      state.latest[payload.pageNumber] = getIds(payload.objects);

      payload.objects.forEach((post: PostType) => {
        state.posts[post.id] = post;
      });
    },

    clearCurrentListing: (state: PostsState) => {
      state.currentListing = [];
    },

    activateByCategory: (state: PostsState, { payload }: PayloadAction<PrepareObjectForPageNumber>) => {
      state.currentListing = objectsForPageNumber(state.byCategory, payload.forObject, payload.pageNumber).map(
        (postId: number) => state.posts[postId],
      );
    },

    activateByTag: (state: PostsState, { payload }: PayloadAction<PrepareObjectForPageNumber>) => {
      state.currentListing = objectsForPageNumber(state.byTag, payload.forObject, payload.pageNumber).map(
        (postId: number) => state.posts[postId],
      );
    },

    activateByAuthor: (state: PostsState, { payload }: PayloadAction<PrepareObjectForPageNumber>) => {
      state.currentListing = objectsForPageNumber(state.byAuthor, payload.forObject, payload.pageNumber).map(
        (postId: number) => state.posts[postId],
      );
    },

    activateLatest: (state: PostsState, { payload }: PayloadAction<PrepareObjectForPageNumber>) => {
      state.currentListing = [];
      if (state.latest[payload.pageNumber]) {
        state.currentListing = state.latest[payload.pageNumber].map((postId: number) => state.posts[postId]);
      }
    },
  },
});

export const postsState = (state: RootState): PostsState => state[NAME];

export const postsSelector = createSelector(postsState, (state: PostsState) => state.posts);

export const currentListingSelector = createSelector(postsState, (state: PostsState) => state.currentListing);

export const pagesInLatestSelector = createSelector(postsState, (state: PostsState) =>
  Object.keys(state.latest).map(Number),
);

export const postsInLatestSelector = createSelector(postsState, (state: PostsState) => state.latest);

export const postsByCategorySelector = createSelector(postsState, (state: PostsState) => state.byCategory);

export const {
  setByAuthor,
  setByTag,
  setByCategory,
  setLatest,
  activateByAuthor,
  activateByCategory,
  activateLatest,
  activateByTag,
  clearCurrentListing,
} = postsSlice.actions;

export interface PostsDispatch {
  setByAuthor: (postsByAuthor: ObjectsByPage<PostType>) => void;
  setByTag: (postsByTag: ObjectsByPage<PostType>) => void;
  setByCategory: (postsByCategory: ObjectsByPage<PostType>) => void;
  setLatest: (latestPosts: ObjectsByPage<PostType>) => void;
}

export default postsSlice.reducer;
