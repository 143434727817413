import React, { FormEvent, useEffect, useRef, useState } from 'react';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import Input, { InputTypeEnum } from '../../simple/Input';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import ArrowRight from '../../../assets/svg/arrow-right.svg';
import IconLink, { IconStrokeEnum } from '../../simple/IconLink';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import { EmailFormFields } from 'react-mailchimp-subscribe';

export type MailchimpFormProps = {
  status: 'error' | 'success' | 'sending' | null;
  message: string | Error | null;
  onValidated: (formData: EmailFormFields) => void;
};

export default function MailchimpForm({ status, onValidated }: MailchimpFormProps): JSX.Element {
  const t = useTranslateMessage();
  const matches = useMediaQuery(mediaQueries.md, true);
  const [inputEmailValue, setInputEmailValue] = useState<string>('');
  const subscribeMessage = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (subscribeMessage.current) {
      status === 'sending' && subscribeMessage.current.classList.remove('MailchimpForm--message-hidden');
    }
    status === 'success' && setInputEmailValue('');
  }, [status]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onValidated({
      EMAIL: inputEmailValue,
    });
  };

  const handleEmailValue = (event: React.FormEvent<HTMLInputElement>) => {
    setInputEmailValue(event.currentTarget.value);
  };

  return (
    <form className={'MailchimpForm'} onSubmit={handleSubmit}>
      <div className={`MailchimpForm--field mb-6`}>
        <Input
          name="email"
          type={InputTypeEnum.EMAIL}
          placeholder={t('newsletter.your-email-address')}
          onChange={handleEmailValue}
          value={inputEmailValue}
          isRequired={true}
        />
        {matches ? (
          <Button
            type="submit"
            btnType={ButtonTypeEnum.PRIMARY}
            onClick={() => null}
            width={ButtonWidthEnum.DEF}
            height={ButtonHeightEnum.DEF}
          >
            {t('newsletter.subscribe')}
          </Button>
        ) : (
          <IconLink
            stroke={IconStrokeEnum.WHITE}
            onClick={() => null}
            component={'button'}
            className={ButtonTypeEnum.PRIMARY}
          >
            <ArrowRight />
          </IconLink>
        )}
      </div>

      {status !== null && (
        <p className={'MailchimpForm--message MailchimpForm--message-hidden p-5 mb-6'} ref={subscribeMessage}>
          {status === 'sending' && t('newsletter.sending')}
          {status === 'error' && t('newsletter.error')}
          {status === 'success' && t('newsletter.success')}
        </p>
      )}
    </form>
  );
}
