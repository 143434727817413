import React, { useEffect } from 'react';

declare global {
  interface Window {
    googletag: any;
    protag: any;
  }
}

function AdRunScript({ id }: { id: string }) {
  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    window.protag = window.protag || { cmd: [] };
    window.protag.cmd.push(function () {
      window.protag.display(`${id}`);
    });
  }, []);
  return <></>;
}

const AdRunScriptMemo = React.memo(AdRunScript);

function AdvertisementBlock({ id }: { id: string }): JSX.Element {
  return (
    <>
      <div id={id} />
      <AdRunScriptMemo id={id} />
    </>
  );
}

export default AdvertisementBlock;
