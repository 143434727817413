import SubscriptionPage from '../pages/SubscriptionPage';
import ErrorPage from '../pages/404';
import LatestPage from '../pages/LatestPage';
import AllTopicsPage from '../pages/AllTopicsPage';
import SearchPage from '../pages/SearchPage';
import CategoryPage from '../pages/CategoryPage';
import TagPage from '../pages/TagPage';
import AuthorPage from '../pages/AuthorPage';
//import ProductPage from '../pages/ProductPage';
import PagePage from '../pages/PagePage';
import { IRouteParams } from './NewRoutes';
import Homepage from '../pages/Homepage';
import PostPage from '../pages/PostPage';
//import ProductsListPage from '../pages/ProductsListPage';
//import CheckoutPage from '../pages/CheckoutPage';
//import PaymentPage from '../pages/PaymentPage';
//import ThankYouPage from '../pages/ThankyouPage';
//import CartPage from '../pages/CartPage';
//import PaymentFailedPage from '../pages/PaymentFailedPage';

export interface IMultiLanguageRouteSpec {
  routeName: string;
  path: {
    [lng: string]: string;
  };
  Component: React.ComponentType<IRouteParams>;
  exact: boolean;
  routeMeta: unknown;
  params?: unknown;
}

interface IRouteMappings {
  Languages: string[];
  DefaultLanguage: string;
  UrlPrefix: {
    [lng: string]: string;
  };
  HomepageURLS: { [lng: string]: string };
  HomepagePath: IMultiLanguageRouteSpec;

  Paths: IMultiLanguageRouteSpec[];
}

export function RoutePathInLanguage(
    language: string,
    route: IMultiLanguageRouteSpec,
    routeList: IRouteMappings,
): string {
  if (route.routeName === 'HOMEPAGE') return route.path[language];
  return routeList.UrlPrefix[language] + route.path[language];
}

export default {
  Languages: ['en', 'de'],
  DefaultLanguage: 'en',
  UrlPrefix: {
    en: '',
    de: '/:lng(de)',
  },

  HomepageURLS: {
    en: '/',
    de: '/:lng(de)',
  },

  HomepagePath: {
    routeName: 'HOMEPAGE',
    path: {
      en: '/',
      de: '/:lng(de)',
    },
    exact: true,
    Component: Homepage,
    routeMeta: {},
  } as IMultiLanguageRouteSpec,

  Paths: [
    {
      routeName: 'SUBSCRIPTION',
      path: {
        en: '/subscribe',
        de: '/subsssdiehard',
      },
      exact: true,
      Component: SubscriptionPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'ERROR',
      path: {
        en: '/404',
        de: '/404',
      },
      exact: true,
      Component: ErrorPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'LATEST',
      path: {
        en: '/latest',
        de: '/neueste',
      },
      exact: true,
      Component: LatestPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'ALLTOPICS',
      path: {
        en: '/all-topics',
        de: '/alle-themen',
      },
      exact: true,
      Component: AllTopicsPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'SEARCH',
      path: {
        en: '/search/:searchTerm/:pageNumber?',
        de: '/suche/:searchTerm/:pageNumber?',
      },
      exact: true,
      Component: SearchPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'CATEGORY',
      path: {
        en: '/category/:slug',
        de: '/category/:slug',
      },
      exact: true,
      Component: CategoryPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'TAG',
      path: {
        en: '/tag/:slug',
        de: '/tag/:slug',
      },
      exact: true,
      Component: TagPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'AUTHOR',
      path: {
        en: '/author/:slug',
        de: '/author/:slug',
      },
      exact: true,
      Component: AuthorPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'POST',
      path: {
        en: '/:year/:month/:day/:slug',
        de: '/:year/:month/:day/:slug',
      },
      exact: true,
      Component: PostPage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
    {
      routeName: 'PAGE',
      path: {
        en: '/:slug/',
        de: '/:slug/',
      },
      exact: true,
      Component: PagePage,
      routeMeta: {},
    } as IMultiLanguageRouteSpec,
  ],
} as IRouteMappings;
