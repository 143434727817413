import { PagingInfoType } from '../../../../types/PostTypes';
import { useAppSelector } from '../../../../redux/hooks';
import { LatestTagPagingInfoSelector } from '../../../tags/TagsSlice';
import useLangSelector from '../../../Hooks/useLangSelector';

export default function useAllTagsPagingInfo(): PagingInfoType {
  return (
    useLangSelector(useAppSelector(LatestTagPagingInfoSelector)) ||
    ({
      pages: 0,
      total: 0,
    } as PagingInfoType)
  );
}
