import React from 'react';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import SWLink from '../../fuctional/SWLink';
import ProductType from '../../../types/ProductType';
import { generateProductUrl } from '../../router/GenerateUrl';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import { useHistory } from 'react-router-dom';
import { useSetProductQuantityInCart } from '../../../core/Cart/LocalStorage/CartContextProvider';
import Image from '../../simple/Image';

export enum ProductPromotionalSize {
  LARGE = 'large',
  SMALL = 'small',
}

export type ProductPromotionalProps = {
  type: ProductPromotionalSize;
  product: ProductType;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export default function ProductPromotional({
  type,
  product,
  children,
  className,
  style,
}: ProductPromotionalProps): JSX.Element {
  const t = useTranslateMessage();
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);
  const language = useCurrentLanguage();
  const history = useHistory();
  const setQuantity = useSetProductQuantityInCart();
  const handleBuy = () => {
    setQuantity(`${product.id}`, 1);
    language.code !== 'en' ? history.push(`/${language.code}/cart`) : history.push('/cart');
  };
  const url = product.slug !== 'storybook' ? generateProductUrl(product, language.code) : '';
  return (
    <div
      className={`ProductPromotional ${type === 'large' ? 'p-10 p-md-15 Layout--TwoColumn-Grid' : 'p-10'}  ${
        className ? className : ''
      }`}
      style={style}
    >
      <div
        className={`ProductPromotional--image-wrapper ${
          type === 'large' ? 'ProductPromotional--image-decoration' : ''
        }`}
      >
        <Image
          itemWithImages={product}
          className={`ProductPromotional--image ${type === 'large' ? 'p-0 p-md-15' : ''}`}
        />
      </div>
      <div className={`ProductPromotional--content ${type === 'large' ? 'pr-0 pr-md-6' : ''}`}>
        {type === 'large' && matchesMediumUp && <p className={`ProductPromotional--caption`}>Research</p>}
        <h3
          className={`ProductPromotional--title ${
            type === 'large' ? 'py-5 py-md-3 ProductPromotional--title-large' : 'py-5'
          }`}
        >
          {product.name}
        </h3>
        <Button
          btnType={ButtonTypeEnum.PRIMARY}
          width={ButtonWidthEnum.FULLWIDTH}
          height={ButtonHeightEnum.DEF}
          onClick={handleBuy}
        >
          {t('cta.buy-now')}
        </Button>
        <div className={'Divider--text py-3'}>
          <span>{t('text.or')}</span>
        </div>
        <Button
          btnType={ButtonTypeEnum.LIGHT_LESS}
          width={ButtonWidthEnum.FULLWIDTH}
          height={ButtonHeightEnum.DEF}
          component={SWLink}
          to={url}
          title={product.name}
        >
          {t('cta.learn-more')}
        </Button>
        {children}
      </div>
    </div>
  );
}
