import { LanguageWrapper } from '../../types/StoreTypes';
import ProductType from '../../types/ProductType';

export interface ProductState {
  allProducts: LanguageWrapper<number[]>;
}

export const ProductQueries = {
  getAllProducts: (state: ProductState): LanguageWrapper<number[]> => state.allProducts,
};

export const ProductReducers = {
  setAllProducts: (state: ProductState, products: ProductType[], language: string): void => {
    state.allProducts[language] = products.map((product: ProductType) => product.id);
  },
};
