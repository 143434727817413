import { CategoryType } from '../../../types/PostTypes';
import { FcWpRawCategory } from '../types/fcWpRawTypes';

export default function mapFcWpCategory(category: FcWpRawCategory): CategoryType {
  const res = {
    id: Number(category.id),
    slug: category.slug,
    name: category.name,
    meta: category.yoast_head_json,
  } as CategoryType;

  if (category.description) res.description = category.description;
  if (category.primary) res.primary = category.primary;
  if (category.link) res.description = category.link;

  return res;
}
