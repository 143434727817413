import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeCategories } from '../../../../core/bucket/BucketSlice';
import { setLatestCategories, setLatestCategoriesPagingInfo } from '../../../../core/categories/CategoriesSlice';
import { LObjectsByPage } from '../../../../types/PayloadActionTypes';
import { CategoryType } from '../../../../types/PostTypes';
import CategoryApi from '../../category/CategoryApi';

export function ProvideCategoriesByPageNumber(pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'all-categories',
      sign: '' + pageNumber,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (pageNumber: number): Promise<void> => {
      const categoryApi = new CategoryApi(ENV_API_URL);
      await categoryApi.setPerPage(100).setPageNumber(pageNumber).fetch();

      if (categoryApi.result && categoryApi.result.length > 0) {
        dispatch(storeCategories({ [lang]: categoryApi.result }));
        dispatch(
          setLatestCategories({
            language: lang,
            pageNumber: pageNumber,
            objects: categoryApi.result,
          } as LObjectsByPage<CategoryType>),
        );
        if (categoryApi.pagingInfo) dispatch(setLatestCategoriesPagingInfo({ [lang]: categoryApi.pagingInfo }));
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(pageNumber);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
