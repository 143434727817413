import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import postsReducer from './slices/postsSlice';
import appReducer from './slices/appSlice';
import categoriesReducer from './slices/categoriesSlice';
import menuReducer from './slices/menuSlice';
import bucketReducer from '../core/bucket/BucketSlice';
import postsReducer2 from '../core/posts/PostsSlice';
import menuReducer2 from '../core/menu/MenuSlice';
import requestsReducer from '../core/requests/RequestsSlice';
import dataProviderReducer from '../core/dataProviders/DataProviderSlice';
import categoriesReducer2 from '../core/categories/CategoriesSlice';
import tagsReducer from '../core/tags/TagsSlice';
import authorsReducer from '../core/authors/AuthorsSlice';
import appReducer2 from '../core/app/AppSlice';
import productsReducer from '../core/products/ProductSlice';

const createStore = () => {
  const storeConfig = {
    reducer: {
      posts: postsReducer,
      categories: categoriesReducer,
      menu: menuReducer,
      app: appReducer,

      Bucket: bucketReducer,
      Posts: postsReducer2,
      Menu: menuReducer2,
      Requests: requestsReducer,
      DataProvider: dataProviderReducer,
      Categories: categoriesReducer2,
      Tags: tagsReducer,
      Authors: authorsReducer,
      App: appReducer2,
      Products: productsReducer,
    },
  };

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window !== undefined && window.__INITIAL_STATE__) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      storeConfig.preloadedState = window.__INITIAL_STATE__;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // delete window.__INITIAL_STATE__;
    }
  } catch (ex) {}

  return configureStore(storeConfig);
};

const store = createStore();

let storeFn = () => store;
if (ENV_SSR) storeFn = createStore;

export { storeFn };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
