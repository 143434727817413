import React from 'react';
import hasValidData from '../../../utils/hasValidData';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import VerticalCardPosts from '../cards/VerticalCardPosts';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import VerticalCardPostsSkeleton from '../../skeletons/VerticalCardPostsSkeleton';
import ButtonSkeleton from '../../skeletons/ButtonSkeleton';
import { CategoryType, PostType } from '../../../types/PostTypes';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';

export type PostPageShareArticleProps = {
  filteredCategoryPosts: PostType[];
  postCatPostsReqState: DataRequestStateEnum;
  category: CategoryType | null;
  categoryReqState: DataRequestStateEnum;
  className?: string;
};

export default function PostPageRelatedArticles({
  filteredCategoryPosts,
  postCatPostsReqState,
  category,
  categoryReqState,
  className,
}: PostPageShareArticleProps): JSX.Element {
  return (
    <div className={`PostPageRelatedArticles Layout--container ${className ? className : ''}`}>
      <div className={'PostPage--MediumWidth-Centered pb-8 pt-8 pb-md-26 pt-md-14'}>
        {hasValidData(filteredCategoryPosts, postCatPostsReqState) && hasValidData(category, categoryReqState) ? (
          <>
            <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
              <h2>{category.name}</h2>
            </SectionHeader>

            <VerticalCardPosts posts={filteredCategoryPosts.slice(0, 2)} />

            <Button
              btnType={ButtonTypeEnum.PRIMARY_LESS}
              width={ButtonWidthEnum.FULLWIDTH}
              height={ButtonHeightEnum.DEF}
              className={'display-none'}
            >
              + Follow Category
            </Button>
          </>
        ) : (
          <>
            <VerticalCardPostsSkeleton title={true} placeholders={2} />
            <ButtonSkeleton height={ButtonHeightEnum.DEF} width={'100%'} />
          </>
        )}
      </div>
    </div>
  );
}
