import React from 'react';

export enum ButtonTypeEnum {
  PRIMARY = 'Button--primary',
  SECONDARY = 'Button--secondary',
  LIGHT_LESS = 'Button--light_less',
  DARK_LESS = 'Button--dark_less',
  PRIMARY_LESS = 'Button--primary_less',
  GHOST = 'Button--ghost',
}

export enum ButtonWidthEnum {
  FULLWIDTH = 'Button--fullwidth',
  WIDE = 'Button--wide',
  DEF = '',
}

export enum ButtonHeightEnum {
  THIN = 'Button--thin',
  THICK = 'Button--thick',
  DEF = '',
}

interface ButtonProps<C extends React.ElementType> {
  component?: C;
  children: React.ReactNode;
  btnType: ButtonTypeEnum;
  width: ButtonWidthEnum;
  height: ButtonHeightEnum;
  onClick?: (e?: React.ReactNode) => void;
  className?: string;
}

type ButtonPropsType<C extends React.ElementType> = ButtonProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof ButtonProps<C>>;

const Button = <C extends React.ElementType>(props: ButtonPropsType<C>): JSX.Element => {
  const { children, component, btnType, height, width = ButtonWidthEnum.DEF, onClick, className, ...rest } = props;
  const Component = component || 'button';
  return (
    <Component
      className={`Button ${btnType} ${width} ${height} ${className ? className : ''}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Button;
