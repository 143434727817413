import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from './DataProviderState';
import { useAppSelector } from '../../redux/hooks';
import { DataProviderSelector } from './DataProviderSlice';
import useLangSelector from '../Hooks/useLangSelector';

export default function useDataProviderState(
  type: string,
  sign: string | null,
): [
  /// Provider state
  DataRequestStateEnum,
  /// setType callback function
  (type: string) => void,
  /// setSign callback function
  (sign: string | null) => void,
] {
  const providerStateList = useLangSelector(useAppSelector(DataProviderSelector));
  const [providerState, setProviderState] = useState<DataRequestStateEnum>(() => {
    if (sign && providerStateList && providerStateList[type] && providerStateList[type][sign])
      return providerStateList[type][sign];
    return DataRequestStateEnum.PENDING;
  });
  const [currentType, setCurrentType] = useState<string>(type);
  const [currentSign, setCurrentSign] = useState<string | null>(sign);

  useEffect(() => {
    /// pause effect execution
    if (currentSign === null) return;
    if (currentType === null) return;

    if (
      providerStateList &&
      providerStateList.hasOwnProperty(currentType) &&
      providerStateList[currentType].hasOwnProperty(currentSign)
    ) {
      setProviderState(providerStateList[currentType][currentSign]);
    } else {
      setProviderState(DataRequestStateEnum.NOT_FOUND);
    }
  }, [providerStateList, currentType, currentSign]);

  const setSign = (newSign: string | null) => setCurrentSign(newSign);
  const setType = (newType: string) => setCurrentType(newType);

  return [providerState, setType, setSign];
}
