import axios from 'axios';
import { FcWpRawMenuItem } from '../types/fcWpRawMenuItem';
import mapFcWpMenuItem from '../mappers/mapFcWpMenuItem';
import { MenuItemsByGroup } from '../../../core/menu/MenuState';

const API_MENU = '/wp-json/fc_api/v1/menu';

export default class FcMenuApi {
  private slugs: string[];
  private language?: string;

  constructor(private readonly wpHost: string) {
    this.slugs = [];
  }

  menuSlug(slug: string): FcMenuApi {
    if (this.slugs.indexOf(slug) === -1) {
      this.slugs.push(slug);
    }
    return this;
  }

  menuSlugs(slugs: string[]): FcMenuApi {
    slugs.forEach((slug: string) => {
      if (this.slugs.indexOf(slug) === -1) {
        this.slugs.push(slug);
      }
    });
    return this;
  }

  setLanguage(language: string): FcMenuApi {
    this.language = language;
    return this;
  }

  generateUrl(): string {
    const args = this.slugs.map((slug: string) => 'include[]=' + slug);
    const lang = this.language && this.language !== 'en' ? '/' + this.language : '';
    return this.wpHost + lang + API_MENU + '?' + args.join('&');
  }

  async fetch(): Promise<MenuItemsByGroup> {
    ENV_SSR && console.log('SSR-fetch-fc-menu');
    const response = await axios.get(this.generateUrl());
    if (response.status !== 200) {
      throw new Error('request failed');
    }

    return response.data.reduce((acc: MenuItemsByGroup, menuItem: FcWpRawMenuItem) => {
      acc[menuItem.menu] = acc[menuItem.menu] || [];
      acc[menuItem.menu].push(mapFcWpMenuItem(menuItem));
      return acc;
    }, {});
  }
}
