import { WpRawCategory, WpRawFeaturedMedia, WpRawPost, WpRawTag, WpTerm } from '../types/wpRawTypes';
import mapWpTag from './mapWpTag';
import mapWpCategory from './mapWpCategory';
import { PostImage, PostType } from '../../../types/PostTypes';
import mapWpAuthor from './mapWpAuthor';

/**
 * wp:term is array of objects
 * as we dont care about the groups, this reduce function will flatten groups to one array of objects
 */
export function reduceWpTermToFlatArray(acc: WpTerm[], item: WpTerm[]): WpTerm[] {
  return [...acc, ...item];
}

/**
 * each WpTermType has property "taxonomy" that defines WpTermType subobject
 */
export function filterByTaxonomy(taxonomy: string): (item: WpTerm) => boolean {
  return (item: WpTerm): boolean => item.taxonomy === taxonomy;
}

/// mapper helper functions -------------------------------------------------------------------------

const mapTags = (source: WpRawPost, target: PostType) => {
  if (!source._embedded['wp:term']) return;
  const termObjects = source._embedded['wp:term'].reduce(reduceWpTermToFlatArray, []);
  target.tags = (termObjects.filter(filterByTaxonomy('post_tag')) as WpRawTag[]).map(mapWpTag);
};

const mapCategories = (source: WpRawPost, target: PostType) => {
  if (!source._embedded['wp:term']) return;
  const termObjects = source._embedded['wp:term'].reduce(reduceWpTermToFlatArray, []);
  target.categories = (termObjects.filter(filterByTaxonomy('category')) as WpRawCategory[]).map(mapWpCategory);
};

const mapAuthors = (source: WpRawPost, target: PostType) => {
  if (!source._embedded['author']) return;
  target.authors = source._embedded.author.map(mapWpAuthor);
};

const mapImages = (source: WpRawPost, target: PostType) => {
  if (!source._embedded['wp:featuredmedia']) return;
  const images = source._embedded['wp:featuredmedia'].filter(
    (media: WpRawFeaturedMedia) => media.media_type === 'image',
  );
  if (images.length === 0) return;

  const toPostImage = (obj: any): PostImage => ({
    alt: obj.alt || '',
    width: obj.width,
    height: obj.height,
    url: obj.source_url,
  });

  // eslint-disable-next-line prettier/prettier
  if (images[0].media_details.sizes.medium_large) target.imageMediumLarge = toPostImage(images[0].media_details.sizes.medium_large);
  if (images[0].media_details.sizes.medium) target.imageMedium = toPostImage(images[0].media_details.sizes.medium);
  if (images[0].media_details.sizes.large) target.imageLarge = toPostImage(images[0].media_details.sizes.large);
  if (images[0].media_details.sizes.full) target.imageFull = toPostImage(images[0].media_details.sizes.full);
};

/// main mapper   -----------------------------------------------------------------------------------

export default function mapWpPost(wpPostResponse: WpRawPost): PostType {
  const result: PostType = {
    id: wpPostResponse.id,
    slug: wpPostResponse.slug,
    title: wpPostResponse.title.rendered,
    link: wpPostResponse.link,
    date: wpPostResponse.date,
    tags: [],
    categories: [],
    meta: wpPostResponse.yoast_head_json,
    authors: [],
  };

  if (wpPostResponse.content) {
    result.content = wpPostResponse.content.rendered;
  }

  mapTags(wpPostResponse, result);
  mapCategories(wpPostResponse, result);
  mapImages(wpPostResponse, result);
  mapAuthors(wpPostResponse, result);

  return result;
}
