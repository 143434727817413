import { AppDispatch, AppThunk } from '../../../../redux/store';
import { storePages, storePageSlugMapping } from '../../../../core/bucket/BucketSlice';
import PageApi from '../../page/FcPageApi';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';

export function ProvidePageBySlug(slug: string, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'page-by-slug',
      sign: slug,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (slug: string): Promise<void> => {
      const api = new PageApi(ENV_API_URL);
      const page = await api.setSlug(slug).setLanguage(lang).fetchSingle();
      if (page && page.length > 0) {
        dispatch(storePages({ [lang]: [page[0]] }));
        if (page[0].slug !== slug) {
          dispatch(storePageSlugMapping({ [lang]: [{ ...page[0], slug: slug }] }));
        }
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(slug);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
