import { useEffect, useState } from 'react';
import { MenuItemType } from '../types/MenuTypes';
import { DataRequestStateEnum } from '../core/dataProviders/DataProviderState';
import useMenuBySlugProvider from '../core/dataProviders/providers/useMenuBySlugProvider';

function prepareMenuItems(menuItems: MenuItemType[]): MenuItemType[] {
  return menuItems.map((menuItem: MenuItemType) => {
    let url = menuItem.url;
    try {
      const urlOrigin = new URL(menuItem.url).origin;
      url =
        urlOrigin === ENV_API_URL || urlOrigin === ENV_DOMAIN_URL
          ? menuItem.url.replace(new URL(menuItem.url).origin, '')
          : menuItem.url;
      if (url.startsWith('/category')) {
        const splitUrl = url.split('/');
        url = `/${splitUrl[1]}/${splitUrl[splitUrl.length - 2]}/`;
      }
    } catch (ex) {}
    return {
      ...menuItem,
      url: url,
    };
  });
}

export default function useTheFixMenuBySlugProvider(
  slug: string,
  includeInSSR = true,
): [
  /// MenuItems
  MenuItemType[],
  /// Req state
  DataRequestStateEnum,
] {
  const [menuItems, dataReq] = useMenuBySlugProvider(slug, includeInSSR);
  const [fixedMenuItems, setFixedMenuItems] = useState<MenuItemType[]>(() => prepareMenuItems(menuItems));

  useEffect(() => {
    setFixedMenuItems(prepareMenuItems(menuItems));
  }, [menuItems]);

  if (ENV_SSR && includeInSSR) return [prepareMenuItems(menuItems), dataReq];

  return [fixedMenuItems, dataReq];
}
