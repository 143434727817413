import React, { useRef } from 'react';

interface InjectScriptsProps {
  header: string;
  footer: string;
}

interface InjectScriptsLiveProps extends InjectScriptsProps {
  scriptPlaceholderRef: HTMLElement | null;
}

/**
 * to attach scripts to the content, we need to render them twice..
 *
 * @param footer
 * @param header
 * @param scriptPlaceholderRef
 * @constructor
 */
function InjectScriptsLiveRaw({ footer, header, scriptPlaceholderRef }: InjectScriptsLiveProps) {
  if (scriptPlaceholderRef === null) return <></>;

  const fragment = document.createRange().createContextualFragment(header + footer);

  /// This function will be called once first cycle is done
  function onDoneLoading() {
    if (scriptPlaceholderRef === null) return;
    const fragment2 = document.createRange().createContextualFragment(header + footer);
    scriptPlaceholderRef.innerHTML = '';
    scriptPlaceholderRef.appendChild(fragment2);
  }

  /// we are keeping track of currently loading scripts
  const scriptsToWaitFor: string[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [...fragment.children]
    /// not interested in inlined scripts
    .filter((el) => el.attributes['src'])
    .forEach((el) => {
      const src = el.attributes['src'];
      /// register script in scriptsToWaitFor array
      scriptsToWaitFor.push(src.value);
      /// Attach event listener for it
      el.addEventListener('load', () => {
        /// once event is called, we can remove it from waiting list
        const pos = scriptsToWaitFor.indexOf(src.value);
        if (pos > -1) {
          scriptsToWaitFor.splice(pos, 1);
          if (scriptsToWaitFor.length === 0) onDoneLoading();
        }
      });
    });

  scriptPlaceholderRef.innerHTML = '';
  scriptPlaceholderRef.appendChild(fragment);

  return <></>;
}

const InjectScriptsLive = React.memo(InjectScriptsLiveRaw);

function InjectScripts({ footer, header }: InjectScriptsProps) {
  const divRef = useRef(null);

  if (ENV_SSR) {
    /// In case of SSR, just put all the code in
    return (
      <>
        <div ref={divRef} dangerouslySetInnerHTML={{ __html: header + footer }} />
        <InjectScriptsLive header={header} footer={footer} scriptPlaceholderRef={divRef.current} />
      </>
    );
  }

  return (
    <>
      <div ref={divRef} />
      <InjectScriptsLive header={header} footer={footer} scriptPlaceholderRef={divRef.current} />
    </>
  );
}

export default InjectScripts;
