import React from 'react';
import { PostType } from '../../../types/PostTypes';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import CardPost from '../../compound/post/cards/CardPost';
import CardProduct from '../../compound/post/cards/CardProduct';
import ProductType from '../../../types/ProductType';

export enum HorizontalCardPostsType {
  PRODUCTS = 'products',
  POSTS = 'posts',
}

export type HorizontalCardPostsProps = {
  title?: string;
  posts?: PostType[];
  products?: ProductType[];
  className?: string;
};

export default function HorizontalCardPosts({
  title,
  posts,
  products,
  className,
}: HorizontalCardPostsProps): JSX.Element {
  return (
    <div className={`HorizontalCardPosts ${className ? className : ''}`}>
      {title && (
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{title}</h2>
        </SectionHeader>
      )}
      <div className={'Layout--ThreeColumn-Grid'}>
        {posts && posts?.map((post: PostType) => <CardPost key={post.id} post={post} className={'mb-7 mb-lg-8'} />)}
        {products &&
          products?.map(
            (product: ProductType) =>
              product.slug !== '' && <CardProduct key={product.id} product={product} className={'mb-7 mb-lg-8'} />,
          )}
      </div>
    </div>
  );
}
