import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../DataProviderState';
import useDataProviderState from '../useDataProviderState';
import useDataProviderOrder from '../useDataProviderOrder';
import { useAppSelector } from '../../../redux/hooks';
import { PostType } from '../../../types/PostTypes';
import { PostSlugMapSelector, PostsSelector } from '../../bucket/BucketSlice';
import useDataProviderSSROrder from '../useDataProviderSSROrder';
import useLangSelector from '../../Hooks/useLangSelector';
import { IndexedObjects, SlugToIdMapper } from '../../../types/StoreTypes';

function getFullPost(
  slug: string | null,
  postSlugMap: SlugToIdMapper | undefined,
  posts: IndexedObjects<PostType> | undefined,
): PostType | null {
  if (postSlugMap && posts && slug && postSlugMap[slug] && posts[postSlugMap[slug]] && posts[postSlugMap[slug]].footer)
    return posts[postSlugMap[slug]];
  return null;
}

export default function usePostBySlugProvider(
  slug: string | null,
  includeInSSR = true,
): [
  /// Post object
  PostType | null,
  /// Request state
  DataRequestStateEnum,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();

  /// Redux Selectors ----------------------------------------------------------
  const postSlugMap = useLangSelector(useAppSelector(PostSlugMapSelector));
  const posts = useLangSelector(useAppSelector(PostsSelector));

  /// Current hook state -------------------------------------------------------
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => null);
  const [reqState, , setRequestSign] = useDataProviderState('post-by-slug', slug);
  const [post, setPost] = useState<PostType | null>(() =>
    slug && postSlugMap && posts ? getFullPost(slug, postSlugMap, posts) : null,
  );

  /// on slug change  ----------------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug) {
      setCurrentSlug(slug);
      let foundPost: PostType | null = null;
      if (slug) foundPost = getFullPost(slug, postSlugMap, posts);
      setPost(foundPost);

      /// Order data if there is not matching category in the bucket  ------------
      if (slug) {
        setRequestSign(slug);
        if (foundPost === null) orderData('post-by-slug', slug);
      }
    }
  }, [slug]);

  useEffect(() => {
    /// Make sure we are listening to effect hook
    if (post !== null) return;
    if (currentSlug) setPost(getFullPost(currentSlug, postSlugMap, posts));
  }, [postSlugMap, posts]);

  /// SSR Data  ----------------------------------------------------------------
  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('post-by-slug', slug);

  return [post, post ? DataRequestStateEnum.FULFILLED : reqState];
}
