import React from 'react';
import PostDateAndAuthorSkeleton from './PostDateAndAuthorSkeleton';
import EstimatedReadTimeSkeleton from './EstimatedReadTimeSkeleton';
import Skeleton from 'react-loading-skeleton';

export default function CardPostSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`CardPost ${className ? className : ''}`}>
      <figure className={'CardPost--figure'}>
        <span className={'CardPost--figure-ImgWrapper mb-5'}>
          <Skeleton className={'CardPost--figure-Img'} />
        </span>
        <figcaption>
          <PostDateAndAuthorSkeleton showAuthorAvatar={false} />
          <h3 className={'CardPost--figcaption-Title'}>
            <Skeleton className={'CardPost--figcaption-TitleLink'} count={3} />
          </h3>
        </figcaption>
      </figure>
      <div className={'CardPost--action pb-4'}>
        <Skeleton height={0} />
        <EstimatedReadTimeSkeleton />
      </div>
    </div>
  );
}
