import { TagsState } from './TagsState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LObjectsByPage, LObjectsByPageForString } from '../../types/PayloadActionTypes';
import { PagingInfoType, TagType } from '../../types/PostTypes';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';
import { TagsQueries, TagsReducers } from './TagsState';
import { LanguageWrapper, LIndexedObjects, LIndexedObjectsByString } from '../../types/StoreTypes';

export const SLICE_NAME = 'Tags';

export const initialState: TagsState = {
  latestTags: {},
  latestTagsPaging: {
    en: {
      total: 0,
      pages: 0,
    },
  },
  tagsBySearchTerm: {},
  topTagsForCategory: {},
  tagsBySearchTermPaging: {},
};

export const TagsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLatestTags: (state: TagsState, { payload }: PayloadAction<LObjectsByPage<TagType>>): void => {
      TagsReducers.setLatest(state, payload.language, payload.pageNumber, payload.objects);
    },
    setLatestTagsPagingInfo: (state: TagsState, { payload }: PayloadAction<LanguageWrapper<PagingInfoType>>): void => {
      Object.keys(payload).forEach((lang: string) => TagsReducers.setLatestPagingInfo(state, payload[lang], lang));
    },
    setTagsBySearchTerm: (state: TagsState, { payload }: PayloadAction<LObjectsByPageForString<TagType>>): void => {
      TagsReducers.setTagsBySearchTerm(state, payload.forObject, payload.pageNumber, payload.objects, payload.language);
    },
    setTopTagsForCategory: (state: TagsState, { payload }: PayloadAction<LIndexedObjects<TagType[]>>): void => {
      Object.keys(payload).forEach((lang: string) => {
        Object.keys(payload[lang]).forEach((categoryId: string) => {
          TagsReducers.setTopTagsForCategory(
            state,
            Number(categoryId),
            payload[lang][Number(categoryId)].map((tag: TagType) => tag.id),
            lang,
          );
        });
      });
    },
    setTagsBySearchTermPagingInfo: (
      state: TagsState,
      { payload }: PayloadAction<LIndexedObjectsByString<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) =>
        Object.keys(payload[lang]).forEach((id: string) =>
          TagsReducers.setTagsBySearchTermPagingInfo(state, id, payload[lang][id], lang),
        ),
      );
    },
  },
});

export const getTagsState = (state: RootState): TagsState => state[SLICE_NAME];

export const {
  setLatestTags,
  setLatestTagsPagingInfo,
  setTagsBySearchTerm,
  setTagsBySearchTermPagingInfo,
  setTopTagsForCategory,
} = TagsSlice.actions;

export const LatestTagsSelector = createSelector(getTagsState, TagsQueries.getLatestTags);
export const LatestCTagsAvailablePagesSelector = createSelector(getTagsState, TagsQueries.getAvailablePagesForLatest);
export const LatestTagPagingInfoSelector = createSelector(getTagsState, TagsQueries.getLatestPagingInfo);
export const TagsBySearchTermSelector = createSelector(getTagsState, TagsQueries.getTagsBySearchTerm);
export const TopTagsForCategory = createSelector(getTagsState, TagsQueries.getTopTagsForCategory);

export default TagsSlice.reducer;
