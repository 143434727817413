import React from 'react';

export enum SectionHeaderBorderStyle {
  THICK = 'SectionHeader--BorderThick',
  NONE = 'SectionHeader--BorderNone',
}

export type SectionHeaderProps = {
  borderStyle: SectionHeaderBorderStyle;
  className?: string;
  children: React.ReactNode;
};

export default function SectionHeader({ borderStyle, className, children }: SectionHeaderProps): JSX.Element {
  return <div className={`SectionHeader pt-4 ${borderStyle} ${className ? className : ''}`}>{children}</div>;
}
