import useDataProviderOrder from '../../useDataProviderOrder';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import { useEffect, useState } from 'react';
import useDataProviderState from '../../useDataProviderState';
import { useAppSelector } from '../../../../redux/hooks';
import { DataRequestStateEnum } from '../../DataProviderState';
import { TagsBySearchTermSelector } from '../../../tags/TagsSlice';
import useLangSelector from '../../../Hooks/useLangSelector';
import { IndexedObjectsByString, PagedObjects } from '../../../../types/StoreTypes';
import useTagsFiller from '../../../Hooks/useTagsFiller';
import { TagType } from '../../../../types/PostTypes';

function getTagsBySearchTerm(
  searchTerm: string | null,
  pageNumber: number,
  tagsBySearchTerm: IndexedObjectsByString<PagedObjects<number[]>> | undefined,
): number[] {
  if (searchTerm && tagsBySearchTerm) {
    if (tagsBySearchTerm[searchTerm] && tagsBySearchTerm[searchTerm][pageNumber]) {
      return tagsBySearchTerm[searchTerm][pageNumber];
    }
  }
  return [];
}

export default function useTagsBySearchTermProvider(
  searchTerm: string | null,
  pageNumber: number,
): [
  /// Tags by category
  TagType[],
  /// Tags Request status,
  DataRequestStateEnum,
] {
  const orderData = useDataProviderOrder();
  const tagsFiller = useTagsFiller();

  /// Selectors ----------------------------------------------------------------
  const tagsBySearchTerm = useLangSelector(useAppSelector(TagsBySearchTermSelector));

  /// Request info  ------------------------------------------------------------
  const createRequestSign = (slug: string, pageNumber: number) => `${slug}|${pageNumber}`;
  const [reqState, , setReqSign] = useDataProviderState(
    'tags-by-search-term',
    createRequestSign(searchTerm || '', pageNumber),
  );

  /// first run setup ----------------------------------------------------------
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string | null>(() =>
    searchTerm !== null ? encodeURI(searchTerm) : null,
  );
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(() => pageNumber);
  const [currentTags, setCurrentTags] = useState<TagType[]>(() =>
    tagsFiller(getTagsBySearchTerm(searchTerm, pageNumber, tagsBySearchTerm)),
  );

  /// useEffect on input params ------------------------------------------------
  useEffect(() => {
    if (searchTerm !== currentSearchTerm || pageNumber !== currentPageNumber) {
      setCurrentSearchTerm(searchTerm);
      setCurrentPageNumber(pageNumber);
      if (searchTerm === null) {
        setCurrentTags([]);
        setReqSign(null);
        return;
      }

      const newTags = getTagsBySearchTerm(searchTerm, pageNumber, tagsBySearchTerm);

      setCurrentTags(tagsFiller(newTags));
      setReqSign(createRequestSign(searchTerm, pageNumber));
    }
  }, [searchTerm, pageNumber]);

  /// useEffect on Selectors  --------------------------------------------------
  useEffect(() => {
    if (currentSearchTerm && currentTags.length === 0) {
      const newTags = getTagsBySearchTerm(currentSearchTerm, pageNumber, tagsBySearchTerm);
      setCurrentTags(tagsFiller(newTags));
    }
  }, [tagsBySearchTerm]);

  /// useEffect on reqState ----------------------------------------------------
  useEffect(() => {
    if (currentSearchTerm && currentTags.length === 0 && reqState === DataRequestStateEnum.NOT_FOUND) {
      orderData('tags-by-search-term', createRequestSign(currentSearchTerm, currentPageNumber));
    }
  }, [reqState, currentSearchTerm]);

  /// SSR ----------------------------------------------------------------------
  if (ENV_SSR && searchTerm) useDataProviderSSROrder('tags-by-search-term', createRequestSign(searchTerm, pageNumber));

  return [currentTags, reqState];
}
