import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeAuthors, storeCategories, storePosts, storeTags } from '../../../../core/bucket/BucketSlice';
import { setTrendingPosts } from '../../../../core/posts/PostsSlice';
import FcPostsApi from '../../post/FcPostsApi';
import { AuthorType, CategoryType, TagType } from '../../../../types/PostTypes';
import collectObjects from './CollectObjects';

export default function ProvideTrendingPostsByCategorySlug(
  categorySlug: string,
  pageNumber: number,
  lang: string,
): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'trending-posts-by-category-slug',
      sign: `${categorySlug}|${pageNumber}`,
      language: lang,
    } as DataProviderRequestSign;

    try {
      dispatch(runRequest(requestSign));
      const postApi = new FcPostsApi(ENV_API_URL);
      postApi.setPage(pageNumber).areTrending().setLanguage(lang);

      if (categorySlug !== 'latest') postApi.filterByCategory(categorySlug);
      await postApi.fetch();
      const posts = postApi.result || [];

      if (posts.length > 0) {
        /// Find category ID by categorySlug
        let category = [{ id: 0 }];
        if (categorySlug !== 'latest')
          category = posts[0].categories.filter((category: CategoryType) => category.slug === categorySlug);

        if (category.length > 0) {
          dispatch(storePosts({ [lang]: posts }));
          dispatch(
            setTrendingPosts({
              language: lang,
              forObject: category[0].id,
              pageNumber: pageNumber,
              objects: posts,
            }),
          );
          dispatch(storeCategories({ [lang]: collectObjects<CategoryType>(posts, 'categories') }));
          dispatch(storeTags({ [lang]: collectObjects<TagType>(posts, 'tags') }));
          dispatch(storeAuthors({ [lang]: collectObjects<AuthorType>(posts, 'authors') }));
        }
      } else {
        dispatch(emptyResponseRequest(requestSign));
      }
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      dispatch(failedRequest(requestSign));
    }
  };
}
