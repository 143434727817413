import React from 'react';
import { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import MiniPostWrapper from '../sections/wrapper/MiniPostWrapper';
import SectionHeaderSkeleton from './SectionHeaderSkeleton';
import MiniNumberedPostSkeleton from './MiniNumberedPostSkeleton';

export type NumberedVerticalListPostsSkeletonProps = {
  title: boolean;
  placeholders: number;
  className?: string;
};

export default function NumberedVerticalListPostsSkeleton({
  title,
  placeholders,
  className,
}: NumberedVerticalListPostsSkeletonProps): JSX.Element {
  return (
    <div className={`NumberedVerticalListPosts ${className ? className : ''}`}>
      {title && <SectionHeaderSkeleton className={'mb-4 mb-md-6'} borderStyle={SectionHeaderBorderStyle.THICK} />}
      {new Array(placeholders).fill(0).map((_, index) => (
        <MiniPostWrapper key={index} className={'NumberedVerticalListPosts--Items py-4'}>
          <span className={'NumberedVerticalListPosts--Items-Number'}>{index + 1}</span>
          <MiniNumberedPostSkeleton className={'ml-9'} />
        </MiniPostWrapper>
      ))}
    </div>
  );
}
