import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../store';
import { createSelector } from 'reselect';

export const NAME = 'app';

export interface AppState {
  isLoading: boolean;
  pendingRequests: string[];
  failedRequests: string[];
  fulfilledRequests: string[];
  homepageIsLoading: boolean;
  homepageHasError: boolean;
  categoryBySlugPending: string[];
  categoryBySlugFailed: string[];
}

const initialState: AppState = {
  pendingRequests: [],
  failedRequests: [],
  fulfilledRequests: [],
  isLoading: false,
  homepageIsLoading: false,
  homepageHasError: false,
  categoryBySlugPending: [],
  categoryBySlugFailed: [],
};

export const appSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    pendingRequest: (state: AppState, { payload }: PayloadAction<string>) => {
      state.pendingRequests.push(payload);
    },

    fulfilledRequest: (state: AppState, { payload }: PayloadAction<string>) => {
      state.pendingRequests.splice(state.pendingRequests.indexOf(payload));
      state.fulfilledRequests.push(payload);
    },

    failedRequests: (state: AppState, { payload }: PayloadAction<string>) => {
      state.pendingRequests.splice(state.pendingRequests.indexOf(payload));
      state.failedRequests.push(payload);
    },

    pendingHomepage: (state: AppState) => {
      state.homepageIsLoading = true;
      state.homepageHasError = false;
    },
    fulfilledHomepage: (state: AppState) => {
      state.homepageIsLoading = false;
      state.homepageHasError = false;
    },
    rejectedHomepage: (state: AppState) => {
      state.homepageIsLoading = false;
      state.homepageHasError = true;
    },

    categoryBySlugPending: (state: AppState, { payload }: PayloadAction<string>) => {
      state.categoryBySlugPending.push(payload);
    },
    categoryBySlugFailed: (state: AppState, { payload }: PayloadAction<string>) => {
      state.categoryBySlugFailed.push(payload);
    },
    categoryBySlugFulfilled: (state: AppState, { payload }: PayloadAction<string>) => {
      state.categoryBySlugPending = state.categoryBySlugPending.filter((slug: string) => slug !== payload);
    },
  },
});

export const appState = (state: RootState): AppState => state[NAME];

export const pendingRequestsSelector = createSelector(appState, (state: AppState) => state.pendingRequests);
export const fulfilledRequestsSelector = createSelector(appState, (state: AppState) => state.fulfilledRequests);
export const failedRequestsSelector = createSelector(appState, (state: AppState) => state.failedRequests);

export const homepageIsLoadingSelector = createSelector(appState, (state: AppState) => state.homepageIsLoading);
export const homepageHasErrorSelector = createSelector(appState, (state: AppState) => state.homepageHasError);

export const {
  pendingRequest,
  fulfilledRequest,
  failedRequests,
  fulfilledHomepage,
  pendingHomepage,
  rejectedHomepage,

  categoryBySlugPending,
  categoryBySlugFulfilled,
  categoryBySlugFailed,
} = appSlice.actions;

export default appSlice.reducer;
