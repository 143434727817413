import { ProductQueries, ProductReducers, ProductState } from './ProductState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageWrapper } from '../../types/StoreTypes';
import ProductType from '../../types/ProductType';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';

export const SLICE_NAME = 'Products';

const initialState: ProductState = {
  allProducts: {},
};

export const ProductSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAllProducts: (state: ProductState, { payload }: PayloadAction<LanguageWrapper<ProductType[]>>): void => {
      Object.keys(payload).forEach((lang: string) => {
        ProductReducers.setAllProducts(state, payload[lang], lang);
      });
    },
  },
});

export const getProductsState = (state: RootState): ProductState => state[SLICE_NAME];

export const { setAllProducts } = ProductSlice.actions;

export const AllProductsSelector = createSelector(getProductsState, ProductQueries.getAllProducts);

export default ProductSlice.reducer;
