import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import WooProductApi from '../../product/WooProductApi';
import { storeProducts, storeProductSlugMapping } from '../../../../core/bucket/BucketSlice';

export function ProvideProductBySlug(slug: string, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'product-by-slug',
      sign: slug,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (): Promise<void> => {
      const productApi = new WooProductApi(WOOCOMMERCE_PROXY);
      const products = await productApi.setLanguage(lang).fetchProductBySlug(slug);
      if (products && products.length > 0) {
        dispatch(storeProducts({ [lang]: products }));
        if (products[0].slug !== slug) {
          dispatch(storeProductSlugMapping({ [lang]: [{ ...products[0], slug: slug }] }));
          dispatch(storeProductSlugMapping({ [lang]: [{ ...products[0], slug: slug }] }));
        }
        return;
      }
      throw new Error('404');
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi();
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
