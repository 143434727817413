import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ILanguage, languages, messages } from '../../../configs/thefix/languages';
import { useHistory, useLocation } from 'react-router-dom';
import { matchUrl } from '../../components/router/routeList';
import { matchUrl as matchUrlNew } from '../../components/router/matchUrl';
import routeList from '../../components/router/NewRouteList';
import { switchURLLanguage } from '../../components/router/GenerateUrl';
import NewRouteList from '../../components/router/NewRouteList';

export interface ILanguageContext {
  language: ILanguage;
  availableLanguages: ILanguage[];
  t: (messageId: string, forceLanguage?: string) => any;
  changeLanguage: (languageKey: string) => void;
}

const LanguageContext = React.createContext<ILanguageContext>({
  language: languages[0],
  availableLanguages: languages,
  t: (messageId) => messageId,
  changeLanguage: () => {
    /* */
  },
});

interface ILanguageContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

export default function LanguageContextProvider({ children }: ILanguageContextProviderProps): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const [, language] = matchUrlNew(location.pathname);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const lng = language ? language : NewRouteList.DefaultLanguage;

  const [languageObject, setLanguageObject] = useState<ILanguage>(() => {
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].code === lng) return languages[i];
    }
    return languages[0];
  });

  const translate = useCallback(
    (messageId: string, forceLanguage?: string): any => {
      if (forceLanguage && messages[forceLanguage]) return messages[forceLanguage][messageId];
      return messages[lng][messageId];
    },
    [lng],
  );

  const changeLanguage = useCallback(() => {
    const [, lang] = matchUrlNew(location.pathname);
    let newLanguage = routeList.DefaultLanguage;
    let useNextLang = false;
    for (let i = 0; i < routeList.Languages.length; i++) {
      if (useNextLang) {
        newLanguage = routeList.Languages[i];
        break;
      }
      if (routeList.Languages[i] === lang) useNextLang = true;
    }
    history.push(switchURLLanguage(location.pathname, newLanguage));
  }, [location]);

  useEffect(() => {
    const currentMatch = matchUrl(location.pathname);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const lng = currentMatch.params && currentMatch.params.lng ? currentMatch.params.lng : ENV_DEFAULT_LANGUAGE;

    let lang = languages[0];
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].code === lng) lang = languages[i];
    }
    setLanguageObject(lang);
  }, [location]);

  return (
    <LanguageContext.Provider
      value={{ language: languageObject, availableLanguages: languages, t: translate, changeLanguage: changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useTranslateMessage(): (messageId: string, forceLanguage?: string) => any {
  const languageContext = useContext(LanguageContext);
  return languageContext.t;
}

export function useAvailableLanguages(): ILanguage[] {
  const languageContext = useContext(LanguageContext);
  return languageContext.availableLanguages;
}

export function useChangeLanguage(): (languageKey: string) => void {
  const languageContext = useContext(LanguageContext);
  return languageContext.changeLanguage;
}

export function useCurrentLanguage(): ILanguage {
  const languageContext = useContext(LanguageContext);
  return languageContext.language;
}

export function useSwitchToNextLanguage(): () => void {
  const { language, availableLanguages, changeLanguage } = useContext(LanguageContext);

  return useCallback(() => {
    let toLanguage = 0;
    for (let i = 0; i < availableLanguages.length; i++) {
      if (availableLanguages[i].code === language.code) {
        if (i + 1 >= availableLanguages.length) toLanguage = 0;
        else toLanguage = i + 1;
        break;
      }
    }
    changeLanguage(languages[toLanguage].code);
  }, [language, availableLanguages, changeLanguage]);
}
