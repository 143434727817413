import React from 'react';
import Checkbox from '../../../assets/svg/checkbox.svg';
import CapitalCta, { CapitalCtaColorEnum } from '../../simple/CapitalCta';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';

export type ChooseNewsletterOptionProps = {
  title: string;
  subtitle: string;
  price: string;
  description: string;
  onClick: (data: HTMLDivElement) => void;
  id: string;
  className?: string;
};

export default function ChooseNewsletterOption({
  title,
  subtitle,
  price,
  description,
  onClick,
  id,
  className,
}: ChooseNewsletterOptionProps): JSX.Element {
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);

  return (
    <div
      className={`ChooseNewsletterOption ${className ? className : ''}`}
      id={id}
      onClick={(e) => onClick(e.currentTarget)}
    >
      <div className={'ChooseNewsletterOption--SelectorTitlePrice pr-5'}>
        <div className={'ChooseNewsletterOption--Selector'}>
          <div>
            <Checkbox />
          </div>
        </div>
        <div className={'ChooseNewsletterOption--TitlePrice pt-7 pb-5'}>
          <div className={'ChooseNewsletterOption--Title'}>
            <h4>{title}</h4>
            <p>{subtitle}</p>
          </div>
          <div className={'ChooseNewsletterOption--Price'}>
            <p>{price}</p>
          </div>
        </div>
        <div />
        {matchesMediumUp && <div className={'Divider Divider--dashed Divider--dark mb-4'} />}
      </div>
      {!matchesMediumUp && <div className={'Divider Divider--dashed Divider--dark mb-4 mx-5'} />}
      <div className={'ChooseNewsletterOption--Description pb-5 pl-5 pl-md-0'}>
        <div />
        <div>
          <p className={'pb-2'}>{description}</p>
        </div>
      </div>
    </div>
  );
}
