import React from 'react';
import { CategoryType } from '../../types/PostTypes';
import SWLink from '../fuctional/SWLink';
import { generateCategoryUrl } from '../router/GenerateUrl';
import ReactHtmlParser from 'react-html-parser';
import { useCurrentLanguage } from '../../core/i18/LanguageContextProvider';

export enum CategoryTypeEnum {
  PRIMARY = 'Button--primary',
  SECONDARY = 'Button--secondary',
  TERTIARY = 'Button--tertiary',
  SPECIAL_ONE = 'Button--special_one',
  SPECIAL_TWO = 'Button--special_two',
}

export interface CategoryBadgeProps {
  type?: CategoryTypeEnum;
  className?: string;
  category: CategoryType;
}

function CategoryBadgeComponent({
  type = CategoryTypeEnum.SPECIAL_TWO,
  className,
  category,
}: CategoryBadgeProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = category.slug !== 'storybook' ? generateCategoryUrl(category, language.code) : '';

  return (
    <SWLink to={url} className={`CategoryBadge ${type} ${className ? className : ''}`}>
      {ReactHtmlParser(category.name)}
    </SWLink>
  );
}

export const CategoryBadge = CategoryBadgeComponent;
