import React from 'react';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import IphoneImage from '../../../assets/img/iphone.png';
import MailchimpFormWrapper from '../../compound/newsletter/MailchimpFormWrapper';

export default function SubscribeToNewsletter({ className }: { className?: string }): JSX.Element {
  const t = useTranslateMessage();
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);
  return (
    <div className={`SubscribeToNewsletter SubscribeToNewsletter--dark ${className ? className : ''}`}>
      <div className={'Layout--container Layout--TwoColumn-Grid'}>
        <div className={'SubscribeToNewsletter--content-center pt-12 pb-2 pb-md-12'}>
          <h3 className={'SubscribeToNewsletter--caption mb-5'}>The Fix Newsletter</h3>
          <p className={'SubscribeToNewsletter--hook mb-4'}>
            {t('newsletter.title-normal')} <span>{t('newsletter.title-highlight')}</span>
          </p>
          <MailchimpFormWrapper />
        </div>
        <div className={'SubscribeToNewsletter--image-center'}>
          {matchesMediumUp && (
            <img
              src={IphoneImage}
              width={'388px'}
              height={'459px'}
              alt={'Image of phone with installed app of TheFix'}
            />
          )}
        </div>
      </div>
    </div>
  );
}
