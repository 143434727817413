import React from 'react';
import IconLink, { IconFillEnum } from '../../simple/IconLink';
import Linkedin from '../../../assets/svg/in.svg';
import Facebook from '../../../assets/svg/fb.svg';
import Twitter from '../../../assets/svg/tw.svg';
import Send from '../../../assets/svg/send.svg';
import { ButtonTypeEnum } from '../../simple/Button';

interface FooterSocialNavType {
  fill: IconFillEnum;
}

export default function FooterSocialNav({ fill }: FooterSocialNavType): JSX.Element {
  return (
    <nav className={'FooterSocialNav'}>
      <IconLink
        fill={fill}
        component={'a'}
        href={'https://www.linkedin.com/company/the-fix-media/'}
        target={'_blank'}
        className={`p-3 p-md-0 mr-3 mr-md-2 mb-3 mb-md-0 ${ButtonTypeEnum.LIGHT_LESS}`}
        title={'LinkedIn of TheFix'}
      >
        <Linkedin />
      </IconLink>
      <IconLink
        fill={fill}
        component={'a'}
        href={'https://www.facebook.com/TheFixMedia/'}
        target={'_blank'}
        className={`p-3 p-md-0 mr-3 mr-md-2 mb-3 mb-md-0 ${ButtonTypeEnum.LIGHT_LESS}`}
        title={'Facebook of TheFix'}
      >
        <Facebook />
      </IconLink>
      <IconLink
        fill={fill}
        component={'a'}
        href={'https://twitter.com/ThefixMedia'}
        target={'_blank'}
        className={`p-3 p-md-0 mr-3 mr-md-2 mb-3 mb-md-0 ${ButtonTypeEnum.LIGHT_LESS}`}
        title={'Twitter of TheFix'}
      >
        <Twitter />
      </IconLink>
      <IconLink
        fill={fill}
        component={'a'}
        href={'https://t.me/thefixmedia'}
        target={'_blank'}
        className={`p-3 p-md-0 mr-3 mr-md-2 mb-3 mb-md-0 ${ButtonTypeEnum.LIGHT_LESS}`}
        title={'Telegram of TheFix'}
      >
        <Send />
      </IconLink>
    </nav>
  );
}
