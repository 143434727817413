import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { ProvideCategoryBySlug } from './actions/ProvideCategoryBySlug';

export default function useCategoryDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('category-slug', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((slug: string) => dispatch(ProvideCategoryBySlug(slug, lang))),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((slug: string) => dispatch(ProvideCategoryBySlug(slug, lang))),
    );
  }
}
