/// Requests are stored in format:
/// ----------------------------------------------------------------------------
/// {
///  keyword for type: {
///    sign: DataRequestStateEnum
///  }
/// }
/// ----------------------------------------------------------------------------

import nestedPropertiesExists from '../../utils/nestedPropertiesExists';

export enum DataRequestStateEnum {
  NOT_FOUND = 'not_found',
  PENDING = 'pending',
  RUNNING = 'running',
  FULFILLED = 'fulfilled',
  FAILED = 'failed',
  EMPTY_RESPONSE = 'empty_response',
}

export interface DataRequests {
  [lng: string]: {
    [type: string]: {
      [sign: string]: DataRequestStateEnum;
    };
  };
}

/// Slice state ----------------------------------------------------------------

export interface DataProviderState {
  requests: DataRequests;
  ssr: DataRequests;
}

/// Queries   ------------------------------------------------------------------

export const DataProviderQueries = {
  getRequests: (state: DataProviderState): DataRequests => state.requests,
  getSsrRequests: (state: DataProviderState): DataRequests => state.requests,
  /**
   * this query is NOT to be used in selector!
   * it is custom made for SSR
   * @param state
   */
  getDoneLoading: (state: DataProviderState): boolean => {
    const stoppers = [DataRequestStateEnum.PENDING, DataRequestStateEnum.RUNNING];
    let result = true;
    for (const language of Object.keys(state.requests))
      for (const type of Object.keys(state.requests[language]))
        for (const sign of Object.keys(state.requests[language][type])) {
          if (stoppers.indexOf(state.requests[language][type][sign]) > -1) {
            result = false;
          }
        }
    return result;
  },
};

/// Reducers  ------------------------------------------------------------------

export const DataProviderReducers = {
  add: (state: DataProviderState, type: string, sign: string, language: string): void => {
    state.requests[language] = state.requests[language] || { [type]: {} };
    state.requests[language][type] = state.requests[language][type] || {};
    if (
      state.requests[language][type][sign] !== DataRequestStateEnum.RUNNING &&
      state.requests[language][type][sign] !== DataRequestStateEnum.FULFILLED
    )
      state.requests[language][type][sign] = DataRequestStateEnum.PENDING;
  },

  start: (state: DataProviderState, type: string, sign: string, language: string): void => {
    if (nestedPropertiesExists(state.requests, [language, type, sign]))
      state.requests[language][type][sign] = DataRequestStateEnum.RUNNING;
  },

  fulfilled: (state: DataProviderState, type: string, sign: string, language: string): void => {
    /// we can mark request as fulfilled without being created in a first place
    /// useful when we use data that was fetched from alternative way(as a dependency in other request)
    state.requests[language] = state.requests[language] || { [type]: {} };
    state.requests[language][type] = state.requests[language][type] || {};
    state.requests[language][type][sign] = DataRequestStateEnum.FULFILLED;
  },

  failed: (state: DataProviderState, type: string, sign: string, language: string): void => {
    if (nestedPropertiesExists(state.requests, [language, type, sign]))
      state.requests[language][type][sign] = DataRequestStateEnum.FAILED;
  },

  emptyResponse: (state: DataProviderState, type: string, sign: string, language: string): void => {
    if (nestedPropertiesExists(state.requests, [language, type, sign]))
      state.requests[language][type][sign] = DataRequestStateEnum.EMPTY_RESPONSE;
  },

  addSsr: (state: DataProviderState, type: string, sign: string, language: string): void => {
    state.ssr[language] = state.ssr[language] || { [type]: {} };
    state.ssr[language][type] = state.ssr[language][type] || {};
    state.ssr[language][type][sign] = DataRequestStateEnum.PENDING;
  },

  flushSsr: (state: DataProviderState): void => {
    if (Object.keys(state.requests).length === 0) state.requests = { ...state.ssr };
  },
};
