import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { ProvideAuthorsByPageNumber } from './actions/ProvideAuthorsByPageNumber';

export default function useAllAuthorsDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('all-authors', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) =>
        dispatch(ProvideAuthorsByPageNumber(Number(pageNumber), lang)),
      ),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) =>
        dispatch(ProvideAuthorsByPageNumber(Number(pageNumber), lang)),
      ),
    );
  }
}
