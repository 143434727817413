import React from 'react';
import Forbes from '../../../assets/img/Forbes.png';
import CNN from '../../../assets/img/CNN.png';
import NewYorkTimes from '../../../assets/img/NewYorkTimes.png';

export type PartnersPromoProps = {
  className?: string;
};

export default function PartnersPromo({ className }: PartnersPromoProps): JSX.Element {
  return (
    <div className={`PartnersPromo pt-5 pb-5 pt-md-12 pb-md-12 ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <img src={CNN} alt={'...'} className={'PartnersPromo--image mr-8'} />
        <img src={NewYorkTimes} alt={'...'} className={'PartnersPromo--image mr-8'} />
        <img src={Forbes} alt={'...'} className={'PartnersPromo--image'} />
      </div>
    </div>
  );
}
