import { WpCategoryOptions } from './WpCategoryOptions';
import { CategoryType, PagingInfoType } from '../../../types/PostTypes';
import axios from 'axios';
import mapWpCategory from '../mappers/mapWpCategory';
import mapWpPagingInfo from '../mappers/mapWpPagingInfo';

const API_CATEGORIES = '/wp-json/wp/v2/categories';

export default class CategoryApi {
  private options: WpCategoryOptions;
  public result?: CategoryType[];
  public pagingInfo?: PagingInfoType;

  constructor(private readonly wpHost: string) {
    this.options = {
      categoryId: [],
      slug: [],
    };
  }

  setPageNumber(pageNumber: number): CategoryApi {
    this.options.page = pageNumber;
    return this;
  }

  setPerPage(perPage: number): CategoryApi {
    this.options.perPage = perPage;
    return this;
  }

  setSlug(slug: string): CategoryApi {
    this.options.slug.push(slug);
    return this;
  }

  setCategoryId(categoryId: number): CategoryApi {
    this.options.categoryId.push(categoryId);
    return this;
  }

  setMinimalResult(): CategoryApi {
    this.options._fields = 'id,name,slug,link,yoast_head';
    return this;
  }

  setLanguage(language: string): CategoryApi {
    this.options.language = language;
    return this;
  }

  generateUrl(): string {
    let result: string[] = [];
    if (this.options.categoryId.length > 0) {
      result = [...result, ...this.options.categoryId.map((id: number) => 'includes[]=' + id)];
    }
    if (this.options.slug.length > 0) {
      result = [...result, ...this.options.slug.map((slug: string) => 'slug[]=' + slug)];
    }
    if (this.options.perPage) result.push('per_page=' + this.options.perPage);
    if (this.options.page) result.push('page=' + this.options.page);
    if (this.options._fields) result.push('_fields=', this.options._fields);

    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';

    return this.wpHost + lang + API_CATEGORIES + '?' + result.join('&');
  }

  async fetch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-category');
    const response = await axios.get(this.generateUrl());
    if (response.status === 200) {
      this.pagingInfo = mapWpPagingInfo(response.headers);
      this.result = response.data.map(mapWpCategory);
      return;
    }
    throw new Error('request failed');
  }
}
