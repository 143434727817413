import React, { useContext, useEffect, useState } from 'react';

declare global {
  interface Window {
    newSWReadyCallback?: () => void;
    __NEW_SW_READY__: boolean;
  }
}

const SWUpdateContext = React.createContext({});
const useSWUpdateContext = () => useContext(SWUpdateContext);

const SWUpdateContextProvider = ({ children }: { children: React.ReactNode }): any => {
  const [newSWReady, setNewSWReady] = useState(false);

  useEffect(() => {
    if ('__NEW_SW_READY__' in window && window.__NEW_SW_READY__) {
      setNewSWReady(true);
    } else {
      window.newSWReadyCallback = () => {
        setNewSWReady(true);
      };
      return () => {
        delete window.newSWReadyCallback;
      };
    }
  }, []);

  return <SWUpdateContext.Provider value={{ newSWReady: newSWReady }}>{children}</SWUpdateContext.Provider>;
};

const withSWUpdate = (Component: any) => (props: any) => {
  const { newSWReady }: any = useSWUpdateContext();

  return <Component {...props} newSWReady={newSWReady} />;
};

export default SWUpdateContextProvider;
export { useSWUpdateContext, withSWUpdate };
