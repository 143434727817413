import { AppQueries, AppReducers, AppState } from './AppState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';

export const SLICE_NAME = 'App';

export const initialState: AppState = {
  language: 'en',
};

export const AppSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLanguage: (state: AppState, { payload }: PayloadAction<string>): void => AppReducers.setLanguage(state, payload),
  },
});

export const getAppState = (state: RootState): AppState => state[SLICE_NAME];

export const { setLanguage } = AppSlice.actions;

export const getLanguageSelector = createSelector(getAppState, AppQueries.getLanguage);

export default AppSlice.reducer;
