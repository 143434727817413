import { WooProductImage } from '../types/WooProduct';
import { ProductImageType } from '../../../types/ProductType';

export default function mapWooProductImage(image: WooProductImage[]): {
  medium: ProductImageType;
  mediumLarge: ProductImageType;
  large: ProductImageType;
  full: ProductImageType;
} {
  if (image.length !== 0) {
    const regex = new RegExp(/fit=(.*?)&ssl/);
    const fit = image[0].src.match(regex);
    const widthHeightArray = `${fit?.[1].split('%2C')}`;
    const originalWidth = +widthHeightArray[0];
    const originalHeight = +widthHeightArray[1];
    const aspectRatioModifier = originalWidth / originalHeight;
    const srcBase = image[0].src.split('fit=')[0];
    const srcEnd = image[0].src.split('&ssl')[1];
    const mediumWidth = 300;
    const mediumLargeWidth = 768;
    const largeWidth = 950;

    return {
      medium: {
        url: `${srcBase}fit=${mediumWidth}%2C${mediumWidth * aspectRatioModifier}$sll${srcEnd}`,
        alt: image[0].alt,
        name: image[0].name,
        width: mediumWidth,
        height: mediumWidth * aspectRatioModifier,
      },
      mediumLarge: {
        url: `${srcBase}fit=${mediumLargeWidth}%2C${mediumLargeWidth * aspectRatioModifier}$sll${srcEnd}`,
        alt: image[0].alt,
        name: image[0].name,
        width: mediumLargeWidth,
        height: mediumLargeWidth * aspectRatioModifier,
      },
      large: {
        url: `${srcBase}fit=${largeWidth}%2C${largeWidth * aspectRatioModifier}$sll${srcEnd}`,
        alt: image[0].alt,
        name: image[0].name,
        width: largeWidth,
        height: largeWidth * aspectRatioModifier,
      },
      full: {
        url: image[0].src,
        alt: image[0].alt,
        name: image[0].name,
        width: originalWidth,
        height: originalHeight,
      },
    };
  }

  return {
    medium: {
      url: '',
      alt: '',
      name: '',
      width: 0,
      height: 0,
    },
    mediumLarge: {
      url: '',
      alt: '',
      name: '',
      width: 0,
      height: 0,
    },
    large: {
      url: '',
      alt: '',
      name: '',
      width: 0,
      height: 0,
    },
    full: {
      url: '',
      alt: '',
      name: '',
      width: 0,
      height: 0,
    },
  };
}
