import { WpRawTag } from '../types/wpRawTypes';
import { TagType } from '../../../types/PostTypes';

export default function mapWpTag(tag: WpRawTag): TagType {
  const res = {
    id: tag.id,
    slug: tag.slug,
    name: tag.name,
    link: tag.link,
    meta: tag.yoast_head_json,
  } as TagType;

  if (tag.description) res.description = tag.description;

  return res;
}
