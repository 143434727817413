import React from 'react';
import PostDetailShareArticle from '../../compound/post/page/PostDetailShareArticle';

export default function PostPageShareArticle({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`PostPageShareArticle Layout--container ${className ? className : ''}`}>
      <div className={'Divider Divider--dashed Divider--dark'} />

      <div className={'PostPage--ThinWidth-Centered'}>
        <PostDetailShareArticle />
      </div>

      <div className={'Divider Divider--dashed Divider--dark'} />
    </div>
  );
}
