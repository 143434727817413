import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PostDateAndAuthorSkeleton from './PostDateAndAuthorSkeleton';
import EstimatedReadTimeSkeleton from './EstimatedReadTimeSkeleton';

export default function PostListItemSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <SkeletonTheme inline={true}>
      <div className={`PostListItem ${className ? className : ''}`}>
        <figure className={'PostListItem--figure Layout--TwoColumn-Grid'}>
          <span className={'PostListItem--figure-ImgWrapper'}>
            <Skeleton className={'PostListItem--figure-Img'} />
          </span>
          <figcaption className={'PostListItem--figcaption pl-lg-8'}>
            <div className={'PostListItem--content pb-4 pb-lg-0'}>
              <PostDateAndAuthorSkeleton className={'mb-4 pt-6 pt-lg-0'} showAuthorAvatar={false} />
              <h3 className={'PostListItem--figcaption-Title'}>
                <Skeleton count={3} className={'PostListItem--figcaption-TitleLink'} />
              </h3>
            </div>
            <div className={'PostListItem--action pb-4'}>
              <Skeleton height={0} />
              <EstimatedReadTimeSkeleton />
            </div>
          </figcaption>
        </figure>
      </div>
    </SkeletonTheme>
  );
}
