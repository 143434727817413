import { trackGTMEvent } from './trackGTMEvent';

export const trackPageViewGTMEvent = (pagePostType: string, pageCategory: string[], pagePostAuthor: string[]): void => {
  trackGTMEvent({
    pagePostAuthors: pagePostAuthor,
    pageCategories: pageCategory,
    pagePostType: pagePostType,
    event: 'pageview',
  });
};

export default trackPageViewGTMEvent;
