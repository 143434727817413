import React from 'react';
import SWLink from '../../../fuctional/SWLink';
import { PostType } from '../../../../types/PostTypes';
import { postUrl } from '../../../router/routeList';
import MiniPostWrapper from '../../../sections/wrapper/MiniPostWrapper';
import DateAndAuthor from '../../../simple/DateAndAuthor';
import { useCurrentLanguage } from '../../../../core/i18/LanguageContextProvider';
import Image from '../../../simple/Image';

export interface MiniImagePostProps {
  post: PostType;
  className?: string;
}

export default function MiniImagePost({ post, className }: MiniImagePostProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = postUrl(post, language.code);

  return (
    <MiniPostWrapper className={`MiniImagePost ${className ? className : ''}`}>
      <SWLink to={post.link} title={post.title} className={'MiniImagePost--ImgWrapper'}>
        <Image itemWithImages={post} className={'MiniImagePost--Img'} />
      </SWLink>
      <div className={'MiniImagePost--content ml-6'}>
        <DateAndAuthor date={post.date} author={post.authors} className={'mb-2'} />
        <SWLink to={url} title={post.title} className={'MiniImagePost--title'}>
          {post.title}
        </SWLink>
      </div>
    </MiniPostWrapper>
  );
}
