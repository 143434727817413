import React from 'react';
/// Wordpress API
import useCategoryDataProviderHandler from '../connectors/wordpress/dataProviders/useCategoryDataProviderHandler';
import usePostsByCategoryIdDataProviderHandler from '../connectors/wordpress/dataProviders/usePostsByCategoryIdDataProviderHandler';
import useAllCategoriesDataProviderHandler from '../connectors/wordpress/dataProviders/useAllCategoriesDataProviderHandler';
/// Fatchilli API
import useMenuBySlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/useMenuBySlugDataProviderHandler';
import useLatestPostsDataProviderHandler from '../connectors/fcWordpress/dataProviders/useLatestPostsDataProviderHandler';
import usePostsByCategorySlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/usePostsByCategorySlugProviderHandler';
import usePostBySlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/usePostBySlugDataProviderHandler';
import usePostsByTagSlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/usePostsByTagSlugProviderHandler';
import useTagBySlugDataProviderHandler from '../connectors/wordpress/dataProviders/useTagBySlugDataProviderHandler';
import useAllTagsDataProviderHandler from '../connectors/wordpress/dataProviders/useAllTagsDataProviderHandler';
import useAllAuthorsDataProviderHandler from '../connectors/wordpress/dataProviders/useAllAuthorsDataProviderHandler';
import useAuthorBySlugDataProviderHandler from '../connectors/wordpress/dataProviders/useAuthorBySlugDataProviderHandler';
import usePostsByAuthorSlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/usePostsByAuthorSlugProviderHandler';
import usePostsBySearchTermDataProviderHandler from '../connectors/fcWordpress/dataProviders/usePostsBySearchTermDataProviderHandler';
import usePopularPostsByCategorySlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/usePopularPostsByCategorySlugProviderHandler';
import useTrendingPostsByCategorySlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/useTrendingPostsByCategorySlugProviderHandler';
import useFcPageBySlugDataProviderHandler from '../connectors/fcWordpress/dataProviders/useFcPageBySlugDataProviderHandler';
import useAllProductsDataProviderHandler from '../connectors/WPWoocommerce/dataProviders/useAllProductsDataProviderHandler';
import useProductBySlugDataProviderHandler from '../connectors/WPWoocommerce/dataProviders/useProductBySlugDataProviderHandler';
import useSearchTagsDataProviderHandler from '../connectors/wordpress/dataProviders/useSearchTagsDataProviderHandler';

export type DataProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export default function DataProvider({ children }: DataProviderProps): JSX.Element {
  useLatestPostsDataProviderHandler();
  useCategoryDataProviderHandler();
  usePostsByCategoryIdDataProviderHandler();
  useAllCategoriesDataProviderHandler();
  useMenuBySlugDataProviderHandler();
  usePostsByCategorySlugDataProviderHandler();
  usePostBySlugDataProviderHandler();
  usePostsByTagSlugDataProviderHandler();
  useTagBySlugDataProviderHandler();
  useSearchTagsDataProviderHandler();
  useAllTagsDataProviderHandler();
  useAllAuthorsDataProviderHandler();
  useAuthorBySlugDataProviderHandler();
  usePostsByAuthorSlugDataProviderHandler();
  // usePageBySlugDataProviderHandler();
  useFcPageBySlugDataProviderHandler();
  usePostsBySearchTermDataProviderHandler();

  usePopularPostsByCategorySlugDataProviderHandler();
  useTrendingPostsByCategorySlugDataProviderHandler();

  useAllProductsDataProviderHandler();
  useProductBySlugDataProviderHandler();

  return <>{children}</>;
}
