import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeAuthors } from '../../../../core/bucket/BucketSlice';
import { setLatestAuthors, setLatestAuthorsPagingInfo } from '../../../../core/authors/AuthorsSlice';
import { LObjectsByPage } from '../../../../types/PayloadActionTypes';
import { AuthorType } from '../../../../types/PostTypes';
import AuthorApi from '../../author/AuthorApi';

export function ProvideAuthorsByPageNumber(pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'all-authors',
      sign: '' + pageNumber,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (pageNumber: number): Promise<void> => {
      const authorApi = new AuthorApi(ENV_API_URL);
      await authorApi.setPerPage(100).setPageNumber(pageNumber).fetch();
      if (authorApi.result && authorApi.result.length > 0) {
        dispatch(storeAuthors({ [lang]: authorApi.result }));
        dispatch(
          setLatestAuthors({
            language: lang,
            pageNumber: pageNumber,
            objects: authorApi.result,
          } as LObjectsByPage<AuthorType>),
        );
        if (authorApi.pagingInfo) dispatch(setLatestAuthorsPagingInfo({ [lang]: authorApi.pagingInfo }));
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(pageNumber);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
