import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AuthorSkeleton from './AuthorSkeleton';

export default function DateAndAuthorSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <SkeletonTheme inline={true}>
      <div className={`DateAndAuthor ${className ? className : ''}`}>
        <span className={'DateAndAuthor-date'}>
          <Skeleton width={90} />
        </span>
        <AuthorSkeleton />
      </div>
    </SkeletonTheme>
  );
}
