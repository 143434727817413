import React from 'react';
import { AvatarCircle, AvatarCircleBorderStyle } from '../../../simple/AvatarCircle';
import { AuthorType } from '../../../../types/PostTypes';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';
import { generateAuthorUrl } from '../../../router/GenerateUrl';
import SWLink from '../../../fuctional/SWLink';

export type PostDetailAuthorNameProps = {
  author: AuthorType;
};

export default function PostDetailAuthorName({ author }: PostDetailAuthorNameProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = author.avatar_big || '';
  const authorRoute = author.name !== 'storybook' ? generateAuthorUrl(author, language.code) : '';
  const t = useTranslateMessage();
  return (
    <div className={`PostDetailAuthorName`}>
      <AvatarCircle
        className={'mr-4'}
        link={authorRoute}
        imageSrc={url}
        alt={author.name}
        width={62}
        height={62}
        borderStyle={AvatarCircleBorderStyle.THICK}
      />
      <div className={'PostDetailAuthorName--AuthorName'}>
        <span className={'PostDetailAuthorName--AuthorName-By'}>{t('author.article-by')}</span>
        <h2 className={'PostDetailAuthorName--AuthorName-Author mb-2'}>
          <SWLink to={authorRoute}>{author.name}</SWLink>
        </h2>
      </div>
    </div>
  );
}
