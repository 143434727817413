export interface GTMTrackOptions {
  pagePostAuthors?: string[];
  pageCategories?: string[];
  pagePostType: string;
  event: string;
}

export const trackGTMEvent = (options: GTMTrackOptions): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(options);
};

export default trackGTMEvent;
