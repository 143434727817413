import React from 'react';
import { hot } from 'react-hot-loader';

import './../assets/scss/App.scss';
import { Provider } from 'react-redux';
import { storeFn } from '../redux/store';
import { BrowserRouter } from 'react-router-dom';
import ScrollRestoration from 'react-scroll-restoration';
import LanguageContextProvider from '../core/i18/LanguageContextProvider';
import NewRoutes from './router/NewRoutes';
import SWUpdateContextProvider from '../core/SWUpdateContextProvider';
import CartContextProvider from '../core/Cart/LocalStorage/CartContextProvider';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <LanguageContextProvider>
          <SWUpdateContextProvider>
            <CartContextProvider>
              <ScrollRestoration />
              <Provider store={storeFn()}>
                <NewRoutes />
              </Provider>
            </CartContextProvider>
          </SWUpdateContextProvider>
        </LanguageContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default hot(module)(App);
