import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import { PageType } from '../../../../types/PostTypes';
import { PageSlugMapSelector, PagesSelector } from '../../../bucket/BucketSlice';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import useLangSelector from '../../../Hooks/useLangSelector';
import { IndexedObjects, SlugToIdMapper } from '../../../../types/StoreTypes';

function getFullPage(
  slug: string | null,
  pageSlugMap: SlugToIdMapper | undefined,
  pages: IndexedObjects<PageType> | undefined,
): PageType | null {
  if (pageSlugMap && pages && slug && pageSlugMap[slug] && pages[pageSlugMap[slug]]) return pages[pageSlugMap[slug]];
  return null;
}

export default function usePageBySlugProvider(
  slug: string | null,
  includeInSSR = true,
): [
  /// Page object
  PageType | null,
  /// Request state
  DataRequestStateEnum,
  /// setPageSlug callback
  (slug: string) => void,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();

  /// Redux Selectors ----------------------------------------------------------
  const pageSlugMap = useLangSelector(useAppSelector(PageSlugMapSelector));
  const pages = useLangSelector(useAppSelector(PagesSelector));

  /// Current hook state -------------------------------------------------------
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => slug);
  const [reqState, , setRequestSign] = useDataProviderState('page-by-slug', currentSlug);
  const [page, setPage] = useState<PageType | null>(() =>
    slug && pageSlugMap && pages ? getFullPage(slug, pageSlugMap, pages) : null,
  );

  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('page-by-slug', currentSlug);

  useEffect(() => {
    if (currentSlug === null) return; /// pause effect execution

    if (!pageSlugMap || !pages || !pageSlugMap.hasOwnProperty(currentSlug)) {
      if (reqState !== DataRequestStateEnum.RUNNING) {
        orderData('page-by-slug', currentSlug);
      }
      return;
    }

    setPage(pages[pageSlugMap[currentSlug]]);
  }, [currentSlug, pageSlugMap, pages]);

  const setSlugCallback = (slug: string) => {
    if (slug === currentSlug) return;
    setCurrentSlug(slug);
    setRequestSign(slug);
    setPage(null);
  };

  return [page, page ? DataRequestStateEnum.FULFILLED : reqState, setSlugCallback];
}
