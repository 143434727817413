import hasValidData from '../../../utils/hasValidData';
import React, { useEffect, useRef, useState } from 'react';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import VerticalCardPosts from '../cards/VerticalCardPosts';
import VerticalCardPostsSkeleton from '../../skeletons/VerticalCardPostsSkeleton';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import SWLink from '../../fuctional/SWLink';
//import ProductPromotional, { ProductPromotionalSize } from '../../compound/product/ProductPromotional';
//import VerticalListProducts from '../lists/VerticalListProducts';
//import Skeleton from 'react-loading-skeleton';
//import VerticalListProductsSkeleton from '../../skeletons/VerticalListProductsSkeleton';
//import CapitalCta, { CapitalCtaColorEnum } from '../../simple/CapitalCta';
import { TagCloudSection } from './TagCloudSection';
import useLatestPostsProvider from '../../../core/dataProviders/providers/useLatestPostsProvider';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import NumberedVerticalListPosts from '../lists/NumberedVerticalListPosts';
import NumberedVerticalListPostsSkeleton from '../../skeletons/NumberedVerticalListPostsSkeleton';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
//import useTrendingPostsByCategorySlugProvider from '../../../core/dataProviders/providers/useTrendingPostsByCategorySlugProvider';
//import useAllProductsProvider from '../../../core/dataProviders/providers/products/useAllProductsProvider';
//import ProductType from '../../../types/ProductType';
import usePostBySlugProvider from '../../../core/dataProviders/providers/usePostBySlugProvider';
import AdvertisementBlock from '../../fuctional/AdvertismentBlock';

export type LatestPostsSectionProps = { className?: string; currentScroll: number };

export default function LatestPostsSection({ className, currentScroll }: LatestPostsSectionProps): JSX.Element {
  const t = useTranslateMessage();
  const language = useCurrentLanguage();
  const [latestPosts, latestPostsReqState] = useLatestPostsProvider(1, false);
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);

  const [slug1, setSlug1] = useState<string | null>(null);
  const [slug2, setSlug2] = useState<string | null>(null);
  const [slug3, setSlug3] = useState<string | null>(null);
  const [slug4, setSlug4] = useState<string | null>(null);
  const [slug5, setSlug5] = useState<string | null>(null);

  const [post1, reqState1] = usePostBySlugProvider(slug1, false);
  const [post2, reqState2] = usePostBySlugProvider(slug2, false);
  const [post3, reqState3] = usePostBySlugProvider(slug3, false);
  const [post4, reqState4] = usePostBySlugProvider(slug4, false);
  const [post5, reqState5] = usePostBySlugProvider(slug5, false);

  const [slug, setSlug] = useState<string | null>(null);
  const [slugSet, setSlugSet] = useState<boolean>(false);
  const [isInView, setIsInView] = useState<boolean>(false);
  const sectionWrapper = useRef<HTMLDivElement>(null);
  /*const [trendingPosts, trendingPostsReqState] = useTrendingPostsByCategorySlugProvider(slug, 1, false);*/
  // const [allProducts, allProductReqState] = useAllProductsProvider(false);
  // const [filteredProducts, setFilteredProducts] = useState<ProductType[]>();
  // const [realMoneyProduct, setRealMoneyProduct] = useState<ProductType[]>();

  // useEffect(() => {
  //   const realProducts = allProducts.filter((product) => {
  //     return product.slug !== '';
  //   });
  //   const pricedProducts = allProducts.filter((product) => {
  //     return product.price !== 0;
  //   });
  //   const realPricedProduct = realProducts.filter((realProduct) =>
  //     pricedProducts.some(({ slug }) => realProduct.slug === slug),
  //   );
  //   const realOtherProducts =
  //     realPricedProduct.length > 0
  //       ? realProducts.filter((realProduct) => realProduct.slug !== realPricedProduct[0].slug)
  //       : realProducts;
  //   setRealMoneyProduct(realPricedProduct);
  //   setFilteredProducts(realOtherProducts);
  // }, [allProducts, allProductReqState]);

  useEffect(() => {
    if (
        !slugSet &&
        sectionWrapper.current &&
        sectionWrapper.current.getBoundingClientRect().bottom >= 0 &&
        sectionWrapper.current.getBoundingClientRect().top <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      setIsInView(true);
      setSlugSet(true);
    }
  }, [currentScroll]);

  useEffect(() => {
    /*isInView && setSlug('latest');*/
    if (language.code === 'en') {
      isInView && setSlug1('social-advanced-search-is-something-that-you-can-do-on-twitter-too');
      isInView &&
      setSlug2(
          'the-senders-name-of-your-newsletter-can-make-a-huge-difference-in-the-open-rate-also-should-you-use-emojis-in-email-subjects',
      );
      isInView &&
      setSlug3(
          'if-you-properly-welcome-new-users-and-subscribers-theyll-stay-longer-heres-how-to-design-your-onboarding-experience',
      );
      isInView && setSlug4('the-kyiv-independent-its-all-about-freedom');
      isInView && setSlug5('focus-on-value-instead-of-reach-ideas-for-metrics-to-use-in-your-newsroom');
    }
    if (language.code === 'de') {
      isInView && setSlug1('social-advanced-search-is-something-that-you-can-do-on-twitter-too');
      isInView &&
      setSlug2(
          'the-senders-name-of-your-newsletter-can-make-a-huge-difference-in-the-open-rate-also-should-you-use-emojis-in-email-subjects',
      );
      isInView &&
      setSlug3(
          'if-you-properly-welcome-new-users-and-subscribers-theyll-stay-longer-heres-how-to-design-your-onboarding-experience',
      );
      isInView && setSlug4('the-kyiv-independent-its-all-about-freedom');
      isInView && setSlug5('focus-on-value-instead-of-reach-ideas-for-metrics-to-use-in-your-newsroom');
    }
  }, [isInView]);

  return (
      <div className={`LatestPostsSection Layout--container ${className ? className : ''}`} ref={sectionWrapper}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{t('title.latest-stories')}</h2>
        </SectionHeader>

        <div className={'Layout--TwoToOneColumns-Grid'}>
          <div>
            {hasValidData(latestPosts, latestPostsReqState) ? (
                <VerticalCardPosts posts={latestPosts.slice(0, 6)} />
            ) : (
                <VerticalCardPostsSkeleton title={false} placeholders={6} />
            )}

            <Button
                btnType={ButtonTypeEnum.PRIMARY_LESS}
                width={matchesMediumUp ? ButtonWidthEnum.WIDE : ButtonWidthEnum.FULLWIDTH}
                height={ButtonHeightEnum.DEF}
                className={'mb-8 mt-1 mt-md-0'}
                component={SWLink}
                to={'/latest'}
            >
              {t('cta.explore-more')}
            </Button>

            <div className={'Divider Divider--dark mb-8 mb-md-12'} />

            <AdvertisementBlock id={'protag-in_feed_1'} />
          </div>

          <div>
            {/*{allProductReqState === DataRequestStateEnum.FULFILLED && realMoneyProduct && realMoneyProduct.length > 0 && (*/}
            {/*  <ProductPromotional type={ProductPromotionalSize.SMALL} product={filteredProducts[0]} className={'mb-7'} />*/}
            {/*)}*/}
            {/*{allProductReqState === DataRequestStateEnum.FULFILLED &&*/}
            {/*  (!realMoneyProduct || realMoneyProduct.length === 0) &&*/}
            {/*  filteredProducts &&*/}
            {/*  filteredProducts.length > 0 && (*/}
            {/*    <ProductPromotional*/}
            {/*      type={ProductPromotionalSize.SMALL}*/}
            {/*      product={filteredProducts[0]}*/}
            {/*      className={'mb-7'}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*{allProductReqState !== DataRequestStateEnum.FULFILLED && (*/}
            {/*  <Skeleton width={'100%'} height={490} className={'mb-7'} />*/}
            {/*)}*/}

            {/*{allProductReqState === DataRequestStateEnum.FULFILLED &&*/}
            {/*  (!realMoneyProduct || realMoneyProduct.length === 0) &&*/}
            {/*  filteredProducts &&*/}
            {/*  filteredProducts.length > 1 && <VerticalListProducts products={[filteredProducts[1]]} className={'mb-3'} />}*/}

            {/*{allProductReqState === DataRequestStateEnum.FULFILLED &&*/}
            {/*  realMoneyProduct &&*/}
            {/*  filteredProducts &&*/}
            {/*  filteredProducts.length > 0 && <VerticalListProducts products={[realMoneyProduct[0]]} className={'mb-3'} />}*/}

            {/*{allProductReqState !== DataRequestStateEnum.FULFILLED && (*/}
            {/*  <VerticalListProductsSkeleton placeholders={1} className={'mb-3'} />*/}
            {/*)}*/}

            {/*<CapitalCta color={CapitalCtaColorEnum.SECONDARY} component={SWLink} to={'/research'} className={'mb-7'}>*/}
            {/*  {t('cta.explore-more')}*/}
            {/*</CapitalCta>*/}

            <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.NONE}>
              <h2>
                {t('title.topic')} <span>{t('title.to-fix')}</span>
              </h2>
            </SectionHeader>

            <TagCloudSection />

            <div className={'mb-8'}>
              {/*{hasValidData(trendingPosts, trendingPostsReqState) ? (
              <NumberedVerticalListPosts posts={trendingPosts.slice(0, 5)} title={t('title.trending-now')} />
            ) : (
              <NumberedVerticalListPostsSkeleton title={true} placeholders={5} />
            )}*/}
              {reqState1 === DataRequestStateEnum.FULFILLED &&
              post1 !== null &&
              reqState2 === DataRequestStateEnum.FULFILLED &&
              post2 !== null &&
              reqState3 === DataRequestStateEnum.FULFILLED &&
              post3 !== null &&
              reqState4 === DataRequestStateEnum.FULFILLED &&
              post4 !== null &&
              reqState5 === DataRequestStateEnum.FULFILLED &&
              post5 !== null ? (
                  <NumberedVerticalListPosts posts={[post1, post2, post3, post4, post5]} title={t('title.trending-now')} />
              ) : (
                  <NumberedVerticalListPostsSkeleton title={true} placeholders={5} />
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

