import { PagingInfoType } from '../../../../types/PostTypes';
import { useAppSelector } from '../../../../redux/hooks';
import useLangSelector from '../../../Hooks/useLangSelector';
import {
  LatestPostsPagingInfoSelector,
  PostsByAuthorPagingInfoSelector,
  PostsByCategoryPagingInfoSelector,
  PostsBySearchTermPagingInfoSelector,
  PostsByTagPagingInfoSelector,
} from '../../../posts/PostsSlice';
import { AuthorSlugMapSelector, CategorySlugMapSelector, TagSlugMapSelector } from '../../../bucket/BucketSlice';

export function useLatestPostsPagingInfo(): PagingInfoType {
  return (
    useLangSelector(useAppSelector(LatestPostsPagingInfoSelector)) || {
      pages: 0,
      total: 0,
    }
  );
}

export function usePostsByCategoryPagingInfo(slug: string): PagingInfoType {
  const category = useLangSelector(useAppSelector(CategorySlugMapSelector));
  const paging = useLangSelector(useAppSelector(PostsByCategoryPagingInfoSelector));

  if (category && paging && category[slug] && paging[category[slug]]) return paging[category[slug]];
  return {
    pages: 0,
    total: 0,
  };
}

export function usePostsByTagPagingInfo(slug: string): PagingInfoType {
  const tags = useLangSelector(useAppSelector(TagSlugMapSelector));
  const paging = useLangSelector(useAppSelector(PostsByTagPagingInfoSelector));

  if (tags && paging && tags[slug] && paging[tags[slug]]) return paging[tags[slug]];
  return {
    pages: 0,
    total: 0,
  };
}

export function usePostsByAuthorPagingInfo(slug: string): PagingInfoType {
  const authors = useLangSelector(useAppSelector(AuthorSlugMapSelector));
  const paging = useLangSelector(useAppSelector(PostsByAuthorPagingInfoSelector));

  if (authors && paging && authors[slug] && paging[authors[slug]]) return paging[authors[slug]];
  return {
    pages: 0,
    total: 0,
  };
}

export function usePostsBySearchTermPagingInfo(searchTerm: string): PagingInfoType {
  const paging = useLangSelector(useAppSelector(PostsBySearchTermPagingInfoSelector));
  if (paging && paging[searchTerm]) return paging[searchTerm];
  return {
    pages: 0,
    total: 0,
  };
}
