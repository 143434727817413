import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PostListItemSkeleton from './PostListItemSkeleton';

export type VerticalListPostsSkeletonProps = {
  title: boolean;
  placeholders: number;
  className?: string;
  children?: JSX.Element;
};

export default function VerticalCardPostsSkeleton({
  title,
  placeholders,
  className,
  children,
}: VerticalListPostsSkeletonProps): JSX.Element {
  return (
    <div className={`VerticalCardPosts ${className ? className : ''}`}>
      {title && (
        <div className={`SectionHeader pt-4 mb-4 mb-md-6 SectionHeader--BorderThick`}>
          <h2 style={{ width: '25%' }}>
            <Skeleton />
          </h2>
        </div>
      )}
      <div className={'Layout--OneColumnVertical-Grid'}>
        {new Array(placeholders).fill(0).map((_, index) => (
          <PostListItemSkeleton key={`PostListItemSkeleton${index}`} className={'mb-7 mb-lg-9'} />
        ))}
        {children}
      </div>
    </div>
  );
}
