import React, { useCallback, useEffect, useRef } from 'react';
import useShareOnFacebookOnClick from '../../../../core/Hooks/SocialSharing/useShareOnFacebookOnClick';
import useShareOnLinkedInOnClick from '../../../../core/Hooks/SocialSharing/useShareOnLinkedInOnClick';
import useShareOnTwitterOnClick from '../../../../core/Hooks/SocialSharing/useShareOnTwitterOnClick';
import useShareOnTelegramOnClick from '../../../../core/Hooks/SocialSharing/useShareOnTelegramOnClick';
import IconLink, { IconFillEnum } from '../../../simple/IconLink';
import { ButtonTypeEnum } from '../../../simple/Button';
import LinkedIn from '../../../../assets/svg/in.svg';
import Facebook from '../../../../assets/svg/fb.svg';
import Twitter from '../../../../assets/svg/tw.svg';
import Send from '../../../../assets/svg/send.svg';
import { useLocation } from 'react-router-dom';

export type ShareLinksMenuProps = {
  toggleShareMenuFn: () => void;
  className?: string;
};

export default function ShareLinksMenu({ toggleShareMenuFn, className }: ShareLinksMenuProps): JSX.Element {
  const popupRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [shareOnFacebookUrl, shareOnFacebookOnClick] = useShareOnFacebookOnClick(ENV_DOMAIN_URL, location.pathname);
  const [shareOnLinkedInUrl, shareOnLinkedOnInClick] = useShareOnLinkedInOnClick(ENV_DOMAIN_URL, location.pathname);
  const [shareOnTwitterUrl, shareOnTwitterOnClick] = useShareOnTwitterOnClick(ENV_DOMAIN_URL, location.pathname);
  const [shareOnTelegramUrl, shareOnTelegramOnClick] = useShareOnTelegramOnClick(ENV_DOMAIN_URL, location.pathname);

  const onWindowClickEventListener = useCallback(
    (event: MouseEvent) => {
      /// everything outside of this menu (popupRef) will trigger toggleShareMenuFn
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        toggleShareMenuFn();
      }
    },
    [toggleShareMenuFn],
  );

  useEffect(() => {
    /// we will create an event handler for "window" clicks
    /// it will be used to close open menus
    window.addEventListener('click', onWindowClickEventListener);

    /// unregister event listener
    return () => {
      window.removeEventListener('click', onWindowClickEventListener);
    };
  }, [popupRef]);

  return (
    <div className={`ShareLinksMenu ${className ? className : ''}`} ref={popupRef}>
      <div className={'ShareLinksMenu--Nav'}>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS}`}
          href={shareOnLinkedInUrl}
          onClick={shareOnLinkedOnInClick}
        >
          <LinkedIn />
        </IconLink>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS}`}
          href={shareOnFacebookUrl}
          onClick={shareOnFacebookOnClick}
        >
          <Facebook />
        </IconLink>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS}`}
          href={shareOnTwitterUrl}
          onClick={shareOnTwitterOnClick}
        >
          <Twitter />
        </IconLink>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS}`}
          href={shareOnTelegramUrl}
          onClick={shareOnTelegramOnClick}
        >
          <Send />
        </IconLink>
      </div>
    </div>
  );
}
