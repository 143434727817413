import {
  LanguageWrapper,
  LIndexedObjects,
  LIndexedObjectsByString,
  LPagedObjects,
  PagedObjects,
} from '../../types/StoreTypes';
import { PagingInfoType, PostType } from '../../types/PostTypes';

export interface PostsState {
  latestPosts: LPagedObjects<number[]>;
  postsByCategory: LIndexedObjects<PagedObjects<number[]>>;
  postsByTag: LIndexedObjects<PagedObjects<number[]>>;
  postsByAuthor: LIndexedObjects<PagedObjects<number[]>>;
  postsBySearchTerm: LIndexedObjectsByString<PagedObjects<number[]>>;
  popularPosts: LIndexedObjects<PagedObjects<number[]>>;
  trendingPosts: LIndexedObjects<PagedObjects<number[]>>;

  latestPostsPaging: LanguageWrapper<PagingInfoType>;
  postsByCategoryPaging: LIndexedObjects<PagingInfoType>;
  postsByTagPaging: LIndexedObjects<PagingInfoType>;
  postsByAuthorPaging: LIndexedObjects<PagingInfoType>;
  postsBySearchTermPaging: LIndexedObjectsByString<PagingInfoType>;
}

export const PostsQueries = {
  getAvailablePagesForLatest: (state: PostsState): number[] => Object.keys(state.latestPosts).map(Number),
  getLatestPosts: (state: PostsState): LPagedObjects<number[]> => state.latestPosts,
  getLatestPostsPagingInfo: (state: PostsState): LanguageWrapper<PagingInfoType> => state.latestPostsPaging,

  getAvailableCategoryIds: (state: PostsState): number[] => Object.keys(state.postsByCategory).map(Number),
  getAvailablePagesByCategoryId: (state: PostsState, categoryId: number): number[] =>
    Object.keys(state.postsByCategory[categoryId]).map(Number),
  getPostsByCategory: (state: PostsState): LIndexedObjects<PagedObjects<number[]>> => state.postsByCategory,
  getPostsByCategoryPagingInfo: (state: PostsState): LIndexedObjects<PagingInfoType> => state.postsByCategoryPaging,

  getAvailableTagIds: (state: PostsState): number[] => Object.keys(state.postsByTag).map(Number),
  getAvailablePagesByTagId: (state: PostsState, tagId: number): number[] =>
    Object.keys(state.postsByTag[tagId]).map(Number),
  getPostsByTag: (state: PostsState): LIndexedObjects<PagedObjects<number[]>> => state.postsByTag,
  getPostsByTagPagingInfo: (state: PostsState): LIndexedObjects<PagingInfoType> => state.postsByTagPaging,

  getAvailableAuthorIds: (state: PostsState): number[] => Object.keys(state.postsByAuthor).map(Number),
  getAvailablePagesByAuthorId: (state: PostsState, authorId: number): number[] =>
    Object.keys(state.postsByAuthor[authorId]).map(Number),
  getPostsByAuthor: (state: PostsState): LIndexedObjects<PagedObjects<number[]>> => state.postsByAuthor,
  getPostsByAuthorPagingInfo: (state: PostsState): LIndexedObjects<PagingInfoType> => state.postsByAuthorPaging,

  getAvailableSearchTerms: (state: PostsState): string[] => Object.keys(state.postsBySearchTerm),
  getAvailablePagesBySearchTerm: (state: PostsState, searchTerm: string): number[] =>
    Object.keys(state.postsBySearchTerm[searchTerm]).map(Number),
  getPostsBySearchTerm: (state: PostsState): LIndexedObjectsByString<PagedObjects<number[]>> => state.postsBySearchTerm,
  getPostsBySearchTermPagingInfo: (state: PostsState): LIndexedObjectsByString<PagingInfoType> =>
    state.postsBySearchTermPaging,

  getPopularPosts: (state: PostsState): LIndexedObjects<PagedObjects<number[]>> => state.popularPosts,
  getTrendingPosts: (state: PostsState): LIndexedObjects<PagedObjects<number[]>> => state.trendingPosts,
};

export const PostsReducers = {
  setPostsByCategory: (
    state: PostsState,
    categoryId: number,
    pageNumber: number,
    posts: PostType[],
    language: string,
  ): void => {
    state.postsByCategory[language] = state.postsByCategory[language] || {};
    state.postsByCategory[language][categoryId] = state.postsByCategory[language][categoryId] || {};
    state.postsByCategory[language][categoryId][pageNumber] = posts.map((post: PostType) => post.id);
  },
  setPostsByTag: (state: PostsState, tagId: number, pageNumber: number, posts: PostType[], language: string): void => {
    state.postsByTag[language] = state.postsByTag[language] || {};
    state.postsByTag[language][tagId] = state.postsByTag[language][tagId] || {};
    state.postsByTag[language][tagId][pageNumber] = posts.map((post: PostType) => post.id);
  },
  setPostsByAuthor: (
    state: PostsState,
    authorId: number,
    pageNumber: number,
    posts: PostType[],
    language: string,
  ): void => {
    state.postsByAuthor[language] = state.postsByAuthor[language] || {};
    state.postsByAuthor[language][authorId] = state.postsByAuthor[language][authorId] || {};
    state.postsByAuthor[language][authorId][pageNumber] = posts.map((post: PostType) => post.id);
  },
  setLatest: (state: PostsState, pageNumber: number, posts: PostType[], language: string): void => {
    state.latestPosts[language] = state.latestPosts[language] || {};
    state.latestPosts[language][pageNumber] = posts.map((post: PostType) => post.id);
  },
  setPostsBySearchTerm: (
    state: PostsState,
    searchTerm: string,
    pageNumber: number,
    posts: PostType[],
    language: string,
  ): void => {
    state.postsBySearchTerm[language] = state.postsBySearchTerm[language] || {};
    state.postsBySearchTerm[language][searchTerm] = state.postsBySearchTerm[language][searchTerm] || {};
    state.postsBySearchTerm[language][searchTerm][pageNumber] = posts.map((post: PostType) => post.id);
  },
  setPopularPosts: (
    state: PostsState,
    categoryId: number,
    pageNumber: number,
    posts: PostType[],
    language: string,
  ): void => {
    state.popularPosts[language] = state.popularPosts[language] || {};
    state.popularPosts[language][categoryId] = state.popularPosts[language][categoryId] || {};
    state.popularPosts[language][categoryId][pageNumber] = posts.map((post: PostType) => post.id);
  },
  setTrendingPosts: (
    state: PostsState,
    categoryId: number,
    pageNumber: number,
    posts: PostType[],
    language: string,
  ): void => {
    state.trendingPosts[language] = state.trendingPosts[language] || {};
    state.trendingPosts[language][categoryId] = state.trendingPosts[language][categoryId] || {};
    state.trendingPosts[language][categoryId][pageNumber] = posts.map((post: PostType) => post.id);
  },

  setLatestPostsPagingInfo: (state: PostsState, pagingInfo: PagingInfoType, language: string): void => {
    state.latestPostsPaging[language] = pagingInfo;
  },
  setPostsByCategoryPagingInfo: (state: PostsState, id: number, pagingInfo: PagingInfoType, language: string): void => {
    state.postsByCategoryPaging[language] = state.postsByCategoryPaging[language] || {};
    state.postsByCategoryPaging[language][id] = pagingInfo;
  },
  setPostsByTagPagingInfo: (state: PostsState, id: number, pagingInfo: PagingInfoType, language: string): void => {
    state.postsByTagPaging[language] = state.postsByTagPaging[language] || {};
    state.postsByTagPaging[language][id] = pagingInfo;
  },
  setPostsByAuthorPagingInfo: (state: PostsState, id: number, pagingInfo: PagingInfoType, language: string): void => {
    state.postsByAuthorPaging[language] = state.postsByAuthorPaging[language] || {};
    state.postsByAuthorPaging[language][id] = pagingInfo;
  },
  setPostsBySearchTermPagingInfo: (
    state: PostsState,
    id: string,
    pagingInfo: PagingInfoType,
    language: string,
  ): void => {
    state.postsBySearchTermPaging[language] = state.postsBySearchTermPaging[language] || {};
    state.postsBySearchTermPaging[language][id] = pagingInfo;
  },
};
