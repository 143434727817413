import { useEffect, useState } from 'react';
import { CategoryType } from '../../../../types/PostTypes';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import useCategoriesFiller from '../../../Hooks/useCategoriesFiller';
import { LatestCategoriesSelector } from '../../../categories/CategoriesSlice';
import useDataProviderMarkAsFulfilled from '../../useDataProviderMarkAsFulfilled';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import useLangSelector from '../../../Hooks/useLangSelector';

export default function useAllCategories(
  pageNumber: number,
  includeInSSR = true,
): [
  /// Categories
  CategoryType[],
  /// Request State
  DataRequestStateEnum,
  /// Set page number callback
  (pageNumber: number) => void,
] {
  const [reqState, , setDataProviderPageNumber] = useDataProviderState('all-categories', '' + pageNumber);
  const orderData = useDataProviderOrder();
  const fulfillDataRequest = useDataProviderMarkAsFulfilled();
  const latestCategories = useLangSelector(useAppSelector(LatestCategoriesSelector));
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(pageNumber);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const categoryFiller = useCategoriesFiller();

  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('all-categories', '' + pageNumber);

  useEffect(() => {
    if (latestCategories && latestCategories[currentPageNumber]) {
      setCategories(categoryFiller(latestCategories[currentPageNumber]));
      fulfillDataRequest('all-categories', '' + currentPageNumber);
    } else {
      setCategories([]);
      if (reqState !== DataRequestStateEnum.RUNNING) {
        orderData('all-categories', '' + currentPageNumber);
      }
    }
  }, [latestCategories, currentPageNumber]);

  const setPageNumberCallback = (pageNumber: number): void => {
    setCurrentPageNumber(pageNumber);
    setDataProviderPageNumber('' + pageNumber);
  };

  return [categories, reqState, setPageNumberCallback];
}
