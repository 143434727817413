import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeAuthors, storeAuthorSlugMapping } from '../../../../core/bucket/BucketSlice';
import AuthorApi from '../../author/AuthorApi';

export function ProvideAuthorBySlug(slug: string, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'author-slug',
      sign: slug,
      language: lang,
    } as DataProviderRequestSign;

    const runApi = async (slug: string): Promise<void> => {
      const authorApi = new AuthorApi(ENV_API_URL);
      await authorApi.setSlug(slug).fetch();
      if (authorApi.result && authorApi.result.length === 1) {
        dispatch(storeAuthors({ [lang]: [authorApi.result[0]] }));
        if (authorApi.result[0].slug !== slug) {
          dispatch(storeAuthorSlugMapping({ [lang]: [{ ...authorApi.result[0], slug: slug }] }));
        }
      } else {
        throw new Error('404');
      }
    };

    try {
      dispatch(runRequest(requestSign));
      await runApi(slug);
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ex.message === '404' || ex.message.indexOf('code 404') > -1) dispatch(emptyResponseRequest(requestSign));
      else dispatch(failedRequest(requestSign));
    }
  };
}
