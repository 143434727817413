import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function AuthorSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <span className={`Author ${className ? className : ''}`}>
      <Skeleton width={90} />
    </span>
  );
}
