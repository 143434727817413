import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../DataProviderState';
import useDataProviderState from '../useDataProviderState';
import useDataProviderOrder from '../useDataProviderOrder';
import usePostsFiller from '../../Hooks/usePostsFiller';
import { useAppSelector } from '../../../redux/hooks';
import { PostType } from '../../../types/PostTypes';
import { LatestPostsSelector } from '../../posts/PostsSlice';
import useDataProviderMarkAsFulfilled from '../useDataProviderMarkAsFulfilled';
import useDataProviderSSROrder from '../useDataProviderSSROrder';
import useLangSelector from '../../Hooks/useLangSelector';

/**
 * Hook that returns latest posts by page number
 * if data is not available, it will place an order for it to
 * redux DataProvider slice
 *
 * @param pageNumber
 * @param includeInSSR
 */
export default function useLatestPostsProvider(
  pageNumber: number | null,
  includeInSSR = true,
): [
  /// Posts by page number
  PostType[],
  /// Request state
  DataRequestStateEnum,
  /// Set page number callback
  (pageNumber: number) => void,
] {
  const filler = usePostsFiller();
  const [latestRequestState, , setDataProviderPageNumber] = useDataProviderState('latest-posts', '' + pageNumber);
  const orderData = useDataProviderOrder();
  const latestPosts = useLangSelector(useAppSelector(LatestPostsSelector));
  const [posts, setPosts] = useState<PostType[]>(() => {
    if (pageNumber !== null && latestPosts && latestPosts[pageNumber]) return filler(latestPosts[pageNumber]);
    return [];
  });
  const [currentPageNumber, setCurrentPageNumber] = useState<number | null>(pageNumber);
  const fulfillDataRequest = useDataProviderMarkAsFulfilled();

  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('latest-posts', '' + currentPageNumber);

  useEffect(() => {
    if (currentPageNumber === null) {
      setPosts([]);
      setDataProviderPageNumber(null);
      return;
    }
    if (latestPosts && latestPosts.hasOwnProperty(currentPageNumber)) {
      setPosts(filler(latestPosts[currentPageNumber]));
      fulfillDataRequest('latest-posts', '' + currentPageNumber);
    } else {
      setPosts([]);
      /// If there is no order, or running request, run it
      if (latestRequestState !== DataRequestStateEnum.RUNNING) {
        orderData('latest-posts', '' + currentPageNumber);
      }
    }
  }, [latestPosts, currentPageNumber]);

  /// Page number is changed in providerState hook and in current state variable
  const setPageNumber = (pageNumber: number): void => {
    setCurrentPageNumber(pageNumber);
    setDataProviderPageNumber('' + pageNumber);
  };

  return [posts, latestRequestState, setPageNumber];
}
