import { LanguageWrapper, LPagedObjects } from '../../types/StoreTypes';
import { CategoryType, PagingInfoType } from '../../types/PostTypes';

export interface CategoriesState {
  latestCategories: LPagedObjects<number[]>;
  latestCategoriesPaging: LanguageWrapper<PagingInfoType>;
}

export const CategoriesQueries = {
  getAvailablePagesForLatest: (state: CategoriesState): number[] => Object.keys(state.latestCategories).map(Number),
  getLatestCategories: (state: CategoriesState): LPagedObjects<number[]> => state.latestCategories,
  getLatestPagingInfo: (state: CategoriesState): LanguageWrapper<PagingInfoType> => state.latestCategoriesPaging,
};

export const CategoriesReducers = {
  setLatest: (state: CategoriesState, pageNumber: number, categories: CategoryType[], language: string): void => {
    state.latestCategories[language] = state.latestCategories[language] || {};
    state.latestCategories[language][pageNumber] = categories.map((category: CategoryType) => category.id);
  },
  setLatestPagingInfo: (state: CategoriesState, pagingInfo: PagingInfoType, language: string): void => {
    state.latestCategoriesPaging[language] = pagingInfo;
  },
};
