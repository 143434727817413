import { WpOrderEnum } from '../post/WpPostOptions';

export enum WpTagOrderEnum {
  id = 'id',
  include = 'include',
  name = 'name',
  slug = 'slug',
  include_slugs = 'include_slugs',
  term_group = 'term_group',
  description = 'description',
  count = 'count',
}

export interface WpTagOptions {
  language?: string;
  page?: number;
  perPage?: number;
  tagId: number[];
  slug: string[];
  search?: string;
  _fields?: string;
  orderby?: WpTagOrderEnum;
  order?: WpOrderEnum;
}
