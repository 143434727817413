import { TagType } from '../../types/PostTypes';
import { useAppSelector } from '../../redux/hooks';
import { TagsSelector } from '../bucket/BucketSlice';
import useLangSelector from './useLangSelector';

export default function useTagsFiller(): (ids: number[]) => TagType[] {
  const tags = useLangSelector(useAppSelector(TagsSelector));

  return (ids: number[]): TagType[] => {
    return tags
      ? ids
          /// use only available tags
          .filter((id: number): boolean => tags.hasOwnProperty(id))
          /// map id to tagId
          .map((id: number): TagType => tags[id])
      : [];
  };
}
