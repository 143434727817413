import { useAppDispatch } from '../../redux/hooks';
import { addRequest } from './DataProviderSlice';
import { useParams } from 'react-router-dom';

export default function useDataProviderOrder(): (type: string, sign: string) => void {
  const params = useParams<{ lng?: string }>();
  const dispatch = useAppDispatch();

  return (type: string, sign: string): void => {
    dispatch(
      addRequest({
        type: type,
        sign: sign,
        language: params.lng ? params.lng : 'en',
      }),
    );
  };
}
