import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import usePostBySlugProvider from '../../core/dataProviders/providers/usePostBySlugProvider';
import PostPageBanner from '../sections/post/PostPageBanner';
import useFCPostsByCategorySlugProvider from '../../core/dataProviders/providers/useFCPostsByCategorySlugProvider';
import useCategoryBySlugProvider from '../../core/dataProviders/providers/categories/useCategoryBySlugProvider';
import { CategoryType, PostType } from '../../types/PostTypes';
import PostPageBannerSkeleton from '../skeletons/PostPageBannerSkeleton';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import InjectScripts from '../fuctional/InjectScripts';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import { generatePostUrl } from '../router/GenerateUrl';
import { IRouteParams } from '../router/NewRoutes';
import PostPageContentWrapper from '../sections/post/PostPageContentWrapper';
import PostPageAuthors from '../sections/post/PostPageAuthors';
import PostPageShareArticle from '../sections/post/PostPageShareArticle';
import PostPageRelatedArticles from '../sections/post/PostPageRelatedArticles';
import use404Page from '../../core/Hooks/use404Page';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';
import getPostAuthorsArray from '../../core/GTMTracking/helpers/getPostAuthorsArray';
import getPostCategoriesArray from '../../core/GTMTracking/helpers/getPostCategoriesArray';

export default function PostPage({ language }: IRouteParams): JSX.Element {
  const params = useParams<{ slug: string }>();
  const history = useHistory();
  const t = useTranslateMessage();

  const [post, reqState] = usePostBySlugProvider(params.slug);
  const [categorySlug, setCategorySlug] = useState<string | null>(null);
  const [category, categoryReqState] = useCategoryBySlugProvider(categorySlug, false);
  const [postCatPosts, postCatPostsReqState] = useFCPostsByCategorySlugProvider(categorySlug, 1, false);
  const [filteredCategoryPosts, setFilteredCategoryPosts] = useState<PostType[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<CategoryType[] | undefined>(post?.categories);
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(post, reqState) ? post : null);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const location = useLocation();

  use404Page(reqState, language);

  useEffect(() => {
    hasValidData(post, reqState) && setAnchor(document.getElementById(location.hash.substring(1)));
  }, [location, reqState, post]);

  useEffect(() => {
    if (anchor !== null) {
      const headerOffset = 70;
      const elementPosition = anchor.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [anchor]);

  useEffect(() => {
    if (hasValidData(post, reqState)) {
      setMetaDataPost(post);
      const filterPrimaryCategory =
        post.categories?.length > 0 && post.categories?.filter((category: CategoryType) => category.primary);
      filterPrimaryCategory && filterPrimaryCategory.length === 0
        ? setFilteredCategories(() => {
            const filterDisplayCategory = post.categories.filter(
              (category: CategoryType) =>
                category.slug !== t('slug.category.featured') && category.slug !== t('slug.category.top-stories'),
            );
            return filterDisplayCategory ? filterDisplayCategory : post.categories;
          })
        : setFilteredCategories(filterPrimaryCategory ? filterPrimaryCategory : []);
      trackPageViewGTMEvent('post-detail', getPostCategoriesArray(post), getPostAuthorsArray(post));
    }
  }, [reqState, post]);

  useEffect(() => {
    if (post) {
      history.replace(generatePostUrl(post, language));
    }
  }, [post, language]);

  useEffect(() => {
    filteredCategories !== undefined && filteredCategories.length > 0 && setCategorySlug(filteredCategories[0].slug);
  }, [filteredCategories]);

  useEffect(() => {
    hasValidData(postCatPosts, postCatPostsReqState) &&
      hasValidData(post, reqState) &&
      setFilteredCategoryPosts(postCatPosts.filter((filterPost) => filterPost.slug !== post.slug));
  }, [postCatPosts, postCatPostsReqState, reqState, post]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      {/* external scripts  -------------------------------------------------------------- */}
      {post && post.footer && post.header && <InjectScripts header={post.header} footer={post.footer} />}
      {/* -------------------------------------------------------------------------------- */}

      <div className={'PostPage'}>
        {hasValidData(post, reqState) ? (
          <PostPageBanner post={post} className={'mb-13'} category={category} />
        ) : (
          <PostPageBannerSkeleton className={'mb-13'} />
        )}

        <PostPageContentWrapper
          post={post}
          reqState={reqState}
          filteredCategoryPosts={filteredCategoryPosts}
          postCatPostsReqState={postCatPostsReqState}
        />

        <PostPageAuthors post={post} reqState={reqState} />

        <PostPageShareArticle />

        <PostPageRelatedArticles
          filteredCategoryPosts={filteredCategoryPosts}
          postCatPostsReqState={postCatPostsReqState}
          category={category}
          categoryReqState={categoryReqState}
        />
      </div>
    </>
  );
}
