import React from 'react';
import IconLink, { IconFillEnum } from '../../../simple/IconLink';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../../simple/Button';
import LinkedIn from '../../../../assets/svg/in.svg';
import Twitter from '../../../../assets/svg/tw.svg';
import Instagram from '../../../../assets/svg/instagram.svg';
import Facebook from '../../../../assets/svg/fb.svg';
import { AuthorType } from '../../../../types/PostTypes';

export type PostDetailAuthorSocialsProps = {
  author: AuthorType;
};
export default function PostDetailAuthorSocials({ author }: PostDetailAuthorSocialsProps): JSX.Element {
  return (
    <div className={'PostDetailAuthorSocials'}>
      <div className={'PostDetailAuthorSocials--Icons'}>
        {author.socials !== undefined && (
          <>
            {author.socials.linkedin !== undefined && (
              <IconLink
                fill={IconFillEnum.SECONDARY}
                component={'a'}
                className={`${ButtonTypeEnum.DARK_LESS} mr-1`}
                href={author.socials.linkedin}
              >
                <LinkedIn />
              </IconLink>
            )}
            {author.socials.twitter !== undefined && (
              <IconLink
                fill={IconFillEnum.SECONDARY}
                component={'a'}
                className={`${ButtonTypeEnum.DARK_LESS} mr-1`}
                href={author.socials.twitter}
              >
                <Twitter />
              </IconLink>
            )}
            {author.socials.facebook !== undefined && (
              <IconLink
                fill={IconFillEnum.SECONDARY}
                component={'a'}
                className={`${ButtonTypeEnum.DARK_LESS} mr-1`}
                href={author.socials.facebook}
              >
                <Facebook />
              </IconLink>
            )}
            {author.socials.instagram !== undefined && (
              <IconLink
                fill={IconFillEnum.SECONDARY}
                component={'a'}
                className={`${ButtonTypeEnum.DARK_LESS}`}
                href={author.socials.instagram}
              >
                <Instagram />
              </IconLink>
            )}
          </>
        )}
      </div>
      <Button
        btnType={ButtonTypeEnum.PRIMARY}
        width={ButtonWidthEnum.WIDE}
        height={ButtonHeightEnum.THIN}
        className={'PostDetailAuthorSocials--Button display-none'}
      >
        Follow author
      </Button>
    </div>
  );
}
