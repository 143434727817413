import React, { useState } from 'react';

export enum CapitalCtaColorEnum {
  WHITE = 'CapitalCta--white',
  BLACK = 'CapitalCta--black',
  PRIMARY = 'CapitalCta--primary',
  SECONDARY = 'CapitalCta--secondary',
}

interface CapitalCtaProps<C extends React.ElementType> {
  component?: C;
  children: React.ReactNode;
  color: CapitalCtaColorEnum;
  /*onClickChildrenTo?: string;*/
  onClick?: () => void;
  className?: string;
}

type CapitalCtaPropsType<C extends React.ElementType> = CapitalCtaProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof CapitalCtaProps<C>>;

const CapitalCta = <C extends React.ElementType>(props: CapitalCtaPropsType<C>): JSX.Element => {
  const { children, component, color, onClick, className, ...rest } = props;
  const Component = component || 'button';

  return (
    <Component className={`CapitalCta ${color} ${className ? className : ''}`} onClick={onClick} {...rest}>
      {children}
    </Component>
  );
};

export default CapitalCta;
