import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import { AuthorType } from '../../../../types/PostTypes';
import { AuthorSlugMapSelector, AuthorsSelector } from '../../../bucket/BucketSlice';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import { IndexedObjects, SlugToIdMapper } from '../../../../types/StoreTypes';
import useLangSelector from '../../../Hooks/useLangSelector';

/**
 * Recognize "full" author, and result with it ONLY if there is one
 *  Authors are "full" if there is .meta field
 *
 * @param slug
 * @param slugToAuthorId
 * @param authors
 */
function getFullAuthor(
  slug: string,
  slugToAuthorId: SlugToIdMapper | undefined,
  authors: IndexedObjects<AuthorType> | undefined,
): AuthorType | null {
  if (
    slugToAuthorId &&
    authors &&
    slugToAuthorId[slug] &&
    authors[slugToAuthorId[slug]] &&
    authors[slugToAuthorId[slug]].meta
  )
    return authors[slugToAuthorId[slug]];
  return null;
}

export default function useAuthorBySlugProvider(
  slug: string | null,
  includeInSSR = true,
): [
  /// Author object
  AuthorType | null,
  /// Request state
  DataRequestStateEnum,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();

  /// Redux Selectors ----------------------------------------------------------
  const authors = useLangSelector(useAppSelector(AuthorsSelector));
  const slugToAuthorId = useLangSelector(useAppSelector(AuthorSlugMapSelector));

  /// Current hook state -------------------------------------------------------
  const [author, setAuthor] = useState<AuthorType | null>(() =>
    slug ? getFullAuthor(slug, slugToAuthorId, authors) : null,
  );
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => null);
  const [reqState, , setRequestSign] = useDataProviderState('author-slug', slug);

  /// on slug change  ----------------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug) {
      setCurrentSlug(slug);
      let foundAuthor: AuthorType | null = null;
      if (slug) foundAuthor = getFullAuthor(slug, slugToAuthorId, authors);
      setAuthor(foundAuthor);

      /// Order data if there is not matching category in the bucket  ------------
      if (slug) {
        setRequestSign(slug);
        if (foundAuthor === null) orderData('author-slug', slug);
      }
    }
  }, [slug]);

  useEffect(() => {
    /// Make sure we are listening to effect hook
    if (author !== null) return;
    if (currentSlug) setAuthor(getFullAuthor(currentSlug, slugToAuthorId, authors));
  }, [slugToAuthorId, authors]);

  /// SSR Data  ----------------------------------------------------------------
  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('author-slug', slug);

  /// Hook result --------------------------------------------------------------
  return [author, author ? DataRequestStateEnum.FULFILLED : reqState];
}
