import { BucketQueries, BucketReducers, BucketState } from './BucketState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthorType, CategoryType, PageType, PostType, TagType } from '../../types/PostTypes';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';
import { LanguageWrapper } from '../../types/StoreTypes';
import ProductType from '../../types/ProductType';

export const SLICE_NAME = 'Bucket';

const initialState: BucketState = {
  posts: {},
  categories: {},
  tags: {},
  authors: {},
  pages: {},
  products: {},
  postSlugMap: {},
  categorySlugMap: {},
  tagSlugMap: {},
  authorSlugMap: {},
  pageSlugMap: {},
  productSlugMap: {},
};

export const BucketSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    storePosts: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<PostType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storePosts(state, payload[lng], lng)),
    storeFullPosts: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<PostType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeFullPosts(state, payload[lng], lng)),
    storeTags: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<TagType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeTags(state, payload[lng], lng)),
    storeCategories: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<CategoryType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeCategories(state, payload[lng], lng)),
    storeAuthors: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<AuthorType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeAuthors(state, payload[lng], lng)),
    storePages: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<PageType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storePages(state, payload[lng], lng)),
    storeProducts: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<ProductType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeProducts(state, payload[lng], lng)),
    storeCategorySlugMapping: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<CategoryType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeCategoryMapping(state, payload[lng], lng)),
    storeTagSlugMapping: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<TagType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeTagMapping(state, payload[lng], lng)),
    storeAuthorSlugMapping: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<AuthorType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeAuthorMapping(state, payload[lng], lng)),
    storePostSlugMapping: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<PostType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storePostMapping(state, payload[lng], lng)),
    storePageSlugMapping: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<PageType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storePageMapping(state, payload[lng], lng)),
    storeProductSlugMapping: (state: BucketState, { payload }: PayloadAction<LanguageWrapper<ProductType[]>>) =>
      Object.keys(payload).forEach((lng: string) => BucketReducers.storeProductMapping(state, payload[lng], lng)),
  },
});

export const {
  storePosts,
  storeFullPosts,
  storeTags,
  storeCategories,
  storeAuthors,
  storePages,
  storeProducts,
  storeCategorySlugMapping,
  storeTagSlugMapping,
  storePostSlugMapping,
  storePageSlugMapping,
  storeAuthorSlugMapping,
  storeProductSlugMapping,
} = BucketSlice.actions;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getBucketState = (state: RootState): BucketState => state[SLICE_NAME];

export const PostsSelector = createSelector(getBucketState, BucketQueries.getPosts);
export const PostSlugMapSelector = createSelector(getBucketState, BucketQueries.getPostSlugMap);
export const TagsSelector = createSelector(getBucketState, BucketQueries.getTags);
export const TagSlugMapSelector = createSelector(getBucketState, BucketQueries.getTagSlugMap);
export const CategoriesSelector = createSelector(getBucketState, BucketQueries.getCategories);
export const CategorySlugMapSelector = createSelector(getBucketState, BucketQueries.getCategorySlugMap);
export const AuthorsSelector = createSelector(getBucketState, BucketQueries.getAuthors);
export const AuthorSlugMapSelector = createSelector(getBucketState, BucketQueries.getAuthorSlugMap);
export const PagesSelector = createSelector(getBucketState, BucketQueries.getPages);
export const PageSlugMapSelector = createSelector(getBucketState, BucketQueries.getPageSlugMap);
export const ProductsSelector = createSelector(getBucketState, BucketQueries.getProducts);
export const ProductSlugMapSelector = createSelector(getBucketState, BucketQueries.getProductSlugMap);

export default BucketSlice.reducer;
