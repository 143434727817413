import { PostType } from '../../types/PostTypes';
import { useAppSelector } from '../../redux/hooks';
import { PostsSelector } from '../bucket/BucketSlice';
import useLangSelector from './useLangSelector';

export default function usePostsFiller(): (ids: number[]) => PostType[] {
  const posts = useLangSelector(useAppSelector(PostsSelector));

  return (ids: number[]): PostType[] => {
    return posts
      ? ids
          /// use only available posts
          .filter((id: number): boolean => posts.hasOwnProperty(id))
          /// map id to postId
          .map((id: number): PostType => posts[id])
      : [];
  };
}
