import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import useDataProviderFilterByState from '../../../core/dataProviders/useDataProviderFilterByState';
import { DataRequestStateEnum } from '../../../core/dataProviders/DataProviderState';
import { ProvideTagsByPageNumber } from './actions/ProvideTagsByPageNumber';

export default function useAllTagsDataProviderHandler(): void {
  const dispatch = useAppDispatch();
  const pendingOrders = useDataProviderFilterByState('all-tags', DataRequestStateEnum.PENDING);

  useEffect(() => {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) => dispatch(ProvideTagsByPageNumber(Number(pageNumber), lang))),
    );
  }, [pendingOrders]);

  if (ENV_SSR) {
    Object.keys(pendingOrders).forEach((lang: string) =>
      pendingOrders[lang].forEach((pageNumber: string) => dispatch(ProvideTagsByPageNumber(Number(pageNumber), lang))),
    );
  }
}
