import React from 'react';
import MiniPostWrapper from '../sections/wrapper/MiniPostWrapper';
import DateAndAuthorSkeleton from './DateAndAuthorSkeleton';
import Skeleton from 'react-loading-skeleton';

export default function MiniImagePostSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <MiniPostWrapper className={`MiniImagePost ${className ? className : ''}`}>
      <span className={'MiniImagePost--ImgWrapper'}>
        <Skeleton className={'MiniImagePost--Img'} />
      </span>
      <div className={'MiniImagePost--content ml-6'}>
        <DateAndAuthorSkeleton className={'mb-2'} />
        <Skeleton count={3} className={'MiniImagePost--title'} />
      </div>
    </MiniPostWrapper>
  );
}
