import { LIndexedObjects, LSlugToIdMapper } from '../../types/StoreTypes';
import { AuthorType, CategoryType, PageType, PostType, TagType } from '../../types/PostTypes';
import ProductType from '../../types/ProductType';
export interface BucketState {
  posts: LIndexedObjects<PostType>;
  tags: LIndexedObjects<TagType>;
  categories: LIndexedObjects<CategoryType>;
  authors: LIndexedObjects<AuthorType>;
  pages: LIndexedObjects<PageType>;
  products: LIndexedObjects<ProductType>;

  postSlugMap: LSlugToIdMapper;
  tagSlugMap: LSlugToIdMapper;
  categorySlugMap: LSlugToIdMapper;
  authorSlugMap: LSlugToIdMapper;
  pageSlugMap: LSlugToIdMapper;
  productSlugMap: LSlugToIdMapper;
}

export const BucketQueries = {
  getPosts: (state: BucketState): LIndexedObjects<PostType> => state.posts,
  getTags: (state: BucketState): LIndexedObjects<TagType> => state.tags,
  getCategories: (state: BucketState): LIndexedObjects<CategoryType> => state.categories,
  getAuthors: (state: BucketState): LIndexedObjects<AuthorType> => state.authors,
  getPages: (state: BucketState): LIndexedObjects<PageType> => state.pages,
  getProducts: (state: BucketState): LIndexedObjects<ProductType> => state.products,

  getPostSlugMap: (state: BucketState): LSlugToIdMapper => state.postSlugMap,
  getTagSlugMap: (state: BucketState): LSlugToIdMapper => state.tagSlugMap,
  getCategorySlugMap: (state: BucketState): LSlugToIdMapper => state.categorySlugMap,
  getAuthorSlugMap: (state: BucketState): LSlugToIdMapper => state.authorSlugMap,
  getPageSlugMap: (state: BucketState): LSlugToIdMapper => state.pageSlugMap,
  getProductSlugMap: (state: BucketState): LSlugToIdMapper => state.productSlugMap,
};

function filterOutUndefined(obj: any): any {
  return Object.keys(obj).reduce((acc: any, it: string) => {
    if (obj[it]) acc[it] = obj[it];
    return acc;
  }, {});
}

export const BucketReducers = {
  storePosts: (state: BucketState, posts: PostType[], language: string): void => {
    posts.forEach((post: PostType) => {
      state.postSlugMap[language] = state.postSlugMap[language] || {};
      state.postSlugMap[language][post.slug] = post.id;
      state.posts[language] = state.posts[language] || {};
      if (state.posts[language][post.id])
        state.posts[language][post.id] = {
          ...state.posts[language][post.id],
          ...filterOutUndefined(post),
          translations:
            state.posts[language][post.id].translations.length > 0
              ? state.posts[language][post.id].translations
              : post.translations,
        };
      else state.posts[language][post.id] = post;
    });
  },

  /// store posts without merging
  storeFullPosts: (state: BucketState, posts: PostType[], language: string): void => {
    posts.forEach((post: PostType) => {
      state.postSlugMap[language] = state.postSlugMap[language] || {};
      state.postSlugMap[language][post.slug] = post.id;
      state.posts[language] = state.posts[language] || {};
      state.posts[language][post.id] = post;
    });
  },

  storeTags: (state: BucketState, tags: TagType[], language: string): void => {
    tags.forEach((tag: TagType) => {
      state.tagSlugMap[language] = state.tagSlugMap[language] || {};
      state.tagSlugMap[language][tag.slug] = tag.id;
      state.tags[language] = state.tags[language] || {};
      if (state.tags[language][tag.id])
        state.tags[language][tag.id] = { ...state.tags[language][tag.id], ...filterOutUndefined(tag) };
      else state.tags[language][tag.id] = tag;
    });
  },

  storeCategories: (state: BucketState, categories: CategoryType[], language: string): void => {
    categories.forEach((category: CategoryType) => {
      state.categorySlugMap[language] = state.categorySlugMap[language] || {};
      state.categorySlugMap[language][category.slug] = state.categorySlugMap[language][category.slug] || category.id;
      state.categories[language] = state.categories[language] || {};
      if (state.categories[language].hasOwnProperty(category.id)) {
        state.categories[language][category.id] = {
          ...state.categories[language][category.id],
          ...filterOutUndefined(category),
        };
      } else state.categories[language][category.id] = category;
    });
  },

  storeCategoryMapping: (state: BucketState, categories: CategoryType[], language: string): void => {
    categories.forEach((category: CategoryType) => {
      // state.categorySlugMultiLanguageSlag[language] = state.categorySlugMultiLanguageSlag[language] || {};
      // state.categorySlugMultiLanguageSlag[language][category.slug] = category
      state.categorySlugMap[language] = state.categorySlugMap[language] || {};
      state.categorySlugMap[language][category.slug] = category.id;
    });
  },

  storeTagMapping: (state: BucketState, tags: TagType[], language: string): void => {
    tags.forEach((tag: TagType) => {
      state.tagSlugMap[language] = state.tagSlugMap[language] || {};
      state.tagSlugMap[language][tag.slug] = tag.id;
    });
  },

  storeAuthorMapping: (state: BucketState, tags: AuthorType[], language: string): void => {
    tags.forEach((author: AuthorType) => {
      state.authorSlugMap[language] = state.authorSlugMap[language] || {};
      state.authorSlugMap[language][author.slug] = author.id;
    });
  },

  storePostMapping: (state: BucketState, tags: PostType[], language: string): void => {
    tags.forEach((post: PostType) => {
      state.postSlugMap[language] = state.postSlugMap[language] || {};
      state.postSlugMap[language][post.slug] = post.id;
    });
  },

  storePageMapping: (state: BucketState, tags: PageType[], language: string): void => {
    tags.forEach((post: PageType) => {
      state.pageSlugMap[language] = state.pageSlugMap[language] || {};
      state.pageSlugMap[language][post.slug] = post.id;
    });
  },

  storeAuthors: (state: BucketState, authors: AuthorType[], language: string): void => {
    authors.forEach((author: AuthorType) => {
      state.authorSlugMap[language] = state.authorSlugMap[language] || {};
      state.authorSlugMap[language][author.slug] = author.id;
      state.authors[language] = state.authors[language] || {};
      if (state.authors[language][author.id])
        state.authors[language][author.id] = {
          ...state.authors[language][author.id],
          ...filterOutUndefined(author),
          socials: { ...state.authors[language][author.id].socials, ...author.socials },
        };
      else state.authors[language][author.id] = author;
    });
  },

  storePages: (state: BucketState, pages: PageType[], language: string): void => {
    pages.forEach((post: PageType) => {
      state.pageSlugMap[language] = state.pageSlugMap[language] || {};
      state.pageSlugMap[language][post.slug] = post.id;
      state.pages[language] = state.pages[language] || {};
      state.pages[language][post.id] = post;
    });
  },

  storeProducts: (state: BucketState, products: ProductType[], language: string): void => {
    products.forEach((product: ProductType) => {
      state.productSlugMap[language] = state.productSlugMap[language] || {};
      state.productSlugMap[language][product.slug] = product.id;
      state.products[language] = state.products[language] || {};
      state.products[language][product.id] = product;
    });
  },

  storeProductMapping: (state: BucketState, tags: ProductType[], language: string): void => {
    tags.forEach((product: ProductType) => {
      state.productSlugMap[language] = state.productSlugMap[language] || {};
      state.productSlugMap[language][product.slug] = product.id;
    });
  },
};
