import React, { useEffect, useRef, useState } from 'react';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import hasValidData from '../../../utils/hasValidData';
import SWLink from '../../fuctional/SWLink';
import { generateCategoryUrl } from '../../router/GenerateUrl';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../cards/HorizontalCardPosts';
import HorizontalCardPostsSkeleton from '../../skeletons/HorizontalCardPostsSkeleton';
import useCategoryBySlugProvider from '../../../core/dataProviders/providers/categories/useCategoryBySlugProvider';
import useFCPostsByCategorySlugProvider from '../../../core/dataProviders/providers/useFCPostsByCategorySlugProvider';
import { IRouteParams } from '../../router/NewRoutes';

export type OpinionsSectionProps = {
  IRouteParams: IRouteParams;
  className?: string;
  currentScroll: number;
};

export default function OpinionsSection({ IRouteParams, className, currentScroll }: OpinionsSectionProps): JSX.Element {
  const t = useTranslateMessage();
  const [slug, setSlug] = useState<string | null>(null);
  const [slugSet, setSlugSet] = useState<boolean>(false);
  const [isInView, setIsInView] = useState<boolean>(false);
  const sectionWrapper = useRef<HTMLDivElement>(null);
  const [opinionsCategory, opinionsCategoryReqState] = useCategoryBySlugProvider(slug, false);
  const [opinionsPosts, opinionsPostsReqState] = useFCPostsByCategorySlugProvider(slug, 1, false);

  useEffect(() => {
    if (
      !slugSet &&
      sectionWrapper.current &&
      sectionWrapper.current.getBoundingClientRect().bottom >= 0 &&
      sectionWrapper.current.getBoundingClientRect().top <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      setIsInView(true);
      setSlugSet(true);
    }
  }, [currentScroll]);

  useEffect(() => {
    isInView && setSlug(t('slug.category.opinion'));
  }, [isInView]);

  return (
    <div className={`OpinionsSection Layout--container ${className ? className : ''}`} ref={sectionWrapper}>
      {hasValidData(opinionsCategory, opinionsCategoryReqState) &&
      hasValidData(opinionsPosts, opinionsPostsReqState) ? (
        <>
          <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
            <h2>{opinionsCategory.name}</h2>
            <div className={'SectionHeader--right'}>
              <SWLink to={generateCategoryUrl(opinionsCategory, IRouteParams.language)}>{t('cta.view-all')}</SWLink>
            </div>
          </SectionHeader>
          <HorizontalCardPosts posts={opinionsPosts.slice(0, 3)} />
        </>
      ) : (
        <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={3} title={true} />
      )}
    </div>
  );
}
