export interface AppState {
  language: string;
}

export const AppQueries = {
  getLanguage: (state: AppState): string => state.language,
};

export const AppReducers = {
  setLanguage: (state: AppState, language: string): void => {
    state.language = language;
  },
};
