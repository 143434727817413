import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeTags } from '../../../../core/bucket/BucketSlice';
import { setTagsBySearchTerm, setTagsBySearchTermPagingInfo } from '../../../../core/tags/TagsSlice';
import TagApi from '../../tag/TagApi';
import { WpTagOrderEnum } from '../../tag/WpTagOptions';
import { WpOrderEnum } from '../../post/WpPostOptions';

export default function ProvideTagsBySearchTerm(searchTerm: string, pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'tags-by-search-term',
      sign: `${searchTerm}|${pageNumber}`,
      language: lang,
    };

    try {
      dispatch(runRequest(requestSign));
      const tagApi = new TagApi(ENV_API_URL);
      await tagApi
        .setPerPage(100)
        .setOrderBy(WpTagOrderEnum.count)
        .setOrder(WpOrderEnum.Desc)
        .setPageNumber(pageNumber)
        .setLanguage(lang)
        .filterBySearchTerm(searchTerm)
        .fetchSearch();
      const tags = tagApi.result || [];
      if (tags.length > 0) {
        dispatch(storeTags({ [lang]: tags }));
        dispatch(
          setTagsBySearchTerm({
            language: lang,
            forObject: searchTerm,
            pageNumber: pageNumber,
            objects: tags,
          }),
        );
        if (tagApi.pagingInfo) dispatch(setTagsBySearchTermPagingInfo({ [lang]: { [searchTerm]: tagApi.pagingInfo } }));
        dispatch(fulfilledRequest(requestSign));
      } else {
        dispatch(emptyResponseRequest(requestSign));
      }
    } catch (ex) {
      dispatch(failedRequest(requestSign));
    }
  };
}
