import React, { useCallback, useContext, useEffect, useState } from 'react';

/// ----------------------------------------------------------------------------

export interface IProduct {
  id: string;
  quantity: number;
}

export interface ICart {
  products: IProduct[];
}

export interface ICartContext {
  addToCart: (productId: string, quantity: number) => void;
  setProductQuantityInCart: (productId: string, quantity: number) => void;
  removeFromCart: (productId: string) => void;
  emptyCart: () => void;
  cart: ICart;
}

/// ----------------------------------------------------------------------------

const CartContext = React.createContext({});

interface ICartContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

function copyCart(cart: ICart): ICart {
  return { ...cart, products: [...cart.products] };
}

function getCart(currentCart: ICart): ICart {
  try {
    if (window && window.localStorage) {
      const cartFromStorage = window.localStorage.getItem('cart');
      if (cartFromStorage === null) return currentCart;

      try {
        const jsonCart = JSON.parse(cartFromStorage);
        if (jsonCart.hasOwnProperty('products')) {
          return jsonCart;
        }
      } catch (ex) {
        /// it is not valid json??
        window.localStorage.removeItem('cart');
      }
    }
  } catch (ex) {
    /// SSR... ignore cart
  }
  return currentCart;
}

function setCartToLocalStorage(cart: ICart): ICart {
  const filteredCart = { ...cart, products: [...cart.products.filter((product: IProduct) => product.quantity > 0)] };
  if (window && window.localStorage) {
    /// Filtered cart is cart without products with quantity: 0
    window.localStorage.setItem('cart', JSON.stringify(filteredCart));
  }
  return filteredCart;
}

function addToCart(cart: ICart, productId: string, quantity: number, exactQuantity: boolean): ICart {
  const newCart = copyCart(cart);
  let found = false;
  for (const product of newCart.products) {
    if (product.id === productId) {
      if (exactQuantity) product.quantity = quantity;
      else product.quantity += quantity;
      found = true;
      break;
    }
  }
  if (!found) {
    newCart.products.push({
      id: productId,
      quantity: quantity,
    });
  }
  return newCart;
}

function removeFromCart(cart: ICart, productId: string): ICart {
  const newCart = copyCart(cart);
  newCart.products = newCart.products.filter((product: IProduct) => product.id !== productId);
  return newCart;
}

function emptyCart(): ICart {
  return { products: [] };
}

export default function CartContextProvider({ children }: ICartContextProviderProps): JSX.Element {
  const [cart, setCart] = useState<ICart>(() => getCart({ products: [] }));
  const setProductQuantityCallback = useCallback(
    (productId: string, quantity: number) => {
      const newCart = getCart(cart);
      const savedCart = setCartToLocalStorage(addToCart(newCart, productId, quantity, true));
      setCart(savedCart);
    },
    [cart],
  );

  const updateCart = useCallback(() => {
    setCart(getCart(cart));
  }, []);

  const removeProductFromCartCallback = useCallback(
    (productId: string) => {
      const newCart = getCart(cart);
      const savedCart = setCartToLocalStorage(removeFromCart(newCart, productId));
      setCart(savedCart);
    },
    [cart],
  );

  const addProductToCartCallback = useCallback(
    (productId: string, quantity: number) => {
      const newCart = getCart(cart);
      const savedCart = setCartToLocalStorage(addToCart(newCart, productId, quantity, false));
      setCart(savedCart);
    },
    [cart],
  );

  const emptyCartCallback = useCallback(() => {
    const savedCart = setCartToLocalStorage(emptyCart());
    setCart(savedCart);
  }, []);

  useEffect(() => {
    if (window && window.localStorage) {
      window.addEventListener('storage', updateCart);
      return () => window.removeEventListener('storage', updateCart);
    }
  });

  return (
    <CartContext.Provider
      value={{
        cart: cart,
        addToCart: addProductToCartCallback,
        removeFromCart: removeProductFromCartCallback,
        setProductQuantityInCart: setProductQuantityCallback,
        emptyCart: emptyCartCallback,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useAddToCart(): (productId: string, quantity: number) => void {
  const cartContext = useContext(CartContext) as ICartContext;
  return cartContext.addToCart;
}

export function useSetProductQuantityInCart(): (productId: string, quantity: number) => void {
  const cartContext = useContext(CartContext) as ICartContext;
  return cartContext.setProductQuantityInCart;
}

export function useRemoveFromCart(): (productId: string) => void {
  const cartContext = useContext(CartContext) as ICartContext;
  return cartContext.removeFromCart;
}

export function useCart(): ICart {
  const cartContext = useContext(CartContext) as ICartContext;
  return cartContext.cart;
}

export function useEmptyCert(): () => void {
  const cartContext = useContext(CartContext) as ICartContext;
  return cartContext.emptyCart;
}
