import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import useLangSelector from '../../../Hooks/useLangSelector';
import ProductType from '../../../../types/ProductType';
import { AllProductsSelector } from '../../../products/ProductSlice';
import useProductsFiller from '../../../Hooks/useProductsFiller';

export default function useAllProductsProvider(includeInSSR = true): [
  /// Categories
  ProductType[],
  /// Request State
  DataRequestStateEnum,
] {
  const [reqState] = useDataProviderState('all-products', '1');
  const orderData = useDataProviderOrder();
  const productFiller = useProductsFiller();
  const allProducts = useLangSelector(useAppSelector(AllProductsSelector));
  const [products, setProducts] = useState<ProductType[]>(() => {
    if (allProducts === undefined) return [];
    return productFiller(allProducts);
  });

  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('all-products', '1');

  useEffect(() => {
    if (allProducts) {
      setProducts(productFiller(allProducts));
    } else {
      setProducts([]);
      if (reqState !== DataRequestStateEnum.RUNNING) {
        orderData('all-products', '1');
      }
    }
  }, [allProducts]);

  return [products, reqState];
}
