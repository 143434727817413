import { RequestsQueries, RequestsReducers, RequestsState } from './RequestsState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';

export const SLICE_NAME = 'Requests';

export const initialState: RequestsState = {
  requests: {},
};

export const RequestsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    startRequest: (state: RequestsState, { payload }: PayloadAction<string>): void =>
      RequestsReducers.startRequest(state, payload),
    fulfilledRequest: (state: RequestsState, { payload }: PayloadAction<string>): void =>
      RequestsReducers.fulfilledRequest(state, payload),
    failedRequest: (state: RequestsState, { payload }: PayloadAction<string>): void =>
      RequestsReducers.failedRequest(state, payload),
  },
});

export const { startRequest, fulfilledRequest, failedRequest } = RequestsSlice.actions;

export const getRequestsState = (state: RootState): RequestsState => state[SLICE_NAME];

export const RequestsSelector = createSelector(getRequestsState, RequestsQueries.getRequests);

export default RequestsSlice.reducer;
