import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storePosts } from '../../../../core/bucket/BucketSlice';
import { setPostsByTag, setPostsByTagPagingInfo } from '../../../../core/posts/PostsSlice';
import FcPostsApi from '../../post/FcPostsApi';
import { TagType } from '../../../../types/PostTypes';

export default function ProvidePostsByTagSlug(tagSlug: string, pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'posts-by-tag-slug',
      sign: `${tagSlug}|${pageNumber}`,
      language: lang,
    } as DataProviderRequestSign;

    try {
      dispatch(runRequest(requestSign));
      const postApi = new FcPostsApi(ENV_API_URL);
      await postApi.setPage(pageNumber).filterByTag(tagSlug).setLanguage(lang).setPerPage(9).fetch();
      const posts = postApi.result || [];
      if (posts.length > 0) {
        /// Find tag ID by tagSlug
        const tags = posts[0].tags.filter((tag: TagType) => tag.slug === tagSlug);
        if (tags.length > 0) {
          dispatch(storePosts({ [lang]: posts }));
          dispatch(
            setPostsByTag({
              language: lang,
              forObject: tags[0].id,
              pageNumber: pageNumber,
              objects: posts,
            }),
          );
        }
        // dispatch(storeCategories({ [lang]: collectObjects<CategoryType>(posts, 'categories') }));
        // dispatch(storeTags({ [lang]: collectObjects<TagType>(posts, 'tags') }));
        // dispatch(storeAuthors({ [lang]: collectObjects<AuthorType>(posts, 'authors') }));
        if (postApi.pagingInfo) dispatch(setPostsByTagPagingInfo({ [lang]: { [tags[0].id]: postApi.pagingInfo } }));
        dispatch(fulfilledRequest(requestSign));
      } else {
        dispatch(emptyResponseRequest(requestSign));
      }
    } catch (ex) {
      dispatch(failedRequest(requestSign));
    }
  };
}
