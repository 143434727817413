import React, { useEffect, useRef, useState } from 'react';
import HeaderLine from '../sections/everywhere/HeaderLine';
import Footer from '../sections/everywhere/Footer';
import useMediaQuery, { mediaQueries } from '../../core/Hooks/useMediaQuery';
import HorizontalMenu from '../sections/everywhere/HorizontalMenu';
import useTheFixMenuBySlugProvider from '../../thefix/useTheFixMenuBySlugProvider';
import HamburgerMenu from '../compound/masthead/HamburgerMenu';
import SlideOutWrapper from '../sections/wrapper/SlideOutWrapper';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../simple/Button';
import SearchForm from '../compound/masthead/SearchForm';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import CookiesPopup from '../compound/cookies/CookiesPopup';
import UkraineFlag from '../../assets/img/ukraine-flag.png';
import CloseIcon from '../../assets/svg/close.svg';
import IconLink, { IconBorderEnum } from '../simple/IconLink';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export type PageContainerProps = {
  children: JSX.Element | JSX.Element[];
};

export default function PageContainer({ children }: PageContainerProps): JSX.Element {
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);
  const [windowWidth, setWindowWidth] = useState(0);
  const [hamburgerShown, setHamburgerShown] = useState(false);
  const [searchShown, setSearchShown] = useState(false);
  const [stickyNavigationShown, setStickyNavigationShown] = useState(false);
  const [attentionShown, setAttentionShown] = useState(false);

  const t = useTranslateMessage();
  let navigationHeight = 0;

  const stickyNavigation = useRef<HTMLDivElement>(null);
  const navigation = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [mainMenuItems] = useTheFixMenuBySlugProvider(t('slug.menu.main-menu'), false);
  const [subMenuItems] = useTheFixMenuBySlugProvider(t('slug.menu.sub-menu'), false);
  const [footerMenuItems] = useTheFixMenuBySlugProvider(t('slug.menu.footer-menu'), false);

  const setWindowSizeOnResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', setWindowSizeOnResize);
    return () => window.removeEventListener('resize', setWindowSizeOnResize);
  }, []);

  useEffect(() => {
    navigationHeight = navigation.current.getBoundingClientRect().height;
    if (window.scrollY < navigationHeight) {
      navigation.current.style.minHeight = `0px`;
      navigationHeight = navigation.current.getBoundingClientRect().height;
    }
    navigation.current.style.minHeight = `${navigationHeight}px`;
  }, [windowWidth]);

  const handleScroll = () => {
    navigationHeight = navigation.current.getBoundingClientRect().height;
    const navigationMinHeight = window.scrollY < navigationHeight ? 0 : navigationHeight;
    navigation.current.style.minHeight = `${navigationMinHeight}px`;
    setStickyNavigationShown(window.scrollY > navigationHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    sessionStorage.getItem('attentionShown') === null && setAttentionShown(true);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleAttentionShown = () => {
    sessionStorage.setItem('attentionShown', 'false');
    setAttentionShown(false);
  };

  useEffect(() => {
    if (stickyNavigation.current) {
      stickyNavigationShown && stickyNavigation.current.classList.remove('HorizontalMenu--Sticky-hidden');
    }
  }, [stickyNavigationShown]);

  const toggleHamburger = () => {
    setHamburgerShown((previousState: boolean) => {
      return !previousState;
    });
    if (matchesMediumUp) {
      setSearchShown(false);
    }
  };

  const toggleSearch = () => {
    setSearchShown((previousState: boolean) => {
      return !previousState;
    });
    if (matchesMediumUp) {
      setHamburgerShown(false);
    }
  };

  useEffect(() => {
    searchShown && document.getElementById('search-input')?.focus();
  }, [searchShown]);

  return (
    <div className={'PageContainer'}>
      {attentionShown && (
        <div className={'PageContainer-attention py-3'}>
          <div className={'Layout--container'}>
            <p
              style={
                matchesMediumUp
                  ? { margin: '0 auto', transform: 'translateX(20px)' }
                  : { marginRight: '5px', textAlign: 'center' }
              }
            >
              Subscribe to our{' '}
              <a href={'https://www.linkedin.com/company/the-fix-media'} target={'_blank'} rel="noreferrer">
                LinkedIn
              </a>{' '}
              so you don't miss important media news and analysis
            </p>

            <IconLink
              component={'button'}
              className={ButtonTypeEnum.GHOST}
              border={IconBorderEnum.WHITE}
              onClick={handleAttentionShown}
              aria-label={'Close announcement'}
            >
              <CloseIcon />
            </IconLink>
          </div>
        </div>
      )}
      <nav ref={navigation}>
        {matchesMediumUp && (
          <div className={'Layout--container'}>
            <HeaderLine className={'py-6'} hamburgerHandler={toggleHamburger} hamburgerShown={hamburgerShown} />
          </div>
        )}

        <div
          className={`HorizontalMenu--Wrapper ${
            stickyNavigationShown ? 'HorizontalMenu--Sticky HorizontalMenu--Sticky-hidden' : ''
          }`}
          ref={stickyNavigation}
        >
          <div className={'Layout--container py-0'} id={'header-line'}>
            {!matchesMediumUp && (
              <HeaderLine
                className={`${stickyNavigationShown && matchesMediumUp ? 'pt-6 pb-29' : 'py-6'}`}
                hamburgerHandler={toggleHamburger}
                hamburgerShown={hamburgerShown}
              />
            )}
          </div>

          {matchesMediumUp && (
            <div className={'Layout--container'}>
              {!stickyNavigationShown && <div className={'Divider Divider--dark mb-6'} />}
              <HorizontalMenu
                menuItems={mainMenuItems}
                hamburgerHandler={toggleHamburger}
                hamburgerShown={hamburgerShown}
                searchHandler={toggleSearch}
                stickyNavigationShown={stickyNavigationShown}
                className={`${stickyNavigationShown ? 'py-3' : 'pb-5'}`}
              />
            </div>
          )}

          {hamburgerShown && (
            <SlideOutWrapper
              toggleSlideOut={toggleHamburger}
              windowWidth={windowWidth}
              mediaQuery={mediaQueries.md}
              className={`${!matchesMediumUp ? 'HamburgerMenu--SlideOut' : ''}`}
            >
              <div className={'Layout--container pt-6 pt-md-0'}>
                <HamburgerMenu
                  mainMenuItems={mainMenuItems}
                  subMenuItems={subMenuItems}
                  hamburgerShown={hamburgerShown}
                  hamburgerHandler={toggleHamburger}
                  className={'py-0 py-md-9'}
                />
              </div>
            </SlideOutWrapper>
          )}

          {searchShown && matchesMediumUp && (
            <SlideOutWrapper
              toggleSlideOut={toggleSearch}
              mediaQuery={mediaQueries.md}
              className={'py-8'}
              windowWidth={windowWidth}
            >
              <div className={'Layout--container'}>
                <SearchForm toggleSearch={toggleSearch}>
                  <Button btnType={ButtonTypeEnum.PRIMARY} width={ButtonWidthEnum.DEF} height={ButtonHeightEnum.DEF}>
                    {t('search.label')}
                  </Button>
                </SearchForm>
              </div>
            </SlideOutWrapper>
          )}
        </div>

        {!matchesMediumUp && (
          <div className={'Help-Ukraine p-5'}>
            <a
              href={'https://helpukraine.thefix.media/'}
              target={'_blank'}
              rel={'noreferrer'}
              className={'Help-Ukraine-link'}
            >
              <img src={UkraineFlag} alt={'Ukraine flag'} />
              &nbsp; Help Ukraine
            </a>
          </div>
        )}
      </nav>

      {hamburgerShown && !matchesMediumUp && <div className={'Backdrop Backdrop-secondary'} />}
      {children}

      <CookiesPopup />
      <Footer menuItems={footerMenuItems} />
    </div>
  );
}
