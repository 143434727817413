import { AuthorsState } from './AuthorsState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LObjectsByPage } from '../../types/PayloadActionTypes';
import { AuthorType, PagingInfoType } from '../../types/PostTypes';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';
import { AuthorsQueries, AuthorsReducers } from './AuthorsState';
import { LanguageWrapper } from '../../types/StoreTypes';

export const SLICE_NAME = 'Authors';

const initialState: AuthorsState = {
  latestAuthors: {},
  latestAuthorsPaging: {
    en: {
      total: 0,
      pages: 0,
    },
  },
};

export const AuthorsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLatestAuthors: (state: AuthorsState, { payload }: PayloadAction<LObjectsByPage<AuthorType>>): void => {
      AuthorsReducers.setLatest(state, payload.pageNumber, payload.objects, payload.language);
    },
    setLatestAuthorsPagingInfo: (
      state: AuthorsState,
      { payload }: PayloadAction<LanguageWrapper<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) => AuthorsReducers.setLatestPagingInfo(state, payload[lang], lang));
    },
  },
});

export const getAuthorsState = (state: RootState): AuthorsState => state[SLICE_NAME];

export const { setLatestAuthors, setLatestAuthorsPagingInfo } = AuthorsSlice.actions;

export const LatestAuthorsSelector = createSelector(getAuthorsState, AuthorsQueries.getLatestAuthors);
export const LatestAuthorsAvailablePagesSelector = createSelector(
  getAuthorsState,
  AuthorsQueries.getAvailablePagesForLatest,
);
export const LatestAuthorsPagingInfoSelector = createSelector(getAuthorsState, AuthorsQueries.getLatestPagingInfo);

export default AuthorsSlice.reducer;
