import React from 'react';
import { TagType } from '../../types/PostTypes';
import TagBadge, { TagTypeEnum } from '../simple/TagBadge';
import BadgeSkeleton from '../skeletons/BadgeSkeleton';
import { DataRequestStateEnum } from '../../core/dataProviders/DataProviderState';

export type TagListProps = {
  tagListItems: TagType[];
  tagListReqState: DataRequestStateEnum;
  tagListHandler?: () => void;
  type: TagTypeEnum;
  onClick?: () => void;
  className?: string;
  inline?: boolean;
};

export default function TagList({
  tagListItems,
  tagListReqState,
  tagListHandler,
  type,
  onClick,
  className,
  inline,
}: TagListProps): JSX.Element {
  return (
    <div className={`TagList ${className ? className : ''}`}>
      {tagListHandler && <div className={'Overlay Overlay--OneThirdBottom-White'} onClick={tagListHandler} />}
      <div className={'TagList-tags'}>
        {tagListItems.length > 0 &&
          tagListItems.map((tag: TagType) => (
            <p key={tag.id} className={`${inline ? 'display-inline' : ''}`}>
              <TagBadge tag={tag} type={type} className={'mb-2 mr-2'} onClick={onClick ? onClick : undefined} />
            </p>
          ))}
      </div>
      {tagListReqState === DataRequestStateEnum.RUNNING && (
        <div className={'TagList-tags'}>
          <BadgeSkeleton placeholders={48} className={'mb-2 mr-2'} inline={inline} />
        </div>
      )}
    </div>
  );
}
