import React from 'react';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import CapitalCta, { CapitalCtaColorEnum } from '../../simple/CapitalCta';
import ProductForm from '../../compound/product/ProductForm';
import ProductType from '../../../types/ProductType';
import { useHistory } from 'react-router-dom';
import { useSetProductQuantityInCart } from '../../../core/Cart/LocalStorage/CartContextProvider';
import InnerHTML from 'dangerously-set-html-content';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export type ProductPageBannerProps = {
  product: ProductType;
  className?: string;
};

export default function ProductPageBanner({ className, product }: ProductPageBannerProps): JSX.Element {
  const t = useTranslateMessage();
  return (
    <div className={`ProductPageBanner ${className ? className : ''}`}>
      <div className={'Layout--container'}>
        <div className={'ProductPageBanner--Wrapper Layout--TwoColumn-Grid'}>
          <div className={'ProductPageBanner--Headlines'}>
            <p className={'ProductPageBanner--Caption pb-5'}>Research</p>
            <h1 className={'ProductPageBanner--Title pb-6'}>{product.name}</h1>
            <div className={'ProductPageBanner--Description pb-6'}>
              {product.slug === 'storybook' ? (
                <div dangerouslySetInnerHTML={{ __html: product.excerpt }} />
              ) : (
                <>
                  {ENV_SSR ? (
                    <div dangerouslySetInnerHTML={{ __html: product.excerpt }} />
                  ) : (
                    <InnerHTML html={product.excerpt} />
                  )}
                </>
              )}
            </div>
            <div className={'ProductPageBanner--Price py-6 mb-7'}>
              {/*<div className={'ProductPageBanner--Price-Members pr-7 mr-7'}>
                <p className={'ProductPageBanner--Price-Caption'}>Price for members:</p>
                <p className={'ProductPageBanner--Price-ValueDiscount'}>
                  €200.00
                  <Button
                    btnType={ButtonTypeEnum.PRIMARY}
                    width={ButtonWidthEnum.DEF}
                    height={ButtonHeightEnum.THIN}
                    component={'span'}
                    className={'ml-2'}
                  >
                    Save 30%
                  </Button>
                </p>
              </div>*/}
              <div className={'ProductPageBanner--Price-Regular pr-0 pr-md-15'}>
                <p className={'ProductPageBanner--Price-Caption'}>{t('text.price')}:</p>
                <p className={'ProductPageBanner--Price-Value'}>
                  {product.price > 0 ? `${product.price}€` : t('text.free')}
                </p>
              </div>
            </div>
          </div>
          <ProductForm product={product} />
        </div>
      </div>
    </div>
  );
}
