import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  failedRequestGroup,
  fulfilledRequest,
  runRequestGroup,
  emptyResponseRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import FcMenuApi from '../../menu/FcMenuApi';
import { setMenuByGroup } from '../../../../core/menu/MenuSlice';
import { LMenuItemsByGroup } from '../../../../core/menu/MenuState';

export default function ProvideMenuBySlugs(slugs: string[], lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestsSign = slugs.map((slug: string) => ({
      type: 'menu-by-slug',
      sign: slug,
      language: lang,
    }));

    try {
      dispatch(runRequestGroup(requestsSign));
      const menuApi = new FcMenuApi(ENV_API_URL);
      menuApi.menuSlugs(slugs).setLanguage(lang);
      const menuItems = await menuApi.fetch();

      slugs.forEach((slug: string) => {
        menuItems[slug] = menuItems[slug] || [];
      });

      const menuGroups = {
        [lang]: {
          ...menuItems,
        },
      } as LMenuItemsByGroup;

      dispatch(setMenuByGroup(menuGroups));

      slugs.forEach((slug: string) => {
        if (menuItems[slug] && menuItems[slug].length > 0) {
          dispatch(
            fulfilledRequest({
              type: 'menu-by-slug',
              sign: slug,
              language: lang,
            }),
          );
        } else {
          console.log('empty!!!!!');
          dispatch(
            emptyResponseRequest({
              type: 'menu-by-slug',
              sign: slug,
              language: lang,
            }),
          );
        }
      });
    } catch (ex) {
      dispatch(failedRequestGroup(requestsSign));
    }
  };
}
