import { PostType } from '../../../../types/PostTypes';

/**
 * function collects all objects from propertyName with type <T> from the posts
 * it results in array of unique objects
 *
 * @param posts
 * @param propertyName
 */
export default function collectObjects<T>(posts: PostType[], propertyName: string): T[] {
  const alreadyCollected: { [id: number]: boolean } = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return posts.reduce((acc: T[], post: PostType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (post[propertyName]) {
      /// if there is available data in property 'propertyName'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      post[propertyName]
        /// collect only once
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter((obj: { id: number }) => !alreadyCollected.hasOwnProperty(obj.id))
        /// for each object, add it to acc, and set it to alreadyCollected object
        .forEach((obj: T) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alreadyCollected[obj.id] = true;
          acc.push(obj);
        });
    }
    return acc;
  }, []);
}
