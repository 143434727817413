import React from 'react';
import { PostType } from '../../../types/PostTypes';
import MiniNumberedPost from '../../compound/post/lists/MiniNumberedPost';
import SectionHeader, { SectionHeaderBorderStyle } from '../../simple/SectionHeader';
import MiniPostWrapper from '../wrapper/MiniPostWrapper';

export type NumberedVerticalListPostsProps = {
  title?: string;
  posts: PostType[];
  className?: string;
};

export default function NumberedVerticalListPosts({
  title,
  posts,
  className,
}: NumberedVerticalListPostsProps): JSX.Element {
  return (
    <div className={`NumberedVerticalListPosts ${className ? className : ''}`}>
      {title && (
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>{title}</h2>
        </SectionHeader>
      )}
      {posts.map((post: PostType, i: number) => (
        <MiniPostWrapper key={post.id} className={'NumberedVerticalListPosts--Items py-4'}>
          <span className={'NumberedVerticalListPosts--Items-Number'}>{i + 1}</span>
          <MiniNumberedPost post={post} className={'ml-9'} />
        </MiniPostWrapper>
      ))}
    </div>
  );
}
