import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  emptyResponseRequest,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import { storeAuthors, storeCategories, storePosts } from '../../../../core/bucket/BucketSlice';
import { setPostsByAuthor, setPostsByAuthorPagingInfo } from '../../../../core/posts/PostsSlice';
import FcPostsApi from '../../post/FcPostsApi';
import { AuthorType, CategoryType } from '../../../../types/PostTypes';
import collectObjects from './CollectObjects';

export default function ProvidePostsByAuthorSlug(authorSlug: string, pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'posts-by-author-slug',
      sign: `${authorSlug}|${pageNumber}`,
      language: lang,
    } as DataProviderRequestSign;

    try {
      dispatch(runRequest(requestSign));
      const postApi = new FcPostsApi(ENV_API_URL);
      await postApi.setPage(pageNumber).filterByAuthor(authorSlug).setLanguage(lang).setPerPage(9).fetch();
      const posts = postApi.result || [];
      if (posts.length > 0) {
        /// Find author ID by authorSlug
        const authors = posts[0].authors.filter((author: AuthorType) => author.slug === authorSlug);
        if (authors.length > 0) {
          dispatch(storePosts({ [lang]: posts }));
          dispatch(
            setPostsByAuthor({
              language: lang,
              forObject: authors[0].id,
              pageNumber: pageNumber,
              objects: posts,
            }),
          );
          dispatch(storeCategories({ [lang]: collectObjects<CategoryType>(posts, 'categories') }));
          dispatch(storeAuthors({ [lang]: collectObjects<AuthorType>(posts, 'authors') }));
          dispatch(storeAuthors({ [lang]: collectObjects<AuthorType>(posts, 'authors') }));
          if (postApi.pagingInfo)
            dispatch(setPostsByAuthorPagingInfo({ [lang]: { [authors[0].id]: postApi.pagingInfo } }));
          dispatch(fulfilledRequest(requestSign));
        } else {
          dispatch(emptyResponseRequest(requestSign));
        }
      } else {
        dispatch(emptyResponseRequest(requestSign));
      }
    } catch (ex) {
      dispatch(failedRequest(requestSign));
    }
  };
}
