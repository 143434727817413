import { AppDispatch, AppThunk } from '../../../../redux/store';
import {
  DataProviderRequestSign,
  failedRequest,
  fulfilledRequest,
  runRequest,
} from '../../../../core/dataProviders/DataProviderSlice';
import PostsApi from '../../post/PostsApi';
import { storePosts } from '../../../../core/bucket/BucketSlice';
import { setPostsByCategory } from '../../../../core/posts/PostsSlice';

export default function ProvidePostsByCategoryId(categoryId: number, pageNumber: number, lang: string): AppThunk {
  return async (dispatch: AppDispatch): Promise<void> => {
    const requestSign = {
      type: 'posts-by-category-id',
      sign: `${categoryId}|${pageNumber}`,
      language: lang,
    } as DataProviderRequestSign;

    try {
      dispatch(runRequest(requestSign));
      const postApi = new PostsApi(ENV_API_URL);
      postApi.setPage(pageNumber);
      postApi.filterByCategory(categoryId);
      const posts = await postApi.fetch();

      dispatch(storePosts({ [lang]: posts }));
      dispatch(
        setPostsByCategory({
          language: lang,
          forObject: categoryId,
          pageNumber: pageNumber,
          objects: posts,
        }),
      );
      dispatch(fulfilledRequest(requestSign));
    } catch (ex) {
      dispatch(failedRequest(requestSign));
    }
  };
}
