import React from 'react';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import MailchimpFormWrapper from '../../compound/newsletter/MailchimpFormWrapper';

export default function ContentSubscribeForm({ className }: { className?: string }): JSX.Element {
  const t = useTranslateMessage();
  return (
    <div className={`ContentSubscribeForm ContentSubscribeForm--light ${className ? className : ''}`}>
      <div className={'ContentSubscribeForm--content-center py-6 px-2 px-md-0 pt-md-10 pb-md-4'}>
        <h3 className={'ContentSubscribeForm--caption mb-5'}>The Fix Newsletter</h3>
        <p className={'ContentSubscribeForm--hook mb-4'}>
          {t('newsletter.title-normal')} <span>{t('newsletter.title-highlight')}</span>
        </p>
        <MailchimpFormWrapper />
      </div>
    </div>
  );
}
