import React, { useEffect, useRef, useState } from 'react';
import ChooseNewsletterOption from './ChooseNewsletterOption';
import Input, { InputTypeEnum } from '../../simple/Input';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import Checkbox, { CheckboxBoxColorEnum, CheckboxCheckColorEnum, CheckboxTextColorEnum } from '../../simple/Checkbox';

export default function ChooseNewsletterForm({ className }: { className?: string }): JSX.Element {
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);
  const newsletterSelector = useRef<HTMLSelectElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>('editorial');
  const [emailInputValue, setEmailInputValue] = useState<string>('');
  const [inputCheckboxValue, setInputCheckboxValue] = useState<boolean>(false);
  const selectHandler = (e: HTMLDivElement) => {
    setSelectedValue(e.id);
  };

  const selectedHandler = () => {
    document
      .getElementsByClassName('ChooseNewsletterOption--Selected')[0]
      ?.classList.remove('ChooseNewsletterOption--Selected');
    document.getElementById(selectedValue)?.classList.add('ChooseNewsletterOption--Selected');
  };

  const handleEmailValue = (event: React.FormEvent<HTMLInputElement>) => {
    setEmailInputValue(event.currentTarget.value);
  };

  useEffect(() => {
    selectedHandler();
  }, [selectedValue]);

  return (
    <form className={`ChooseNewsletterForm ${className ? className : ''}`}>
      <h2 className={'ChooseNewsletterForm--Title pb-1 pb-md-12'}>Choose your newsletters</h2>

      <select
        name="newsletters"
        id="newsletters"
        className={'display-none'}
        ref={newsletterSelector}
        value={selectedValue}
        onChange={selectedHandler}
      >
        <option value="editorial">Editorial Newsletter</option>
        <option value="small">Small Newsletter</option>
        <option value="medium">Medium info news</option>
        <option value="pro">The Fir Pro Newsletter</option>
      </select>

      <div className={'Layout--TwoColumn-Grid'}>
        <ChooseNewsletterOption
          id={'editorial'}
          title={'Editorial Newsletter'}
          subtitle={'Weekday evenings'}
          price={'Free'}
          description={
            'Our editors guide you through the biggest stories of the day, help you discover new ideas, and surprise you with moments of delight.'
          }
          onClick={selectHandler}
        />
        <ChooseNewsletterOption
          id={'small'}
          title={'Small newsletter'}
          subtitle={'Sunday evenings'}
          price={'€49 / month'}
          description={
            'Our editors guide you through the biggest stories of the day, help you discover new ideas, and surprise you with moments of delight.'
          }
          onClick={selectHandler}
        />
        <ChooseNewsletterOption
          id={'medium'}
          title={'Medium info news'}
          subtitle={'Sunday evenings'}
          price={'€100 / month'}
          description={
            'Our climate reporter, Robinson Meyer, brings you the biggest ideas and most vital information to help you.'
          }
          onClick={selectHandler}
        />
        <ChooseNewsletterOption
          id={'pro'}
          title={'The Fix pro newsletter'}
          subtitle={'Sunday evenings'}
          price={'€130 / month'}
          description={'News stories told in large-format images by some of the best photojournalists in the world.'}
          onClick={selectHandler}
        />
      </div>

      <div className={'Divider Divider--dashed Divider--dark mt-8 mt-md-16 mb-5 mb-md-10'} />
      <h2 className={'ChooseNewsletterForm--Title pb-5 pb-md-12'}>Now add your e-mail</h2>

      <div className={'ChooseNewsletterForm--Input'}>
        <div className={'mb-6'}>
          <Input
            type={InputTypeEnum.TEXT}
            value={emailInputValue}
            onChange={handleEmailValue}
            placeholder={'yourname@example.com'}
            className={'mb-5 mb-md-0'}
          />
          <Button
            btnType={ButtonTypeEnum.PRIMARY}
            onClick={() => null}
            width={matchesMediumUp ? ButtonWidthEnum.DEF : ButtonWidthEnum.FULLWIDTH}
            height={ButtonHeightEnum.DEF}
          >
            Subscribe newsletter
          </Button>
        </div>
      </div>
      <div className={'ChooseNewsletterForm--Checkbox'}>
        <Checkbox
          id={'terms_conditions'}
          checked={inputCheckboxValue}
          onChange={setInputCheckboxValue}
          textColor={CheckboxTextColorEnum.SECONDARY}
          checkColor={CheckboxCheckColorEnum.SECONDARY}
          boxColor={CheckboxBoxColorEnum.LIGHT}
        >
          I agree with{' '}
          <a href={'%'} title={'agreement'}>
            terms & conditions
          </a>
        </Checkbox>
      </div>
    </form>
  );
}
