import { CategoriesQueries, CategoriesReducers, CategoriesState } from './CategoriesState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LObjectsByPage } from '../../types/PayloadActionTypes';
import { CategoryType, PagingInfoType } from '../../types/PostTypes';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';
import { LanguageWrapper } from '../../types/StoreTypes';

export const SLICE_NAME = 'Categories';

const initialState: CategoriesState = {
  latestCategories: {},
  latestCategoriesPaging: {
    en: {
      total: 0,
      pages: 0,
    },
  },
};

export const CategoriesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLatestCategories: (state: CategoriesState, { payload }: PayloadAction<LObjectsByPage<CategoryType>>): void => {
      CategoriesReducers.setLatest(state, payload.pageNumber, payload.objects, payload.language);
    },
    setLatestCategoriesPagingInfo: (
      state: CategoriesState,
      { payload }: PayloadAction<LanguageWrapper<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) =>
        CategoriesReducers.setLatestPagingInfo(state, payload[lang], lang),
      );
    },
  },
});

export const getCategoriesState = (state: RootState): CategoriesState => state[SLICE_NAME];

export const { setLatestCategories, setLatestCategoriesPagingInfo } = CategoriesSlice.actions;

export const LatestCategoriesSelector = createSelector(getCategoriesState, CategoriesQueries.getLatestCategories);
export const LatestCategoriesAvailablePagesSelector = createSelector(
  getCategoriesState,
  CategoriesQueries.getAvailablePagesForLatest,
);
export const LatestCategoriesPagingInfoSelector = createSelector(
  getCategoriesState,
  CategoriesQueries.getLatestPagingInfo,
);

export default CategoriesSlice.reducer;
