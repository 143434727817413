import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function BadgeSkeleton({
  className,
  placeholders,
  inline,
}: {
  className?: string;
  placeholders: number;
  inline?: boolean;
}): JSX.Element {
  return (
    <SkeletonTheme inline={true}>
      {new Array(placeholders).fill(0).map((_, index) => (
        <p key={index} className={`${inline ? 'display-inline' : ''}`}>
          <Skeleton className={`CategoryBadge ${className ? className : ''}`} width={100} style={{ border: 'none' }} />
        </p>
      ))}
    </SkeletonTheme>
  );
}
