import { useEffect, useState } from 'react';
import { PostType } from '../../../types/PostTypes';
import { DataRequestStateEnum } from '../DataProviderState';
import useDataProviderState from '../useDataProviderState';
import { useAppSelector } from '../../../redux/hooks';
import { TagSlugMapSelector } from '../../bucket/BucketSlice';
import { PostsByTagSelector } from '../../posts/PostsSlice';
import useDataProviderOrder from '../useDataProviderOrder';
import usePostsFiller from '../../Hooks/usePostsFiller';
import useDataProviderSSROrder from '../useDataProviderSSROrder';
import useLangSelector from '../../Hooks/useLangSelector';
import { IndexedObjects, PagedObjects, SlugToIdMapper } from '../../../types/StoreTypes';

function getTagIdBySlug(slug: string | null, tagSlugToId: SlugToIdMapper | undefined): number | null {
  if (slug && tagSlugToId) {
    if (tagSlugToId[slug]) return tagSlugToId[slug];
  }
  return null;
}

function getPostsByTagId(
  tagId: number | null,
  pageNumber: number,
  postsByTag: IndexedObjects<PagedObjects<number[]>> | undefined,
): number[] | null {
  if (tagId && postsByTag) {
    if (postsByTag[tagId] && postsByTag[tagId][pageNumber]) {
      return postsByTag[tagId][pageNumber];
    }
  }
  return null;
}

export default function useFCPostsByTagSlugProvider(
  slug: string | null,
  pageNumber: number,
  includeInSSR = true,
): [
  /// Posts by tag
  PostType[],
  /// Posts Request status,
  DataRequestStateEnum,
] {
  const orderData = useDataProviderOrder();
  const postFiller = usePostsFiller();

  /// Selectors ----------------------------------------------------------------
  const tagSlugToId = useLangSelector(useAppSelector(TagSlugMapSelector));
  const postsByTag = useLangSelector(useAppSelector(PostsByTagSelector));

  /// Request info  ------------------------------------------------------------
  const createRequestSign = (slug: string, pageNumber: number) => `${slug}|${pageNumber}`;
  const [reqState, , setReqSign] = useDataProviderState(
    'posts-by-tag-slug',
    slug ? createRequestSign(slug, pageNumber) : null,
  );

  /// first run setup ----------------------------------------------------------
  const [currentSlug, setCurrentSlug] = useState<string | null>(() => slug);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(() => pageNumber);
  const [currentTagId, setCurrentTagId] = useState<number | null>(() => getTagIdBySlug(slug, tagSlugToId));
  const [currentPosts, setCurrentPosts] = useState<PostType[] | null>(() => {
    const posts = getPostsByTagId(currentTagId, pageNumber, postsByTag);
    if (posts) return postFiller(posts);
    return null;
  });

  /// useEffect on input params ------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug || pageNumber !== currentPageNumber) {
      setCurrentSlug(slug);
      setCurrentPageNumber(pageNumber);
      if (slug === null) {
        setCurrentTagId(null);
        setCurrentPosts(null);
        setReqSign(null);
        return;
      }

      const newTagId = getTagIdBySlug(slug, tagSlugToId);
      const newPosts = getPostsByTagId(newTagId, pageNumber, postsByTag);

      if (newPosts) setCurrentPosts(postFiller(newPosts));
      else setCurrentPosts(null);
      setCurrentTagId(newTagId);
      setReqSign(createRequestSign(slug, pageNumber));
    }
  }, [slug, pageNumber]);

  /// useEffect on Selectors  --------------------------------------------------
  useEffect(() => {
    if (currentTagId === null) setCurrentTagId(getTagIdBySlug(currentSlug, tagSlugToId));
    if (currentPosts === null) {
      const newPosts = getPostsByTagId(currentTagId, pageNumber, postsByTag);
      if (newPosts) setCurrentPosts(postFiller(newPosts));
    }
  }, [tagSlugToId, postsByTag]);

  /// useEffect on reqState ----------------------------------------------------
  useEffect(() => {
    if (currentSlug && currentPosts === null) {
      if (reqState !== DataRequestStateEnum.PENDING && reqState !== DataRequestStateEnum.EMPTY_RESPONSE)
        orderData('posts-by-tag-slug', createRequestSign(currentSlug, currentPageNumber));
    }
  }, [reqState]);

  /// SSR ----------------------------------------------------------------------
  ENV_SSR &&
    includeInSSR &&
    useDataProviderSSROrder('posts-by-tag-slug', slug ? createRequestSign(slug, currentPageNumber) : null);

  /// Result  ------------------------------------------------------------------
  return [currentPosts || [], reqState];
}
