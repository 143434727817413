import routeList, { RoutePathInLanguage } from './NewRouteList';
import { AuthorType, CategoryType, PageType, PostType, TagType } from '../../types/PostTypes';
import getRoute from './getRoute';
import { generatePath } from 'react-router-dom';
import { matchUrl } from './matchUrl';
import NewRouteList from './NewRouteList';
import ProductType from '../../types/ProductType';

export function generateHomepage(language: string): string {
  return generatePath(routeList.HomepagePath.path[language], {
    lng: language,
  });
}

export function generateCategoryUrl(category: CategoryType, language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('CATEGORY');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      slug: category.slug,
    });
  }
  return '#';
}

export function generateTagUrl(tag: TagType, language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('TAG');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      slug: tag.slug,
    });
  }
  return '#';
}

export function generateAuthorUrl(author: AuthorType, language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('AUTHOR');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      slug: author.slug,
    });
  }
  return '#';
}

export function generatePostUrl(post: PostType, language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('POST');
  const dateObject = new Date(post.date);
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      slug: post.slug,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth() + 1,
      day: dateObject.getDate(),
    });
  }
  return '#';
}

export function generatePageUrl(page: PageType, language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('PAGE');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      slug: page.slug,
    });
  }
  return '#';
}

export function generateSearchUrl(searchTerm: string, language?: string, pageNumber?: number): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('SEARCH');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      searchTerm: searchTerm,
      pageNumber: pageNumber,
    });
  }
  return '#';
}

export function generate404Url(language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('ERROR');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
    });
  }
  return '#';
}

export function switchURLLanguage(url: string, toLanguage: string): string {
  const [route] = matchUrl(url);
  const newUrl = RoutePathInLanguage(toLanguage, route, routeList);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return generatePath(newUrl, { ...route.params, lng: toLanguage });
}

export function generateProductUrl(product: ProductType, language?: string): string {
  const lng = language || NewRouteList.DefaultLanguage;
  const route = getRoute('PRODUCT');
  if (route) {
    const url = RoutePathInLanguage(lng, route, routeList);
    return generatePath(url, {
      lng: lng,
      slug: product.slug,
    });
  }
  return '#';
}
