import React from 'react';
import { PostType } from '../../../../types/PostTypes';
import { CategoryBadge, CategoryTypeEnum } from '../../../simple/CategoryBadge';
import { PostDateAndAuthor } from '../PostDateAndAuthor';
import EstimatedReadTime from '../../../simple/EstimatedReadTime';
import { postUrl } from '../../../router/routeList';
import SWLink from '../../../fuctional/SWLink';
import { useFilterCategory } from '../../../hooks/useFilterCategory';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';
import ReactHtmlParser from 'react-html-parser';
import Image from '../../../simple/Image';

export interface CardPostProps {
  post: PostType;
  className?: string;
  style?: React.CSSProperties;
}

export default function CardPost({ post, className, style }: CardPostProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = postUrl(post, language.code);
  const t = useTranslateMessage();

  const partnerCategory = useFilterCategory(post, t('slug.category.partner'), CategoryTypeEnum.SPECIAL_ONE);
  const opinionCategory = useFilterCategory(post, t('slug.category.opinion'), CategoryTypeEnum.TERTIARY);
  const filteredCategory = partnerCategory || opinionCategory;

  return (
    <div className={`CardPost ${className ? className : ''}`} style={style}>
      <figure className={'CardPost--figure'}>
        {filteredCategory && (
          <CategoryBadge
            type={filteredCategory?.CategoryTypeEnum}
            category={filteredCategory.type}
            className={'mb-3'}
          />
        )}
        <SWLink to={url} title={post.title} className={'CardPost--figure-ImgWrapper mb-5'}>
          <Image itemWithImages={post} className={'CardPost--figure-Img'} />
        </SWLink>
        <figcaption>
          <PostDateAndAuthor post={post} showAuthorAvatar={false} />
          <h3 className={'CardPost--figcaption-Title'}>
            <SWLink to={url} title={post.title} className={'CardPost--figcaption-TitleLink'}>
              {ReactHtmlParser(post.title)}
            </SWLink>
          </h3>
        </figcaption>
      </figure>
      <div className={'CardPost--action pb-4'}>
        <span>&nbsp;</span>
        <EstimatedReadTime post={post} />
      </div>
    </div>
  );
}
