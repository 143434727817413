import React, { useEffect } from 'react';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../simple/Button';
import SWLink from '../fuctional/SWLink';
import { Helmet } from 'react-helmet';
import { useCurrentLanguage } from '../../core/i18/LanguageContextProvider';
import { homepageUrl } from '../router/routeList';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';
import { useLocation } from 'react-router-dom';

export default function ErrorPage(): JSX.Element {
  const location = useLocation();
  const language = useCurrentLanguage();
  const homepage = homepageUrl(language.code);
  useEffect(() => {
    trackPageViewGTMEvent('404', ['404'], ['theFix']);
  }, []);
  return (
    <>
      <Helmet>
        <title>404 - TheFix Media</title>
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href={`https://thefix.media${location.pathname}`} />
      </Helmet>
      <div className={'ErrorPage mb-10'}>
        <div className={'Layout--container py-20 py-md-40'}>
          <div className={'ErrorPage--wrapper py-md-10'}>
            <p className={'ErrorPage--Caption pb-3 pb-md-6'}>Something wrong</p>
            <h1 className={'ErrorPage--Title pb-3 pb-md-6'}>Page not found. Let’s fix it.</h1>
            <p className={'ErrorPage--Description pb-4 pb-md-8'}>
              It either was moved, no longer exists or the address is not right.
            </p>
            <div className={'Divider Divider--light Divider--dashed mx-10 mb-5 mb-md-10'} />
            <Button
              btnType={ButtonTypeEnum.PRIMARY}
              width={ButtonWidthEnum.WIDE}
              height={ButtonHeightEnum.THICK}
              component={SWLink}
              to={homepage}
            >
              Back to homepage
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
