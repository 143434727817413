import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import SectionHeader, { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import ReactPaginate from 'react-paginate';
import { ButtonTypeEnum } from '../simple/Button';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import HorizontalCardPostsSkeleton from '../skeletons/HorizontalCardPostsSkeleton';
import Skeleton from 'react-loading-skeleton';
import useTagBySlugProvider from '../../core/dataProviders/providers/tags/useTagBySlugProvider';
import useFCPostsByTagSlugProvider from '../../core/dataProviders/providers/useFCPostsByTagSlugProvider';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import { usePostsByTagPagingInfo } from '../../core/dataProviders/providers/posts/postsPagingInfoHooks';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import { generateTagUrl } from '../router/GenerateUrl';
import { IRouteParams } from '../router/NewRoutes';
import ReactHtmlParser from 'react-html-parser';
import use404Page from '../../core/Hooks/use404Page';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function TagPage({ language }: IRouteParams): JSX.Element {
  const params = useParams<{ slug: string }>();
  const history = useHistory();
  const t = useTranslateMessage();
  const tagPaging = usePostsByTagPagingInfo(params.slug);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [tag, tagReqState] = useTagBySlugProvider(params.slug);
  const [tagPosts, tagPostsReqState] = useFCPostsByTagSlugProvider(params.slug, currentPageNumber + 1);
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(tag, tagReqState) ? tag : null);

  use404Page(tagReqState, language);

  useEffect(() => {
    if (hasValidData(tag, tagReqState)) {
      setMetaDataPost(tag);
      trackPageViewGTMEvent('posts-by-tag', ['posts-by-tag'], ['theFix']);
    }
  }, [tagReqState, tag]);

  useEffect(() => {
    if (tag) {
      history.replace(generateTagUrl(tag, language));
    }
  }, [tag, language]);

  useEffect(() => {
    setCurrentPageNumber(0);
  }, [params]);

  const handlePageChange = (event: { selected: number }) => {
    setCurrentPageNumber(event.selected);
  };

  useEffect(() => {
    hasValidData(tagPosts, tagPostsReqState) &&
      window.scrollTo({
        behavior: 'smooth',
        top: 200,
      });
  }, [currentPageNumber, tagPostsReqState]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <LeftAlignedPageBanner
        caption={t('caption.topic')}
        requestState={tagReqState}
        title={true}
        titleValue={tag?.name}
        description={false}
        topics={false}
        className={'mb-12'}
      />

      <div className={'Layout--container'}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>
            {t('title.latest-in')} {tag ? ReactHtmlParser(tag.name) : <Skeleton width={200} />}
          </h2>
        </SectionHeader>

        {hasValidData(tagPosts, tagPostsReqState) ? (
          <HorizontalCardPosts posts={tagPosts} />
        ) : (
          <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={9} title={false} />
        )}
      </div>

      {tagPaging.pages > 1 && (
        <div className={'Layout--container pb-15'}>
          <ReactPaginate
            className={'ReactPaginate--container'}
            pageClassName={`Button ${ButtonTypeEnum.DARK_LESS} ReactPaginate--container-item`}
            activeClassName={`${ButtonTypeEnum.PRIMARY_LESS} ReactPaginate--container-item`}
            previousClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--previous`}
            nextClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--next`}
            breakClassName={`display-none`}
            breakLabel="..."
            nextLabel={''}
            onPageChange={handlePageChange}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            pageCount={tagPaging.pages}
            initialPage={0}
            previousLabel={''}
          />
        </div>
      )}
    </>
  );
}
