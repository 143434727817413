import React from 'react';
import CardPostSkeleton from './CardPostSkeleton';
import { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import SectionHeaderSkeleton from './SectionHeaderSkeleton';
import { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import CardProductSkeleton from './CardProductSkeleton';

export type HorizontalCardPostsSkeletonProps = {
  title: boolean;
  placeholders: number;
  type: HorizontalCardPostsType;
  className?: string;
};

export default function HorizontalCardPostsSkeleton({
  title,
  placeholders,
  type,
  className,
}: HorizontalCardPostsSkeletonProps): JSX.Element {
  return (
    <div className={`HorizontalCardPosts ${className ? className : ''}`}>
      {title && <SectionHeaderSkeleton className={`mb-4 mb-md-6`} borderStyle={SectionHeaderBorderStyle.THICK} />}
      {type === 'posts' && (
        <div className={'Layout--ThreeColumn-Grid'}>
          {new Array(placeholders).fill(0).map((_, index) => (
            <CardPostSkeleton className={`mb-7 mb-lg-8`} key={`post${index}`} />
          ))}
        </div>
      )}

      {type === 'products' && (
        <div className={'Layout--ThreeColumn-Grid'}>
          {new Array(placeholders).fill(0).map((_, index) => (
            <CardProductSkeleton className={`mb-7 mb-lg-8`} key={`product${index}`} />
          ))}
        </div>
      )}
    </div>
  );
}
