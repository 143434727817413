import { useHistory, useParams } from 'react-router-dom';
import { generateSearchUrl } from '../../components/router/GenerateUrl';

export default function useSearchTerm(): (searchTerm: string, pageNumber?: number) => void {
  const params = useParams<{ lng?: string }>();
  const history = useHistory();
  return (searchTerm: string, pageNumber?: number) => {
    if (pageNumber && pageNumber === 1) pageNumber = undefined;
    history.push(generateSearchUrl(searchTerm, params.lng ? params.lng : 'en', pageNumber));
  };
}
