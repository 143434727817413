import React from 'react';
import { PostType } from '../../../types/PostTypes';
import { AvatarCircle, AvatarCircleBorderStyle } from '../../simple/AvatarCircle';
import DateAndAuthor from '../../simple/DateAndAuthor';
import AuthorPlaceholder from '../../../assets/img/authorPlaceholder.png';
import { generateAuthorUrl } from '../../router/GenerateUrl';
import { useCurrentLanguage } from '../../../core/i18/LanguageContextProvider';

export type PostDateAndAuthorProps = {
  post: PostType;
  showAuthorAvatar: boolean;
  className?: string;
};

export function PostDateAndAuthor({ post, showAuthorAvatar, className }: PostDateAndAuthorProps): JSX.Element {
  const language = useCurrentLanguage();
  if (!post.authors || post.authors.length === 0) return <></>;
  const url = post.authors[0].name !== 'storybook' ? generateAuthorUrl(post.authors[0], language.code) : '';

  return (
    <div className={`PostDateAndAuthor ${className ? className : ''}`}>
      {showAuthorAvatar && (
        <AvatarCircle
          link={url}
          imageSrc={post.authors[0].avatar_big ? post.authors[0].avatar_big : AuthorPlaceholder}
          alt={post.authors[0].name}
          width={44}
          height={44}
          borderStyle={AvatarCircleBorderStyle.NONE}
        />
      )}
      <DateAndAuthor date={post.date} author={post.authors} />
    </div>
  );
}
