import { PostsQueries, PostsReducers, PostsState } from './PostsState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LObjectsByPage, LObjectsByPageForString } from '../../types/PayloadActionTypes';
import { PagingInfoType, PostType } from '../../types/PostTypes';
import { RootState } from '../../redux/store';
import { createSelector } from 'reselect';
import { LanguageWrapper, LIndexedObjects, LIndexedObjectsByString } from '../../types/StoreTypes';

export const SLICE_NAME = 'Posts';

const initialState: PostsState = {
  latestPosts: {},
  postsByTag: {},
  postsByCategory: {},
  postsByAuthor: {},
  postsBySearchTerm: {},
  trendingPosts: {},
  popularPosts: {},

  latestPostsPaging: {},
  postsByCategoryPaging: {},
  postsByTagPaging: {},
  postsByAuthorPaging: {},
  postsBySearchTermPaging: {},
};

export const PostsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLatestPosts: (state: PostsState, { payload }: PayloadAction<LObjectsByPage<PostType>>): void => {
      PostsReducers.setLatest(state, payload.pageNumber, payload.objects, payload.language);
    },
    setPostsByTag: (state: PostsState, { payload }: PayloadAction<LObjectsByPage<PostType>>): void => {
      PostsReducers.setPostsByTag(state, payload.forObject, payload.pageNumber, payload.objects, payload.language);
    },
    setPostsByCategory: (state: PostsState, { payload }: PayloadAction<LObjectsByPage<PostType>>): void => {
      PostsReducers.setPostsByCategory(state, payload.forObject, payload.pageNumber, payload.objects, payload.language);
    },
    setPostsByAuthor: (state: PostsState, { payload }: PayloadAction<LObjectsByPage<PostType>>): void => {
      PostsReducers.setPostsByAuthor(state, payload.forObject, payload.pageNumber, payload.objects, payload.language);
    },
    setPostsBySearchTerm: (state: PostsState, { payload }: PayloadAction<LObjectsByPageForString<PostType>>): void => {
      PostsReducers.setPostsBySearchTerm(
        state,
        payload.forObject,
        payload.pageNumber,
        payload.objects,
        payload.language,
      );
    },
    setPopularPosts: (state: PostsState, { payload }: PayloadAction<LObjectsByPage<PostType>>): void => {
      PostsReducers.setPopularPosts(state, payload.forObject, payload.pageNumber, payload.objects, payload.language);
    },
    setTrendingPosts: (state: PostsState, { payload }: PayloadAction<LObjectsByPage<PostType>>): void => {
      PostsReducers.setTrendingPosts(state, payload.forObject, payload.pageNumber, payload.objects, payload.language);
    },

    setLatestPostsPagingInfo: (
      state: PostsState,
      { payload }: PayloadAction<LanguageWrapper<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) =>
        PostsReducers.setLatestPostsPagingInfo(state, payload[lang], lang),
      );
    },
    setPostsByCategoryPagingInfo: (
      state: PostsState,
      { payload }: PayloadAction<LIndexedObjects<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) =>
        Object.keys(payload[lang]).forEach((id: string) =>
          PostsReducers.setPostsByCategoryPagingInfo(state, Number(id), payload[lang][Number(id)], lang),
        ),
      );
    },
    setPostsByTagPagingInfo: (state: PostsState, { payload }: PayloadAction<LIndexedObjects<PagingInfoType>>): void => {
      Object.keys(payload).forEach((lang: string) =>
        Object.keys(payload[lang]).forEach((id: string) =>
          PostsReducers.setPostsByTagPagingInfo(state, Number(id), payload[lang][Number(id)], lang),
        ),
      );
    },
    setPostsByAuthorPagingInfo: (
      state: PostsState,
      { payload }: PayloadAction<LIndexedObjects<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) =>
        Object.keys(payload[lang]).forEach((id: string) =>
          PostsReducers.setPostsByAuthorPagingInfo(state, Number(id), payload[lang][Number(id)], lang),
        ),
      );
    },
    setPostsBySearchTermPagingInfo: (
      state: PostsState,
      { payload }: PayloadAction<LIndexedObjectsByString<PagingInfoType>>,
    ): void => {
      Object.keys(payload).forEach((lang: string) =>
        Object.keys(payload[lang]).forEach((id: string) =>
          PostsReducers.setPostsBySearchTermPagingInfo(state, id, payload[lang][id], lang),
        ),
      );
    },
  },
});

export const getPostsState = (state: RootState): PostsState => state[SLICE_NAME];

export const {
  /// Reducers
  setLatestPosts,
  setPostsByTag,
  setPostsByCategory,
  setPostsByAuthor,
  setPostsBySearchTerm,
  setTrendingPosts,
  setPopularPosts,

  setLatestPostsPagingInfo,
  setPostsByCategoryPagingInfo,
  setPostsByTagPagingInfo,
  setPostsByAuthorPagingInfo,
  setPostsBySearchTermPagingInfo,
} = PostsSlice.actions;

export const LatestPostsSelector = createSelector(getPostsState, PostsQueries.getLatestPosts);
export const LatestPostsAvailablePagesSelector = createSelector(getPostsState, PostsQueries.getAvailablePagesForLatest);
export const PostsByTagSelector = createSelector(getPostsState, PostsQueries.getPostsByTag);
export const PostsByCategorySelector = createSelector(getPostsState, PostsQueries.getPostsByCategory);
export const PostsByAuthorSelector = createSelector(getPostsState, PostsQueries.getPostsByAuthor);
export const PostsBySearchTermSelector = createSelector(getPostsState, PostsQueries.getPostsBySearchTerm);
export const PopularPostsSelector = createSelector(getPostsState, PostsQueries.getPopularPosts);
export const TrendingPostsSelector = createSelector(getPostsState, PostsQueries.getTrendingPosts);

export const LatestPostsPagingInfoSelector = createSelector(getPostsState, PostsQueries.getLatestPostsPagingInfo);

export const PostsByCategoryPagingInfoSelector = createSelector(
  getPostsState,
  PostsQueries.getPostsByCategoryPagingInfo,
);

export const PostsByTagPagingInfoSelector = createSelector(getPostsState, PostsQueries.getPostsByTagPagingInfo);
export const PostsByAuthorPagingInfoSelector = createSelector(getPostsState, PostsQueries.getPostsByAuthorPagingInfo);

export const PostsBySearchTermPagingInfoSelector = createSelector(
  getPostsState,
  PostsQueries.getPostsBySearchTermPagingInfo,
);

export default PostsSlice.reducer;
