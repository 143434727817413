import axios from 'axios';
import mapWooProduct from '../mappers/mapWooProduct';
import ProductType from '../../../types/ProductType';

const API_PRODUCT_BY_SLUG = '/woo/get-product/{slug}/{lng}';
const API_ALL_PRODUCTS = '/woo/get-products/{lng}';

export default class WooProductApi {
  private language?: string;

  constructor(private readonly host: string) {}

  private getAllProductsUrl(): string {
    const lang = this.language || 'en';
    return this.host + API_ALL_PRODUCTS.replace('{lng}', lang);
  }

  private getProductBySlugUrl(slug: string): string {
    const lang = this.language || 'en';
    return this.host + API_PRODUCT_BY_SLUG.replace('{slug}', slug).replace('{lng}', lang);
  }

  setLanguage(lang: string): WooProductApi {
    this.language = lang;
    return this;
  }

  async fetchAllProducts(): Promise<ProductType[]> {
    ENV_SSR && console.log('SSR-fetch-all_woo_products');
    const response = await axios.get(this.getAllProductsUrl());
    if (response.status === 200) {
      return response.data.map(mapWooProduct);
    }
    throw new Error('request failed');
  }

  async fetchProductBySlug(slug: string): Promise<ProductType[]> {
    ENV_SSR && console.log('SSR-fetch-woo_product');
    const response = await axios.get(this.getProductBySlugUrl(slug));
    if (response.status === 200) {
      return response.data.map(mapWooProduct);
    }
    throw new Error('request failed');
  }
}
