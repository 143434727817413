import { CategoryType } from '../../types/PostTypes';
import { useAppSelector } from '../../redux/hooks';
import { CategoriesSelector } from '../bucket/BucketSlice';

export default function useCategoriesFiller(): (ids: number[]) => CategoryType[] {
  const categories = useAppSelector(CategoriesSelector);

  return (ids: number[]): CategoryType[] => {
    return (
      ids
        /// use only available posts
        .filter((id: number): boolean => categories.hasOwnProperty(id))
        /// map id to postId
        .map((id: number): CategoryType => categories[id])
    );
  };
}
