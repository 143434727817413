import { AuthorType } from '../../../types/PostTypes';
import { FcWpRawAuthor } from '../types/fcWpRawTypes';
import { WpRawYoastGraph } from '../../wordpress/types/wpRawTypes';

export default function mapFcWpAuthor(author: FcWpRawAuthor): AuthorType {
  const result = {
    id: Number(author.id),
    slug: author.slug,
    name: author.name,
    meta: author.yoast_head_json,
    description: author.description,
    socials: {},
  } as AuthorType;

  /// From FCApi we are receiving only size 96
  if (author.avatar_urls && author.avatar_urls['96']) {
    result.avatar_small = author.avatar_urls['96'].replace('s=96', 's=24');
    result.avatar_medium = author.avatar_urls['96'].replace('s=96', 's=48');
    result.avatar_big = author.avatar_urls['96'];
  }

  if (author['yoast_head_json'] && author.yoast_head_json['schema'] && author.yoast_head_json.schema['@graph']) {
    author.yoast_head_json.schema['@graph']
      .filter((graph: WpRawYoastGraph) => graph['@type'] === 'Person')
      .forEach((graph: WpRawYoastGraph) => {
        if (graph.sameAs) {
          graph.sameAs.forEach((url: string) => {
            if (url.indexOf('facebook') > -1) result.socials['facebook'] = url;
            else if (url.indexOf('instagram') > -1) result.socials['instagram'] = url;
            else if (url.indexOf('linkedin') > -1) result.socials['linkedin'] = url;
            else if (url.indexOf('twitter') > -1) result.socials['twitter'] = url;
            else result.socials['wwww'] = url;
          });
        }
      });
  }

  return result;
}
