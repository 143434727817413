import React from 'react';
import PostDateAndAuthorSkeleton from './PostDateAndAuthorSkeleton';
import Skeleton from 'react-loading-skeleton';
import EstimatedReadTimeSkeleton from './EstimatedReadTimeSkeleton';

export default function BigHeroPostSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`BigHeroPost ${className ? className : ''}`}>
      <figure className={'BigHeroPost--figure'}>
        <span className={'BigHeroPost--figure-ImgWrapper'}>
          <Skeleton className={`BigHeroPost--figure-Img`} />
        </span>
        <figcaption className={'BigHeroPost--figcaption'}>
          <h1 className={'BigHeroPost--figcaption-Title'}>
            <Skeleton className={'BigHeroPost--figcaption-TitleLink'} count={3} />
          </h1>
        </figcaption>
      </figure>
      <PostDateAndAuthorSkeleton showAuthorAvatar={true} className={'mb-4'} />
      <div className={'HeroPosts--action'}>
        <Skeleton height={0} />
        <EstimatedReadTimeSkeleton />
      </div>
    </div>
  );
}
