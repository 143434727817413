import React, { useState } from 'react';
import Input, { InputTypeEnum } from '../../simple/Input';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import Checkbox, { CheckboxBoxColorEnum, CheckboxCheckColorEnum, CheckboxTextColorEnum } from '../../simple/Checkbox';
import { useHistory } from 'react-router-dom';
import ProductType from '../../../types/ProductType';
import { useCurrentLanguage, useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export type ProductFormProps = {
  product: ProductType;
  className?: string;
};

export default function ProductForm({ className, product }: ProductFormProps): JSX.Element {
  const t = useTranslateMessage();
  const [checkboxPersonalized, setCheckboxPersonalized] = useState<boolean>(false);
  const [checkboxTermsConditions, setCheckboxTermsConditions] = useState<boolean>(false);
  const [firstNameInput, setFirstNameInput] = useState<string>('');
  const [lastNameInput, setLastNameInput] = useState<string>('');
  const [emailInput, setEmailInput] = useState<string>('');
  const [companyInput, setCompanyInput] = useState<string>('');
  const history = useHistory();
  const language = useCurrentLanguage();

  const handleFirstNameValue = (event: React.FormEvent<HTMLInputElement>) => {
    setFirstNameInput(event.currentTarget.value);
  };

  const handleLastNameValue = (event: React.FormEvent<HTMLInputElement>) => {
    setLastNameInput(event.currentTarget.value);
  };

  const handleEmailValue = (event: React.FormEvent<HTMLInputElement>) => {
    setEmailInput(event.currentTarget.value);
  };

  const handleCompanyValue = (event: React.FormEvent<HTMLInputElement>) => {
    setCompanyInput(event.currentTarget.value);
  };

  const handleProceed = () => {
    language.code !== 'en'
      ? history.push(`/${language.code}/checkout`, {
          firstName: firstNameInput,
          lastName: lastNameInput,
          email: emailInput,
          company: companyInput,
        })
      : history.push('/checkout', {
          firstName: firstNameInput,
          lastName: lastNameInput,
          email: emailInput,
          company: companyInput,
        });
  };

  return (
    <div className={`ProductForm--Wrapper ${className ? className : ''}`}>
      <form className={'ProductForm p-8'}>
        <h2 className={'ProductForm--Title'}>Get the report</h2>
        <div className={'Divider Divider--dark mt-3 mb-8'} />
        <div className={'Layout--TwoColumn-Grid mb-4'}>
          <div className={'ProductForm--FirstName'}>
            <p className={'ProductForm--Caption pb-3'}>{t('checkout.fname')}</p>
            <Input
              className={'ProductForm--Input'}
              value={firstNameInput}
              type={InputTypeEnum.TEXT}
              onChange={handleFirstNameValue}
              name="fname"
            />
          </div>
          <div className={'ProductForm--LastName-Wrapper'}>
            <p className={'ProductForm--Caption pb-3'}>{t('checkout.lname')}</p>
            <Input
              className={'ProductForm--Input'}
              value={lastNameInput}
              type={InputTypeEnum.TEXT}
              onChange={handleLastNameValue}
              name="lname"
            />
          </div>
        </div>
        <p className={'ProductForm--Caption pb-3'}>{t('productpage.email')}</p>
        <Input
          className={'ProductForm--Input mb-4'}
          value={emailInput}
          type={InputTypeEnum.EMAIL}
          onChange={handleEmailValue}
          placeholder={'yourname@example.com'}
          name="email"
        />
        <p className={'ProductForm--Caption pb-3'}>{t('checkout.company')}</p>
        <Input
          className={'ProductForm--Input mb-4'}
          value={companyInput}
          type={InputTypeEnum.TEXT}
          onChange={handleCompanyValue}
          name="company"
        />
        {/*<Checkbox
          checked={checkboxPersonalized}
          id={'personalized'}
          textColor={CheckboxTextColorEnum.SECONDARY}
          checkColor={CheckboxCheckColorEnum.WHITE}
          boxColor={CheckboxBoxColorEnum.LIGHT}
          onChange={setCheckboxPersonalized}
        >
          I&#39;d like to receive personalized offers
        </Checkbox>
        <div className={'Divider Divider--dark my-4'} />
        <Checkbox
          checked={checkboxTermsConditions}
          id={'terms-conditions'}
          textColor={CheckboxTextColorEnum.SECONDARY}
          checkColor={CheckboxCheckColorEnum.WHITE}
          boxColor={CheckboxBoxColorEnum.SECONDARY}
          onChange={setCheckboxTermsConditions}
        >
          I agree with{' '}
          <a href={'%'} title={'agreement'}>
            terms & conditions
          </a>
        </Checkbox>*/}
        <Button
          className={'mt-4'}
          btnType={ButtonTypeEnum.PRIMARY}
          width={ButtonWidthEnum.FULLWIDTH}
          height={ButtonHeightEnum.THICK}
          onClick={handleProceed}
        >
          {t('productpage.proceed')} {product.price > 0 ? `${t('payment.pay')} ${product.price}€` : t('cta.download')}
        </Button>
      </form>
    </div>
  );
}
