import React from 'react';
import Skeleton from 'react-loading-skeleton';
import EstimatedReadTimeSkeleton from './EstimatedReadTimeSkeleton';
import PostDateAndAuthorSkeleton from './PostDateAndAuthorSkeleton';

export default function HeroPostSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`HeroPost ${className ? className : ''}`}>
      <figure className={'HeroPost--figure mb-6 mb-md-11'}>
        <Skeleton className={'HeroPost--figure-Img'} />
        <figcaption className={'HeroPost--figcaption ml-0 ml-md-8 mt-5 mt-md-0'}>
          <h3 className={'HeroPost--figcaption-Title mb-5'}>
            <Skeleton count={3} className={'HeroPost--figcaption-TitleLink'} />
          </h3>
          <PostDateAndAuthorSkeleton showAuthorAvatar={false} />
        </figcaption>
      </figure>
      <div className={'HeroPosts--action'}>
        <Skeleton height={0} />
        <EstimatedReadTimeSkeleton />
      </div>
    </div>
  );
}
