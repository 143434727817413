import React, { useEffect, useState } from 'react';

import useCategoryBySlugProvider from '../../core/dataProviders/providers/categories/useCategoryBySlugProvider';
import { useHistory, useParams } from 'react-router-dom';
import useFCPostsByCategorySlugProvider from '../../core/dataProviders/providers/useFCPostsByCategorySlugProvider';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import SectionHeader, { SectionHeaderBorderStyle } from '../simple/SectionHeader';
import ReactPaginate from 'react-paginate';
import { ButtonTypeEnum } from '../simple/Button';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import HorizontalCardPostsSkeleton from '../skeletons/HorizontalCardPostsSkeleton';
import Skeleton from 'react-loading-skeleton';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import { usePostsByCategoryPagingInfo } from '../../core/dataProviders/providers/posts/postsPagingInfoHooks';
import useLazyFCTopTagsForCategorySlugProvider from '../../core/dataProviders/providers/useLazyFCTopTagsForCategorySlugProvider';
import { generateCategoryUrl } from '../router/GenerateUrl';
import { IRouteParams } from '../router/NewRoutes';
import ReactHtmlParser from 'react-html-parser';
import use404Page from '../../core/Hooks/use404Page';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function CategoryPage({ language }: IRouteParams): JSX.Element {
  const t = useTranslateMessage();
  const history = useHistory();
  const params = useParams<{ lng?: string; slug: string }>();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [category, categoryReqState] = useCategoryBySlugProvider(params.slug);
  const [categoryPosts, categoryPostsReqState] = useFCPostsByCategorySlugProvider(params.slug, currentPageNumber + 1);
  const categoryPaging = usePostsByCategoryPagingInfo(params.slug);
  const topTags = useLazyFCTopTagsForCategorySlugProvider(params.slug);
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(category, categoryReqState) ? category : null);

  use404Page(categoryReqState, language);

  useEffect(() => {
    if (hasValidData(category, categoryReqState)) {
      setMetaDataPost(category);
      trackPageViewGTMEvent('posts-by-category', ['posts-by-category'], ['theFix']);
    }
  }, [categoryReqState, category]);

  useEffect(() => {
    if (category) {
      history.replace(generateCategoryUrl(category, language));
    }
  }, [category, language]);

  useEffect(() => {
    setCurrentPageNumber(0);
  }, [params.slug]);

  const handlePageChange = (event: { selected: number }) => {
    setCurrentPageNumber(event.selected);
  };

  useEffect(() => {
    hasValidData(categoryPosts, categoryPostsReqState) &&
      window.scrollTo({
        behavior: 'smooth',
        top: 200,
      });
  }, [currentPageNumber, categoryPostsReqState]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>

      <LeftAlignedPageBanner
        caption={t('caption.category')}
        requestState={categoryReqState}
        title={true}
        titleValue={category?.name}
        description={true}
        descriptionValue={category?.description}
        topics={true}
        topicsArray={topTags}
        className={'mb-12'}
      />

      <div className={'Layout--container'}>
        <SectionHeader className={'mb-5 mb-md-7'} borderStyle={SectionHeaderBorderStyle.THICK}>
          <h2>
            {t('title.latest-in')} {category ? ReactHtmlParser(category.name) : <Skeleton width={200} />}
          </h2>
        </SectionHeader>

        {hasValidData(categoryPosts, categoryPostsReqState) ? (
          <HorizontalCardPosts posts={categoryPosts} />
        ) : (
          <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={9} title={false} />
        )}
      </div>

      {categoryPaging.pages > 1 && (
        <div className={'Layout--container pb-15'}>
          <ReactPaginate
            className={'ReactPaginate--container'}
            pageClassName={`Button ${ButtonTypeEnum.DARK_LESS} ReactPaginate--container-item`}
            activeClassName={`${ButtonTypeEnum.PRIMARY_LESS} ReactPaginate--container-item`}
            previousClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--previous`}
            nextClassName={`Button Button--dark_less-roti ReactPaginate--container-item ReactPaginate--next`}
            breakLabel=""
            nextLabel={''}
            onPageChange={handlePageChange}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            pageCount={categoryPaging.pages}
            forcePage={currentPageNumber}
            previousLabel={''}
          />
        </div>
      )}
    </>
  );
}
