import React, { FormEvent, FormEventHandler, useState } from 'react';
import Input, { InputTypeEnum } from '../../simple/Input';
import useSearchTerm from '../../../core/Hooks/useSearchTerm';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export type SearchFormProps = {
  className?: string;
  toggleSearch?: () => void;
  children?: React.ReactNode;
};

export default function SearchForm({ className, toggleSearch, children }: SearchFormProps): JSX.Element {
  const t = useTranslateMessage();
  const searchTerm = useSearchTerm();
  const [inputSearchValue, setInputSearchValue] = useState<string>('');
  const searchSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (toggleSearch) {
      toggleSearch();
    }
    searchTerm(inputSearchValue);
  };

  const handleSearchValue = (event: React.FormEvent<HTMLInputElement>) => {
    setInputSearchValue(event.currentTarget.value);
  };

  return (
    <form className={`SearchForm ${className ? className : ''}`} onSubmit={searchSubmitHandler}>
      {children}
      <Input
        id={'search-input'}
        type={InputTypeEnum.TEXT}
        value={inputSearchValue}
        onChange={handleSearchValue}
        placeholder={t('search.hint')}
      />
    </form>
  );
}
