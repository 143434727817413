import React, { useEffect } from 'react';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../sections/cards/HorizontalCardPosts';
import LeftAlignedPageBanner from '../sections/LeftAlignedPageBanner';
import HorizontalCardPostsSkeleton from '../skeletons/HorizontalCardPostsSkeleton';
import useAllProductsProvider from '../../core/dataProviders/providers/products/useAllProductsProvider';
import usePageBySlugProvider from '../../core/dataProviders/providers/pages/usePageBySlugProvider';
import { useTranslateMessage } from '../../core/i18/LanguageContextProvider';
import { DataRequestStateEnum } from '../../core/dataProviders/DataProviderState';
import Helmet from 'react-helmet';
import useParseWpMetaData from '../../core/Hooks/useParseWpMetaData';
import hasValidData from '../../utils/hasValidData';
import trackPageViewGTMEvent from '../../core/GTMTracking/trackPageViewGTMEvent';

export default function ProductsListPage(): JSX.Element {
  const t = useTranslateMessage();
  const [page, reqState] = usePageBySlugProvider(t('slug.page.research'));
  const [metaData, setMetaDataPost] = useParseWpMetaData(hasValidData(page, reqState) ? page : null);
  const [allProducts, allProductReqState] = useAllProductsProvider();

  useEffect(() => {
    if (hasValidData(page, reqState)) {
      setMetaDataPost(page);
      trackPageViewGTMEvent('products-list', ['products-list'], ['theFix']);
    }
  }, [reqState, page]);

  return (
    <>
      <Helmet>{metaData ? metaData.map((item) => item) : undefined}</Helmet>
      <LeftAlignedPageBanner
        caption={'Products'}
        title={true}
        titleValue={'Explore resources'}
        description={false}
        topics={false}
        className={'mb-12'}
      />

      <div className={'Layout--container'}>
        {allProductReqState === DataRequestStateEnum.FULFILLED && allProducts.length !== 0 ? (
          <HorizontalCardPosts products={allProducts} />
        ) : (
          <HorizontalCardPostsSkeleton title={false} placeholders={3} type={HorizontalCardPostsType.PRODUCTS} />
        )}
      </div>
    </>
  );
}
