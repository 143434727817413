import React, { useEffect, useState } from 'react';
import { CategoryType, PostType, TagType } from '../../../types/PostTypes';
import { CategoryBadge, CategoryTypeEnum } from '../../simple/CategoryBadge';
import DateAndAuthor from '../../simple/DateAndAuthor';
import EstimatedReadTime from '../../simple/EstimatedReadTime';
import TagBadge, { TagTypeEnum } from '../../simple/TagBadge';
import { useFilterCategory } from '../../hooks/useFilterCategory';
import ShareIcon from '../../../assets/svg/share.svg';
import ShareLinksMenu from '../../compound/post/page/ShareLinksMenu';
import { useLocation } from 'react-router-dom';
import ImagePlaceholder from '../../../assets/img/imagePlaceholder.png';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import Image from '../../simple/Image';

export type PostPageBannerProps = {
  post: PostType;
  category: CategoryType | null;
  className?: string;
};

export default function PostPageBanner({ post, category, className }: PostPageBannerProps): JSX.Element {
  const t = useTranslateMessage();
  const partnerCategory = useFilterCategory(post, t('slug.category.partner'), CategoryTypeEnum.SPECIAL_ONE);
  const [sponsorLogoUrl, setSponsorLogoUrl] = useState<string | undefined>();
  const [sponsorLogoAlt, setSponsorLogoAlt] = useState<string | undefined>();

  const [shareMenuShown, setShareMenuShown] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (partnerCategory) {
      if (document.getElementsByClassName('sponsor-item default').length > 0) {
        setSponsorLogoUrl(
          document.getElementsByClassName('sponsor-item default')[0].getElementsByTagName('img')[0].src,
        );
        setSponsorLogoAlt(
          document.getElementsByClassName('sponsor-item default')[0].getElementsByTagName('img')[0].alt,
        );
      }
    }
  }, [category]);

  const toggleShareMenuFn = () => {
    setShareMenuShown((previousState) => {
      return !previousState;
    });
  };

  const toggleShareOnClick = (event: React.MouseEvent) => {
    if (!(navigator && window && window.innerWidth < 768)) {
      toggleShareMenuFn();
      event.preventDefault();
      return false;
    }

    const domParser = new DOMParser();
    const shareTitle = domParser.parseFromString(post.title, 'text/html').body.textContent || undefined;
    try {
      navigator.share({
        url: location.pathname,
        title: shareTitle,
      });
      return;
    } catch (ex) {}
  };

  return (
    <>
      {partnerCategory && (
        <div className={'PostPageBanner--partner'}>
          <div className={'Layout--container Layout--ThreeColumnCentered-Flex py-6'}>
            <div className={'Layout--left mr-5'}>
              {sponsorLogoUrl && sponsorLogoUrl.length > 0 && (
                <img
                  src={sponsorLogoUrl ? sponsorLogoUrl : ImagePlaceholder}
                  alt={sponsorLogoAlt ? sponsorLogoAlt : 'undefined'}
                  className={'PostPageBanner--partner-image'}
                />
              )}
            </div>
            <div className={'Layout--center'} style={{ flex: 2 }}>
              <CategoryBadge type={partnerCategory.CategoryTypeEnum} category={partnerCategory.type} />
            </div>
            <div className={'Layout--right'}>
              <div className={'PostPageBanner--partner-share'}>
                <p onClick={toggleShareOnClick}>
                  <ShareIcon className={'mr-2 pr-2'} />
                  {t('socials.share-now')}
                </p>
                <div>
                  {shareMenuShown && <ShareLinksMenu toggleShareMenuFn={toggleShareMenuFn} className={'p-4'} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`PostPageBanner ${className ? className : ''} ${
          partnerCategory ? 'GradBackground py-17 py-md-25' : 'py-8 py-md-13'
        }`}
        style={{
          backgroundImage: partnerCategory ? `url(${post.imageFull ? post.imageFull.url : ImagePlaceholder}` : 'none',
        }}
      >
        <div className={'Layout--container'}>
          {!partnerCategory && (
            <div className={'PostPageBanner--ImgWrapper mb-12'}>
              <Image itemWithImages={post} className={'PostPageBanner--Img'} />
            </div>
          )}

          <div className={'PostPageBanner--wrapper px-0 px-md-11'}>
            <div className={'PostPageBanner--caption pb-6 pb-md-13'}>
              {!partnerCategory && category !== null && (
                <CategoryBadge category={category} type={CategoryTypeEnum.TERTIARY} className={'mr-4 mb-4 mb-sm-0'} />
              )}
              <DateAndAuthor date={post.date} author={post.authors} />
            </div>

            <h1 className={'PostPageBanner--title pb-5 pt-5'}>{post.title}</h1>
            <nav className={'PostPageBanner--tags pt-5 pb-10'}>
              {post.tags.map((tag: TagType) => (
                <TagBadge key={tag.id} tag={tag} type={TagTypeEnum.LIGHT_LESS} className={'mr-2 mb-2'} />
              ))}
            </nav>

            <div className={'PostPageBanner--action'}>
              <span>&nbsp;</span>
              <EstimatedReadTime post={post} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
