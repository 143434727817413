import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ButtonHeightEnum } from '../simple/Button';

interface ButtonSkeletonProps {
  width: number | string;
  height: ButtonHeightEnum;
  className?: string;
}

export default function ButtonSkeleton({ width, height, className }: ButtonSkeletonProps): JSX.Element {
  return (
    <Skeleton className={`Button ${height} ${className ? className : ''}`} width={width} style={{ border: 'none' }} />
  );
}
