import React, { useContext, useEffect, useState } from 'react';
import { setLanguage } from './AppSlice';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import DataProvider from '../../thefix/DataProvider';

export interface IAppContext {
  language: string;
}

const AppContext = React.createContext<IAppContext>({
  language: 'en',
});

const useAppContext = () => useContext(AppContext);

type IAppContextProviderParams = {
  children: JSX.Element | JSX.Element[];
};

const AppContextProvider = ({ children }: IAppContextProviderParams): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams<{ lng?: string }>();
  const [language, setContextLanguage] = useState<string>(params.lng ? params.lng : 'en');

  useEffect(() => {
    setLanguage(params.lng ? params.lng : 'en');
    setContextLanguage(params.lng ? params.lng : 'en');
    dispatch(setLanguage(params.lng ? params.lng : 'en'));
  }, [params]);

  return (
    <AppContext.Provider value={{ language: language }}>
      <DataProvider>{children}</DataProvider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
