import React from 'react';
import Time from '../../assets/svg/time.svg';
import Skeleton from 'react-loading-skeleton';

export default function EstimatedReadTimeSkeleton(): JSX.Element {
  return (
    <div className={'EstimatedReadTime'}>
      <p className={'EstimatedReadTime--time'}>
        <Time />
        <Skeleton width={90} style={{ marginLeft: 0 }} />
      </p>
    </div>
  );
}
