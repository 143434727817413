import React from 'react';
import SWLink from '../../../fuctional/SWLink';
import { PostType } from '../../../../types/PostTypes';
import { CategoryBadge, CategoryTypeEnum } from '../../../simple/CategoryBadge';
import { postUrl } from '../../../router/routeList';
import { useFilterCategory } from '../../../hooks/useFilterCategory';
import { useCurrentLanguage, useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';

export interface MiniNumberedPostProps {
  post: PostType;
  className?: string;
}

export default function MiniNumberedPost({ post, className }: MiniNumberedPostProps): JSX.Element {
  const language = useCurrentLanguage();
  const url = postUrl(post, language.code);
  const t = useTranslateMessage();

  const partnerCategory = useFilterCategory(post, t('slug.category.partner'), CategoryTypeEnum.SPECIAL_ONE);
  const opinionCategory = useFilterCategory(post, t('slug.category.opinion'), CategoryTypeEnum.TERTIARY);
  const filteredCategory = partnerCategory || opinionCategory;

  return (
    <div className={`MiniNumberedPost ${className ? className : ''}`}>
      {/** category badges ***************************************/}

      {filteredCategory && (
        <div className={'MiniNumberedPost--categories'}>
          <CategoryBadge
            category={filteredCategory.type}
            type={filteredCategory.CategoryTypeEnum}
            className={'mb-4 mr-1'}
          />
        </div>
      )}

      {/** Post title *******************************************/}
      <SWLink to={url} title={post.title} className={'MiniNumberedPost--Title'}>
        {post.title}
      </SWLink>
    </div>
  );
}
