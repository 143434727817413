import WooProduct from '../types/WooProduct';
import ProductType from '../../../types/ProductType';
import mapWooProductImage from './mapWooProductImage';

export default function mapWooProduct(product: WooProduct): ProductType {
  const mappedImage = mapWooProductImage(product.images);
  return {
    id: product.id,
    slug: product.slug,
    name: product.name,
    description: product.description,
    excerpt: product.short_description,
    imageMedium: mappedImage.medium,
    imageMediumLarge: mappedImage.mediumLarge,
    imageLarge: mappedImage.large,
    imageFull: mappedImage.full,
    price: Number(product.price),
    regularPrice: Number(product.regular_price),
    priceHtml: product.price_html,
    meta: product.yoast_head_json,
    metaData: product.meta_data,
  } as ProductType;
}
