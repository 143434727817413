import React from 'react';
import { MenuItemType } from '../../../types/MenuTypes';
import SWLink from '../../fuctional/SWLink';
import useMediaQuery, { mediaQueries } from '../../../core/Hooks/useMediaQuery';
import Button, { ButtonHeightEnum, ButtonTypeEnum, ButtonWidthEnum } from '../../simple/Button';
import UserIcon from '../../../assets/svg/user.svg';
import HeaderLine from '../../sections/everywhere/HeaderLine';
import SearchForm from './SearchForm';
import IconLink, { IconFillEnum } from '../../simple/IconLink';
import SearchIcon from '../../../assets/svg/search.svg';
import Skeleton from 'react-loading-skeleton';

export type HamburgerProps = {
  subMenuItems: { name: string; url: string }[];
  mainMenuItems: { name: string; url: string }[];
  hamburgerHandler: () => void;
  hamburgerShown: boolean;
  className?: string;
};

export default function HamburgerMenu({
  subMenuItems,
  mainMenuItems,
  hamburgerHandler,
  hamburgerShown,
  className,
}: HamburgerProps): JSX.Element {
  const matchesMediumUp = useMediaQuery(mediaQueries.md, true);

  return (
    <div className={`HamburgerMenu ${className ? className : ''}`}>
      {matchesMediumUp ? (
        <ul className={'HorizontalMenu--menu-items'}>
          {subMenuItems.length > 0
            ? subMenuItems.map((item: MenuItemType, index) => (
                <li key={index} className={'HorizontalMenu--menu-items-item px-2'}>
                  <SWLink to={item.url} className={'HorizontalMenu--menu-items-item-link'} onClick={hamburgerHandler}>
                    {item.name}
                  </SWLink>
                </li>
              ))
            : new Array(6).fill(0).map((_, index) => (
                <li key={`menuSkeleton${index}`} className={'HorizontalMenu--menu-items-item px-2'}>
                  <Skeleton width={90} className={'HorizontalMenu--menu-items-item-link'} />
                </li>
              ))}
        </ul>
      ) : (
        <>
          <HeaderLine hamburgerShown={hamburgerShown} hamburgerHandler={hamburgerHandler} className={'pb-6'} />

          <div className={'Divider Divider--dark mb-6'} />

          <a
            className={'HeaderLine--PromoLink pb-6'}
            target={'_blank'}
            href={'https://www.thefix.foundation/'}
            rel="noreferrer"
          >
            The Fix Foundation
          </a>

          <SearchForm className={'mb-6'} toggleSearch={hamburgerHandler}>
            <IconLink
              component={'button'}
              fill={IconFillEnum.SECONDARY}
              className={`${ButtonTypeEnum.PRIMARY_LESS}`}
              aria-label={'Open Search'}
            >
              <SearchIcon />
            </IconLink>
          </SearchForm>

          <ul className={'HorizontalMenu--menu-items pb-8'}>
            {mainMenuItems.length > 0
              ? mainMenuItems.map((item: MenuItemType, index) => (
                  <li key={index} className={'HorizontalMenu--menu-items-item p-2'}>
                    <SWLink to={item.url} className={'HorizontalMenu--menu-items-item-link'} onClick={hamburgerHandler}>
                      {item.name}
                    </SWLink>
                  </li>
                ))
              : new Array(4).fill(0).map((_, index) => (
                  <li key={`menuSkeleton${index}`} className={'HorizontalMenu--menu-items-item p-2'}>
                    <Skeleton width={90} className={'HorizontalMenu--menu-items-item-link'} />
                  </li>
                ))}
            {subMenuItems.length > 0
              ? subMenuItems.map((item: MenuItemType, index) => (
                  <li key={index} className={'HorizontalMenu--menu-items-item p-2'}>
                    <SWLink to={item.url} className={'HorizontalMenu--menu-items-item-link'} onClick={hamburgerHandler}>
                      {item.name}
                    </SWLink>
                  </li>
                ))
              : new Array(4).fill(0).map((_, index) => (
                  <li key={`menuSkeleton${index}`} className={'HorizontalMenu--menu-items-item p-2'}>
                    <Skeleton width={90} className={'HorizontalMenu--menu-items-item-link'} />
                  </li>
                ))}
          </ul>

          <div className={'HamburgerMenu--TwoColumn py-8 display-none'}>
            <Button
              btnType={ButtonTypeEnum.SECONDARY}
              component={SWLink}
              to={'/subscription'}
              width={ButtonWidthEnum.FULLWIDTH}
              height={ButtonHeightEnum.DEF}
              onClick={hamburgerHandler}
            >
              Newsletter
            </Button>
            <Button
              btnType={ButtonTypeEnum.PRIMARY}
              onClick={hamburgerHandler}
              width={ButtonWidthEnum.FULLWIDTH}
              height={ButtonHeightEnum.DEF}
            >
              Signup
            </Button>
          </div>
          <div className={'Divider Divider--dark display-none'} />

          <SWLink to={'#'} className={'HeaderLine--UserLogin pr-4 py-8 display-none'} onClick={hamburgerHandler}>
            <UserIcon className={'p-2'} />
            Login
          </SWLink>
        </>
      )}
    </div>
  );
}
