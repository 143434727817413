import { WpAuthorOptions } from './WpAuthorOptions';
import { AuthorType, PagingInfoType } from '../../../types/PostTypes';
import axios from 'axios';
import mapWpAuthor from '../mappers/mapWpAuthor';
import mapWpPagingInfo from '../mappers/mapWpPagingInfo';

const API_AUTHORS = '/wp-json/wp/v2/users';

export default class AuthorApi {
  private options: WpAuthorOptions;
  public result?: AuthorType[];
  public pagingInfo?: PagingInfoType;

  constructor(private readonly wpHost: string) {
    this.options = {
      authorId: [],
      slug: [],
    };
  }

  setPageNumber(pageNumber: number): AuthorApi {
    this.options.page = pageNumber;
    return this;
  }

  setPerPage(perPage: number): AuthorApi {
    this.options.perPage = perPage;
    return this;
  }

  setSlug(slug: string): AuthorApi {
    this.options.slug.push(slug);
    return this;
  }

  setAuthorId(authorId: number): AuthorApi {
    this.options.authorId.push(authorId);
    return this;
  }

  setMinimalResult(): AuthorApi {
    this.options._fields = 'id,name,slug,link,yoast_head';
    return this;
  }

  generateUrl(): string {
    let result: string[] = [];
    if (this.options.authorId.length > 0) {
      result = [...result, ...this.options.authorId.map((id: number) => 'includes[]=' + id)];
    }
    if (this.options.slug.length > 0) {
      result = [...result, ...this.options.slug.map((slug: string) => 'slug[]=' + slug)];
    }
    if (this.options.perPage) result.push('per_page=' + this.options.perPage);
    if (this.options.page) result.push('page=' + this.options.page);
    if (this.options._fields) result.push('_fields=', this.options._fields);

    return this.wpHost + API_AUTHORS + '?' + result.join('&');
  }

  async fetch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-author');
    const response = await axios.get(this.generateUrl());
    if (response.status === 200) {
      this.pagingInfo = mapWpPagingInfo(response.headers);
      this.result = response.data.map(mapWpAuthor);
      return;
    }
    throw new Error('request failed');
  }
}
