import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function MiniNumberedPostSkeleton({ className }: { className?: string }): JSX.Element {
  return (
    <div className={`MiniNumberedPost ${className ? className : ''}`}>
      <Skeleton count={3} className={'MiniNumberedPost--Title'} />
    </div>
  );
}
