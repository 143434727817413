import hasValidData from '../../../utils/hasValidData';
import React, { useEffect, useRef, useState } from 'react';
import useFCPostsByCategorySlugProvider from '../../../core/dataProviders/providers/useFCPostsByCategorySlugProvider';
import useCategoryBySlugProvider from '../../../core/dataProviders/providers/categories/useCategoryBySlugProvider';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';
import HorizontalCardPosts, { HorizontalCardPostsType } from '../cards/HorizontalCardPosts';
import HorizontalCardPostsSkeleton from '../../skeletons/HorizontalCardPostsSkeleton';

export type TopStoriesSectionProps = { className?: string; currentScroll: number };

export default function TopStoriesSection({ className, currentScroll }: TopStoriesSectionProps): JSX.Element {
  const t = useTranslateMessage();
  const [slug, setSlug] = useState<string | null>(null);
  const [slugSet, setSlugSet] = useState<boolean>(false);
  const [isInView, setIsInView] = useState<boolean>(false);
  const sectionWrapper = useRef<HTMLDivElement>(null);
  const [topStoriesPosts, topStoriesPostsReqState] = useFCPostsByCategorySlugProvider(slug, 1, false);
  const [topStoriesCategory, topStoriesCategoryReqState] = useCategoryBySlugProvider(slug, false);

  useEffect(() => {
    if (
      !slugSet &&
      sectionWrapper.current &&
      sectionWrapper.current.getBoundingClientRect().bottom >= 0 &&
      sectionWrapper.current.getBoundingClientRect().top <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      setIsInView(true);
      setSlugSet(true);
    }
  }, [currentScroll]);

  useEffect(() => {
    isInView && setSlug(t('slug.category.top-stories'));
  }, [isInView]);

  return (
    <div className={`TopStoriesSection Layout--container ${className ? className : ''}`} ref={sectionWrapper}>
      {hasValidData(topStoriesCategory, topStoriesCategoryReqState) &&
      hasValidData(topStoriesPosts, topStoriesPostsReqState) ? (
        <HorizontalCardPosts title={topStoriesCategory.name} posts={topStoriesPosts.slice(0, 3)} />
      ) : (
        <HorizontalCardPostsSkeleton type={HorizontalCardPostsType.POSTS} placeholders={3} title={true} />
      )}
    </div>
  );
}
