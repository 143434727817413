import React from 'react';
import { ButtonHeightEnum } from '../simple/Button';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ButtonSkeleton from './ButtonSkeleton';

export default function PostDetailAuthorSocialsSkeleton(): JSX.Element {
  return (
    <SkeletonTheme inline={true}>
      <div className={'PostDetailAuthorSocials'}>
        <div className={'PostDetailAuthorSocials--Icons'}>
          <Skeleton width={26} height={26} className={'mr-1'} />
          <Skeleton width={26} height={26} className={'mr-2'} />
        </div>
      </div>
    </SkeletonTheme>
  );
}
