import { useEffect, useState } from 'react';
import { DataRequestStateEnum } from '../../DataProviderState';
import useDataProviderState from '../../useDataProviderState';
import useDataProviderOrder from '../../useDataProviderOrder';
import { useAppSelector } from '../../../../redux/hooks';
import { TagType } from '../../../../types/PostTypes';
import { TagsSelector, TagSlugMapSelector } from '../../../bucket/BucketSlice';
import useDataProviderSSROrder from '../../useDataProviderSSROrder';
import { IndexedObjects, SlugToIdMapper } from '../../../../types/StoreTypes';
import useLangSelector from '../../../Hooks/useLangSelector';

/**
 * Recognize "full" tag, and result with it ONLY if there is one
 *  tags are "full" if there is .meta field
 *
 * @param slug
 * @param slugToTagId
 * @param tags
 */
function getFullTag(
  slug: string,
  slugToTagId: SlugToIdMapper | undefined,
  tags: IndexedObjects<TagType> | undefined,
): TagType | null {
  if (slugToTagId && tags && slugToTagId[slug] && tags[slugToTagId[slug]] && tags[slugToTagId[slug]].meta)
    return tags[slugToTagId[slug]];
  return null;
}

export default function useTagBySlugProvider(
  slug: string | null,
  includeInSSR = true,
): [
  /// Tag object
  TagType | null,
  /// Request state
  DataRequestStateEnum,
] {
  /// Helper hooks  ------------------------------------------------------------
  const orderData = useDataProviderOrder();

  /// Redux Selectors ----------------------------------------------------------
  const tags = useLangSelector(useAppSelector(TagsSelector));
  const slugToTagId = useLangSelector(useAppSelector(TagSlugMapSelector));

  /// Current hook state -------------------------------------------------------
  const [tag, setTag] = useState<TagType | null>(() => (slug ? getFullTag(slug, slugToTagId, tags) : null));
  const [reqState, , setRequestSign] = useDataProviderState('tag-slug', slug);
  const [currentSlug, setCurrentSlug] = useState<string | null>(null);

  /// on slug change  ----------------------------------------------------------
  useEffect(() => {
    if (slug !== currentSlug) {
      setCurrentSlug(slug);
      let foundTag: TagType | null = null;
      if (slug) foundTag = getFullTag(slug, slugToTagId, tags);
      setTag(foundTag);

      /// Order data if there is not matching tag in the bucket  ------------
      if (slug) {
        setRequestSign(slug);
        if (foundTag === null) orderData('tag-slug', slug);
      }
    }
  }, [slug]);

  useEffect(() => {
    /// Make sure we are listening to effect hook
    if (tag !== null) return;
    if (currentSlug) setTag(getFullTag(currentSlug, slugToTagId, tags));
  }, [slugToTagId, tags]);

  /// SSR Data  ----------------------------------------------------------------
  if (ENV_SSR && includeInSSR) useDataProviderSSROrder('tag-slug', '' + slug);

  /// Hook result --------------------------------------------------------------
  return [tag, tag ? DataRequestStateEnum.FULFILLED : reqState];
}
