import React, { useState } from 'react';
import useAllTagsProvider from '../../../core/dataProviders/providers/tags/useAllTagsProvider';
import TagList from '../../compound/TagList';
import { TagTypeEnum } from '../../simple/TagBadge';
import CapitalCta, { CapitalCtaColorEnum } from '../../simple/CapitalCta';
import { useTranslateMessage } from '../../../core/i18/LanguageContextProvider';

export function TagCloudSection(): JSX.Element {
  const t = useTranslateMessage();
  const [allTags, allTagsReqState] = useAllTagsProvider(1, false);

  const [allTopicsShown, setAllTopicsShown] = useState(true);
  const toggleAllTopics = () => {
    setAllTopicsShown((previousState: boolean) => {
      return !previousState;
    });
  };

  return (
    <>
      <TagList
        inline={true}
        type={TagTypeEnum.DARK_LESS}
        className={`${allTopicsShown && 'TagList--hidden'}`}
        tagListItems={allTags.slice(0, 30)}
        tagListReqState={allTagsReqState}
        tagListHandler={toggleAllTopics}
      />

      <CapitalCta color={CapitalCtaColorEnum.PRIMARY} className={'mb-7 mt-2'} onClick={toggleAllTopics}>
        {allTopicsShown ? '+' : '-'} {t('cta.view-more')}
      </CapitalCta>
    </>
  );
}
