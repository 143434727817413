import { WpPagingInfo } from '../types/wpRawTypes';
import { PagingInfoType } from '../../../types/PostTypes';

export default function mapWpPagingInfo(responseHeaders: WpPagingInfo): PagingInfoType | undefined {
  if (!responseHeaders['x-wp-totalpages'] && !responseHeaders['x-wp-total']) return;

  return {
    total: Number(responseHeaders['x-wp-total']),
    pages: Number(responseHeaders['x-wp-totalpages']),
  };
}
