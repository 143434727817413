import { PageType, PagingInfoType, PostType } from '../../../types/PostTypes';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { FcOrderEnum, FcPostOptions } from '../post/FcWpPostOptions';
import mapWpPost from '../mappers/mapFcWpPost';

const API_PAGES = '/wp-json/fc_api/v1/pages';

export default class FcPostsApi {
  private options: FcPostOptions;
  public result?: PostType[];
  public pagingInfo?: PagingInfoType;

  constructor(private readonly wpHost: string) {
    this.options = {
      order: FcOrderEnum.Desc,
      posts: [],
      embed: 1,
    };
  }

  arePopular(): FcPostsApi {
    this.options.range = 'last30days';
    return this;
  }

  areTrending(): FcPostsApi {
    this.options.range = 'last7days';
    return this;
  }

  setSlug(slug: string): FcPostsApi {
    this.options.slug = slug;
    return this;
  }

  orderBy(order: FcOrderEnum): FcPostsApi {
    this.options.order = order;
    return this;
  }

  setPage(pageNumber: number): FcPostsApi {
    this.options.pageNumber = pageNumber;
    return this;
  }

  setPerPage(perPage: number): FcPostsApi {
    this.options.perPage = perPage;
    return this;
  }

  setLanguage(language: string): FcPostsApi {
    this.options.language = language;
    return this;
  }

  filterByAuthor(author: number | string): FcPostsApi {
    this.options.author = author;
    return this;
  }

  filterByCategory(category: number | string): FcPostsApi {
    this.options.category = category;
    return this;
  }

  filterByTag(tag: number | string): FcPostsApi {
    this.options.tag = tag;
    return this;
  }

  filterByPost(post: number | string): FcPostsApi {
    this.options.posts.push(post);
    return this;
  }

  filterBySearchTerm(searchTerm: string): FcPostsApi {
    this.options.search = searchTerm;
    return this;
  }

  generateUrl(): string {
    let result: string[] = [];

    result.push('order=' + this.options.order);

    if (this.options.embed === 1) {
      result = [...result, '_embed=1'];
    }

    if (this.options.author) {
      result = [...result, 'author=' + this.options.author];
    }
    if (this.options.tag) {
      result = [...result, 'tag=' + this.options.tag];
    }
    if (this.options.category) {
      result = [...result, 'category=' + this.options.category];
    }
    if (this.options.posts.length > 0) {
      result = [...result, ...this.options.posts.map(this.mapCreateKey('includes'))];
    }
    if (this.options.perPage) {
      result = [...result, 'per_page=' + this.options.perPage];
    }
    if (this.options.pageNumber) {
      result = [...result, 'page=' + this.options.pageNumber];
    }
    if (this.options.slug) {
      result = [...result, 'slug=' + this.options.slug];
    }
    if (this.options.search) {
      result = [...result, 'search=' + encodeURI(this.options.search)];
    }
    if (this.options.range) {
      result = [...result, 'most_read=1', 'range=' + this.options.range];
    }

    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';
    return this.wpHost + lang + API_PAGES + '?' + result.join('&');
  }

  generateSinglePostUrl(): string {
    let result: string[] = [];

    if (this.options.slug) {
      result = [...result, 'slug=' + this.options.slug];
    }
    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';
    return this.wpHost + lang + API_PAGES + '?' + result.join('&');
  }

  mapCreateKey(keyName: string): (id: number | string) => string {
    return (id: number | string): string => `${keyName}[]=${id}`;
  }

  async fetchSingle(): Promise<PageType[]> {
    let isCached = false;
    let result: PageType[] = [];

    this.generateSinglePostUrl().split('=')[this.generateSinglePostUrl().split('=').length - 1] === 'homepage' &&
      (await axios
        .get(ENV_DOMAIN_URL + '/cache/homepage.json')
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            isCached = true;
            result = response.data.map(mapWpPost);
          }
        })
        .catch((reason: AxiosError) => {
          reason.response?.status === 404 && (isCached = false);
        }));

    !isCached &&
      (await axios
        .get(this.generateSinglePostUrl())
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            result = response.data.map(mapWpPost);
          }
        })
        .catch((reason: AxiosError) => {
          throw new Error(reason.message);
        }));

    if (result.length !== 0) {
      return result;
    }
    throw new Error('request failed');
  }

  async fetch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-fc-page');
    const response = await axios.get(this.generateUrl());
    if (response.status === 200) {
      this.result = response.data.map(mapWpPost);
      return;
    }
    throw new Error('request failed');
  }
}
