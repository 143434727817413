import React, { useState, useEffect } from 'react';
import { PostTypesType } from '../../types/PostTypes';

function parseRobots(post: PostTypesType | null): string {
  if (post === null || !post.meta || !post.meta.robots) return '';
  return Object.values(post.meta.robots).join(', ');
}

function parseTwitterMisc(post: PostTypesType | null): (React.ReactNode | undefined)[] {
  const result: React.ReactNode[] = [];
  if (post === null || !post?.meta || !post.meta.twitter_misc) return result;

  Object.entries(post.meta.twitter_misc).forEach(([key, value]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result.push(<meta key={`${key}`} name={key} content={value} />);
  });
  return result;
}

function parseMetaData(
  currentPost: PostTypesType | null,
  parsedRobotsContent: string,
  parsedTwitterMisc: (React.ReactNode | undefined)[],
): (React.ReactNode | undefined)[] {
  return [
    currentPost?.meta.robots ? <meta key={'metaRobots'} name="robots" content={parsedRobotsContent} /> : undefined,
    currentPost?.meta.title ? <title key={'currentPost.meta.title'}>{currentPost.meta.title}</title> : undefined,
    currentPost?.meta.description ? (
      <meta key={'currentPost.meta.description'} name="description" content={currentPost.meta.description} />
    ) : undefined,
    currentPost?.meta.canonical ? (
      <link key={'currentPost.meta.canonical'} rel="canonical" href={currentPost.meta.canonical} />
    ) : undefined,
    currentPost?.meta.og_locale ? (
      <meta key={'currentPost.meta.og_locale'} property="og:locale" content={currentPost.meta.og_locale} />
    ) : undefined,
    currentPost?.meta.og_type ? (
      <meta key={'currentPost.meta.og_type'} property="og:type" content={currentPost.meta.og_type} />
    ) : undefined,
    currentPost?.meta.og_title ? (
      <meta key={'currentPost.meta.og_title'} property="og:title" content={currentPost.meta.og_title} />
    ) : undefined,
    currentPost?.meta.og_description ? (
      <meta
        key={'currentPost.meta.og_description'}
        property="og:description"
        content={currentPost.meta.og_description}
      />
    ) : undefined,
    currentPost?.meta.og_url ? (
      <meta key={'currentPost.meta.og_url'} property="og:url" content={currentPost.meta.og_url} />
    ) : undefined,
    currentPost?.meta.og_site_name ? (
      <meta key={'currentPost.meta.og_site_name'} property="og:site_name" content={currentPost.meta.og_site_name} />
    ) : undefined,
    currentPost?.meta.article_published_time ? (
      <meta
        key={'currentPost.meta.article_published_time'}
        property="article:published_time"
        content={currentPost.meta.article_published_time}
      />
    ) : undefined,
    currentPost?.meta.article_modified_time ? (
      <meta
        key={'currentPost.meta.article_published_time'}
        property="article:modified_time"
        content={currentPost.meta.article_published_time}
      />
    ) : undefined,
    currentPost?.meta.og_image ? (
      <meta key={'currentPost.meta.og_image.url'} property="og:image" content={currentPost.meta.og_image[0].url} />
    ) : undefined,
    currentPost?.meta.og_image ? (
      <meta
        key={'currentPost.meta.og_image.width'}
        property="og:image:width"
        content={currentPost.meta.og_image[0].width}
      />
    ) : undefined,
    currentPost?.meta.og_image ? (
      <meta
        key={'currentPost.meta.og_image.height'}
        property="og:image:height"
        content={currentPost.meta.og_image[0].height}
      />
    ) : undefined,
    currentPost?.meta.twitter_card ? (
      <meta key={'currentPost.meta.twitter_card'} name="twitter:card" content={currentPost.meta.twitter_card} />
    ) : undefined,
    parsedTwitterMisc ? parsedTwitterMisc.map((item) => item) : undefined,
    currentPost?.meta.schema ? (
      <script key={'schema'} type="application/ld+json">
        {JSON.stringify(currentPost.meta.schema)}
      </script>
    ) : undefined,
  ];
}

export default function useParseWpMetaData(
  post: PostTypesType | null,
): [(React.ReactNode | undefined)[], (arg0: PostTypesType) => void] {
  /// initialize state with post
  const [currentPost, setCurrentPost] = useState<PostTypesType | null>(() => post);
  const [parsedRobotsContent, setParsedRobotsContent] = useState<string>(() => parseRobots(currentPost));
  const [parsedTwitterMisc, setParsedTwitterMisc] = useState<(React.ReactNode | undefined)[]>(() =>
    parseTwitterMisc(currentPost),
  );
  const [parsedMetaData, setParsedMetaData] = useState<(React.ReactNode | undefined)[]>(() =>
    parseMetaData(currentPost, parsedRobotsContent, parsedTwitterMisc),
  );
  useEffect(() => {
    setParsedTwitterMisc(parseTwitterMisc(post));
    setParsedRobotsContent(parseRobots(currentPost));
  }, [currentPost]);

  useEffect(() => {
    setParsedMetaData(parseMetaData(currentPost, parsedRobotsContent, parsedTwitterMisc));
  }, [parsedTwitterMisc, parsedRobotsContent]);

  function setPost(newPost: PostTypesType) {
    if (currentPost !== newPost) {
      setCurrentPost(newPost);
    }
  }

  return [parsedMetaData, setPost];
}
