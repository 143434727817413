import { FcPostOptions, FcOrderEnum } from './FcWpPostOptions';
import axios from 'axios';
import mapWpPost from '../mappers/mapFcWpPost';
import { PagingInfoType, PostType, TagType } from '../../../types/PostTypes';
import mapWpPagingInfo from '../../wordpress/mappers/mapWpPagingInfo';
import { FcWpRawPost } from '../types/fcWpRawTypes';
import mapFcWpTag from '../mappers/mapFcWpTag';

const API_POSTS = '/wp-json/fc_api/v1/posts';
const API_SEARCH = '/wp-json/fc_api/v1/search';

export default class FcPostsApi {
  private options: FcPostOptions;
  public result?: PostType[];
  public pagingInfo?: PagingInfoType;
  public topTags?: TagType[];

  constructor(private readonly wpHost: string) {
    this.options = {
      order: FcOrderEnum.Desc,
      posts: [],
      embed: 1,
    };
  }

  arePopular(): FcPostsApi {
    this.options.range = 'last30days';
    return this;
  }

  areTrending(): FcPostsApi {
    this.options.range = 'last7days';
    return this;
  }

  setSlug(slug: string): FcPostsApi {
    this.options.slug = slug;
    return this;
  }

  orderBy(order: FcOrderEnum): FcPostsApi {
    this.options.order = order;
    return this;
  }

  setPage(pageNumber: number): FcPostsApi {
    this.options.pageNumber = pageNumber;
    return this;
  }

  setPerPage(perPage: number): FcPostsApi {
    this.options.perPage = perPage;
    return this;
  }

  setLanguage(language: string): FcPostsApi {
    this.options.language = language;
    return this;
  }

  filterByAuthor(author: number | string): FcPostsApi {
    this.options.author = author;
    return this;
  }

  filterByCategory(category: number | string): FcPostsApi {
    this.options.category = category;
    return this;
  }

  filterByTag(tag: number | string): FcPostsApi {
    this.options.tag = tag;
    return this;
  }

  filterByPost(post: number | string): FcPostsApi {
    this.options.posts.push(post);
    return this;
  }

  filterBySearchTerm(searchTerm: string): FcPostsApi {
    this.options.search = searchTerm;
    return this;
  }

  generateUrl(): string {
    let result: string[] = [];
    result.push('order=' + this.options.order);

    if (this.options.embed === 1) {
      result = [...result, '_embed=1'];
    }

    if (this.options.author) {
      result = [...result, 'author=' + this.options.author];
    }
    if (this.options.tag) {
      result = [...result, 'tag=' + this.options.tag];
    }
    if (this.options.category) {
      result = [...result, 'category=' + this.options.category];
    }
    if (this.options.posts.length > 0) {
      result = [...result, ...this.options.posts.map(this.mapCreateKey('includes'))];
    }
    if (this.options.perPage) {
      result = [...result, 'per_page=' + this.options.perPage];
    } else {
      result = [...result, 'per_page=' + 11];
    }
    if (this.options.pageNumber) {
      result = [...result, 'page=' + this.options.pageNumber];
    }
    if (this.options.slug) {
      result = [...result, 'slug=' + this.options.slug];
    }
    if (this.options.search) {
      result = [...result, 'search=' + encodeURI(this.options.search)];
    }
    if (this.options.range) {
      result = [...result, 'most_read=1', 'range=' + this.options.range];
    }

    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';
    return this.wpHost + lang + API_POSTS + '?' + result.join('&');
  }

  generateSinglePostUrl(): string {
    let result: string[] = [];
    if (this.options.slug) {
      result = [...result, 'slug=' + this.options.slug];
    }
    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';
    return this.wpHost + lang + API_POSTS + '?' + result.join('&');
  }

  generateSearchUrl(): string {
    let result: string[] = [];

    if (this.options.search) {
      result = [...result, 'search=' + this.options.search];
    }
    if (this.options.pageNumber) {
      result = [...result, 'page=' + this.options.pageNumber];
    }
    result = [...result, 'subtype=post'];

    if (this.options.perPage) {
      result = [...result, 'per_page=' + this.options.perPage];
    } else {
      result = [...result, 'per_page=' + 11];
    }

    const lang = this.options.language && this.options.language !== 'en' ? '/' + this.options.language : '';
    return this.wpHost + lang + API_SEARCH + '?' + result.join('&');
  }

  mapTopTags(posts: FcWpRawPost[]): TagType[] {
    if (posts.length > 0) {
      if (posts[0].top_tags && posts[0].top_tags.length > 0) {
        return posts[0].top_tags.map(mapFcWpTag);
      }
    }
    return [];
  }

  mapCreateKey(keyName: string): (id: number | string) => string {
    return (id: number | string): string => `${keyName}[]=${id}`;
  }

  async fetchSingle(): Promise<PostType[]> {
    ENV_SSR && console.log('SSR-fetch-fc-single_post');
    const response = await axios.get(this.generateSinglePostUrl());
    if (response.status === 200) {
      return response.data.map(mapWpPost);
    }
    throw new Error('request failed');
  }

  async fetch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-fc-posts');
    const response = await axios.get(this.generateUrl());
    if (response.status === 200) {
      this.result = response.data.map(mapWpPost);
      this.pagingInfo = mapWpPagingInfo(response.headers);
      this.topTags = this.mapTopTags(response.data);
      return;
    }
    throw new Error('request failed');
  }

  async fetchSearch(): Promise<void> {
    ENV_SSR && console.log('SSR-fetch-fc-posts_search');
    const response = await axios.get(this.generateSearchUrl());
    if (response.status === 200) {
      this.result = response.data.map(mapWpPost);
      this.pagingInfo = mapWpPagingInfo(response.headers);
      return;
    }
    throw new Error('request failed');
  }
}
