import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ProductPageSkeleton(): JSX.Element {
  return (
    <div>
      <div className={'ProductPage--Content'}>
        <h1>
          <Skeleton />
        </h1>
        <p>
          <Skeleton count={3} />
        </p>
        <p>
          <Skeleton count={4} />
        </p>
        <p>
          <Skeleton count={3} />
        </p>
        <p>
          <Skeleton count={2} />
        </p>
        <h2>
          <Skeleton />
        </h2>
        <p>
          <Skeleton count={3} />
        </p>
        <p>
          <Skeleton count={4} />
        </p>
        <p>
          <Skeleton count={3} />
        </p>
        <p>
          <Skeleton count={2} />
        </p>
      </div>
    </div>
  );
}
