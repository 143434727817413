export enum RequestStateEnum {
  INIT = 'init',
  RUNNING = 'running',
  FULFILLED = 'fulfilled',
  FAILED = 'failed',
}

export type RequestInfo = {
  state: RequestStateEnum;
  lastRunTimestamp: number;
  lastFinishedTimestamp: number;
  errorMessage: string;
};

const newObject = () =>
  ({
    state: RequestStateEnum.INIT,
    lastRunTimestamp: 0,
    lastFinishedTimestamp: 0,
    errorMessage: '',
  } as RequestInfo);

type IndexedRequestInfo = {
  [id: string]: RequestInfo;
};

export interface RequestsState {
  requests: IndexedRequestInfo;
}

export const RequestsQueries = {
  getRequests: (state: RequestsState): IndexedRequestInfo => state.requests,
};

export const RequestsReducers = {
  startRequest: (state: RequestsState, requestId: string): void => {
    state.requests[requestId] = state.requests[requestId] || newObject();
    state.requests[requestId] = {
      ...state.requests[requestId],
      state: RequestStateEnum.RUNNING,
      lastRunTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  fulfilledRequest: (state: RequestsState, requestId: string): void => {
    state.requests[requestId] = state.requests[requestId] || newObject();
    state.requests[requestId] = {
      ...state.requests[requestId],
      state: RequestStateEnum.FULFILLED,
      lastFinishedTimestamp: Math.floor(Date.now() / 1000),
      errorMessage: '',
    };
  },

  failedRequest: (state: RequestsState, requestId: string): void => {
    state.requests[requestId] = state.requests[requestId] || newObject();
    state.requests[requestId] = {
      ...state.requests[requestId],
      state: RequestStateEnum.FAILED,
      lastFinishedTimestamp: Math.floor(Date.now() / 1000),
      errorMessage: 'error',
    };
  },
};
