import {
  LanguageWrapper,
  LIndexedObjects,
  LIndexedObjectsByString,
  LPagedObjects,
  PagedObjects,
} from '../../types/StoreTypes';
import { PagingInfoType, TagType } from '../../types/PostTypes';

export interface TagsState {
  latestTags: LPagedObjects<number[]>;
  latestTagsPaging: LanguageWrapper<PagingInfoType>;
  tagsBySearchTerm: LIndexedObjectsByString<PagedObjects<number[]>>;
  topTagsForCategory: LIndexedObjects<number[]>;
  tagsBySearchTermPaging: LIndexedObjectsByString<PagingInfoType>;
}

export const TagsQueries = {
  getAvailablePagesForLatest: (state: TagsState): number[] => Object.keys(state.latestTags).map(Number),
  getLatestTags: (state: TagsState): LPagedObjects<number[]> => state.latestTags,
  getLatestPagingInfo: (state: TagsState): LanguageWrapper<PagingInfoType> => state.latestTagsPaging,
  getTopTagsForCategory: (state: TagsState): LIndexedObjects<number[]> => state.topTagsForCategory,
  getTagsBySearchTerm: (state: TagsState): LIndexedObjectsByString<PagedObjects<number[]>> => state.tagsBySearchTerm,
  getTagsBySearchTermPagingInfo: (state: TagsState): LIndexedObjectsByString<PagingInfoType> =>
    state.tagsBySearchTermPaging,
};

export const TagsReducers = {
  setLatest: (state: TagsState, language: string, pageNumber: number, tags: TagType[]): void => {
    state.latestTags[language] = state.latestTags[language] || {};
    state.latestTags[language][pageNumber] = tags.map((tag: TagType) => tag.id);
  },
  setLatestPagingInfo: (state: TagsState, pagingInfo: PagingInfoType, language: string): void => {
    state.latestTagsPaging[language] = pagingInfo;
  },
  setTopTagsForCategory: (state: TagsState, forCategoryId: number, tags: number[], language: string): void => {
    state.topTagsForCategory[language] = state.topTagsForCategory[language] || {};
    state.topTagsForCategory[language][forCategoryId] = tags;
  },
  setTagsBySearchTerm: (
    state: TagsState,
    searchTerm: string,
    pageNumber: number,
    tags: TagType[],
    language: string,
  ): void => {
    state.tagsBySearchTerm[language] = state.tagsBySearchTerm[language] || {};
    state.tagsBySearchTerm[language][searchTerm] = state.tagsBySearchTerm[language][searchTerm] || {};
    state.tagsBySearchTerm[language][searchTerm][pageNumber] = tags.map((tag: TagType) => tag.id);
  },
  setTagsBySearchTermPagingInfo: (state: TagsState, id: string, pagingInfo: PagingInfoType, language: string): void => {
    state.tagsBySearchTermPaging[language] = state.tagsBySearchTermPaging[language] || {};
    state.tagsBySearchTermPaging[language][id] = pagingInfo;
  },
};
