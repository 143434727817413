import React from 'react';
import IconLink, { IconFillEnum } from '../../../simple/IconLink';
import { ButtonTypeEnum } from '../../../simple/Button';
import LinkedIn from '../../../../assets/svg/in.svg';
import Twitter from '../../../../assets/svg/tw.svg';
import Facebook from '../../../../assets/svg/fb.svg';
import Send from '../../../../assets/svg/send.svg';
import useShareOnFacebookOnClick from '../../../../core/Hooks/SocialSharing/useShareOnFacebookOnClick';
import useShareOnLinkedInOnClick from '../../../../core/Hooks/SocialSharing/useShareOnLinkedInOnClick';
import useShareOnTwitterOnClick from '../../../../core/Hooks/SocialSharing/useShareOnTwitterOnClick';
import useShareOnTelegramOnClick from '../../../../core/Hooks/SocialSharing/useShareOnTelegramOnClick';
import { useLocation } from 'react-router-dom';
import { useTranslateMessage } from '../../../../core/i18/LanguageContextProvider';

export default function PostDetailShareArticle(): JSX.Element {
  const location = useLocation();
  const t = useTranslateMessage();
  const [shareOnFacebookUrl, shareOnFacebookOnClick] = useShareOnFacebookOnClick(ENV_DOMAIN_URL, location.pathname);
  const [shareOnLinkedInUrl, shareOnLinkedOnInClick] = useShareOnLinkedInOnClick(ENV_DOMAIN_URL, location.pathname);
  const [shareOnTwitterUrl, shareOnTwitterOnClick] = useShareOnTwitterOnClick(ENV_DOMAIN_URL, location.pathname);
  const [shareOnTelegramUrl, shareOnTelegramOnClick] = useShareOnTelegramOnClick(ENV_DOMAIN_URL, location.pathname);

  return (
    <div className={'PostDetailShareArticle py-8'}>
      <p className={'pb-5 pb-md-0'}>
        {t('socials.normal')} <span>{t('socials.highlight')}</span>
      </p>
      <div className={'PostDetailShareArticle--Icons'}>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS} mr-2`}
          href={shareOnLinkedInUrl}
          onClick={shareOnLinkedOnInClick}
        >
          <LinkedIn />
        </IconLink>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS} mr-2`}
          href={shareOnFacebookUrl}
          onClick={shareOnFacebookOnClick}
        >
          <Facebook />
        </IconLink>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS} mr-2`}
          href={shareOnTwitterUrl}
          onClick={shareOnTwitterOnClick}
        >
          <Twitter />
        </IconLink>
        <IconLink
          fill={IconFillEnum.SECONDARY}
          component={'a'}
          className={`${ButtonTypeEnum.DARK_LESS}`}
          href={shareOnTelegramUrl}
          onClick={shareOnTelegramOnClick}
        >
          <Send />
        </IconLink>
      </div>
    </div>
  );
}
